import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Datatable from '../GlobalComponents/DataTable/DataTable';
import {
  getAccounts, deactivateAccount, deleteAccount, activateAccount,
  getAllUsers,
} from '../../actions/accountActions';
import PendingInvites from './PendingInvites';
import { DropDownMenu } from '../GlobalComponents/DropDown/DropDownMenu';
import deactivateIcon from '../../assets/ico-deactivate.svg';
import activedIcon from '../../assets/ico-checked.svg';
import ConfirmDialog from '../GlobalComponents/BlueConfirmDialog';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';


class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      openModal: false,
      row: {},
    };
  }

  componentWillMount() {
    const { getAccounts } = this.props;
    getAccounts()
      .then(() => {
        this.assignAccountsToState(this.props);
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  componentDidMount() {
    const { accounts } = this.props;
    if (_.size(accounts) !== 0) {
      this.assignAccountsToState(this.props);
    }
  }

  componentWillReceiveProps(props) {
    this.assignAccountsToState(props);
  }

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  }

  openModal = (row) => {
    this.setState({
      openModal: true,
      row,
    });
  }

  showNewAccountForm = () => {
    const { history } = this.props;
    history.push('/account/new');
  }

  assignAccountsToState = (props) => {
    const accounts = _.values(props.accounts).filter(account => account.status !== 'REMOVED');
    this.setState({
      accounts: _.sortBy(accounts, acc => acc.name),
    });
  }

  deactivate = (row) => {
    const data = {
      id: row._id,
    };
    const { deactivateAccount } = this.props;
    deactivateAccount(data)
      .then((response) => {
        showNotification({ message: response.message, type: 'success' })
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  activate = (row) => {
    const data = {
      id: row._id,
    };
    const { activateAccount } = this.props;
    activateAccount(data)
      .then((response) => {
        showNotification({ message: response.message, type: 'success' })
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  deleteAccount = (row) => {
    const data = {
      id: row._id,
    };
    const { deleteAccount, getAllUsers } = this.props;
    deleteAccount(data)
      .then(() => {
        getAllUsers();
        showNotification({ message: 'Account Deleted Successfully.', type: 'success' })
        this.setState({
          openModal: false,
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  setDropdowMenu = (cell, row) => {
    const { history } = this.props;
    const menuOptions = [
      {
        text: 'Edit',
        icon: faEdit,
        action: () => history.push(`/account/edit/${row._id}`),
      },
      {
        text: 'Remove',
        icon: faTrash,
        action: () => this.openModal(row),
      },
    ];
    if (row.status === 'ACTIVE') {
      menuOptions[2] = {
        text: 'Deactivate',
        imageIcon: true,
        icon: deactivateIcon,
        action: () => this.deactivate(row),
      };
    } else if (row.status === 'INACTIVE') {
      menuOptions[2] = {
        text: 'Active',
        imageIcon: true,
        icon: activedIcon,
        action: () => this.activate(row),
      };
    }
    return (
      <DropDownMenu
        menuOptions={menuOptions}
      />
    );
  }

  getContact = (cell, row) => {
    const contacts = [];
    row.contact.forEach((contact) => {
      contacts.push(<TableText key={contact._id}>{`${contact.first_name} ${contact.last_name}`}</TableText>);
    });
    return (
      <div>
        {contacts}
      </div>
    );
  }

  getDatabases = (cell, row) => {
    const dbs = [];
    row.databases.forEach((db, index) => {
      dbs.push(<TableText key={index}>{db.name}</TableText>);
    });
    return (
      <div>
        {dbs}
      </div>
    );
  }

  getLocation = (cell, row) => {
    const { city, state } = row;
    return `${city}, ${state}`;
  }

  getDataTableColumns = () => {
    const columns = [
      {
        dataField: 'name',
        text: 'Company',
        sort: true,
        style: {
          width: '20%',
        },
      },
      {
        dataField: 'location',
        text: 'Location',
        style: {
          width: '20%',
        },
        formatter: this.getLocation,
      },
      {
        dataField: 'contact',
        formatter: this.getContact,
        text: 'Contact',
        sort: true,
        style: {
          width: '20%',
        },
      },
      {
        dataField: 'database',
        formatter: this.getDatabases,
        text: 'Database',
        style: {
          width: '20%',
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        style: {
          width: '10%',
        },
      },
      {
        dataField: 'id',
        formatter: this.setDropdowMenu,
        style: {
          width: '10%',
        },
      },
    ];

    return columns;
  }

  render() {
    const { accounts, openModal, row } = this.state;
    const { members } = this.props;
    return (
      <ContentBody>
        <Header
          addAction={this.showNewAccountForm}
        />
        <Container>
          <Datatable keyField="_id" limit={10} columns={this.getDataTableColumns()} data={accounts} />
        </Container>
        <Container>
          <PendingInvites members={members} />
        </Container>
        <ConfirmDialog
          show={openModal}
          title="Delete Account?"
          message="Are you sure you want to delete this account?"
          onConfirm={() => this.deleteAccount(row)}
          onCancel={this.closeModal}
        />
      </ContentBody>
    );
  }
}

const ContentBody = styled.div`
  width: 84%;
  padding: 0px 8% 0 8%;
`;

const Container = styled.div`
  display: inline-block;
  width: 100%;
  padding: 20px 0 25px 0;
  ${''}
`;
const TableText = styled.p`
  line-height: 0.5;
`;

const mapStateToProps = (state) => {
  const members = [];
  _.forEach(state.AccountReducer.accounts, (acc) => {
    acc.members.forEach((member) => {
      members.push({ ...member, accountName: acc.name });
    });
  });
  return {
    accounts: state.AccountReducer.accounts,
    members,
  };
};

const mapDispatchToProps = dispatch => ({
  getAccounts: () => dispatch(getAccounts()),
  deactivateAccount: data => dispatch(deactivateAccount(data)),
  activateAccount: data => dispatch(activateAccount(data)),
  deleteAccount: data => dispatch(deleteAccount(data)),
  getAllUsers: () => dispatch(getAllUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);

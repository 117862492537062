import _ from 'lodash';

export const setLabelsAction = payload => (dispatch, getState) => {
  const labels = [];
  const { Data } = getState().spreadsheet;
  Data.Tabs[0].Targets.forEach((target) => {
    labels.push(target.targetName);
  });


  payload.map((pl) => {
    pl.labels = labels;
    return pl;
  });

  dispatch({ type: 'SET_SPREADSHEET_CHART_LABELS', payload });
};
export const setSpreadsheetChartsLoaded = payload => ({ type: 'SET_SPREADSHEET_CHARTS_LOADED', payload });

export const resetSpreadSheetCharts = () => ({ type: 'RESET_SPREADSHEET_CHARTS' });

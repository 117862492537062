import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const getIcon = (info, { left, right }) => {
  if (info) {
    const { icon, type, style } = info;
    if (icon) {
      if (type === 'font') {
        return (
          <Icon
            rightMargin={right}
            leftMargin={left}
            icon={icon}
            style={style || {}}
          />
        );
      }
      if (type === 'img') {
        return (
          <ImageIcon
            rightMargin={right}
            leftMargin={left}
            src={icon}
            style={style || {}}
          />
        );
      }
    }
  }
  return null;
};

export const ToolbarButton = ({
  text,
  leftIcon,
  rightIcon,
  ...props
}) => {
  const leftSideIcon = getIcon(leftIcon, { right: true });
  const rightSideIcon = getIcon(rightIcon, { left: true });
  return (
    <Button {...props} className="toolbar-button">
      {leftSideIcon}
      <span>{text}</span>
      {rightSideIcon}
    </Button>
  );
};

ToolbarButton.propTypes = {
  leftIcon: PropTypes.instanceOf(Object),
  rightIcon: PropTypes.instanceOf(Object),
  text: PropTypes.string.isRequired,
};

ToolbarButton.defaultProps = {
  leftIcon: null,
  rightIcon: null,
};

const Button = styled.button`
  height: 100%;
  border-style: none;
  border: none;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  font-family: Roboto;
  font-weight: 400;
  font-size: 12px;
  color: #37474f;
  background-color: ${props => (props.active ? '#E2E7EA' : 'white')};
  cursor: pointer;
  padding: 3px 8px 2px 8px;
  outline: none;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: normal !important;
    opacity: 0.4 !important;
  }
  &:hover {
    background-color: #e2e7ea !important;
  }
`;

const ImageIcon = styled.img`
  margin-right: ${({ rightMargin }) => (rightMargin ? '5px' : '0px')};
  margin-left: ${({ leftMargin }) => (leftMargin ? '5px' : '0px')};
`;

const Icon = styled(({ rightMargin, leftMargin, ...props }) => (
  <FontAwesomeIcon {...props} />
))`
  font-size: 13px;
  margin-right: ${({ rightMargin }) => (rightMargin ? '5px' : '0px')};
  margin-left: ${({ leftMargin }) => (leftMargin ? '5px' : '0px')};
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

class TableBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      data: props.data,
      arrows: _.keyBy(this.addArrowIcon(faAngleDown), 'id'),
      orderSelected: {},
      limit: props.limit || 20,
    };
  }

  componentWillReceiveProps(props) {
    const { orderSelected } = this.state;
    const { keepSortingOnChange } = props;
    let dataFromProps = props.data;
    if (keepSortingOnChange) {
      if (_.size(orderSelected) !== 0) {
        const { key, sorted } = orderSelected;
        dataFromProps = _.orderBy(dataFromProps, key, sorted ? 'desc' : 'asc');
      }
    }
    this.setState({
      data: dataFromProps,
    });
  }

  showMoreClick = () => {
    const { limit } = this.state;
    this.setState({ limit: limit + 10 });
  }

  setColumns = (data, columns) => {
    const { limit } = this.state;
    const limitOfRows = limit;
    const rows = [];
    data.forEach((value, dataIndex) => {
      if (dataIndex < limitOfRows) {
        rows.push((
          <Row key={dataIndex}>
            {
              columns.map((column, index) => (
                <Column key={index} style={{ ...column.columnStyle }}>
                  {
                      column.formatter
                        ? column.formatter(!column.dataField ? value[column.dataField] : column.dataField, value, dataIndex)
                        : value[column.dataField]
                    }
                </Column>
              ))
            }
          </Row>
        ));
      }
    });
    return rows;
  }

  getTableHeader = (columns, arrows) => (
    <Row bgColor="transparent">
      {
        columns.map((value, index) => (
          <Column key={index} style={{ ...value.style, color: '#607D8B', fontSize: '13px' }}>
            {
                value.sort
                  ? (
                    <Button
                      key={index}
                      value={index}
                      onClick={e => this.changeArrowOrder(e, value)}
                      style={{
                        color: '#607D8B',
                        fontSize: '13px',
                        paddingLeft: 0,
                        cursor: 'pointer',
                      }}
                    >
                      <span
                        style={{
                          color: '#607D8B', fontSize: '13px',
                        }}
                      >
                        {value.text}
                      </span>
                      <FontAwesomeIcon style={{ color: '#607D8B', fontSize: '13px', marginLeft: '5px' }} icon={arrows[index].icon} />
                    </Button>
                  ) : value.text
              }
          </Column>
        ))
        }
    </Row>
  )

  addArrowIcon = arrowIcon => this.props.columns.map((value, index) => ({
    id: index,
    field: value.dataField,
    icon: arrowIcon,
    sorted: false,
  }))

  changeArrowOrder = (e, value) => {
    const index = e.currentTarget.value;
    const { arrows, data } = this.state;
    const { sorted } = arrows[index];
    const key = arrows[index].field;
    this.setState({
      orderSelected: {
        key,
        sorted,
      },
    });
    const newData = _.orderBy(data, key, sorted ? 'desc' : 'asc');
    this.setState({
      data: newData,
      arrows: {
        ...this.state.arrows,
        [index]: {
          ...arrows[index],
          icon: sorted ? faAngleDown : faAngleUp,
          sorted: !sorted,
        },
      },
    });
  }

  render() {
    const {
      data, columns, arrows, limit,
    } = this.state;
    const { showHeader } = this.props;
    return (
      <React.Fragment>
        <Table>
          {
          showHeader
          && (
          <Header>
            {this.getTableHeader(columns, arrows)}
          </Header>
          )}
          <Body>
            {this.setColumns(data, columns)}
          </Body>
        </Table>
        {
          (data.length > limit)
          && (
          <ShowMoreButton onClick={this.showMoreClick}>
            Show more
            {' '}
            <FontAwesomeIcon icon={faAngleDown} />
          </ShowMoreButton>
          )
        }
      </React.Fragment>
    );
  }
}

const ShowMoreButton = styled.div`
  margin: 10px 10px;
  color: #19A2C5;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Roboto';
`;
const Header = styled.thead`
  font-size: 0.92em;
  font-weight: 400;
  color: #607D8B;
`;
const Body = styled.tbody`
  border: 1px solid rgba(0,0,0,0.2);
  font-size: 0.9em;
`;
const Row = styled.tr`
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  border-bottom: 1px solid rgba(0,0,0,0.2);
  height: 35px !important;
`;
const Column = styled.td`
  min-width: auto;
  padding: 0 0 0 10px;
  ${({ noPadding }) => noPadding && 'padding: 0px;'}
  color: #37474F;
  font-size: 13px;
`;

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: rgba(0,0,0,0.8);
`;

TableBody.propTypes = {
  data: propTypes.array,
  columns: propTypes.array,
  showHeader: propTypes.bool,
  keepSortingOnChange: propTypes.bool,
};

TableBody.defaultProps = {
  showHeader: true,
  keepSortingOnChange: true,
};

export default TableBody;

import styled from 'styled-components'

export const TableHeader = styled.div`
  box-sizing: border-box;
  background-color: #F6F7F8;
  overflow: hidden;
  position: absolute;
  min-height: 40px;
  height: 150px;
  max-height: 148px;
  width: ${({ newWidth }) => (newWidth ? newWidth : '100%')};
  white-space: nowrap;
  margin-top: -160px;
  border-bottom: 2px solid #E2E7EA;
`;

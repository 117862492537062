import React, { Component } from 'react';
import styled from 'styled-components';


class TitleBar extends Component {
  render() {
    return (
      <Menu>
        <Title>
          Add Questions
        </Title>
      </Menu>
    );
  }
}

const Menu = styled.div`
  display: block;
  padding: 14px 32px;
`;

const Title = styled.div`
  height: 24px;
  width: 134px;
  color: #37474F;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.13px;
  line-height: 24px;
`;

export default TitleBar;

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getMembers, sendInvitations, cancelInvitation } from '../../actions/teamActions';
import Datatable from '../GlobalComponents/DataTable/DataTable';
import { Button, Row, Col } from '../GlobalComponents/BasicControls';
import { getAllUsers } from '../../actions/accountActions';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

class PendingInvites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      rows: {},
      disableButtons: false,
    };
  }

  componentWillMount() {
    const { getAllUsers, getMembers, user } = this.props;
    getAllUsers()
      .then(() => {
        const { teamId } = user;
        getMembers(teamId._id)
          .then(() => {
            this.assignMembersToState(this.props);
          })
          .catch((err) => {
            const { message, type } = getErrorMessage(err);
            showNotification({ message, type });
          });
      });
  }

  componentDidMount() {
    const { members } = this.props;
    if (_.size(members) !== 0) {
      this.assignMembersToState(this.props);
    }
  }

  componentWillReceiveProps(props) {
    this.assignMembersToState(props);
  }

  assignMembersToState = (props) => {
    const filteredMembers = _.values(props.members).filter(member => member.status === 'INVITED');
    const members = filteredMembers.map(member => ({
      ...member,
      accepted: member.accepted,
      user_type: member.user_type,
    }));

    this.setState({
      members,
    });
  }

  handleSelection = (e, row) => {
    if (e.currentTarget.checked) {
      const { rows } = this.state;
      this.setState({
        rows: {
          ...rows,
          [row._id]: row,
        },
      });
    } else {
      const { rows } = this.state;
      const newRows = _.filter(rows, o => o._id.toString() !== row._id.toString());
      this.setState({
        rows: _.keyBy(newRows, '_id'),
      });
    }
  }

  selectRow = (cell, row, index) => (
    <input
      key={index}
      className="checkbox-in-table"
      onChange={e => this.handleSelection(e, row)}
      name="selected"
      type="checkbox"
    />
  )

  uncheckInputs = () => {
    const checkboxes = document.getElementsByClassName('checkbox-in-table');
    for (let i = 0; i < checkboxes.length; i += 1) {
      const checkbox = checkboxes[i];
      checkbox.checked = false;
    }
  }

  resendInvitation = () => {
    const { rows } = this.state;
    const sizeOfMembers = _.size(rows);
    if (sizeOfMembers === 0) {
      showNotification({ message: 'Please, select a receiver', type: 'warning' });
    } else {
      this.setState({ disableButtons: true });
      const { rows } = this.state;
      const { user, sendInvitations } = this.props;
      const data = {
        teamId: user.teamId._id,
        rows,
      };
      sendInvitations(data)
        .then((response) => {
          const errorInvites = response.sent.filter(result => result.accepted.length > 0);
          if (errorInvites.length === 0) {
            this.setState({
              disableButtons: false,
              rows: {},
            }, () => {
              this.uncheckInputs();
            });
            showNotification({ message: 'Some invitations were not sent', type: 'warning' });
          } else {
            this.setState({
              disableButtons: false,
              rows: {},
            }, () => {
              this.uncheckInputs();
            });
            showNotification({ message: 'Invitations were sent successfully', type: 'success' });
          }
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type });
          this.setState({
            disableButtons: false,
          });
        });
    }
  }

  cancelInvitation = () => {
    const { rows } = this.state;
    const sizeOfMembers = _.size(rows);
    if (sizeOfMembers === 0) {
      showNotification({ message: 'Please, select a team member', type: 'warning' });
    } else {
      const { rows } = this.state;
      const { user, cancelInvitation } = this.props;

      const data = {
        teamId: user.teamId._id,
        rows,
      };
      cancelInvitation(data)
        .then(() => {
          this.setState({
            rows: {},
          }, () => {
            this.uncheckInputs();
          });
          showNotification({ message: 'Invitations were canceled successfully', type: 'success' });
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type });
        });
    }
  }

  render() {
    const columns = [
      {
        dataField: 'first_name',
        text: 'First Name',
        sort: true,
        columnStyle: {
          width: '15%',
        },
      },
      {
        dataField: 'last_name',
        text: 'Last Name',
        sort: true,
        columnStyle: {
          width: '15%',
        },
      },
      {
        dataField: 'email',
        text: 'Email',
        columnStyle: {
          width: '30%',
        },
      },
      {
        dataField: 'accountName',
        text: 'Account',
        columnStyle: {
          width: '20%',
        },
      },
      {
        dataField: 'first_name',
        text: '',
        columnStyle: {
          width: '20%',
          padding: '0 0 0 0',
          textAlign: 'center',
        },
        formatter: this.selectRow,
      },
    ];

    const { members, disableButtons } = this.state;
    return (
      <Wrapper>
        <Title>
          Pending Invites
        </Title>
        <Content>
          <DataTableContainer>
            <Datatable showHeader={false} columns={columns} data={members} />
          </DataTableContainer>
          <Options>
            <Row>
              <Col size={4}>
                <Button
                  disabled={disableButtons}
                  onClick={this.resendInvitation}
                  type="button"
                  tColor="rgba(0,0,0,0.6)"
                >
                Resend
                </Button>
              </Col>
              <Col size={8}>
                <Button
                  disabled={disableButtons}
                  onClick={this.cancelInvitation}
                  type="button"
                  tColor="rgba(0,0,0,0.6)"
                >
                Cancel Invitation
                </Button>
              </Col>
            </Row>
          </Options>
        </Content>
      </Wrapper>
    );
  }
}

const Options = styled.div`
  ${''}
  width: 25%;
`;


const Wrapper = styled.div`
    height: 80px;
    ${''}
    ${''}
    width: 100%
`;

const Title = styled.div`
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 76, 103);
    padding: 0 0 20px 0;
    ${''}
`;

const DataTableContainer = styled.div`
  width: 70%;
`;

const Content = styled.div`
  ${''}
  margin: 10px 0 0 0;
  width: 100%;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  ${''}
`;

const mapStateToProps = state => ({
  members: _.map(state.AccountReducer.users, member => ({ ...member, accountName: member.teamId ? member.teamId.name : '' })),
  user: state.AppReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getAllUsers: () => dispatch(getAllUsers()),
  getMembers: teamId => dispatch(getMembers(teamId)),
  sendInvitations: data => sendInvitations(data),
  cancelInvitation: data => dispatch(cancelInvitation(data, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingInvites);

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash/fp';
import __ from 'lodash';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import NavList from './navList';
import {
  AccountDetails, Contacts, DatabasesAndSurveys, Review,
} from './ContentParts';
import { ControlLabel } from '../../GlobalComponents/BasicControls';
import { createAccount, findIfEmailsExist } from '../../../actions/accountActions';
import SupportDocuments from './ContentParts/SupportDocuments';
import ConfirmDialog from '../../GlobalComponents/BlueConfirmDialog';
import { filesAccepted } from './filesAccepted';
import { CustomConfirmDialog } from '../../GlobalComponents/CustomConfirmDialog';
import showNotification from '../../../helpers/showNotification';

class AddUserContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalDB: false,
      openModal: false,
      reviewing: false,
      showNewFormAdded: false,
      selectedItemNav: 1,
      account: {
        logoFile: {},
        details: {
          name: '',
          city: '',
          state: '',
        },
        contact: {
          0: {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            role: 'owner',
          },
        },
        databaseForms: [
          {
            databaseName: 'Manager',
            accesses: ['Manager', 'Business Owner'],
          },
          {
            databaseName: 'Sales',
            accesses: ['User', 'Manager', 'Business Owner'],
          },
        ],
        databases: {
          edit: false,
          0: {
            name: '',
            database: {},
            _type: 1,
            survey: {
              current: '',
              older: '',
            },
          },
          1: {
            name: '',
            database: {},
            _type: 2,
            survey: {
              current: '',
              older: '',
            },
          },
        },
        support: {
          edit: false,
          coding: '',
          listing: '',
          distribution: '',
          methodology: '',
          descriptions: '',
        },
      },
    };
    this.setState = this.setState.bind(this);
  }

  handleSubmit = () => {
    const { selectedItemNav, reviewing } = this.state;
    this.setState({
      ...this.state,
      selectedItemNav: !reviewing ? (selectedItemNav + 1) : 5,
    });
  }

 handleNavItem = (e) => {
   const { value } = e.currentTarget;
   this.setState({
     selectedItemNav: parseInt(value, 10),
   });
 }

  openModal = () => {
    this.setState({
      openModal: true,
    });
  }

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  }

  getDocumentDescription = (key) => {
    let description = '';
    switch (key) {
      case 'descriptions':
        description = 'Descriptions of psychographic groups and guide to implementing them.';
        break;
      case 'methodology':
        description = 'Detailed information on how and when your studies were conducted.';
        break;
      case 'distribution':
        description = 'Population data used to determine county, gender, and race (if applicable) quotas.';
        break;
      case 'listing':
        description = 'Verbatim answers classified as “Other.”  Potentially a source for new leads.';
        break;
      case 'coding':
        description = 'Information on how Domestic/European/Asian Vehicle Make and White Collar/Blue Collar are defined.  Examples of the types of answers coded in Leisure Activities and Occupations questions.';
        break;
      default:
        break;
    }
    return description;
  }

 getDocuments = support => _.compact(__.map(support, (value, key) => (key !== 'edit' && value) && {
   file: value,
   name: key,
   description: this.getDocumentDescription(key),
 }))

 validateDatabasesFilesRequired = () => {
   const allDatabases = [];
   const { account } = this.state;
   const databases = __.values(account.databases);
   const selectedDatabases = _.compact(databases).filter(d => d.name && __.size(d.database) !== 0);
   if (selectedDatabases.length !== 0) {
     const AllDBSelected = selectedDatabases.map(d => __.map(d.database, data => data.name));
     AllDBSelected.forEach((value) => {
       allDatabases.push({
         databases: value,
       });
     });
   }
   let canContinue = true;
   if (allDatabases.length !== 0) {
     allDatabases.forEach((data) => {
       if (canContinue) {
         let equalDB = 0;
         data.databases.forEach((fileAdded) => {
           if (filesAccepted.includes(fileAdded)) {
             equalDB += 1;
           }
         });
         if (equalDB !== filesAccepted.length) {
           canContinue = false;
         }
       }
     });
   }
   return canContinue;
 }

 createAccount = () => {
   const {
     details, contact, databases, support, logoFile,
   } = this.state.account;
   const newDatabases = _.values(databases);
   const data = {
     userParams: _.values(contact).map(user => ({
       first_name: user.firstName,
       last_name: user.lastName,
       email: user.email,
       role: user.role,
       phone1: user.phone1,
       phone2: user.phone2,
     })),
     teamParams: {
       name: details.name,
       city: details.city,
       state: details.state,
     },
     databases: _.compact(newDatabases.filter(value => value !== true)).map((databaseInformation) => {
       const { name, survey, database } = databaseInformation;
       return {
         name,
         database,
         _type: databaseInformation._type,
         current_survey: survey.current ? survey.current : '',
         older_survey: survey.older ? survey.older : '',

       };
     }),
     documents: this.getDocuments(support).filter(file => file.file),
     logoFile,
   };
   const logoFileAdded = Boolean(logoFile.name);
   const { teamParams } = data;
   const accountDetailsAdded = teamParams.name && teamParams.city && teamParams.state;
   const accountDatabaseAdded = _.size(data.databases[1].database) !== 0 && data.databases[1].name;
   if (!accountDetailsAdded) {
     return Promise.reject(new Error('Please, complete the account details section'));
   }
   if (!accountDatabaseAdded) {
     return Promise.reject(new Error('Database 2 is required'));
   }
   if (!logoFileAdded) {
     return Promise.reject(new Error('Please, add a client logo png file'));
   }
   if (accountDetailsAdded && accountDatabaseAdded && logoFileAdded) {
     const canContinue = this.validateDatabasesFilesRequired();
     if (canContinue) {
       const { createAccount } = this.props;
       return createAccount(data);
     }
     this.toogleCustomConfirmDialog();
   }
   return Promise.reject(new Error('Please, complete the fields required'));
 }

  updateParentDatabaseState = (dbInformation) => {
    this.setState({
      account: {
        ...this.state.account,
        databases: {
          ...this.state.account.databases,
          ...dbInformation,
        },
      },
    });
  }

  goToPrevPage = () => {
    const { selectedItemNav } = this.state;
    this.setState({
      selectedItemNav: selectedItemNav - 1,
    });
  }

  onCancelCreating = () => {
    this.openModal();
  }

  onConfirmCancelCreating = () => {
    const { history } = this.props;
    history.push('/accounts');
  }

  findIfEmailsExist = (data) => {
    this.props.findIfEmailsExist(data).then((response) => {
      this.handleSubmit();
    }).catch((err) => {
      showNotification({ message: 'Email already exist', type: 'error' });
    });
  }

 toogleCustomConfirmDialog = () => {
   const { openModalDB } = this.state;
   this.setState({
     openModalDB: !openModalDB,
   });
 }

 getFormForTab = () => {
   const { selectedItemNav, account } = this.state;
   switch (selectedItemNav) {
     case 1:
       return (
         <AccountDetails
           history={this.props.history}
           setState={this.setState}
           state={account.details}
           content={this.state}
           handleSubmit={this.handleSubmit}
           onCancelCreating={this.onCancelCreating}
         />
       );
     case 2:
       return (
         <Contacts
           setState={this.setState}
           state={account.contact}
           content={this.state}
           handleSubmit={this.handleSubmit}
           goToPrevPage={this.goToPrevPage}
           findIfEmailsExist={this.findIfEmailsExist}
           onCancelCreating={this.onCancelCreating}
         />
       );
     case 3:
       return (
         <DatabasesAndSurveys
           updateParentDatabaseState={this.updateParentDatabaseState}
           setState={this.setState}
           state={account.databases}
           content={this.state}
           handleSubmit={this.handleSubmit}
           goToPrevPage={this.goToPrevPage}
           onCancelCreating={this.onCancelCreating}
         />
       );
     case 4:
       return (
         <SupportDocuments
           setState={this.setState}
           state={account.support}
           content={this.state}
           handleSubmit={this.handleSubmit}
           goToPrevPage={this.goToPrevPage}
           onCancelCreating={this.onCancelCreating}
         />
       );
     case 5:
       return (
         <Review
           history={this.props.history}
           createAccount={this.createAccount}
           setState={this.setState}
           content={this.state.account}
           onCancelCreating={this.onCancelCreating}
         />
       );
     default:
       break;
   }
 }

 render() {
   const { openModalDB, openModal } = this.state;
   return (
     <ContentBody>
       <Container className="header">
         {
           <nav className="header__nav-list">
             {
              _.map(item => (
                <li key={item.id}>
                  <NavLink
                    value={item.id}
                    onClick={e => this.handleNavItem(e)}
                    active
                  >
                    <Circle>{item.id}</Circle>
                    {item.title
                  }

                  </NavLink>
                </li>
              ))(NavList)
            }
           </nav>
        }
       </Container>
       <Container>
         {this.getFormForTab()}
       </Container>
       <ConfirmDialog
         onConfirmButtonText="Accept"
         show={openModal}
         title="Create Account"
         message="Are you sure yo want to discard your changes?"
         onConfirm={this.onConfirmCancelCreating}
         onCancel={this.closeModal}
       />
       <CustomConfirmDialog
         onConfirmButtonText="Accept"
         show={openModalDB}
         title="Warning!"
         message="Please, make sure to include the following in databases files:"
         onConfirm={this.toogleCustomConfirmDialog}
         onCancel={this.toogleCustomConfirmDialog}
       >
         {
           filesAccepted.map((value, index) => (
             <Paragraph key={index}>
               <Icon icon={faFile} />
               {' '}
               {value}
             </Paragraph>
           ))
         }
       </CustomConfirmDialog>
     </ContentBody>
   );
 }
}

const ContentBody = styled.div`
  width: 84%;
  padding: 0px 8% 0 8%;
`;

const Container = styled.div`
  display: inline-block;
  width: 100%;
  padding: 20px 0 25px 0;
  ${''}

  .header {
    &__nav-list {
      ${''}
      width: 100%;
      ${''}
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      padding: 0px;

      li {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const NavLink = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: normal;
  display: flex;
  align-items: center;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  font-weight: ${props => (props.active ? 550 : 500)};
  color: rgba(0,0,0,0.7);
  opacity: ${props => (props.active ? 1 : 0.4)};
`;

const Circle = styled.span`
  background-color: #828691;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 0 10px 0 0;
  color: white;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
`;
const Icon = styled(FontAwesomeIcon)`
  color: rgba(0,0,0,0.3);
  margin-right: 5px;
`;

const Paragraph = styled(ControlLabel)`
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding-bottom: 2px;
`;

const mapDispatchToProps = dispatch => ({
  createAccount: data => dispatch(createAccount(data)),
  findIfEmailsExist: data => dispatch(findIfEmailsExist(data)),
});

export default connect(null, mapDispatchToProps)(AddUserContent);

import React, { Component } from 'react';
import styled from 'styled-components';

const ExpandNarrow = ({ handleClick, padding, visible }) => {
  return (
    <ExpandNarrowStyle padding={padding} visible={visible}>
      <p onClick={() => handleClick('OR')}>Expand Target</p>
      <p onClick={() => handleClick('AND')}>Narrow Target {padding > 0 && ' further'}</p>
    </ExpandNarrowStyle>
  );
}

class TargetOperations extends Component {
  handleClick = (operator) => {
    const { showTree, handleShowTree } = this.props;
    this.props.actions.addBlock(operator);
    handleShowTree(showTree);
  }

  render() {
    const { padding, visible } = this.props;
    return (
      <ExpandNarrow handleClick={this.handleClick} padding={padding} visible={visible} />
    );
  }
}

const ExpandNarrowStyle = styled.div` 
  color: #19A2C5;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  margin: 0px;
  padding: 10px 0;
  position: relative;
  ${({ visible }) => `display: ${visible ? 'flex' : 'none'};`}
  ${({ padding }) => `padding-left: ${padding}px;`}
  p {
    margin: 0;
    margin-right: 15px;
    cursor: pointer;
  }
`;

export default TargetOperations;

import React, { Component } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import TitleBar from './TitleBar';
import FooterBar from './FooterBar';
import Previews from './Previews';
import TabsSection from './TabsSection';
import withActions from './withActions';

class ModalContent extends Component {
  render() {
    const {
      actions, close, data, type,
    } = this.props;
    return (
      <>
        <Body>
          <LeftSection>
            <TitleBar />
            <TabsSection actions={actions} data={data} />
          </LeftSection>
          <RightSection>
            <Previews />
            <FooterBar
              close={close}
              actions={actions}
              data={data}
              type={type}
            />
          </RightSection>
        </Body>
      </>
    );
  }
}

ModalContent.propTypes = {
  actions: propTypes.object.isRequired,
  close: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
};

const ContentWithActions = withActions(ModalContent);

const Body = styled.div`
  display: flex;
  width: 945px;
  max-height: 624px;
`;

const LeftSection = styled.div`
  width: 635px;
  height: 624px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background: #f6f7f8;
  border-right: 2px solid #dee1e3;
`;

const RightSection = styled.div`
  width: 313px;
  height: 624px;
  background: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export default ContentWithActions;

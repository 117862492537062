import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import Header from './Header';
import {
  deleteDocument, getSurveys, getTeamDocuments,
} from '../../actions/documentActions';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

class DocumentViewer extends Component {

  componentDidMount() {
    const { getSurveys, user, teamId } = this.props;
    getSurveys()
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
    if (_.size(this.props.documents) !== 0) {
      this.assignDocumentsToState(this.props);
    }

    if (user.role.name === 'admin') {
      this.props.getTeamDocuments(teamId);
    }
  }

  openSurvey = (type) => {
    const { surveys } = this.props;
    const file = new Blob([surveys[type].file], { type: 'application/pdf' });
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL, surveys[type].fileName);
  }

  getDocumentTitle = (key) => {
    let title = '';
    switch (key) {
      case 'descriptions':
        title = 'Psychographic Group Descriptions';
        break;
      case 'methodology':
        title = 'Methodology';
        break;
      case 'distribution':
        title = 'Sample Distribution';
        break;
      case 'listing':
        title = 'Others Listings';
        break;
      case 'coding':
        title = 'Coding';
        break;
      case 'Past Surveys':
        title = 'Past Surveys';
        break;
      default:
        break;
    }
    return title;
  }

  render() {
    const { documentPairs, surveys } = this.props;
    return (
      <React.Fragment>
        <Header buttonDisabled={_.isEmpty(surveys.current)} onClickButton={() => { this.openSurvey('current'); }} />
        <ContentBody>
          <Container>
            {
            documentPairs.length > 0
              ? documentPairs.map((pair) => (
                <Row>
                  {
                  pair.map(doc => (
                    <DocumentSection key={doc._id || doc.fileName}>
                      <DocumentTitle>
                        {this.getDocumentTitle(doc.name)}
                      </DocumentTitle>
                      <DocumentDescription>
                        {doc.description}
                      </DocumentDescription>
                      <DocumentDownload onClick={() => { if (doc.older) this.openSurvey('older'); }} href={doc.url} target="_blank">
                            Download PDF ({ `${doc.size} MB` })
                      </DocumentDownload>
                    </DocumentSection>
                  ))
                }
                </Row>
              ))
              : (
                <NoDocumentsText>
                  No documents to show.
                </NoDocumentsText>
              )}
          </Container>
        </ContentBody>
      </React.Fragment>
    );
  }
}
const NoDocumentsText = styled.div`
  color: #37474F;
  padding-top: 4%;
  padding-right: 8%; 
  padding-bottom: 4%;
  padding-left: 8%;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const ContentBody = styled.div`
  width: 100%;
  background-color: #F6F7F8;
  height: calc(100vh - 80px - 60px);
`;

const Container = styled.div`
  width: 100%;
  padding: 20px 0 25px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 20px 0px 20px 0px;
`;

const DocumentSection = styled.div`
  width:40%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 1px solid #CFD8DC;
`;

const DocumentTitle = styled.div`
  height: 24px;
  color: #37474F;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const DocumentDescription = styled.div`
  color: #37474F;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 20px;
`;

const DocumentDownload = styled.a`
  color: #19A2C5;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 18px;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 0px; 
`;

const mapStateToProps = (state) => {
  const { olderSurvey, currentSurvey, documents } = state.DocumentReducer;
  const { user } = state.AppReducer;
  const isAdmin = user.role.name === 'admin';
  let pairs;
  if (isAdmin) {
    pairs = _.chunk(documents, 2);
  } else {
    pairs = _.chunk(state.AppReducer.user.teamId.documents, 2);
  }
  const surveys = {};
  if (!_.isEmpty(olderSurvey)) {
    const older = {
      ...olderSurvey,
      name: 'Past Surveys',
      older: true,
      description: 'Questionnaires from previous years. Shows full question wording and how each question was asked.',
    };
    if (state.AppReducer.user.teamId.documents.length === 0 && !isAdmin) {
      pairs[0] = [];
      pairs[0][0] = older;
    } else if (isAdmin && _.size(documents) === 0) {
      pairs[0] = [];
      pairs[0][0] = older;
    } else if (pairs[pairs.length - 1][1]) {
      pairs[pairs.length] = [];
      pairs[pairs.length - 1][0] = older;
    } else {
      pairs[pairs.length - 1][1] = older;
    }
    surveys.older = olderSurvey;
  }
  if (!_.isEmpty(currentSurvey)) {
    surveys.current = currentSurvey;
  }
  return {
    documentPairs: pairs,
    surveys,
    user,
    teamId: state.AppReducer.currentAccountId,
  };
};

const mapDispatchToProps = dispatch => ({
  getSurveys: () => dispatch(getSurveys()),
  deleteDocument: data => dispatch(deleteDocument(data)),
  getTeamDocuments: teamId => dispatch(getTeamDocuments(teamId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentViewer);

import {
  ADD_SPREADSHEET_CARD,
  CHANGE_SPREADSHEET_CARD_UNIT,
  CHANGE_SPREADSHEET_CARD_TYPE,
  CHANGE_SPREADSHEET_CARD_TARGET,
  RESET_SPREADSHEET_CARDS,
} from '../actionTypes/spreadsheetCardTypes';

const defaultState = {
  cards: {},
  spreadsheetCards: {},
};

const initialCardState = {
  chartType: 'column',
  unit: 'percentages',
  target: 0,
};

const cardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_SPREADSHEET_CARD:
      return { ...state, spreadsheetCards: { ...state.spreadsheetCards, [action.payload]: initialCardState } };
    case CHANGE_SPREADSHEET_CARD_TYPE:
      return {
        ...state,
        spreadsheetCards: {
          ...state.spreadsheetCards,
          [action.payload.id]: {
            ...state.spreadsheetCards[action.payload.id],
            chartType: action.payload.type,
          },
        },
      };
    case CHANGE_SPREADSHEET_CARD_UNIT:
      return {
        ...state,
        spreadsheetCards: {
          ...state.spreadsheetCards,
          [action.payload.id]: {
            ...state.spreadsheetCards[action.payload.id],
            unit: action.payload.unit,
          },
        },
      };
    case CHANGE_SPREADSHEET_CARD_TARGET:
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload.id]: {
            ...state.cards[action.payload.id],
            target: action.payload.target,
          },
        },
      };
    case RESET_SPREADSHEET_CARDS:
      return defaultState;
    default:
      return state;
  }
};

export default cardReducer;

import React from 'react';

const checkBoxLabelStyle = {
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 400,
  color: '#37474F',
  marginLeft: '5px',
};

const CheckBoxModal = (props) => {
  const {
    handleCheckbox,
    pos,
    isChecked,
    checkBoxText,
    mergedRowListCodes,
    mergedRow,
    answerCode,
    idQueryMergeRow,
  } = props;
  const id = `cb-${pos}`;
  return (
    <label htmlFor={id} className="container">
      <span style={checkBoxLabelStyle}>
        {checkBoxText}
      </span>
      <input
        id={id}
        type="checkbox"
        onClick={event => handleCheckbox(pos, event, {
          pos,
          mergedRowListCodes,
          mergedRow,
          answerCode,
          idQueryMergeRow,
          event,
        })}
        checked={isChecked}
      />
      <span className="checkmark" />
    </label>
  );
};

export default CheckBoxModal;
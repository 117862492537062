import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import _ from 'lodash';
import styled from 'styled-components';
import Plotly from './Plotly';
import colors from './colors';
import './Chart.css';

const Plot = createPlotlyComponent(Plotly);

const plotlyData = {
  name: '',
  type: 'bar',
  x: [],
  y: [],
  marker: { color: colors[1] },
  hoverinfo: 'x+y',
  labelFont: { size: 8 },
  textfont: { size: 8 },
  hoverlabel: { namelength: 5 },
  orientation: 'h',
  sort: false,
};

const layout = {
  width: 400,
  height: 287,
  showlegend: true,
  legend: { x: 0.75, y: -0.42 },
  margin: {
 l: 30, t: 10, b: 90, r: 20 
},
  yaxis: {
    autorange: true,
    ticks: '',
    showticklabels: false,
  },
};

class BarChart extends Component {
  render() {
    const { 
data, grouped, labels: targetLabels, target 
} = this.props;
    const dataSets = data.map(({ labels, values }, index) => {
      const xValues = _.cloneDeep(values);
      const yValues = _.cloneDeep(labels);
      const dataSet = _.cloneDeep(plotlyData);
      dataSet.x = _.reverse(xValues);
      dataSet.y = _.reverse(yValues);
      dataSet.name = grouped ? targetLabels[index].name : targetLabels[target].name;
      dataSet.marker.color = grouped ? colors[index] : colors[target];
      return dataSet;
    });
    return (
      <StyledChart
        className="overview-chart"
        data={dataSets}
        layout={{ ...layout, showlegend: grouped }}
      />
    );
  }
}

const StyledChart = styled(Plot)`
  background-color: transparent;
  .plot-container > .svg-container > svg {
    overflow: visible;
  }
`;
export default BarChart;

import React,{Component} from 'react';
import styled from 'styled-components';
import LoginWindowBody from '../Login/LoginWindowBody';

class LoginWindow extends Component {

  render() {
    return (
      <div>
        <Container>
            <LoginWindowTitle>
            <BoldSpan>STOWELL</BoldSpan> DATA
            </LoginWindowTitle>
            <LoginWindowBody />
        </Container>
      </div>
    )
  }
}

const Container = styled.div`
  width: 550px;
  padding-top: 100px;
  padding-bottom: 60px;
`;

const BoldSpan = styled.span`
  font-weight: 800;
`

const LoginWindowTitle = styled.div`
   background-color:#2F3E48;
   color:white;
   font-size: 20pt;
   font-family: 'Roboto', sans-serif;
   justify-content: space-between;
   font-weight: 300;   
   padding: 14px 20px;
   border-radius: 5px 5px 0px 0px;
   max-height: 60px;
`;

export default LoginWindow;
import _ from 'lodash';
import Axios from 'axios';

import {
  GET_PROJECTS,
  DUPLICATE_PROJECT,
  DELETE_PROJECT,
  SET_SHARED_TEMPLATES,
  ADD_SHARED_TEMPLATE,
  UNSHARE_TEMPLATE,
  DELETE_SHARED_TEMPLATE,
  CLEAN_PROJECTS,
} from '../actionTypes/projectTypes';
import { setCurrentOverview } from './overviewActions';
import showNotification from '../helpers/showNotification';

const getProjectsAction = payload => ({
  type: GET_PROJECTS,
  payload,
});

const duplicateProjectAction = payload => ({
  type: DUPLICATE_PROJECT,
  payload,
});

const deleteProjectAction = payload => ({
  type: DELETE_PROJECT,
  payload,
});

const setSharedTemplates = payload => ({
  type: SET_SHARED_TEMPLATES,
  payload,
});

const addSharedTemplate = payload => ({
  type: ADD_SHARED_TEMPLATE,
  payload,
});

const unshareTemplate = payload => ({
  type: UNSHARE_TEMPLATE,
  payload,
});

const deleteSharedTemplate = payload => ({
  type: DELETE_SHARED_TEMPLATE,
  payload,
});


const getProjects = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const { AppReducer } = getState();
  Axios.post('/api/templates/getTemplates', { accountId: AppReducer.currentAccountId })
    .then((response) => {
      const templates = _.filter((response.data.templates), template => template.database === AppReducer.currentDatabase);
      const newProjects = _.keyBy(templates, '_id');
      dispatch(getProjectsAction(newProjects));
      resolve(response.data.templates);
    })
    .catch((err) => {
      reject(err);
    });
});

const duplicateProject = data => dispatch => new Promise((resolve, reject) => {
  Axios.post('/api/templates', data)
    .then((response) => {
      dispatch(duplicateProjectAction(response.data.template));
      resolve(response.data.template);
    })
    .catch((err) => {
      reject(err);
    });
});

const deleteProject = data => dispatch => new Promise((resolve, reject) => {
  const projectId = data.id || data._id;
  Axios.delete(`/api/templates/${projectId}`)
    .then((response) => {
      dispatch(deleteProjectAction(projectId));
      dispatch(deleteSharedTemplate(projectId));
      resolve(response.data.template);
    })
    .catch((err) => {
      reject(err);
    });
});

const updateProject = data => dispatch => new Promise((resolve, reject) => {
  const projectId = data.id;
  Axios.put(`/api/templates/${projectId}`, data)
    .then((response) => {
      if (response.data.shared) {
        dispatch(addSharedTemplate(response.data));
      } else {
        dispatch(unshareTemplate(response.data));
      }
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
});

const getSharedTemplates = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const { currentAccountId, currentDatabase } = getState().AppReducer;
  Axios.get(`/api/templates/${currentAccountId}/shared`)
    .then((response) => {
      if (response.data.success) {
        const templates = _.filter(response.data.templates,
          template => template.database === currentDatabase);
        dispatch(setSharedTemplates(_.keyBy(templates, '_id')));
        resolve();
      } else {
        reject(response.data);
      }
    })
    .catch((error) => {
      reject(error);
    });
});
const saveProject = (title, type) => (dispatch, getState) => new Promise((resolve, reject) => {
  const {
    AppReducer, overviewChartReducer, targetReducer, bannerReducer, OverviewReducer, overviewCardReducer, ProjectReducer,
  } = getState();
  const filteredProjects = _.filter(ProjectReducer.projects, pr => pr.type === 'PROJECT');
  const { currentOverview } = OverviewReducer;
  const { usedBanner } = overviewChartReducer;
  const { user, currentDatabase, currentAccountId } = AppReducer;
  const {
    _id, bannerType, subType, builder, data,
  } = bannerReducer.currentTemplate.banner;
  const bannerUser = bannerReducer.currentTemplate.banner.user;
  const templateData = {
    charts: overviewChartReducer.charts,
    baseTarget: targetReducer.baseTarget,
    target2: targetReducer.target2,
    cards: overviewCardReducer.cards,
  };
  if (_id) {
    // is update
    templateData.banner = {
      title: bannerReducer.currentTemplate.banner.title,
      _id,
      user: bannerUser,
      bannerType,
      type: bannerReducer.currentTemplate.banner.type,
      subType,
      builder: builder || data,
    };
  } else {
    templateData.banner = usedBanner;
  }

  const isAdmin = user.role && user.role.name === 'admin';
  
  const project = {
    type: 'PROJECT',
    subType: type,
    title,
    data: templateData,
    team: isAdmin ? currentAccountId : user.teamId._id,
    database: currentDatabase,
    user: user._id,
    shared: false,
  };
  if (currentOverview._id) {
    Axios.put(`/api/templates/${currentOverview._id}`, project)
      .then((response) => {
        if (response.data) {
          dispatch(getProjects());
          if (type === 'OVERVIEW') {
            dispatch(setCurrentOverview(response.data));
            showNotification({ message: 'Overview saved', type: 'info' });
          }
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  } else {
    if (filteredProjects.length >= 10) {
      showNotification({ message: 'The last project will be deleted.', type: 'warning', vertical: 'top' });
    }
    Axios.post('/api/templates/', project)
      .then((response) => {
        if (response.data.success) {
          dispatch(getProjects());
          if (type === 'OVERVIEW') {
            dispatch(setCurrentOverview(response.data.template));
            showNotification({ message: 'Overview saved', type: 'success' });
          }
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  }
});

const cleanProjects = () => (dispatch) => {
  dispatch({
    type: CLEAN_PROJECTS,
  });
};

export {
  getProjects,
  duplicateProject,
  deleteProject,
  updateProject,
  getSharedTemplates,
  saveProject,
  cleanProjects,
};

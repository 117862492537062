import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const CloseIcon = ({ onClick }) => (<Icon onClick={onClick}> <FontAwesomeIcon icon={faTimesCircle} /> </Icon>);

const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  color: #A0B3BB;
  font-size: 12px;
  display: none;
  cursor: pointer;
`;

export default CloseIcon;

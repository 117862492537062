import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { DragHandle } from './DragHandle';
import QuestionMenu from './QuestionMenu';
import ChartTypeMenu from './ChartTypeMenu';
import DisplayedUnitMenu from './DisplayedUnitMenu';
import TargetMenu from './TargetMenu';
import ChartContainer from './ChartContainer';
import {
  addCard,
  changeCardType,
  changeCardUnit,
  changeCardTarget,
} from '../../../actions/overviewCardActions';
import { BOTH } from '../../../actionTypes/overviewChartTypes';
import './Card.css';

class Card extends Component {
  render() {
    const {
      id,
      title,
      data,
      banner,
      labels,
      cards,
      changeType,
      changeUnit,
      changeTarget,
    } = this.props;
    const { chartType, unit, target } = cards[id];
    const allTargetsLoaded = data.length > 1;
    return (
      <Container className="card">
        <Header>
          <DragHandle />
          <Title>{title}</Title>
          <QuestionMenu id={id} banner={banner} />
        </Header>
        <ChartContainerStyles>
          <ChartContainer
            labels={labels}
            type={chartType}
            unit={unit}
            target={target}
            data={data}
            />
        </ChartContainerStyles>
        <Footer>
          <ChartTypeMenu
            id={id}
            currentType={chartType}
            currentUnit={unit}
            changeType={changeType}
            grouped={target === 2}
            />
          <DisplayedUnitMenu
            id={id}
            currentUnit={unit}
            currentTarget={target}
            currentType={chartType}
            changeUnit={changeUnit}
            />
          <TargetMenu
            id={id}
            targets={labels}
            currentTarget={target}
            currentType={chartType}
            currentUnit={unit}
            changeTarget={changeTarget}
            allTargetsLoaded={allTargetsLoaded}
          />
        </Footer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  labels: state.targetReducer.labels,
  cards: state.overviewCardReducer.cards,
});

const mapDispatchToProps = dispatch => ({
  addCard: id => dispatch(addCard(id)),
  changeType: (id, type) => dispatch(changeCardType(id, type)),
  changeUnit: (id, unit) => dispatch(changeCardUnit(id, unit)),
  changeTarget: (id, target) => dispatch(changeCardTarget(id, target)),
});

const Container = styled.div`
  width: 410px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #CFD8DC;
`;

const Header = styled.div`
  border-bottom: 2px solid #dee1e3;
  width: 100%;
  height:50px;
  padding: 8px;
  position:relative;
  z-index: 2;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-family: 'Roboto';
  color: #37474F;
  display: table-cell;
  vertical-align: top;
  font-weight: 500;
  font-size:13px;
  min-width: 305px;
  padding-left: 5px;
`;

const ChartContainerStyles = styled.div`
  border-bottom: 2px solid #dee1e3;
  height: 285px;
  padding: 0px 0px 0px 0px;
  position: relative;
`;

const Footer = styled.div`
  padding: 8px;
  height: 48px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Card);

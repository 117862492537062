import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ControlLabel } from '../../GlobalComponents/BasicControls';
import DataTable from '../../GlobalComponents/DataTable/DataTable';
import { DropDownMenu } from '../../GlobalComponents/DropDown/DropDownMenu';
import {
  newProjectIcon, newSpreedSheetIcon, newOverviewIcon, targetIcon, questionIcon,
} from '..';
import { getSharedTemplates } from '../../../actions/projectActions';
import { loadQuestions } from '../../../actions/categories';
import { getErrorMessage } from '../../../actions/appActions';
import showNotification from '../../../helpers/showNotification';

const getUser = (cell, row) => `${row.user.first_name} ${row.user.last_name}`;
const setProjectFormat = (cell, row) => {
  let icon;
  switch (row.subType) {
    case 'TARGET': {
      icon = targetIcon;
      break;
    }
    case 'QUESTION': {
      icon = questionIcon;
      break;
    }
    default: {
      icon = questionIcon;
      break;
    }
  }
  return (
    <RowTitle>
      <img src={icon} alt="" width="20" height="20" style={{ margin: '5px 8px 5px -8px' }} />
      <LinkText>{row.title}</LinkText>
    </RowTitle>
  );
};
const setFormatDate = (cell, row) => (row.createdAt ? moment(row.createdAt).format('MM/DD/YYYY') : '');


const customStyles = {
  control: (base, state) => ({
    ...base,
    width: '150px',
    background: 'white',
    outline: '1px solid rgba(0,0,0,0.2)',
    border: 'none',
    'border-radius': '0.5px',
  }),
};

class SharedTemplates extends Component {

  state = {
   filteredTemplates: null,
  }

  componentDidMount() {
    const { getSharedTemplates, loadQuestions } = this.props;
    Promise
      .all([
        getSharedTemplates(),
        loadQuestions(),
      ])
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({message, type});
      });
  }

  setTemplate = (template, type) => {
    const { actions } = this.props;
    const { setTarget, setBanner } = actions.overview;
    const { setSpreadSheetTarget, setSpreadSheetBanner } = actions.spreadsheet;
    if (template.subType === 'TARGET') {
      if (type === 'overview') {
        setTarget(template);
      } else {
        setSpreadSheetTarget(template);
      }
    } else if (template.subType === 'QUESTION') {
      if (type === 'overview') {
        setBanner(template);
      } else {
        setSpreadSheetBanner(template);
      }
    }
  }

getDropdown = (cell, row) => {
  const menuOptions = [
    {
      text: 'Use in new Spreadsheet',
      imageIcon: true,
      icon: newSpreedSheetIcon,
      action: () => this.setTemplate(row),
    },
    {
      text: 'Use in new Overview',
      imageIcon: true,
      icon: newOverviewIcon,
      action: () => this.setTemplate(row, 'overview'),
    },
  ];
  return (
    <div style={{ marginRight: '10px' }}>
      <DropDownMenu
        icon={newProjectIcon}
        menuOptions={menuOptions}
      />
    </div>
  );
};

columns = [
  {
    dataField: 'title',
    columnStyle: { width: '40%' },
    formatter: setProjectFormat,
    text: 'Name',
    sort: true,
    style: {
      border: 'none !important',
    },
  },
  {
    dataField: 'user', formatter: getUser, columnStyle: { width: '30%' }, text: 'Owner', sort: true,
  },
  {
    formatter: setFormatDate, columnStyle: { width: '20%' }, text: 'Modified', sort: true,
  },
  { formatter: this.getDropdown, columnStyle: { width: '10%' } },
];

  sortByType = (sortBy, order) => {
    const { templates } = this.state;
    const newTemplates = _.orderBy(templates, sortBy, order);
    this.setState({
      keepSortingOnChange: false,
      sort: {
        sorted: true,
        sortBy,
        order,
      },
      templates: newTemplates,
    });
  }

  options = [
    {
      value: 'All',
      label: 'All',
      sortBy: 'createdAt',
      order: 'desc',
      action: this.sortByType,
      sort: 'ALL',
    },
    {
      value: 'Target Templates',
      label: 'Target Templates',
      sortBy: 'subType',
      order: 'desc',
      action: this.sortByType,
      sort: 'TARGET',
    },
    {
      value: 'Questions Templates',
      label: 'Questions Templates',
      sortBy: 'subType',
      order: 'asc',
      action: this.sortByType,
      sort: 'QUESTION',
    },
  ];

  handleSelectChange = (option) => {
    const { templates } = this.props;
    this.setState({
      filteredTemplates: [..._.filter(templates, tmp => tmp.subType === option.sort), ..._.filter(templates, tmp => (tmp.subType !== option.sort))],
    });
  }

  render() {
    const { user, templates } = this.props;
    const { filteredTemplates } = this.state;
    const sortedTemplates = _.orderBy(filteredTemplates || templates, 'createdAt', 'asc');
    return (
      <Container>
        <Header>
          <Topic bold>{user.teamId.name}</Topic>
          <DropdownContainer>
            <SortText>Sort:</SortText>
            <Select
              options={this.options}
              className="react-sort"
              styles={customStyles}
              placeholder=""
              onChange={this.handleSelectChange}
            />
          </DropdownContainer>
        </Header>
        <DataTable
          data={sortedTemplates}
          columns={this.columns}
        />
      </Container>
    );
  }
}

const getJustTemplates = templates => _.filter(templates, tmp => tmp.type === 'TEMPLATE');

const mapStateToProps = store => ({
  user: store.AppReducer.user,
  templates: getJustTemplates(store.ProjectReducer.sharedTemplates),
});

const mapDispatchToProps = dispatch => ({
  loadQuestions: () => dispatch(loadQuestions()),
  getSharedTemplates() {
    return dispatch(getSharedTemplates());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedTemplates);

const SortText = styled.p`
  font-size: 13px;
  color: #37474F;
  margin-right: 10px;
`;

const Container = styled.div`
  transition: 1s ease-in-out;
`;
const LinkText = styled.p`
  color: #19A2C5;
  margin: 0;
  padding: 0;
  cursor: hover;
`;
const DropdownContainer = styled.div`
  width:50px;
  display: flex;
  justify-content: space-around;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Topic = styled(ControlLabel)`
  font-weight: ${({ bold }) => (bold ? 'bold' : '400')};
  font-size: ${props => (props.fontSize ? props.fontSize : '1.1em')};
  color: rgba(65, 71, 76, 1);
  cursor: pointer;
`;

const RowTitle = styled.div`
  display: inline-flex;
  align-items: center;
`;

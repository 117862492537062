// BASIC
export const ADD_BASIC_BANNER_QUESTION = 'ADD_BASIC_BANNER_QUESTION';
export const REMOVE_BASIC_BANNER_QUESTION = 'REMOVE_BASIC_BANNER_QUESTION';
export const ADD_BASIC_BANNER_DATAPOINT = 'ADD_BASIC_BANNER_DATAPOINT';
export const REMOVE_BASIC_BANNER_DATAPOINT = 'REMOVE_BASIC_BANNER_DATAPOINT';
export const RESTORE_BASIC_BUILDER = 'RESTORE_BASIC_BUILDER';
export const RESTORE_ADVANCED_BUILDER = 'RESTORE_ADVANCED_BUILDER';

// Edit Basic
export const EDIT_ADD_BASIC_BANNER_QUESTION = 'EDIT_ADD_BASIC_BANNER_QUESTION';
export const EDIT_REMOVE_BASIC_BANNER_QUESTION = 'EDIT_REMOVE_BASIC_BANNER_QUESTION';
export const EDIT_ADD_BASIC_BANNER_DATAPOINT = 'EDIT_ADD_BASIC_BANNER_DATAPOINT';
export const EDIT_REMOVE_BASIC_BANNER_DATAPOINT = 'EDIT_REMOVE_BASIC_BANNER_DATAPOINT';

// ADVANCED
export const ADD_BANNER_BLOCK = 'ADD_BANNER_BLOCK';
export const REMOVE_BANNER_BLOCK = 'REMOVE_BANNER_BLOCK';
export const ADD_BANNER_QUESTION = 'ADD_BANNER_QUESTION';
export const REMOVE_BANNER_QUESTION = 'REMOVE_BANNER_QUESTION';
export const ADD_BANNER_DATAPOINT = 'ADD_BANNER_DATAPOINT';
export const REMOVE_BANNER_DATAPOINT = 'REMOVE_BANNER_DATAPOINT';
export const CHANGE_BANNER_ITEMS_OPERATOR = 'CHANGE_BANNER_ITEMS_OPERATOR';

// Edit Advanced
export const EDIT_ADD_BANNER_BLOCK = 'EDIT_ADD_BANNER_BLOCK';
export const EDIT_REMOVE_BANNER_BLOCK = 'EDIT_REMOVE_BANNER_BLOCK';
export const EDIT_ADD_BANNER_QUESTION = 'EDIT_ADD_BANNER_QUESTION';
export const EDIT_REMOVE_BANNER_QUESTION = 'EDIT_REMOVE_BANNER_QUESTION';
export const EDIT_ADD_BANNER_DATAPOINT = 'EDIT_ADD_BANNER_DATAPOINT';
export const EDIT_REMOVE_BANNER_DATAPOINT = 'EDIT_REMOVE_BANNER_DATAPOINT';
export const EDIT_CHANGE_BANNER_ITEMS_OPERATOR = 'EDIT_CHANGE_BANNER_ITEMS_OPERATOR';

// Others
export const SAVE_BANNER_TEMPLATE_TO_PROJECTS = 'SAVE_BANNER_TEMPLATE_TO_PROJECTS';
export const SET_BANNER_CURRENT_TAB = 'SET_BANNER_CURRENT_TAB';
export const RESTORE_BASIC_BANNER_TEMPLATE = 'RESTORE_BASIC_BANNER_TEMPLATE';
export const RESTORE_ADVANCED_BANNER_TEMPLATE = 'RESTORE_ADVANCED_BANNER_TEMPLATE';
export const SET_CURRENT_BANNER_TEMPLATE = 'SET_CURRENT_BANNER_TEMPLATE';
export const SET_BANNER = 'SET_BANNER';
export const RESET_ALL_BANNERS = 'RESET_ALL_BANNERS';
export const REMOVE_BANNER = 'REMOVE_BANNER';
export const SAVE_ALL_BANNERS = 'SAVE_ALL_BANNERS';
export const TOGGLE_EDITING_BANNER = 'TOGGLE_EDITING_BANNER';
export const CHANGE_BASIC_BANNER_TITLE = 'CHANGE_BASIC_BANNER_TITLE';
export const CHANGE_ADVANCED_BANNER_TITLE = 'CHANGE_ADVANCED_BANNER_TITLE';
export const CHANGE_EDIT_BANNER_TITLE = 'CHANGE_EDIT_BANNER_TITLE';
export const SET_BASIC_MODAL_SAVED = 'SET_BASIC_MODAL_SAVED';
export const SET_ADVANCED_MODAL_SAVED = 'SET_ADVANCED_MODAL_SAVED';
export const RESET_MODAL = 'RESET_MODAL';
export const COPY_BANNER = 'COPY_BANNER';
export const CLEAR_BANNERS = 'CLEAR_BANNERS';

import React from 'react';
import styled from 'styled-components';
import { FormControl } from '../BasicControls';

const TextInput = props => (
  <TextInputStyled {...props} />

);
TextInput.defaultProps = {
  innerRef: {
    current: {},
  },
};

export default TextInput;

const TextInputStyled = styled(FormControl)`
  width: 96%;
  margin-top: 5px;
  font-size: 13px;
  border: 1px solid rgba(0,0,0,0.3);
  height: 28px;
  ${({ error }) => error && 'outline-color: #ED2A2A;'}
  ${({ success }) => success && 'outline-color: green;'}
`;

import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, ControlLabel } from "../GlobalComponents/BasicControls";

class Body extends Component {
  render() {
    return (
      <Wrapper>
        <ContentBody>
          <Row>
            <Col size={12}>
              <Topic>
                We're here to help
              </Topic>
              <Paragraph>
                We're getting our support documents ready but they're not ready yet. In the mean time,
                if you run into problems or just have a question you nedd answered, feel free to contact
                us at <HighLight href="mailto:support@stowellco.com">support@stowellco.com</HighLight>. Or, call us if you need immediate help.
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col size={6}>
              <Topic
                fontSize="16px">
                Do you want to talk to a human?
              </Topic>
              <Paragraph>
                We're available Monday - Friday, 9:00 am to 5:00 pm PST
              </Paragraph>
              <Paragraph>
                <HighLight
                  style={{
                    fontSize: '1.3em',
                    fontWeight: 600,
                    cursor: 'text',
                  }}>
                  206-727-2700
                </HighLight>
              </Paragraph>
            </Col>
            <Col size={6}>
              <Topic
                fontSize="16px">
                Will you help us?
              </Topic>
              <Paragraph>
                We would love to hear from you about our new software. Love it or hate,
                please share your thoughts and ideas so we can continue to make it work
                better for you.
              </Paragraph>
              <Paragraph>
                Email feedback to 
                <HighLight href="mailto:support@stowellco.com">
                  {' '} support@stowellco.com
                </HighLight>
              </Paragraph>
            </Col>
          </Row>
        </ContentBody>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

const ContentBody = styled.div`
  width: 84%;
  padding: 30px 8% 0 8%;
`;

const Topic = styled(ControlLabel)`
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize || '20px'};
  color: #37474F;
`;

const Paragraph = styled(ControlLabel)`
  padding-top: 10px;
  letter-spacing: 0.2px;
  line-height: 150%;
  font-size: 13px;
  color: #37474F;
`; 

const HighLight = styled.a`
  color: #19A2C5;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
`;

export default Body;
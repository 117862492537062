import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import DataTable from '../../GlobalComponents/DataTable/DataTable';
import { getProjects, duplicateProject, deleteProject } from '../../../actions/projectActions';
import { DropDownMenu } from '../../GlobalComponents/DropDown/DropDownMenu';
import {
  spreadsheetIcon,
  faTrash,
  overviewFile,
  downloadIcon,
  duplicateIcon,
} from '..';
import NoProjects from './NoProjects';
import { downloadSavedOverview } from '../../../actions/overviewExportActions';
import showNotification from '../../../helpers/showNotification';
import { getErrorMessage } from '../../../actions/appActions';

class RecentProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCopiedOrRemoved: {},
      copied: false,
      loading: true,
      pressedOnCollapse: false,
    };
  }

  componentDidMount() {
    const { getProjects } = this.props;
    getProjects()
      .then(() => {
        this.setState({
          loading: false,
        })
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
       showNotification({ message, type });
      });
  }

  openProject = row => () => {
    if (row.subType === 'OVERVIEW') {
      const { overview } = this.props.actions;
      const { banner, baseTarget } = row.data;
      if (banner && baseTarget) {
        overview.setOverview(row);
      } else {
        showNotification({ message: 'Overview error', type: 'warning' });
      }
    } else {
      const { spreadsheet } = this.props.actions;
      spreadsheet.setSpreadSheet(row);
    }
  }

  projectFormatterTitle = (cell, row) => {
    let icon;
    switch (row.subType) {
      case 'OVERVIEW': {
        icon = overviewFile;
        break;
      }
      case 'SPREADSHEET': {
        icon = spreadsheetIcon;
        break;
      }
      default: {
        icon = overviewFile;
        break;
      }
    }
    return (
      <RowTitle>
        <img src={icon} alt="" width="20" height="20" style={{ margin: '5px 8px 5px 0px' }} />
        <LinkText onClick={this.openProject(row)}>{row.title}</LinkText>
      </RowTitle>
    );
  }

  download = async (overview, size) => {
    const { downloadPresentation } = this.props;
    try {
      downloadPresentation(overview, 'overview', size);
    } catch (error) {
      const { message, type } = getErrorMessage(error);
      showNotification({ message, type });
    }
  }

  dropdowMenu = (cell, row, index) => {
    const menuOptions = [
      {
        text: 'Download',
        imageIcon: true,
        icon: downloadIcon,
        items: [],
      },
      {
        text: 'Duplicate',
        imageIcon: true,
        icon: duplicateIcon,
        action: () => this.duplicateRow(row),
      },
      {
        text: 'Remove',
        icon: faTrash,
        action: () => this.deleteRow(row),
      },
    ];
    if (row.subType === 'OVERVIEW') {
      menuOptions[0].items = [
        {
          text: <React.Fragment><ScreenIcon />{' '}as Microsoft PowerPoint Standard (4:3)</React.Fragment>,
          action: () => { this.download(row, '4x3'); },
          closeOnClick: false,
        },
        {
          text: <React.Fragment><ScreenIcon wide />{' '}as Microsoft PowerPoint Widescreen (16:9)</React.Fragment>,
          action: () => { this.download(row, 'WIDE'); },
          closeOnClick: false,
        },
      ];
    } else {
      menuOptions[0].items = [
        {
          text: <React.Fragment><ScreenIcon />{' '}as Microsoft PowerPoint Standard (4:3)</React.Fragment>,
          action: () => { this.download(row, '4x3'); },
          closeOnClick: false,
        },
        {
          text: <React.Fragment><ScreenIcon wide />{' '}as Microsoft PowerPoint Widescreen (16:9)</React.Fragment>,
          action: () => { this.download(row, 'WIDE'); },
          closeOnClick: false,
        },
      ];
    }

    return (
      <DropDownMenu
        menuOptions={menuOptions}
      />
    );
  }

  collapseTable = () => {
    const { pressedOnCollapse } = this.state;
    if (pressedOnCollapse) {
      this.assignProjectsToState(this.props, 5);
    } else {
      this.assignProjectsToState(this.props, 10);
    }
    this.setState({
      pressedOnCollapse: !pressedOnCollapse,
    });
  }

  setFormatDate = (cell, row) => (row.createdAt ? moment(row.createdAt).format('MM/DD/YYYY') : null)

  getTableColumns = () => [
    {
      dataField: 'title',
      columnStyle: {
        width: '60%',
      },
      formatter: this.projectFormatterTitle,
    },
    {
      formatter: this.setFormatDate,
      columnStyle: {
        width: '30%',
      },
    },
    {
      formatter: this.dropdowMenu,
      columnStyle: {
        width: '10%',
      },
    },
  ]

  duplicateRow = (row) => {
    const information = {
      ...row,
      title: `${row.title} Copy`,
    };
    const { duplicateProject } = this.props;
    duplicateProject(information)
      .then((response) => {
        showNotification({ message: `Created "${response.title}"`, type: 'success' });
        this.setState({
          rowCopiedOrRemoved: response,
          copied: true,
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
  }

  deleteRow = (row) => {
    const {
      title,
      _id,
    } = row;
    const data = {
      id: _id,
    };

    showNotification({ message: `Removing "${title}"`, type: 'info' });
    this.setState({
      rowCopiedOrRemoved: row,
    }, () => {
      const { deleteProject } = this.props;
      deleteProject(data)
        .then(() => {
          this.setState({
            copied: false,
          });
          showNotification({ message: `Moved to trash`, type: 'success' });
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type });
        });
    });
  }

  undoDeleting = () => {
    const { duplicateProject } = this.props;
    const { rowCopiedOrRemoved } = this.state;
    duplicateProject(rowCopiedOrRemoved)
      .then(() => {
        showNotification({ message: 'Action undone', type: 'info' })
        this.setState({
          rowCopiedOrRemoved: {},
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
  }

  undoDuplication = () => {
    const { deleteProject } = this.props;
    const { rowCopiedOrRemoved } = this.state;
    const {
      _id,
    } = rowCopiedOrRemoved;
    const data = {
      id: _id,
    };
    deleteProject(data)
      .then(() => {
        showNotification({ message: 'Action undone', type: 'info' })
        this.setState({
          rowCopiedOrRemoved: {},
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
  }

  onUndo = () => {
    const { copied } = this.state;
    if (copied) {
      this.undoDuplication()
    } else {
      this.undoDeleting();
    }
  }


  render() {
    const { loading } = this.state;
    const { projects } = this.props;
    const projectList = _.filter(projects, p => p.type === 'PROJECT');
    return (
      <Container>
        {
          loading
          ?
          <Loader>
             <p>
                <FontAwesomeIcon icon={faCircleNotch} size="3x" spin />
              </p>
          </Loader>
          :
          projectList.length > 0
            ? (
              <div>
                <DataTable
                  data={projectList}
                  columns={this.getTableColumns()}
                  showHeader={false}
                />
                <BottomOption />
              </div>
            )
            : <NoProjects />
        }
      </Container>
    );
  }
}
const LinkText = styled.p`
  color: #19A2C5;
  font-size: 13px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  &:hover{
    color: #117189;
  }
`;
const ScreenIcon = styled.div`
  width: ${({ wide }) => (wide ? '15px' : '12px')};
  height: 10px;
  border: 1px solid #CCCCCC;
  margin-right: ${({ wide }) => (wide ? '5px' : '10px')};
  margin-left: ${({ wide }) => (wide ? '0px' : '0px')};
`;
const Container = styled.div`
  padding: 10px 0 0 0;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  color: #DEE1E3;
`;

const RowTitle = styled.div`
  display: inline-flex;
  align-items: center;
`;

const BottomOption = styled.div`
  width: 100%;
  min-height: 40px;
  padding: 10px 0 0 0;
  border-bottom: 1px solid rgba(0,0,0,0.3);
`;

const mapStateToProps = state => ({
  projects: state.ProjectReducer.projects,
});
const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(getProjects()),
  duplicateProject: data => dispatch(duplicateProject(data)),
  deleteProject: data => dispatch(deleteProject(data)),
  downloadPresentation: (overview, type, size) => dispatch(downloadSavedOverview(overview, type, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentProjects);

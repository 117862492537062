import React,{Component} from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import LoginWindow from '../Login/LoginWindow';
import { withRouter } from 'react-router-dom';

class Login extends Component {
  componentWillMount(){
    if(this.props.logged){
      this.props.history.push('/projects')
    }
  }
  handleChange = (event, index, value) => this.setState({value});

  render() {
    return (
        <Background>
          <LoginWindow />
        </Background>
        )
  }
}

const Background = styled.div`
    background-color: #19A2C5;
    position: absolute;
    width: 100%;
    height: all;
    min-height: 100%;
    display: flex;
    justify-content: center;
`;

const mapStateToProps = store => ({
  logged: store.AppReducer.logged,
})

export default connect(mapStateToProps)(withRouter(Login));
import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { updateCharts } from '../../../actions/overviewChartActions';
import { resetModal } from '../../../actions/targetActions';
import { changeAllCardTarget2AndBase } from '../../../actions/overviewCardActions';

class Buttons extends Component {

  handleEdit = () => {
    const { templates } = this.props.actions;
    templates.changeMenu('edit');
  };

  handleUpdate = () => {
    const { actions: { target, templates } } = this.props;
    templates.update();
    target.setQuery();
    this.handleApply();
  };

  handleApply = () => {
    const {
      close, actions, data, type,
      updateCharts,
      changeAllCardTarget2AndBase,
    } = this.props;
    const { target, spreadsheet } = actions;
    const { currentTemplate } = data.templates;
    target.setTitle(currentTemplate.title);
    target.setQuery();
    target.setTargetId(currentTemplate.id);
    if (type === 'spreadsheet') {
      spreadsheet.addTarget();
    }
    if (type === 'spreadsheetEditTarget') {
      spreadsheet.editTarget();
    }
    if (type === 'target2' || type === 'baseTarget') {
      target.copy(type);
    }
    close();
    updateCharts();
    changeAllCardTarget2AndBase();
    this.resetModal();
  };

  resetModal = () => {
    const { close, actions, resetModal,
    } = this.props;
    const { builder, templates, target } = actions;
    close();
    resetModal();
    builder.reset();
    builder.toggleView('builder');
    // templates.setCurrent({});
    templates.changeMenu('list');
    target.setTitle('');
  }

  render() {
    const { builder, templates } = this.props.data;
    const { currentTarget, target, user } = this.props;
    const { blocks } = builder;
    const { menu } = templates;
    let canUpdate = false;
    let disabled = true;
    if (currentTarget.user && user && target.title) {
      canUpdate = user._id === currentTarget.user._id;
      disabled = !target.title || _.isEmpty(blocks[0].questions);
    }
    return (
      <Container>
        <CancelButton onClick={this.resetModal}>Cancel</CancelButton>
        {(menu === 'selected' && canUpdate) && <EditButton onClick={this.handleEdit}>Edit</EditButton>}
        {(menu === 'edit' && canUpdate) && <UpdateButton onClick={this.handleUpdate}>Update &amp; Apply</UpdateButton>}
        <ApplyButton onClick={this.handleApply} disabled={disabled}>Apply</ApplyButton>
      </Container>
    );
  }
}


const Container = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

const CancelButton = styled.button`
  flex: 1;
  height: 32px;
  background: #CFD8DC;
  color: #607D8B;
  border: none;
  border-radius: 2px;
  font-family: 'Roboto';
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  cursor:pointer;
  margin: 0 10px;
`;

const ApplyButton = styled.button`
  flex: 1;
  height: 32px;
  background: #607D8B;
  color: white;
  border: none;
  border-radius: 3px; 
  font-family: 'Roboto';
  font-weight: 500;
  cursor: pointer;
  margin: 0 10px;
  &:disabled{
    color: #B0BEC5;
  }
`;

const EditButton = styled(ApplyButton)`
  flex: 1;
  height: 32px;
`;
const UpdateButton = styled(ApplyButton)`
  flex: 2;
  height: 32px;
`;

const mapStateToProps = (store) => {
  const { currentTemplate } = store.targetReducer.templates;
  const { projects, sharedTemplates } = store.ProjectReducer;
  const allProjects = {
    ...sharedTemplates,
    ...projects,
  };
  let currentTarget = {};
  if (!_.isEmpty(currentTemplate)) {
    currentTarget = allProjects[currentTemplate.id] || {};
  }
  return {
    user: store.AppReducer.user,
    target: currentTemplate,
    currentTarget,
  };
};

const mapDispatchToProps = dispatch => ({
  updateCharts: () => dispatch(updateCharts()),
  changeAllCardTarget2AndBase: () => dispatch(changeAllCardTarget2AndBase()),
  resetModal: () => dispatch(resetModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);

import React from 'react';
import PropTypes from 'prop-types';

import { ToolbarButton } from './ToolbarButton';

const ToolbarButtonPercentage = (props) => {
  const { functions, showPercentage } = props;
  return (
    <ToolbarButton
      onClick={functions.showPercentage}
      active={showPercentage}
      text="%"
    />
  );
};

ToolbarButtonPercentage.propTypes = {
  functions: PropTypes.instanceOf(Object).isRequired,
  showPercentage: PropTypes.bool,
};

ToolbarButtonPercentage.defaultProps = {
  showPercentage: false,
};

export default ToolbarButtonPercentage;

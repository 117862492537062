import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { faTimes, faFilePdf, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import * as Sentry from "@sentry/react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Title,
  Button,
} from '../../../GlobalComponents/BasicControls';
import { editAccountDocuments } from '../../../../actions/accountActions';
import descriptions from './descriptions';
import showNotification from '../../../../helpers/showNotification';

const documents = [
  {
    name: 'coding',
    title: 'Upload Coding',
    id: 'codingFile',
  },
  {
    name: 'listing',
    title: 'Upload Listing',
    id: 'listingFile',
  },
  {
    name: 'distribution',
    title: 'Upload Distribution',
    id: 'distributionFile',
  },
  {
    name: 'methodology',
    title: 'Upload Methodology',
    id: 'methodologyFile',
  },
  {
    name: 'descriptions',
    title: 'Upload Descriptions',
    id: 'descriptionsFile',
  },
];

const Document = ({
  name, title, id, handleInputFile, getFileLabel, parentState, edit,
}) => (
  <Row>
    <Col size={12}>
      <FormGroup>
        <ControlLabel>
          {title}
        </ControlLabel>
        <InputFile
          autoComplete="off"
          type="file"
          name={name}
          accept="application/pdf"
          onChange={handleInputFile}
          id={id}
        />
        <InputLabel htmlFor={id} style={{ backgroundColor: '#DEE1E3', color: 'black' }}>
          Choose File
        </InputLabel>
        { !!parentState[name] && getFileLabel(parentState[name], name) }
      </FormGroup>
    </Col>
  </Row>
);

class SupportDocuments extends Component {
  state = {
    loading: false,
  }

  handleInputFile = (e) => {
    const { files, name } = e.target;
    if (files.length > 0) {
      const newFileExtension = files[0].name.split('.');
      const extensionIsAccepted = newFileExtension[newFileExtension.length - 1] === 'pdf';
      if (extensionIsAccepted) {
        const {
          state, setState, content, type,
        } = this.props;
        const support = state;
        const _document = !!support[name];
        const deletePrevious = _document ? !!support[name]._id : false;
        let data;
        if (type === 'edit') {
          data = {
            ...support[name],
            updated: true,
            hideInList: false,
            deletePrevious,
            file: files[0],
            showName: files[0].name,
            description: descriptions[name],
          };
        } else {
          data = files[0];
        }
        setState({
          account: {
            ...content.account,
            support: {
              ...support,
              [name]: data,
            },
          },
        });
      } else {
        showNotification({message: 'Please select a pdf file', type: 'warning'});
      }
    }
  }

  handleDeletedFile = (file, name) => {
    const {
      type, setState, content, state,
    } = this.props;
    if (type === 'edit') {
      const support = state;
      const _document = !!support[name];
      const hasId = _document ? !!support[name]._id : false;
      let data;
      if (hasId) {
        data = {
          ...state[name],
          updated: false,
          hideInList: true,
          deletePrevious: true,
        };
      }
      setState({
        account: {
          ...content.account,
          support: {
            ...state,
            [name]: data,
          },
        },
      });
    } else {
      setState({
        account: {
          ...content.account,
          support: {
            ...state,
            [name]: '',
          },
        },
      });
    }
  }

  getFileLabel = (file, name) => {
    const { updated } = file;
    if (file) {
      if (!file.hideInList) {
        return (
          <BrandLabel>
            <ButtonDelete onClick={() => { this.handleDeletedFile(file, name); }}>
              <FontAwesomeIcon icon={faTimes} />
            </ButtonDelete>
            <FontAwesomeIcon
              style={{
                color: 'rgba(0,0,0,0.4)',
                margin: '10px 0 0 10px',
              }}
              icon={faFilePdf}
            />
            <p>
              {updated ? file.showName : `${file.name}.pdf`}
            </p>
          </BrandLabel>
        );
      }
    }
    return null;
  }

  handleSubmit = (e, type) => {
    e.preventDefault();
    if (type === 'edit') {
      this.handleSubmitForEdit();
    } else {
      this.props.handleSubmit(e);
    }
  }

  handleSubmitForEdit = () => {
    showNotification({message: 'Uploading files, please wait.', type: 'warning'})
    this.setState({
      loading: true,
    });
    const {
      editAccountDocuments, teamId, content, editLogoFile,
    } = this.props;
    const { edit, ...files } = this.props.state;
    const promises = [];
    if (content.account.storagedLogo.deleted) {
      const logoFileAdded = Boolean(content.account.logoFile.name);
      if (logoFileAdded) {
        promises.push(editLogoFile(content.account.logoFile, teamId));
      } else {
        showNotification({message: 'Please, select a logo file', type: 'warning'})
        this.setState({
          loading: false,
        });
        return;
      }
    }
    Promise.all(promises)
      .then(() => {
        const filesToUpdate = _.compact(_.map(files, (value, key) => (key !== 'edit' && value) && {
          file: value.file,
          name: key,
          id: value._id,
          description: value.description,
          deleted: value.deletePrevious,
          updated: value.updated,
        }));
        return editAccountDocuments(filesToUpdate, teamId);
      })
      .then(() => {
        showNotification({message: 'Files uploaded successfully!', type: 'success'})
        this.setState({
          loading: false,
        });
        this.props.setState({
          selectedItemNav: 6,
        });
      })
      .catch((err) => {
        showNotification({message: 'Error updating documents', type: 'error'})
        this.setState({
          loading: false,
        });
        Sentry.captureException(err);
      });
  }

  handleInputFileForLogo = (e) => {
    const { files } = e.target;
    const logoFile = files[0];
    const newFileExtension = logoFile.name.split('.');
    const extension = newFileExtension[newFileExtension.length - 1];
    const extensionIsAccepted = extension === 'png';
    if (extensionIsAccepted) {
      const { content, setState, type } = this.props;
      if (type === 'edit') {
        setState({
          account: {
            ...content.account,
            logoFile,
            storagedLogo: {
              ...content.account.storagedLogo,
              deleted: true,
            },
          },
        });
      } else {
        setState({
          account: {
            ...content.account,
            logoFile,
          },
        });
      }
    } else {
      showNotification({message: 'Please select a png file', type: 'warning'})
    }
  }

  deleteLogoFileFromState = () => {
    const { content, setState, type } = this.props;
    if (type === 'edit') {
      setState({
        account: {
          ...content.account,
          logoFile: {},
          storagedLogo: {
            ...content.account.storagedLogo,
            deleted: true,
          },
        },
      });
    } else {
      setState({
        account: {
          ...content.account,
          logoFile: {},
        },
      });
    }
  }

  onCancelCreatingAndEditing = () => {
    const { type, onCancelCreating, onCancelEditing } = this.props;
    if (type === 'edit') {
      onCancelEditing();
    } else {
      onCancelCreating();
    }
  }

  getFileLabelForLogo = (logoFileName, onDelete) => (
    <BrandLabel>
      <ButtonDelete
        onClick={onDelete}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ButtonDelete>
      <FontAwesomeIcon
        style={{
          color: 'rgba(0,0,0,0.4)',
          margin: '10px 0 0 10px',
        }}
        icon={faFileImage}
      />
      <p>
        {logoFileName}
      </p>
    </BrandLabel>
  )

  getBrandFileInfo = () => {
    const { content, type } = this.props;
    if (type === 'edit') {
      if (content.account.storagedLogo.name) {
        if (!content.account.storagedLogo.deleted) {
          return this.getFileLabelForLogo(content.account.storagedLogo.name, this.deleteLogoFileFromState);
        }
      }
    }
    if (content.account.logoFile.name) {
      return this.getFileLabelForLogo(content.account.logoFile.name, this.deleteLogoFileFromState);
    }
  }

  render() {
    const { state, type } = this.props;
    const {
      edit,
    } = state;
    const {  loading } = this.state;
    return (
      <Body>
        <Form onSubmit={e => this.handleSubmit(e, type)}>
          <Title>
            Upload Support Documents
          </Title>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                  Upload Client logo (PNG)*
                </ControlLabel>
                <InputFile
                  autoComplete="off"
                  type="file"
                  name="logo"
                  accept=".png"
                  onChange={this.handleInputFileForLogo}
                  id="logo"
                />
                <InputLabel htmlFor="logo" style={{ backgroundColor: '#DEE1E3', color: 'black' }}>
                  Choose File
                </InputLabel>
                {
                  this.getBrandFileInfo()
                }
              </FormGroup>
            </Col>
          </Row>
          {
            documents.map(doc => (
              <Document
                title={doc.title}
                name={doc.name}
                id={doc.id}
                edit={edit}
                parentState={this.props.state}
                getFileLabel={this.getFileLabel}
                handleInputFile={this.handleInputFile}
              />
            ))
          }
          <Row>
            <Col size={2}>
              <Button
                onClick={this.onCancelCreatingAndEditing}
                type="button"
              >
                Cancel
              </Button>
            </Col>
            <Col size={3}>
              <Button
                disabled={loading}
                tColor="white"
                bgColor="#455A64"
              >
                {this.props.type === 'edit' ? 'Save' : 'Continue'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <ControlLabel>
                *required
              </ControlLabel>
            </Col>
          </Row>
        </Form>
      </Body>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  editAccountDocuments(files, teamId) {
    return dispatch(editAccountDocuments(files, teamId));
  },
});

export default connect(null, mapDispatchToProps)(SupportDocuments);
const Body = styled.div`
  ${''}
  width: 47%;
  padding: 0 3%;
`;
const InputFile = styled.input`
  border: 0;
  padding-left: 0;
  margin-bottom: 5px;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  &:focus {
    border: none;
  }
`;

const InputLabel = styled.label`
  padding: 5px 8px;
  color: #B0BEC5;
  background-color: #EBEFF1;
  border: 1px solid #B0BEC5;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const BrandLabel = styled.div`
  border: 1px solid rgba(0,0,0,0.05);
  width: 100%;
  height: 35px;
  background-color: #FFF;
  margin-top: 10px;
  p {
    font-size: 0.9em;
    font-family: 'Roboto';
    color: rgba(0,0,0,0.8);
    max-height: 20px;
    height: 20px;
    margin-top: -18px;
    margin-left: 30px;
    width: 85%;
  }
`;
const ButtonDelete = styled.div`
  cursor: pointer;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background-color: rgba(0,0,0,0.4);
  text-align: center;
  color: #FFF;
  font-size: 0.6em;
  line-height: 16px;
`;

import axios from 'axios';
import _ from 'lodash';
import * as Sentry from "@sentry/react";

import { LOAD_CATEGORIES, LOAD_QUESTIONS, LOAD_SELECT_DATA } from '../actionTypes/categoryTypes';
import showNotification from '../helpers/showNotification';

const prepareSelectData = (data = []) => {
  const selectData = data.map((cat) => {
    if (cat && _.has(cat, 'subcategories')) {
      const subQuestions = cat.subcategories.map(sub => sub.questions);
      return [..._.flatten(subQuestions), ...cat.questions];
    }
    return null;
  });
  const listOfQuestions = _.flatten(selectData);

  const options = listOfQuestions.map((question) => {
    if (question && _.has(question, 'answers')) {
      const answers = question.answers.map(answer => ({
        label: answer.text,
        value: `${question.id}-${answer.index + 1}`,
        path: question.path,
      }));
      return { label: question.text, options: answers };
    }
    return null;
  });

  return _.compact(options);
};

const normalizeQuestions = (data = []) => {
  const questions = data.map((question) => {
    if (question && _.has(question, 'answers')) {
      let answers = question.answers.map(answer => ({
        id: `${question.id}-${answer.index + 1}`,
        index: answer.index + 1,
        text: answer.text,
      }));
      answers = _.keyBy(answers, 'id');
      return { id: question.id, text: question.text, answers };
    }
    return null;
  });
  return _.keyBy(_.compact(questions), 'id');
};

const tabSelectedIsCombined = (tabs, current) => tabs[current].Name === 'Combined';

const getSelectedTabYears = tabs => tabs
  .filter(tab => tab.Name !== 'Combined' && tab.isVisible)
  .map(tab => tab.Name);

export const loadCategories = path => (dispatch, getState) => {
  const { user } = getState().AppReducer;
  const { currentSurvey } = getState().marketReducer;
  const { currentTab, yearList, Data } = getState().spreadsheet;
  let { year } = currentSurvey;
  let route = `/api/categories/${year}`;
  let years = [];
  let account;
  if (user.role.name === 'admin') {
    account = getState().AppReducer.currentFolderName;
  } else {
    account = getState().AppReducer.user.teamId.folderName;
  }
  const database = getState().AppReducer.currentDatabase;
  if (!account) {
    showNotification({ message: 'Missing account on request.', type: 'warning' });
    return null;
  }
  if (!database) {
    showNotification({ message: 'You must select a database before.', type: 'warning' });
    return null;
  }
  const data = { account, database };
  if (path === '/spreadsheet') {
    const isCombinedTab = tabSelectedIsCombined(Data.Tabs, currentTab);
    if (isCombinedTab) {
      years = getSelectedTabYears(Data.Tabs);
      data.years = years;
      route = '/api/categories/multipleYears';
    } else {
      year = yearList[currentTab] || '';
      route = `/api/categories/${year}`;
    }
  }
  return axios.post(route, data).then((response) => {
    dispatch({ type: LOAD_CATEGORIES, payload: response.data });
    const selectData = prepareSelectData(response.data);
    dispatch({ type: LOAD_SELECT_DATA, payload: selectData });
  }).catch((error) => {
    showNotification({ message:'Error loading categories', type: 'error' })
    Sentry.captureException(error);
  });
};

export const loadQuestions = path => (dispatch, getState) => {
  const { user } = getState().AppReducer;
  const { currentSurvey } = getState().marketReducer;
  const { currentTab, yearList, Data } = getState().spreadsheet;
  let { year } = currentSurvey;
  let route = `/api/questions/${year}`;
  let years = [];
  let account;
  if (user.role.name === 'admin') {
    account = getState().AppReducer.currentFolderName;
  } else {
    account = getState().AppReducer.user.teamId.folderName;
  }
  const database = getState().AppReducer.currentDatabase;
  if (!account) {
    showNotification({ message: 'Missing account on request.', type: 'warning' });
    return null;
  }
  if (!database) {
    showNotification({ message: 'You should select a database before.', type: 'warning' });
    return null;
  }
  const data = { account, database };
  if (path === '/spreadsheet') {
    const isCombinedTab = tabSelectedIsCombined(Data.Tabs, currentTab);
    if (isCombinedTab) {
      years = getSelectedTabYears(Data.Tabs);
      data.years = years;
      route = '/api/questions/multipleYears';
    } else {
      year = yearList[currentTab] || '';
      route = `/api/questions/${year}`;
    }
  }
  return axios.post(route, data).then((response) => {
    const questions = normalizeQuestions(response.data);
    dispatch({ type: LOAD_QUESTIONS, payload: questions });
  }).catch((error) => {
    showNotification({ message:'Error loading questions', type: 'error' })
    Sentry.captureException(error);
  });
};

import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

class Scrollbar extends Component {
  render() {
    return (
      <Scrollbars
        autoHide
        renderTrackHorizontal={props => <div style={{ display: "none" }} />}
        renderThumbHorizontal={props => <div style={{ display: "none" }} />}
      >
      {this.props.children}
      </Scrollbars>
    )
  }
}
    
export default Scrollbar;
import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import styled from 'styled-components';
import _ from 'lodash';
import colors from './colors';
import Plotly from './Plotly';

const Plot = createPlotlyComponent(Plotly);

const tableData = {
  type: 'table',
  name: 'test name',
  header: {
    values: [],
    align: 'right',
    line: { width: 1, color: 'black' },
    fill: { color: colors[0] },
    font: { family: 'Arial', size: 12, color: 'white' },
  },
  cells: {
    values: [],
    align: ['left', 'right'],
    line: { color: 'black', width: 1 },
    font: { family: 'Arial', size: 11, color: ['black'] },
  },
  textfont: { size: '8' },
  hoverinfo: 'none',
  sort: false,
  textposition: 'outside',
};

const layout = {
  width: 450,
  height: 400,
  showlegend: false,
  legend: { x: 0.6, y: 1.2 },
};

class TableChart extends Component {
  parseData = () => { //TO DO: this parser should be dynamic
    const { average, data, grouped, labels: targetLabels, target, unit } = this.props;
    const dataSet = _.cloneDeep(tableData);
    if (unit === 'all') {
      dataSet.header.values = ['','Base %', 'Tar 2 %','Index','Base 000s','Tar 2 000s'];
      dataSet.cells.values.push(data.percentages[0].map(answer => answer.label));
      data.percentages.forEach(set => {
        dataSet.cells.values.push(set.map(answer => answer.value));
      });
      data.index.forEach(set => {
        dataSet.cells.values.push(set.map(answer => answer.value));
      });
      data.thousands.forEach(set => {
        dataSet.cells.values.push(set.map(answer => answer.value));
      });
    } else {
      dataSet.header.values = ['',...targetLabels[target].labels];
      dataSet.cells.values[0] = data[0].labels;
      data.forEach(set => {
        dataSet.cells.values.push(set.values);
      });
    }
    if (average && grouped && average.length > 0) {
      dataSet.cells.values[0].push('Average');
      dataSet.cells.values[1].push(average[0]);
      dataSet.cells.values[2].push(average[1]);
    } else if (average && !grouped){
      dataSet.cells.values[0].push('Average');
      dataSet.cells.values[1].push(average);
    }
    return [dataSet];
  };

  render() {
    const dataSet = this.parseData();
    return (
      <AugmentedSize>
        <StyledChart
          divId="tableChart"
          data={dataSet}
          layout={layout}
        />
      </AugmentedSize>
    );
  }
}

const AugmentedSize = styled.div`
  position: absolute;
  top: -75px;
  left: -20px;
`;

const StyledChart = styled(Plot)`
  background-color: transparent
`;

export default TableChart;

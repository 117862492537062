import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import BannerCell from './BannerCell';
import CheckBoxComponent from './CheckBox';
import BannerMenu from './BannerMenu';
import mergeIcon from '../../assets/ico-mergedData.svg';
import { TableBody } from './Table/TableBody/Tbody';
import { Row } from './Table/TableBody/Row';
import { ColumnCell } from './Table/TableBody/ColumnCell';

class BannerColumnContainer extends Component {
  constructor(props) {
    super(props);
    const { BannerData } = this.props;
    this.state = {
      SelectedAll: false,
      selectedRow: false,
      BannerName: BannerData.BannerName,
      editingName: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { BannerName } = this.state;
    const { BannerData } = nextProps;
    const newBannerName = BannerData.BannerName;
    if (newBannerName !== BannerName) {
      this.setState({
        BannerName: BannerData.BannerName,
      });
    }
  }

  changeBannerTitle = (BannerPos, BannerName) => {
    const { functions } = this.props;
    functions.changeBannerTitle(BannerPos, BannerName);
  }

  handleInputTitle = (evt) => {
    this.setState({ BannerName: evt.target.value });
  }

  selectAllText = (evt) => {
    evt.target.select();
  }

  toogleEditName = () => {
    this.setState(({ editingName }) => ({
      editingName: !editingName,
    }));
  }


  render() {
    const {
      BannerData,
      functions,
      position,
      TargetData,
    } = this.props;
    const {
      BannerName,
      editingName,
    } = this.state;
    const { average } = BannerData;
    const newAverage = _.compact(average);
    return (
      <TableBody>
        <HeaderRow>
          <Cell>
            <RowNumber>
              1
            </RowNumber>
            <CheckBoxSpace isVisible={BannerData.SelectedAll}>
              <CheckBoxComponent
                functions={functions}
                position={position}
                isChecked={BannerData.SelectedAll}
                functionType={1}
              />
            </CheckBoxSpace>
            <ColumnTitle style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {
                !editingName
                  ? (
                    <QuestionName
                      role="button"
                      noText={!BannerName}
                      onDoubleClick={this.toogleEditName}
                    >
                      {BannerName || 'type a name'}
                    </QuestionName>
                  )
                  : (
                    <InputStyle
                      autoFocus
                      type="text"
                      placeholder="type a name"
                      onChange={this.handleInputTitle}
                      value={BannerName}
                      onBlur={() => {
                        this.changeBannerTitle(position, BannerName);
                        this.toogleEditName();
                      }}
                    />
                  )
              }
            </ColumnTitle>
            <IconMenuSpace>
              {
                BannerData.isMerged
                  && (
                    <img alt="" src={mergeIcon} width="15px" height="15px" style={{ marginBottom: '5px' }} />
                  )
              }
            </IconMenuSpace>
            <BannerMenu
              functions={functions}
              position={position}
              TargetData={TargetData}
              isMerged={BannerData.isMerged}
              BannerData={BannerData}
            />
          </Cell>
        </HeaderRow>
        {
          BannerData.BannerDataPoints.map((e, i) => {
            const isShown = e.isVisible && !e.combinedRow;
            return isShown && (
              <BannerRow key={i}>
                <BannerCell
                  rowNumber={i + 2}
                  dataPoint={e}
                  key={i}
                  selected={e.selected}
                  functions={functions}
                  position={position}
                />
              </BannerRow>
            )
          })
        }
        {
          BannerData.hasCumeTotal
          && (
            <BannerRow>
              <BannerCell
                BannerData={BannerData}
                functions={functions}
                rowNumber={BannerData.BannerDataPoints.length + 2}
                selected={false}
                dataPoint={{}}
                position={position}
                isCumeTotal
              />
            </BannerRow>
          )
        }
        {
          (newAverage.length > 0)
            && (
              <BannerRow>
                <BannerCell
                  BannerData={BannerData}
                  functions={functions}
                  rowNumber={BannerData.BannerDataPoints.length + 2}
                  selected={false}
                  dataPoint={{}}
                  position={position}
                  isAverage
                />
              </BannerRow>
            )
        }
        <BannerRow>
          <BannerCell
            BannerData={BannerData}
            functions={functions}
            rowNumber={BannerData.BannerDataPoints.length + 2}
            selected={false}
            dataPoint={{}}
            position={position}
            isLastRow
          />
        </BannerRow>
      </TableBody>
    );
  }
}

const HeaderRow = styled(Row)`
  height: 38px;
  box-sizing: border-box;
  border-top: 2px solid #607D8B;
`;

const BannerRow = styled(Row)`
  box-sizing: border-box;
  height: 32px;
`;

const Cell = styled(ColumnCell)`
  height: 38px;
  display: flex;
  background-color: #E2E7EA;
  border-right: 2px solid #E2E7Ea;
`;

const ColumnTitle = styled.div`
    font-size: 13px;
    font-weight:500;
    color: #37474F; 
    float:left;
    margin: 7px 0px 0px 5px;
    max-width:170px;
    height: calc(100% - 7px);
    text-overflow:ellipsis;
    whiteSpace: nowrap;
    overflow: hidden;

`;

const RowNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #607D8B; 
  float:left;
  margin: 10px 10px 0px 5px;
  width: 10px;
`;

const CheckBoxSpace = styled.div`    
    padding: 10px 0px 0px 15px;
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    float:left;
    ${Cell}: hover &{
        visibility:visible;
    }
`;

const InputStyle = styled.input`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  border: 1pt transparent solid;
  round-corner:2px;
  display: inline;
  background-color: #E2E7EA;
  float:left;
  height:auto;
  width:160px;
  font-size: 13px;
  ::placeholder {
      color: #607D8B;
      font-style: 'italic';
  }; 
  &:hover {
      border: 1pt #CFD8DC solid;
  };

  &:focus {
      outline: none !important;
      border: 1pt #19A2C5 solid;  
      ::selection{
        background: #C5F7E0;
      }  
  }
`;

const IconMenuSpace = styled.div`
  color: #828692;
  font-size: 12px;
  display: table-cell;
  vertical-align: middle;
  padding:0px;
  width: 15px;
  display:inline-block;
  margin-left: 10px;
  margin-right: 28px;
  margin-top: 10px;
`;

const QuestionName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1pt transparent solid;
  background-color: transparent;
  round-corner:2px;
  display: inline;
  float: left;
  height: 100%;
  width: 160px;
  margin-bottom: 12px;
  font-size: 13px;
  padding: 0px 5px 0px 0px;
  color: #607D8B;
  caret-color: #4A90E2;
  opacity: ${({ noText }) => (noText ? 0.6 : 1)}
`;

export default BannerColumnContainer;

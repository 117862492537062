import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import ModalContent from './ModalContent';
import { setBrowserType, closeBrowserManager } from '../../actions/UIManagerActions';
import {
  setCurrentBannerTab,
  resetModal,
} from '../../actions/bannerActions';

const withOpenModal = (WrappedComponent, callback) => props => (
  <WrappedComponent {...props} openModal={callback} />
);

class BuildBannerModal extends Component {
  state = {
    open: false,
  };

  open = () => {
    const {
      preventScroll, type, setCurrentBannerTab, closeBrowserManager, setBrowserType,
    } = this.props;
    if (preventScroll) {
      preventScroll(true);
    }
    this.setState({ open: true });
    setBrowserType('banner');
    closeBrowserManager();

    if (type === 'overview-add') {
      setCurrentBannerTab(0);
    }
  };

  close = () => {
    this.setState({ open: false }, () => {
      const { preventScroll, cleanModal } = this.props;
      if (preventScroll) {
        preventScroll(false);
      }
      cleanModal();
    });
  };

  render() {
    const { open } = this.state;
    const { type, trigger } = this.props;
    const classNames = {
      overlay: 'overlay',
      modal: 'modal',
      closeIcon: 'closeButton',
    };
    const TriggerWithOpenModal = withOpenModal(trigger, this.open);
    return (
      <Fragment>
        <TriggerWithOpenModal />
        <Modal
          open={open}
          onClose={this.close}
          classNames={classNames}
          center
        >
          <ModalContent close={this.close} type={type} />
        </Modal>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setBrowserType: type => dispatch(setBrowserType(type)),
  setCurrentBannerTab: tab => dispatch(setCurrentBannerTab(tab)),
  closeBrowserManager: () => dispatch(closeBrowserManager()),
  cleanModal: () => dispatch(resetModal()),
});

export default connect(null, mapDispatchToProps)(BuildBannerModal);

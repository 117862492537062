import React, { Component } from 'react';
import propTypes from 'prop-types';
import Body from './body';

class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      data: props.data,
      keyField: props.keyField,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      data: props.data,
      showHeader: props.showHeader,
      keepSortingOnChange: props.keepSortingOnChange,
    });
  }

  render() {
    const {
      columns, data, keyField, keepSortingOnChange,
    } = this.state;
    const { showHeader, limit } = this.props;
    return (
      <Body
        keyField={keyField}
        data={data}
        limit={limit}
        columns={columns}
        showHeader={showHeader}
        keepSortingOnChange={keepSortingOnChange}
      />
    );
  }
}

Datatable.propTypes = {
  columns: propTypes.array,
  data: propTypes.array,
  keyField: propTypes.string,
  showHeader: propTypes.bool,
  keepSortingOnChange: propTypes.bool,
};

Datatable.defaultProps = {
  showHeader: true,
  keepSortingOnChange: true,
};

export default Datatable;

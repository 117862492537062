import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';
import Axios from 'axios';
import StowellBox from '../StowellBox';
import {
  Label, Text, Message, ButtonsContainer, AlertBox,
} from '../FormComponents/StyledComponents';
import TextInput from '../FormComponents/TextInput';
import Button from '../FormComponents/Button';

class RecoveryPasswordForm extends Component {
  state = {
    password: '',
    confirmPassword: '',
    errorMessage: '',
    loading: false,
    resetedDone: false,
  }

  confirmPassword = React.createRef();

  componentWillMount() {
    this.checkValidInvitation();
  }

  redirectToLogin = () => {
    const { history } = this.props;
    history.push('/login');
  }

  checkValidInvitation = () => {
    const { match, logged } = this.props;
    const { userId, teamId } = match.params;
    if (!logged) {
      Axios.get(`/api/external/check-confirm-route/${teamId}/${userId}/`)
        .then((res) => {
          if (!res.data.success) {
            this.redirectToLogin();
          }
        })
        .catch(() => {
          this.redirectToLogin();
        });
    } else {
      this.redirectToLogin();
    }
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value.trim(),
    });
  }

  focusInput = () => {
    if (this.confirmPassword.current) {
      this.confirmPassword.current.focus();
    }
  }

  checkForNumberInside = pass => /\d/.test(pass)

  checkForWordInside = pass => /\D/.test(pass)

  checkPasswords = (e) => {
    this.setState({ errorMessage: '' });
    const { confirmPassword, password } = this.state;
    if ((confirmPassword === password)) {
      let isError = false;
      if (password.length < 8) {
        this.setState({ errorMessage: 'Password must have at least 8 characters.' });
        isError = true;
      }
      if (!this.checkForNumberInside(password)) {
        this.setState({ errorMessage: 'Password must have at least a number.' });
        isError = true;
      }
      if (!this.checkForWordInside(password)) {
        this.setState({ errorMessage: 'Password must have at least a letter.' });
        isError = true;
      }
      if (isError) {
        this.focusInput();
        e.preventDefault();
      }
    }
    else {
      this.setState({ errorMessage: 'Passwords do not match.' });
      this.focusInput();
      e.preventDefault();
    }
  }


  acceptInvitation = () => new Promise((resolve, reject) => {
    const { match } = this.props;
    const { password, confirmPassword } = this.state;
    const { teamId, userId } = match.params;
    if (password === confirmPassword) {
      Axios.post(`/api/external/confirm/${teamId}/${userId}/`, { password })
        .then((res) => {
          if (res.data.success) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }

  })

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    this.acceptInvitation()
      .then(() => {
        this.setState({ loading: false });
        this.setState({ resetedDone: true });
        // this.resetPassword();
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ errorMessage: error.message });
      });
  }

  goBack = () => {
    const { history } = this.props;
    history.push('/login');
  }

  render() {
    const {
      errorMessage, successMessage, password, confirmPassword,
      loading, resetedDone,
    } = this.state;
    return (
      <StowellBox>
        <Text fontSize="28">Set new password</Text>
        <MarginTop marginTop="20" />
        {
          resetedDone
          && (
          <AlertBox>
            <Text fontSize="17">Your password has been updated</Text>
          </AlertBox>
          )
        }
        <form onSubmit={this.handleSubmit}>
          <MarginTop marginTop="20" />
          <Label>New password (min. 8 characters, must include at lest one number)</Label>
          <TextInput
            success={!!successMessage}
            error={!!errorMessage}
            type="password"
            name="password"
            onChange={this.handleChange}
            value={password}
            disabled={resetedDone}
          />
          <MarginTop marginTop="20" />
          <Label error={!!errorMessage}>Confirm password</Label>
          {
            !!errorMessage
            && <Message color="#ED2A2A">{errorMessage}</Message>
          }
          <TextInput
            success={!!successMessage}
            error={!!errorMessage}
            type="password"
            name="confirmPassword"
            onChange={this.handleChange}
            value={confirmPassword}
            innerRef={this.confirmPassword}
            disabled={resetedDone}
          />
          <ButtonsContainer justifyContent={resetedDone ? 'space-between' : 'flex-end'}>
            {
              resetedDone
              && (
              <Button
                onClick={this.goBack}
                backgroundColor="#19A2C5"
                icon={faArrowLeft}
                text="Login"
              />
              )
            }
            <Button
              backgroundColor={resetedDone ? 'green' : false}
              icon={faCheck}
              text={resetedDone ? 'Accepted' : 'Accept'}
              onClick={this.checkPasswords}
              disabled={loading || !(!!confirmPassword && !!password) || resetedDone}
              type="submit"
            />
          </ButtonsContainer>
        </form>
      </StowellBox>
    );
  }
}

RecoveryPasswordForm.propTypes = {
  match: PropTypes.shape({ params: { token: PropTypes.string.isRequired } }).isRequired,
  history: PropTypes.shape({ foo: { bar: {} } }).isRequired,
  logged: PropTypes.bool.isRequired,
};

const mapStateToProps = store => ({
  logged: store.AppReducer.logged,
});

export default connect(mapStateToProps)(withRouter(RecoveryPasswordForm));

const MarginTop = styled.div`
  margin-top: ${({ marginTop }) => (MarginTop ? marginTop : '0')}px;
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { getCurrentSurvey } from '../../actions/marketActions';

class MarketSize extends Component {
  componentDidMount() {
    const { getCurrentSurvey } = this.props;
    getCurrentSurvey();
  }

  render() {
    const { currentSurvey: { sample, population }, baseTarget } = this.props;
    return (
      <Size>
        <p style={{margin: 5}}>n = {baseTarget.size || sample} / {population.toLocaleString('en-US')} </p>
      </Size>
    );
  }
}

MarketSize.propTypes = {
  currentSurvey: propTypes.shape({
    sample: propTypes.number.isRequired,
    population: propTypes.number.isRequired,
  }).isRequired,
};
const mapStateToProps = state => ({
  currentSurvey: state.marketReducer.currentSurvey,
  baseTarget: state.targetReducer.baseTarget,
});

const mapDispatchToProps = dispatch => ({
  getCurrentSurvey: () => dispatch(getCurrentSurvey()),
});

const Size = styled.div`
  color: #607D8B;
  font-size: 12px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(MarketSize);

import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MobileMenu } from './MobileMenu';

export class MobileNavigationBar extends Component {
  state = {
    openMenu: false,
  }

  toogleMenu = () => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: !openMenu,
    });
  }

  render() {
    const { openMenu } = this.state;
    const { user } = this.props;
    return (
      <Nav>
        <Background
          openMenu={openMenu}
        >
          <Nav>
            <Container>
              <IconMenu
                onClick={this.toogleMenu}
              >
                <FontAwesomeIcon icon={openMenu ? faTimes : faBars} />
              </IconMenu>
              <TextMenu>
                <span className="stowell">STOWELL </span>
                <span className="data">DATA</span>
              </TextMenu>
              {
                openMenu && (
                <MobileMenu
                  user={user}
                  toogleMenu={this.toogleMenu}
                />
                )
              }
            </Container>
          </Nav>
        </Background>
      </Nav>
    );
  }
}
const Nav = styled.nav`
  background: #263238;
  height: 60px;
  font-family: 'Roboto', sans-serif;
  color: #F6F7F8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  box-sizing: border-box;
`;

const Container = styled.div`
  width: auto;
  height: 60px;
  display: flex;
  padding-left: 10px;
`;

const IconMenu = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  font-size: 20px;
  padding: 3px 20px 0 10px;
  cursor: pointer;
  width: 20px;
`;

const TextMenu = styled.div`
  padding: 20px 10px;
  font-family: Roboto;
  .stowell {
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 800;
  }
  .data {
    color: #FFFFFF;
    font-size: 20px;
  }
`;

const Background = styled.div`
  top: 0;
  ${''}
  position: ${({ openMenu }) => (openMenu ? 'fixed' : 'block')};
  z-index: 99999;
  background-color: rgba(0,0,0,0.35);
  width: 100%;
  height: ${({ openMenu }) => (openMenu ? '100%' : 'all')};
  ${''}
`;

import {
  TARGET_TITLE_ERROR, BROWSER_TYPE, TOGGLE_BROWSER_MANAGER, OPEN_BROWSER_MANAGER, CLOSE_BROWSER_MANAGER,
} from '../actionTypes/UIManagerTypes';

const defaultState = {
  target: {
    missingTitle: false,
    missingQuestions: false,
  },
  browserType: 'target',
  browserManager: {
    activeTree: 0,
    showTree: false,
  },
};

const UIManagerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case TARGET_TITLE_ERROR:
      return { ...state, target: { ...state.target, missingTitle: action.payload } };
    case BROWSER_TYPE:
      return { ...state, browserType: action.payload, browserManager: defaultState.browserManager };
    case TOGGLE_BROWSER_MANAGER:
      return {
        ...state,
        browserManager: {
          ...state.browserManager,
          showTree: !state.browserManager.showTree,
        },
      };
    case OPEN_BROWSER_MANAGER:
      return {
        ...state,
        browserManager: {
          ...state.browserManager,
          showTree: true,
        },
      };
    case CLOSE_BROWSER_MANAGER:
      return {
        ...state,
        browserManager: {
          ...state.browserManager,
          showTree: false,
        },
      };
    default:
      return state;
  }
};

export default UIManagerReducer;

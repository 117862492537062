import Axios from 'axios';
import store from '../store';
import { LogOutAction } from '../actions/appActions';
import { setAuthorizationToken } from './setAuthorizationToken';

export const checkToken = async () => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    try {
      const response = await Axios.post('/api/auth/check-session-token', { token })
      setAuthorizationToken(token);
      return response.data;
    } catch (error) {
      store.dispatch(LogOutAction());
    }
  }
  store.dispatch(LogOutAction());
};

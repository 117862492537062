import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
class Template extends Component {

  handleClick = () => {
    const { template, actions } = this.props;
    const { changeMenu, restore, setCurrent } = actions;
    restore(template.data);
    changeMenu('selected');
    setCurrent({ id: template._id, title: template.title });
  }

  render() {
    const { template, currentTemplate } = this.props;
    const isSelected = template._id === currentTemplate.id;
    const isOwner = this.props.user._id === _.get(template.user,'_id')
    return (
      <Container onClick={this.handleClick}>
        <Title isSelected={isSelected}>
          {template.title}
          {
            (template.shared && !isOwner)
            && (
              <SharedIcon>
                <FontAwesomeIcon icon={faUserFriends} />
              </SharedIcon>
            )
          }
        </Title>
      </Container>
    );
  }
}

const Container = styled.div`
`;

const Title = styled.p`
  margin: 4px 0;
  padding: 0;
  font-size: 13px;
  color: #19A2C5;
  cursor: pointer;
  ${({ isSelected }) => isSelected && 'color: #15738B;'}
`;
const SharedIcon = styled.p`
  color: gray;
  display: inline-block;
  padding: 0px;
  margin-left: 10px;
`;
export default Template;

import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink as RouterLink } from 'react-router-dom';
import './mobile.css';

export class MobileMenu extends Component {
  logOut = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('currentDatabase');
    localStorage.removeItem('currentAccountFolder');
    localStorage.removeItem('currentAccountId');
    window.location.reload();
  }

  getMenuOptions = () => {
    const { user } = this.props;
    const isAdminUser = user.role.name === 'admin';
    const isOwnerOrManagerUser = user.role.name === 'manager' || user.role.name === 'owner';
    let hasMoreThanOneDb = false;
    if (user.teamId.databases.length > 1 || isAdminUser) {
      hasMoreThanOneDb = true;
    }
    const permissions = {};
    if (isAdminUser || isOwnerOrManagerUser) {
      permissions.canSeeManageTeam = true;
      permissions.canSeeChangeDb = true;
    }
    if (isAdminUser) {
      permissions.canSeeAdminButton = true;
      permissions.canSeeChangeDb = true;
    }
    const options = [
      {
        notVisible: !isAdminUser,
        link: '/accounts',
        text: 'Accounts',
      },
      {
        link: '/overview',
        text: 'Overview',
      },
      {
        link: '/projects',
        text: 'My Projects',
      },
      {
        link: '/support',
        text: 'Support',
      },
      {
        link: '/documents',
        text: 'Documents',
      },
      {
        link: '/profile',
        text: 'My Profile',
      },
      {
        notVisible: !permissions.canSeeManageTeam,
        link: '/team/members',
        text: 'Manage Team',
      },
      {
        notVisible: !permissions.canSeeChangeDb,
        disabled: !hasMoreThanOneDb,
        link: '/database-selector',
        text: 'Sign in to other database',
      },
    ];
    return options;
  }

  render() {
    const options = this.getMenuOptions();
    const { toogleMenu, user } = this.props;
    return (
      <Container
        id="container-mobile-menu"
      >
        <Menu>
          {
            options.map((item, index) => !item.notVisible && (
            <Item disabled={item.disabled} key={index}><Link activeClassName="item-active" onClick={toogleMenu} to={item.link}>{item.text}</Link></Item>
            ))
          }
          <Item>
            <p className="username">
              <span>{user.first_name}</span>
            </p>
            <p className="teamname">
              <span>{user.teamId.name}</span>
            </p>
          </Item>
          <Item><Link onClick={this.logOut} to="/">Sign Out</Link></Item>
        </Menu>
      </Container>
    );
  }
}

const Container = styled.div`
  top: 60px;
  left: 0;
  position: absolute;
  width: 270px;
  height: 100%;
  background-color: #FFFFFF;
`;

const Menu = styled.ul`
  width: 270px;
  padding: 0;
`;

const Item = styled.div`
  min-height: 31px;
  padding: 10px 0 0 0;
  border-bottom: 1px solid #CFD8DC;
  color: red;
  color: #37474F;
  font-size: 13px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.5;'};
  p {
    margin: 0;
  }
  .username {
    padding: 0 0 0 18px;
    font-weight: 500;
  }
  .teamname {
    padding: 2px 0 10px 18px;
  }
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: #37474F;
  font-size: 13px;
  width: 231px !important;
  padding: 5px 100px 8px 18px; 
  font-weight: normal;
  letter-spacing: 0.1px;
  line-height: 18px;
  margin: 100px 0 0 0;
`;

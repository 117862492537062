import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Trigger from './SubcategoryTrigger';
import Questions from './Questions';


class Subcategory extends Component {
  render() {
    const {
      actions,
      activeCollapsibles,
      blockId,
      handlers,
      path,
      subcategory,
    } = this.props;
    const { subcategory: activeSubcategory } = activeCollapsibles;
    const open = activeSubcategory === subcategory.name;
    return (
      <Wrapper key={subcategory.name}>
        <Collapsible
          key={subcategory.name}
          accordionPosition={subcategory.name}
          handleTriggerClick={open ? handlers.subcategory.close : handlers.subcategory.open}
          open={open}
          trigger={<Trigger title={subcategory.name} />}
          triggerWhenOpen={<Trigger title={subcategory.name} open />}
          lazyRender={!open}
        >
          <Questions
            actions={actions}
            activeCollapsibles={activeCollapsibles}
            blockId={blockId}
            handlers={handlers}
            path={{ ...path, subcategory: subcategory.name }}
            questions={subcategory.questions}
          />
        </Collapsible>
      </Wrapper>
    );
  }
}

Subcategory.propTypes = {
  actions: propTypes.object.isRequired,
  activeCollapsibles: propTypes.object.isRequired,
  blockId: propTypes.number.isRequired,
  handlers: propTypes.object.isRequired,
  path: propTypes.object.isRequired,
  subcategory: propTypes.object.isRequired,
};

const Wrapper = styled.div`
  border-top: 1px #DEE1E3 solid;
`;

export default Subcategory;

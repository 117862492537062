import React, { Component } from 'react';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import Proptype from 'prop-types';
import { ToolbarButton } from './ToolbarButton';


class ToolbarButtonChart extends Component {
  handleOpenWindowChart = () => {
    const { functions } = this.props;
    functions.openWindowCharts();
  }


  render() {
    const { Data, currentTab } = this.props;

    const isDisabled = Data.Tabs[currentTab].Banners.length < 1;
    return (
      <>
        <ToolbarButton
          id="chart-button-id"
          onClick={this.handleOpenWindowChart}
          text="Chart"
          leftIcon={{
            type: 'font',
            icon: faChartBar,
          }}
          disabled={isDisabled}
        />
      </>
    );
  }
}

ToolbarButtonChart.propTypes = {
  functions: Proptype.object.isRequired,
  Data: Proptype.object.isRequired,
  currentTab: Proptype.number.isRequired,
};

export default ToolbarButtonChart;

import React, { Component } from 'react';
import List from './List';
import EditTemplate from './EditTemplate';

class Templates extends Component {
  render() {
    const { actions, data } = this.props;
    const { menu } = data.templates;
    return (
      <>
        {(menu === 'list' || menu === 'selected') && <List actions={actions.templates} data={data.templates} />}
        { menu === 'edit' && <EditTemplate actions={actions} data={data} /> }
      </>
    );
  }
}

export default Templates;

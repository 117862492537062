import React, { Component } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert2';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// MODAL
import Modal from 'react-responsive-modal';
import ListSubheader from '@material-ui/core/ListSubheader';
import mergeIcon from '../../assets/ico-mergedData.svg';
import CheckBoxComponent from './CheckBox';
import CumeTotalModal from './CumeTotalModal';
import CumeTotalIcon from '../../assets/ico-function.svg';
import InsertDatapointIcon from '../../assets/ico-insertDatapoint.svg';

class BannerCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editCumeTotal: false,
      isClicked: false,
      open: false,
      RowName: this.props.dataPoint.name,
      selectedForCumeTotal: this.props.dataPoint.selectedForCumeTotal,
      openCumeTotalModal: false,
      openAddDataPointModal: false,
      selectedText: false,
      editingName: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dataPoint.name !== this.props.dataPoint.name) {
      this.setState({
        RowName: nextProps.dataPoint.name,
      });
    }
  }

ChangeStyleOnClick = () => {
  this.setState({
    isClicked: !this.state.isClicked,
  });
}

handleClick = (event) => {
  event.preventDefault();

  this.setState({
    open: true,
    anchorEl: event.currentTarget,
  });


}

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  changeRowName = (BannerPos, RowNum, RowName) => {
    this.props.functions.changeRowName(BannerPos, RowNum, RowName);
    this.setState({
      selectedText: false,
    });
  }

  handleInputTitle = (evt) => {
    this.setState({ RowName: evt.target.value });
  }

  selectAllText = (evt) => {
    // evt.target.select();
    this.setState({
      selectedText: true,
    });
  }

  addCumeTotal = (BannerPos) => {
    this.props.functions.addCumeTotal(BannerPos);
  }

  openCumeTotalModal = () => {
    this.setState({
      openCumeTotalModal: true,
    });
  }

  closeCumeTotalModal = () => {
    this.setState({
      openCumeTotalModal: false,
    });
  }

  openAddDataPointModal = () => {
    this.setState({
      openAddDataPointModal: true,
    });
  }

  closeAddDataPointModal = () => {
    this.setState({
      openAddDataPointModal: false,
    });
  }

  handleEditBlock = () => {
    this.props.functions.loadEditBanner(this.props.BannerData.dataStructure, this.props.position);
    this.handleRequestClose();
  }

  getCumeTotalRow = () => {
    const { editCumeTotal } = this.state;
    return (
      <tr>
        <RowNumber>
          {this.props.rowNumber}
        </RowNumber>

        <td style={{ fontWeight: '500', paddingLeft: '10px', color: '#FF8000' }}>
          <IconEditButton
            type="button"
            onClick={() => this.setState({
              openCumeTotalModal: true,
              open: false,
              editCumeTotal: true,
            })}
          >
            <FontAwesomeIcon icon={faEdit} />
          </IconEditButton>
          Cume Total
        </td>
        <Modal
          open={this.state.openCumeTotalModal}
          onClose={this.closeCumeTotalModal}
          classNames={{ overlay: 'overlay', modal: 'modal', closeIcon: 'closeButton' }}
          center
          closeIconSize={20}
          styles={{
            overlay: {
              background: 'rgba(0, 0, 0, 0.5)',
            },
            modal: {
              width: 'auto',
              height: 'auto',
              borderRadius: '12px',
              margin: 'auto',
              padding: 0,
            },

            closeButton: {
              top: '5px',
              right: '10px',
              width: '20px',
              height: '20px',
              fill: 'gray',
            },
          }}
        >
          <CumeTotalModal
            BannerData={this.props.BannerData}
            position={this.props.position}
            functions={this.props.functions}
            closeCumeTotalModal={this.closeCumeTotalModal}
            editCumeTotal={editCumeTotal}
          />
        </Modal>
      </tr>
    );
  }

  openCumeTotalModal = () => {
    if (this.props.BannerData.questionCode) {
      if (this.props.BannerData.bannerDataStructure.bannerType !== "complex") {
        this.setState({ openCumeTotalModal: true, open: false });
      } else {
        this.setState({ openCumeTotalModal: false, open: false });
        swal({
          title: 'Warning',
          text: 'Option not supported for custom questions.',
          allowOutsideClick: false,
        });
      }    
    } else {
      this.setState({ openCumeTotalModal: false, open: false });
      swal({
        title: 'Warning',
        text: 'Please unmerge question blocks before adding cume total to this question.',
        allowOutsideClick: false,
      });
    }



  }

  getIsLastRow = () => {
    const {
      editCumeTotal,
      anchorEl,
      open,
      openCumeTotalModal,
    } = this.state;
    const commonStyle = { fontSize: '13px !important' };
    return (
      <tr>
        <RowNumber>
          <span>
            <span onClick={this.handleClick} style={{ cursor: 'pointer' }}>
              +
            </span>
            <Menu
              id="simple-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleRequestClose}
            >
              <ListSubheader style={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                color: '607D8B',
                outline: 'none',
                fontSize: '12px',
                height: '30px',
                lineHeight: '38px',
              }}
              >
                Insert
              </ListSubheader>
              <MenuItem style={commonStyle} onClick={() => { this.openCumeTotalModal(); }}>
                <IconImage
                  src={CumeTotalIcon}
                  alt=""
                />
                <ItemText>
                  Cume Total
                </ItemText>
              </MenuItem>
              <MenuItem
                disabled
                style={commonStyle}
                onClick={() => {
                  // not implemented in this version 1
                  // this.handleEditBlock()
                }}
              >
                <IconImage
                  src={InsertDatapointIcon}
                  alt=""
                />
                <ItemText>
                  Data-Point
                </ItemText>
              </MenuItem>
            </Menu>
          </span>
        </RowNumber>
        <Modal
          open={openCumeTotalModal}
          onClose={this.closeCumeTotalModal}
          classNames={{ overlay: 'overlay', modal: 'modal', closeIcon: 'closeButton' }}
          center
          closeIconSize={20}
          styles={{
            overlay: {
              background: 'rgba(0, 0, 0, 0.5)',
            },
            modal: {
              width: 'auto',
              height: '520px',
              borderRadius: '12px',
              margin: 'auto',
              padding: 0,
            },

            closeButton: {
              top: '5px',
              right: '10px',
              width: '20px',
              height: '20px',
              fill: 'gray',
            },
          }}
        >
          <CumeTotalModal
            BannerData={this.props.BannerData}
            position={this.props.position}
            functions={this.props.functions}
            closeCumeTotalModal={this.closeCumeTotalModal}
            edit={editCumeTotal}
          />
        </Modal>
        {this.props.isLastRow ? null
          : (
            <CheckBoxSpace isVisible={this.props.selected}>
              {this.props.dataPoint != null ? <CheckBoxComponent isChecked={this.props.selected ? 'checked' : null} functions={this.props.functions} functionType={2} position={this.props.position} rowNum={this.props.rowNumber} /> : null}
            </CheckBoxSpace>
          )
        }
        <DataPoints>
          {this.props.dataPoint.name !== undefined
            ? <InputStyle selectedForCumeTotal={this.props.dataPoint.selectedForCumeTotal} type="text" placeholder="DataPoint name" onClick={this.selectAllText} onChange={this.handleInputTitle} value={this.state.RowName} onBlur={() => this.changeRowName(this.props.position, this.props.rowNumber - 2, this.state.RowName)} />
            : null
          }
        </DataPoints>
        {this.props.dataPoint.mergedRow
          ? <td><img src={mergeIcon} alt="" width="15px" height="15px" style={{ marginBottom: '5px', float: 'right' }} /></td>
          : null
        }

      </tr>
    );
  }

  toogleEditName = () => {
    this.setState(({ editingName }) => ({
      editingName: !editingName,
    }));
  }

  getDatapointName = () => {
    const {
      rowNumber,
      dataPoint,
      functions,
      position,
    } = this.props;
    const { RowName, editingName } = this.state;
    if (dataPoint.name !== undefined) {
      if (!editingName) {
        return (
          <DataPointName
            role="button"
            onDoubleClick={this.toogleEditName}
            selectedForCumeTotal={dataPoint.selectedForCumeTotal}
            noText={!RowName}
          >
            {RowName || 'type data-point name'}
          </DataPointName>
        );
      }
      return (
        <InputStyle
          autoFocus
          selectedForCumeTotal={dataPoint.selectedForCumeTotal}
          type="text"
          placeholder="type data-point name"
          onChange={this.handleInputTitle}
          value={RowName}
          onBlur={() => {
            this.changeRowName(position, rowNumber - 2, RowName);
            this.toogleEditName();
          }}
        />
      );
    }
    return null;
  }

  getDataRow = () => {
    const {
      rowNumber,
      selected,
      dataPoint,
      functions,
      position,
    } = this.props;
    return (
      <tr>
        <RowNumber>
          <span style={{ width: '10px' }}>{rowNumber}</span>
        </RowNumber>
        <CheckBoxSpace isVisible={selected}>
          {
            (dataPoint != null)
              && (
                <CheckBoxComponent
                  isChecked={selected ? 'checked' : null}
                  functions={functions}
                  functionType={2}
                  position={position}
                  rowNum={rowNumber}
                />
              )
          }
        </CheckBoxSpace>
        <DataPoints>
          {this.getDatapointName()}
        </DataPoints>
        {
          dataPoint.mergedRow
            && (
              <td><img src={mergeIcon} alt="" width="15px" height="15px" style={{ marginBottom: '5px', float: 'right' }} /></td>
            )
        }

      </tr>
    );
  }

  getAverageRow = () => {
    const { rowNumber } = this.props;
    return (
      <tr>
        <RowNumber>
          <span style={{ width: '10px' }}>{rowNumber}</span>
        </RowNumber>
        <DataPoints
          style={{ marginLeft: '35px', fontWeight: '500', color: '#19A2C5' }}
        >
          Average
        </DataPoints>
      </tr>
    );
  }

  getLastRow = () => {
    const { isCumeTotal, isAverage, isLastRow } = this.props;
    if (isCumeTotal) {
      return this.getCumeTotalRow();
    }
    if (isLastRow) {
      return this.getIsLastRow();
    }
    if (isAverage) {
      return this.getAverageRow();
    }
    return this.getDataRow();
  }

  render() {
    const ClickedCellStyle = {
      backgroundColor: '#E8F5F9',
    };

    const { selected } = this.props;
    const { isClicked, selectedText } = this.state;
    return (
      <Cell style={isClicked || selected || selectedText ? ClickedCellStyle : null}>
        <table>
          <tbody>
            {this.getLastRow()}
          </tbody>
        </table>
      </Cell>
    );
  }
}


const Cell = styled.div`
    height: 32px;
    border-bottom:  2px solid #E2E7EA;    
    font-size: 13px;
    font-weight:400;
    color: #37474F;
    background-color: white;   
    width:300px;
    display: inline-block;
    border-right: 2px solid #E2E7Ea;
`;

const RowNumber = styled.td`
    font-weight: 400;
    font-size: 12px;
    color: #607D8B; 
    float:left;
    margin: 5px 10px 0px 5px;
    width: 10px;
`;

const DataPoints = styled.td`
    font-size: 13px;
    font-weight:400;
    color: #37474F; 
    width: 200px;
    float:left;
    margin: 3px 0px 0px 5px;
    text-overflow:ellipsis;
    whiteSpace: nowrap;
    overflow: hidden;
`;

const CheckBoxSpace = styled.td`    
    padding: 5px 0px 0px 15px;
    visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
    float:left;
    ${Cell}: hover &{
        visibility:visible;
    }
`;

const InputStyle = styled.input`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1pt transparent solid;
  background-color: transparent;
  round-corner:2px;
  display: inline;
  float:left;
  height:100%;
  width:160px;
  margin-bottom: 15px;
  font-size: 13px;
  padding: 0px 5px 0px 0px;
  color: ${props => props.selectedForCumeTotal ? '#FF8000' : 'black'};
  caret-color: #4A90E2;
  ::placeholder {
      color: #607D8B;
      font-style: 'italic';
  }; 
  &:hover {
      border: 1pt #CFD8DC solid;
  };

  &:focus {
      outline: none !important;
      border: 1pt #19A2C5 solid;  
      ::selection {
        background: #C5F7E0;
      };
  }
`;

const IconEditButton = styled.button`
  background-color: transparent;
  font-weight: 500;
  color: gray;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  padding: 0;
  outline: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
`;

const IconImage = styled.img`
  margin-right: 10px;
  padding-left: 10px;
  width: 15px;
  height: 15px;
`;

const ItemText = styled.span`
  padding-right: 15px;
  color: #37474F;
  font-family: Roboto;
  font-weight: 400;
  font-size: 13px !important;
`;

const getColor = (props) => {
  const { selectedForCumeTotal, noText } = props;
  if (selectedForCumeTotal && !noText) {
    return '#FF8000';
  }
  if (noText) {
    return 'rgba(0,0,0,0.5)';
  }
  return 'black';
};

const DataPointName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1pt transparent solid;
  background-color:transparent;
  round-corner:2px;
  display: inline;
  float: left;
  height: 100%;
  width: 200px;
  margin-bottom: 12px;
  font-size: 13px;
  padding: 0px 5px 0px 0px;
  color: ${props => getColor(props)};
  caret-color: #4A90E2;
`;

export default BannerCell;

import styled from 'styled-components';

export const Label = styled.label`
  color: ${({ error }) => (error ? '#ED2A2A' : '#607D8b')};
  ${({ fontColor }) => (fontColor && `color: ${fontColor};`)};
  font-size: 13px;  
  font-family: Roboto;
  font-weight:400;
  margin-bottom: 5px;
`;

export const Text = styled.p`
  font-family: Roboto;
  margin: 0px;
  padding: 0px;
  font-size: ${({ fontSize }) => (fontSize || '14')}px;
  font-weight: ${({ bold }) => bold && '500'};
  color: ${({ color }) => color || '#37474F'};
  margin-top: ${({ marginTop }) => marginTop || '0'}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}em` : 'normal')};
`;

export const Message = styled.p`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  float: right;
`;

export const ButtonsContainer = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: ${({ justifyContent }) => (justifyContent || 'space-between')};
`;

export const AlertBox = styled.div`
  background-color: rgba(25,162,197, 0.4);
  border-radius: 6px;
  border: 1.5px solid rgba(0, 0, 0, 0.5);
  color: black;
  padding: 15px 10px;
  & > p {
    color: black;
  }
`;

import React,{ Component } from "react";
import UpdateTemplate from './UpdateTemplate';
import Blocks from '../../GlobalComponents/Builder';
import styled from 'styled-components';

class EditTemplate extends Component {
  render(){
    const {actions, data} = this.props;
    return (
      <Container>
        <UpdateTemplate actions={actions.templates} data={data.templates}/>
        <Blocks actions={actions} blocks={data.builder.blocks}/>
      </Container>
    );
  }
}
const Container = styled.div`
  margin-left: 32px;
  height: 100%;
`;
export default EditTemplate;
import { injectGlobal } from 'styled-components';
import OpenSansExtrabold from '../assets/fonts/OpenSans-ExtraBold.ttf';
import OpenSansRegular from '../assets/fonts/OpenSans-Regular.ttf';

injectGlobal`
  @font-face {
    font-family: 'OpenSansExtrabold';
    src: url('${OpenSansExtrabold}') format('opentype');
  }
`;

injectGlobal`
  @font-face {
    font-family: 'OpenSansRegular';
    src: url('${OpenSansRegular}') format('opentype');
  }
`;

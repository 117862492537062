import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import MenuItem from '@material-ui/core/MenuItem';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MergeIcon from '../../../assets/ico-merge.svg';
import { ToolbarButton } from './ToolbarButton';

class ToolbarButtonMerge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const mergeButton = document.getElementById('merge-button-id');
    this.anchorEl = mergeButton;
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (event && this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleMergeBlocks = () => {
    const { functions } = this.props;
    functions.mergeBlock();
    this.handleRequestClose();
  }

  handleMergeRows = () => {
    const { functions } = this.props;
    functions.mergeRows();
    this.handleRequestClose();
  }

  handleUnmergeRows = () => {
    const { functions } = this.props;
    functions.unmergeRows();
    this.handleRequestClose();
  }

  handleUnmergeBlock = () => {
    const { functions } = this.props;
    functions.unmergeBlock();
    this.handleRequestClose();
  }

  render() {
    const { open } = this.state;
    const {
      canMergeRows,
      canMergeBlocks,
      canUnmergeRow,
      canUnmergeBlock,
    } = this.props;
    return (
      <>
        <ToolbarButton
          id="merge-button-id"
          onClick={this.handleToggle}
          text="Merge"
          leftIcon={{
            type: 'img',
            icon: MergeIcon,
            style: {
              height: '15px',
            },
          }}
          rightIcon={{
            type: 'font',
            icon: open ? faAngleUp : faAngleDown,
          }}
          style={{ marginRight: 0 }}
        />
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <Item
                      disabled={!canMergeRows}
                      onClick={this.handleMergeRows}
                    >
                      Merge Rows
                    </Item>
                    <Item
                      disabled={!canMergeBlocks}
                      onClick={this.handleMergeBlocks}
                    >
                      Merge Questions
                    </Item>
                    <Item
                      disabled={!canUnmergeRow}
                      onClick={this.handleUnmergeRows}
                    >
                      Unmerge Rows
                    </Item>
                    <Item
                      disabled={!canUnmergeBlock}
                      onClick={this.handleUnmergeBlock}
                    >
                      Unmerge Questions
                    </Item>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}

ToolbarButtonMerge.propTypes = {
  functions: PropTypes.instanceOf(Object).isRequired,
  canMergeRows: PropTypes.bool.isRequired,
  canMergeBlocks: PropTypes.bool.isRequired,
  canUnmergeRow: PropTypes.bool.isRequired,
  canUnmergeBlock: PropTypes.bool.isRequired,
};

const Item = styled(MenuItem)`
  font-size: 13px !important;
`;

export default ToolbarButtonMerge;

import React from 'react';
import './CheckBoxStyle.css';
import styled from 'styled-components';

const checkBoxLabelStyle = {
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 400,
  color: '#37474F',
  marginLeft: '6px',
};

const CheckBox = props => (
  <Label
    className="container"
    htmlFor={props.id}
    disabled={props.disabled}
    onClick={(e) => {
      if (props.onClick) {
        props.onClick(e, props.name);
      }
    }}
  >
    {
      props.label 
      && 
      <span className="checkbox-label" style={checkBoxLabelStyle}>
        {props.label}
      </span>
    }
    <input {...props} type="checkbox" />
    <span className="checkmark" />
  </Label>
);

const Label = styled.label`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  .checkbox-label {
    margin-left: 10px;
  }
`;

export default CheckBox;

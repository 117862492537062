import Axios from 'axios';
import _ from 'lodash';
import download from 'downloadjs';
import swal from 'sweetalert2';
import Bugsnag from '@bugsnag/js'

import { LOAD_SPREADSHEET_CHARTS } from '../actionTypes/spreadsheetChartTypes';
import showNotification from '../helpers/showNotification';
import { ADD_SPREADSHEET_CARD } from '../actionTypes/spreadsheetCardTypes';
import { RESET_TARGET, TARGET_BUILDER_RESET } from '../actionTypes/targetTypes';
import { getErrorMessage } from './appActions';
import { getProjects } from './projectActions';

export const SpreadSheetSelectAll = BannerPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  let count = 0;
  let canMergeB = false;
  let canUnmergeB = false;
  copy.Tabs.forEach((tab) => {
    if (tab.Banners[BannerPos].selectedRow) {
      tab.Banners[BannerPos].BannerDataPoints.forEach((element) => {
        element.selected = false; /* eslint-disable-line no-param-reassign */
        element.value.forEach((element2) => {
          element2.selected = false; /* eslint-disable-line no-param-reassign */
        });
      });
      tab.Banners[BannerPos].SelectedAll = false;
      tab.Banners[BannerPos].selectedRow = false;
    }
    tab.Banners[BannerPos].BannerDataPoints.forEach((datapoint) => {
      datapoint.selected = datapoint.isVisible ? !datapoint.selected : false; /* eslint-disable-line no-param-reassign */
      datapoint.value.forEach((value) => {
        value.selected = datapoint.isVisible ? !value.selected : false; /* eslint-disable-line no-param-reassign */
      });
    });
    tab.Banners[BannerPos].SelectedAll = !tab.Banners[BannerPos].SelectedAll;
  });
  copy.Tabs[0].Banners.forEach((element) => {
    if (element.SelectedAll) {
      count += 1;
    }
  });
  if (count > 1) {
    canMergeB = true;
  }

  if (copy.Tabs[0].Banners[BannerPos].isMerged) {
    canUnmergeB = true;
  }
  dispatch({
    type: 'SPREADSHEET_SELECT_ALL',
    Data: copy,
    canUnmergeBlock: canUnmergeB,
    canMergeBlocks: canMergeB,
  });
  /* if (copy.Tabs[0].Banners[BannerPos].hasCumeTotal) {
    dispatch(SpreadSheetCalculateCumeTotal(BannerPos));
  } */
};

export const SpreadSheetSelectRow = (BannerPos, RowNum) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  let selectedRowsAux = 0;
  let selectedRowsFlag = false;
  let canUnmergeR = false;
  let canRemoveR = false;
  copy.Tabs.forEach((tab) => {
    tab.Banners[BannerPos].BannerDataPoints.forEach((element, i) => {
      if (RowNum === i + 1) {
        element.selected = !element.selected; /* eslint-disable-line no-param-reassign */
        element.value.forEach((element2) => {
          element2.selected = !element2.selected; /* eslint-disable-line no-param-reassign */
        });
      }
    });
  });
  // VALIDATION FOR SELECTING MULTIPLE ROWS FROM ONLY ONE QUESTION BLOCK
  selectedRowsAux = copy.Tabs[0].Banners.map(element => element.BannerDataPoints.filter(element2 => element2.selected === true).length);
  copy.Tabs[0].Banners[BannerPos].SelectedAll = false;
  copy.Tabs[0].Banners[BannerPos].selectedRow = true;
  selectedRowsAux.forEach((val) => {
    if (selectedRowsFlag) {
      if (val > 0) {
        selectedRowsFlag = false;
      }
    } else
    if (val > 1) {
      selectedRowsFlag = true;
    }
  });

  // VALIDATION FOR SELECTING A MERGED ROW
  copy.Tabs[0].Banners.forEach((element) => {
    element.BannerDataPoints.forEach((element2) => {
      if (element2.mergedRow && element2.selected) {
        canUnmergeR = true;
      }
      if (element2.selected) {
        canRemoveR = true;
      }
    });
  });
  dispatch({
    type: 'SPREADSHEET_SELECT_ROW',
    Data: copy,
    canMergeRows: selectedRowsFlag,
    canUnmergeRow: canUnmergeR,
    canRemoveRows: canRemoveR,
  });
  /* if (copy.Tabs[0].Banners[BannerPos].hasCumeTotal) {
    dispatch(SpreadSheetCalculateCumeTotal(BannerPos));
  } */
};

export const SpreadSheetRemoveBlock = BannerPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners[BannerPos].Visible = false;
    tab.Banners[BannerPos].SelectedAll = false;
    tab.Banners[BannerPos].BannerDataPoints.forEach((element) => {
      element.selected = false; /* eslint-disable-line no-param-reassign */
      element.value.forEach((element2) => {
        element2.selected = false; /* eslint-disable-line no-param-reassign */
      });
    });
  });
  dispatch({
    type: 'SPREADSHEET_REMOVE_BLOCK',
    Data: copy,
    lastBannerDeleted: BannerPos,
  });
};

export const SpreadSheetUndoRemoveBlock = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data, lastBannerDeleted } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners[lastBannerDeleted].Visible = true;
  });
  dispatch({
    type: 'SPREADSHEET_UNDO_REMOVE_BLOCK',
    Data: copy,
  });
};

export const SpreadSheetRemoveRow = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  const dataPoints = [];
  copy.Tabs.forEach((tab) => {
    tab.Banners.forEach((banner) => {
      banner.BannerDataPoints.forEach((datapoint) => {
        if (datapoint.selected) {
          if (datapoint.mergedRow) {
            datapoint.mergedRowList.forEach((answer) => {
              _.unset(banner, `bannerDataStructure.dataForm.${banner.questionCode}.answers.${answer.answerCode}`)
            });
          } else {
            _.unset(banner, `bannerDataStructure.dataForm.${banner.questionCode}.answers.${datapoint.answerCode}`);
          }
          datapoint.isVisible = false; /* eslint-disable-line no-param-reassign */
          datapoint.selected = false; /* eslint-disable-line no-param-reassign */
          dataPoints.push(datapoint.name);
          datapoint.value.forEach((value) => {
            value.selected = false; /* eslint-disable-line no-param-reassign */
          });
        }
      });
    });
  });


  dispatch({
    type: 'SPREADSHEET_REMOVE_ROW',
    Data: copy,
    lastDataPointsDeleted: dataPoints,
  });
};

export const SpreadSheetUndoRemoveRow = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data, lastDataPointsDeleted } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners.forEach((element) => {
      element.BannerDataPoints.forEach((element2) => {
        lastDataPointsDeleted.forEach((element3) => {
          if (element2.name === element3) {
            element2.isVisible = true; /* eslint-disable-line no-param-reassign */
            element2.selected = false; /* eslint-disable-line no-param-reassign */
          }
        });
      });
    });
  });
  dispatch({
    type: 'SPREADSHEET_UNDO_REMOVE_ROW',
    Data: copy,
  });
};

export const SpreadSheetSortBlockAsc = (BannerPos, TargetPos, type) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data, currentTab } = spreadsheet;
  const copy = _.cloneDeep(Data);
  const bannerToSort = copy.Tabs[currentTab].Banners[BannerPos];
  switch (type) {
    case 'percentage':
      bannerToSort.BannerDataPoints = bannerToSort.BannerDataPoints.sort((a, b) => a.value[TargetPos].percentage - b.value[TargetPos].percentage);
      break;
    case 'thousands':
      bannerToSort.BannerDataPoints = bannerToSort.BannerDataPoints.sort((a, b) => a.value[TargetPos].thousands - b.value[TargetPos].thousands);
      break;
    case 'index':
      bannerToSort.BannerDataPoints = bannerToSort.BannerDataPoints.sort((a, b) => {
        let valueB = b.value[TargetPos].index;
        let valueA = a.value[TargetPos].index;
        if (isNaN(valueB)) {
          valueB = 0;
        }
        if (isNaN(valueA)) {
          valueA = 0;
        }
        return valueA - valueB;
      });
      break;
    default:
      break;
  }
  bannerToSort.sorting = { order: 'ASC', type };
  copy.Tabs[currentTab].Banners[BannerPos] = bannerToSort;
  dispatch({
    type: 'SPREADSHEET_SORT_BLOCK_ASC',
    Data: copy,
  });
};

export const SpreadSheetSortBlockDesc = (BannerPos, TargetPos, type) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data, currentTab } = spreadsheet;
  const copy = _.cloneDeep(Data);
  const bannerToSort = copy.Tabs[currentTab].Banners[BannerPos];
  switch (type) {
    case 'percentage':
      bannerToSort.BannerDataPoints = bannerToSort.BannerDataPoints.sort((a, b) => b.value[TargetPos].percentage - a.value[TargetPos].percentage);
      break;
    case 'thousands':
      bannerToSort.BannerDataPoints = bannerToSort.BannerDataPoints.sort((a, b) => b.value[TargetPos].thousands - a.value[TargetPos].thousands);
      break;
    case 'index':
      bannerToSort.BannerDataPoints = bannerToSort.BannerDataPoints.sort((a, b) => {
        let valueB = b.value[TargetPos].index;
        let valueA = a.value[TargetPos].index;
        if (isNaN(valueB)) {
          valueB = 0;
        }
        if (isNaN(valueA)) {
          valueA = 0;
        }
        return valueB - valueA;
      });
      break;
    default:
      break;
  }
  bannerToSort.sorting = { order: 'DESC', type };
  copy.Tabs[currentTab].Banners[BannerPos] = bannerToSort;
  dispatch({
    type: 'SPREADSHEET_SORT_BLOCK_DESC',
    Data: copy,
  });
};

export const SpreadSheetMergeBlock = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data, currentTab } = spreadsheet;
  const copy = _.cloneDeep(Data);
  let lastPosition = 0;
  /* mergeBlock needs to be mutated so we need to disable linter */

  copy.Tabs.forEach((tab) => {
    let mergedBlock = { /* eslint-disable-line prefer-const */
      BannerName: '',
      Visible: true,
      combinedBanner: false,
      BannerDataPoints: [],
      SelectedAll: false,
      selectedRow: false,
      isMerged: true,
      mergedBlockList: [],
      cumeTotalResults: [],
      average: [null],
    };
    tab.Banners.forEach((banner, i) => {
      if (banner.SelectedAll) {
        banner.SelectedAll = false; /* eslint-disable-line no-param-reassign */
        mergedBlock.mergedBlockList.push(banner);
        mergedBlock.BannerName = mergedBlock.BannerName.concat(`${banner.BannerName} ,`);
        banner.BannerDataPoints.forEach((datapoint) => {
          datapoint.selected = false; /* eslint-disable-line no-param-reassign */
          datapoint.value.forEach((value) => {
            value.selected = false; /* eslint-disable-line no-param-reassign */
          });
          const datapointCopy = _.cloneDeep(datapoint);
          mergedBlock.BannerDataPoints.push(datapointCopy);
        });
        banner.combinedBanner = true; /* eslint-disable-line no-param-reassign */
        lastPosition = i;
      }
    });
    mergedBlock.BannerName = mergedBlock.BannerName.slice(0, -2);
    tab.Banners.splice(lastPosition, 0, mergedBlock);
  });
  dispatch({
    type: 'SPREADSHEET_MERGE_BLOCK',
    Data: copy,
    lastMergedBlock: lastPosition,
    lastTabWhereThereWasAMerge: currentTab,
  });
};

export const SpreadSheetChangeBannerTitle = (BannerPos, BannerName) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners[BannerPos].BannerName = BannerName;
  });
  dispatch({
    type: 'SPREADSHEET_CHANGE_BANNER_TITLE',
    Data: copy,
  });
};

const willCalculateCumeTotal = (data = []) => {
  const selectedDataPoints = data
    .filter(d => d.selectedForCumeTotal && d.selected)
    .map(d => d.selectedForCumeTotal);
  return selectedDataPoints.includes(true);
};

export const SpreadSheetMergeRow = () => async (dispatch, getState) => {
  swal({
    title: 'Calculating Merged Rows.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });
  swal.showLoading();

  try {
    const accountName = getState().AppReducer.currentFolderName;
    const databaseName = getState().AppReducer.currentDatabase;
    const { spreadsheet } = getState();
    const { Data, currentTab } = spreadsheet;
    const spreadsheetCopy = _.cloneDeep(Data);
    let lastMergedRowsBlck = null;
    let lastPosition = null;
    const mergeList = [];
    let queryMerge = '(';
    const dataToCumeTotal = {
      bannerPos: null,
      hasToCalculateCumeTotal: false,
    };

    spreadsheetCopy.Tabs.forEach((tab, i) => {
      if (i === 0) {
        tab.Banners.forEach((element, k) => {
          const { hasCumeTotal } = element;
          const hasToCalculateCumeTotal = willCalculateCumeTotal(element.BannerDataPoints);
          element.BannerDataPoints.forEach((element2, i) => {
            const {
              idQueryMergeRow, answerCode, selected, mergedRow,
            } = element2;
            if (selected) {
              if (mergedRow) {
                mergeList.push(idQueryMergeRow.replace(/[()]/g, ''));
                if (hasCumeTotal && hasToCalculateCumeTotal) {
                  dataToCumeTotal.bannerPos = k;
                  dataToCumeTotal.hasToCalculateCumeTotal = hasToCalculateCumeTotal;
                }
              } else {
                mergeList.push(answerCode);
                if (hasCumeTotal && hasToCalculateCumeTotal) {
                  dataToCumeTotal.bannerPos = k;
                  dataToCumeTotal.hasToCalculateCumeTotal = hasToCalculateCumeTotal;
                }
              }
            }
          });
        });
      }
    });

    // Created query to calculate merge rows
    mergeList.map((answerCode, i) => {
      if (i === mergeList.length - 1) {
        queryMerge += `${answerCode})`;
      } else {
        queryMerge += `${answerCode}_OR_`;
      }
    });


    const queries = spreadsheetCopy.Tabs[currentTab].Targets.map((target, index) => Axios.post('/api/mergeRow', {
      account: accountName,
      database: databaseName,
      target: target.questionCode,
      banner: {
        question: queryMerge,
      },
      targetIndex: index,
    }));

    Promise.all(queries).then((queriesResult) => {
      spreadsheetCopy.Tabs.forEach((tab, i) => {
        let rowCount = null;
        let mergedRow = null;
        let flag = false;
        const { hasToCalculateCumeTotal } = dataToCumeTotal;
        tab.Banners.forEach((element, k) => {
          const targetQuantity = tab.Targets[tab.Targets.length - 1].targetQuantity;

          if (!flag) {
            lastPosition = 0;
            rowCount = 0;
            mergedRow = {
              name: '',
              selected: false,
              isVisible: true,
              combinedRow: false,
              mergedRow: true,
              value: [
                {
                  percentage: 0,
                  thousands: 0,
                  curatedPercentage: 0,
                  index: 0,
                  selected: false,
                }],
              mergedRowList: [],
              idQueryMergeRow: '',
            };
            element.SelectedAll = false; /* eslint-disable-line no-param-reassign */


            element.BannerDataPoints.forEach((element2, i) => {
              if (element2.selected) {
                const selectedForCumeTotal = !hasToCalculateCumeTotal ? element2.selectedForCumeTotal : hasToCalculateCumeTotal;
                lastMergedRowsBlck = k;
                lastPosition = i;
                rowCount += 1;
                mergedRow.name = `${mergedRow.name.concat(element2.name)}, `;
                element2.combinedRow = true; /* eslint-disable-line no-param-reassign */
                element2.selected = false; /* eslint-disable-line no-param-reassign */
                element2.selectedForCumeTotal = selectedForCumeTotal; /* eslint-disable-line no-param-reassign */
                mergedRow.selectedForCumeTotal = selectedForCumeTotal;
                mergedRow.mergedRowList.push(element2);

                queriesResult.forEach((mergedData, j) => {
                  if (tab.Name !== 'Combined') {
                    if (mergedData && j < mergedRow.value.length) {
                      mergedRow.value[j].percentage = parseFloat((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].percentages[0].count));
                      mergedRow.value[j].thousands = parseFloat((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].thousands[0].count));
                      mergedRow.value[j].curatedPercentage = parseFloat(percentsToRespondans((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].percentages[0].count), j));
                    } else {
                      mergedRow.value.push({
                        percentage: parseFloat((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].percentages[0].count)),
                        thousands: parseFloat((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].thousands[0].count)),
                        curatedPercentage: parseFloat(percentsToRespondans((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].percentages[0].count), targetQuantity)),
                        index: mergedRow.value.length > 0 ? parseFloat((((mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].percentages[0].count !== null ? mergedData.data.results[tab.Name !== 'Combined' ? tab.Name : 'total'].percentages[0].count : 0) / mergedRow.value[0].percentage) * 100)) : 0,
                      });
                    }
                  }
                });
                flag = true;
              }
            });

            if (rowCount > 1) {
              mergedRow.name = mergedRow.name.slice(0, -2);
              mergedRow.idQueryMergeRow = queryMerge;
              element.BannerDataPoints.splice(lastPosition + 1, 0, mergedRow);
            }
          }
        });

        if (tab.Name === 'Combined') {
          dispatch({
            type: 'SPREADSHEET_MERGE_ROWS',
            Data: spreadsheetCopy,
            lastMergedRowsBlock: lastMergedRowsBlck,
            lastMergedRowPosition: lastPosition + 1,
            lastTabWhereThereWasAMerge: currentTab,
          });

          if (hasToCalculateCumeTotal && dataToCumeTotal.bannerPos !== null) {
            dispatch(SpreadSheetCalculateCumeTotal(dataToCumeTotal.bannerPos));
          }
          swal.close();

          dispatch(SpreadSheetCalculateCombinedTab(spreadsheetCopy));
        }
      });
    });
  } catch (error) {
    showNotification({ message: getErrorMessage(error), type: 'error' });
    swal.close();
    console.error(error);
    Bugsnag.notify(error);
  }
};

export const SpreadSheetUnmergeRow = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = { ...Data };
  copy.Tabs.forEach((tab) => {
    tab.Banners.forEach((element) => {
      element.BannerDataPoints.forEach((element2, i) => {
        if (element2.selected && element2.mergedRow) {
          element2.mergedRowList.forEach((element3) => {
            element3.selectedForCumeTotal = element2.selectedForCumeTotal; /* eslint-disable-line no-param-reassign */
            element3.combinedRow = false; /* eslint-disable-line no-param-reassign */
            if (element.isTemplate) {
              element.BannerDataPoints.forEach((dataPoint) => {
                if (dataPoint.answerCode === element3.answerCode) {
                  dataPoint.combinedRow = false; /* eslint-disable-line no-param-reassign */
                }
              });
            }
          });
          element.BannerDataPoints.splice(i, 1);
        }
      });
    });
  });
  dispatch({
    type: 'SPREADSHEET_UNMERGE_ROWS',
    Data: copy,
    canUnmergeRow: false,
  });
};

export const SpreadSheetUnmergeBlock = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners.forEach((element, i) => {
      if (element.SelectedAll && element.isMerged) {
        element.mergedBlockList.forEach((element2) => {
          element2.combinedBanner = false; /* eslint-disable-line no-param-reassign */
        });
        tab.Banners.splice(i, 1);
      }
    });

    tab.Banners.forEach((banner) => {
      if (banner.isTemplate) {
        banner.combinedBanner = false; /* eslint-disable-line no-param-reassign */
      }
    });
  });
  dispatch({
    type: 'SPREADSHEET_UNMERGE_BLOCK',
    Data: copy,
  });
};

export const SpreadSheetUndoMergeBlock = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data, lastMergedBlock } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners[lastMergedBlock].mergedBlockList.forEach((element) => {
      element.combinedBanner = false; /* eslint-disable-line no-param-reassign */
    });
    tab.Banners.splice(lastMergedBlock, 1);
  });
  dispatch({
    type: 'SPREADSHEET_UNDO_MERGE_BLOCK',
    Data: copy,
  });
};

export const SpreadSheetUndoMergeRow = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const {
    Data, lastMergedRowsBlock, lastMergedRowPosition,
  } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners[lastMergedRowsBlock].BannerDataPoints[lastMergedRowPosition].mergedRowList.forEach((element) => {
      element.combinedRow = false; /* eslint-disable-line no-param-reassign */
    });
    tab.Banners[lastMergedRowsBlock].BannerDataPoints.splice(lastMergedRowPosition, 1);
  });
  dispatch({
    type: 'SPREADSHEET_UNDO_MERGE_ROW',
    Data: copy,
  });
};

export const SpreadSheetCopyBlock = BannerPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    const newCopy = _.cloneDeep(tab.Banners[BannerPos]);
    tab.Banners.splice(BannerPos, 0, newCopy);
  });
  dispatch({
    type: 'SPREADSHEET_COPY_BLOCK',
    Data: copy,
  });
};

export const SpreadSheetShowPercentage = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { showPercentage } = spreadsheet;
  dispatch({
    type: 'SPREADSHEET_SHOW_PERCENTAGE',
    showPercentage: !showPercentage,
  });
};

export const SpreadSheetShowThousands = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { showThousands } = spreadsheet;
  dispatch({
    type: 'SPREADSHEET_SHOW_THOUSANDS',
    showThousands: !showThousands,
  });
};

export const SpreadSheetShowIndex = () => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { showIndex } = spreadsheet;
  dispatch({
    type: 'SPREADSHEET_SHOW_INDEX',
    showIndex: !showIndex,
  });
};

export const SpreadSheetChangeRowName = (BannerPos, RowPos, RowName) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const copy = _.cloneDeep(Data);
  copy.Tabs.forEach((tab) => {
    tab.Banners[BannerPos].BannerDataPoints[RowPos].name = RowName;
    if (tab.Banners[BannerPos].bannerDataStructure) {
      if (tab.Banners[BannerPos].BannerDataPoints[RowPos].answerCode) {
        tab.Banners[BannerPos].bannerDataStructure.dataForm[tab.Banners[BannerPos].questionCode].answers[tab.Banners[BannerPos].BannerDataPoints[RowPos].answerCode].text = RowName;
      }
    }
  });

  dispatch({
    type: 'SPREADSHEET_CHANGE_ROW_NAME',
    Data: copy,
  });
};

export const SpreadSheetLoadYears = () => async (dispatch, getState) => {
  const { spreadsheet } = getState();
  const {
    Data, yearsAreLoaded, isLoadedFromMyProjects, haveStructureBanner,
  } = spreadsheet;
  const copy = _.cloneDeep(Data);
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;

  if (!yearsAreLoaded) {
    swal({
      title: 'Loading years.',
      text: 'Please wait a moment.',
      allowOutsideClick: false,
    });
    swal.showLoading();
    try {
      if (!haveStructureBanner) {
        const response = await Axios.post('/api/yearList', {
          account: accountName,
          database: databaseName,
        })

        const yearList = [];
        const currentSurveyYear = response.data[response.data.length - 1];
        let currentTab = 0;
        response.data.sort((a, b) => b - a);
        response.data.forEach((year, i) => {
          copy.Tabs.push({
            Name: year,
            Targets: [],
            Banners: [],
            isVisible: currentSurveyYear === year,
            isCurrentSurvey: currentSurveyYear === year,
          });
          yearList.push(year);
          if (currentSurveyYear === year) {
            currentTab = i;
          }
        });
        copy.Tabs.push({
          Name: 'Combined',
          Targets: [],
          Banners: [],
          isVisible: false,
          isCurrentSurvey: false,
        });
        copy.Tabs.splice(0, 1);
        dispatch({
          type: 'SPREADSHEET_LOAD_YEARS',
          yearList,
          currentSurveyYear,
          Data: copy,
          currentTab,
        });
        swal.close();
        if (!isLoadedFromMyProjects) {
          dispatch(SpreadSheetAddInitialTarget());
        }
      } else {
        const response = await Axios.post('/api/yearList', {
          account: accountName,
          database: databaseName,
        })
        const yearList = [];
        const currentSurveyYear = response.data[response.data.length - 1];
        let currentTab = 0;
        response.data.sort((a, b) => b - a);
        response.data.forEach((year, i) => {
          yearList.push(year);
          if (currentSurveyYear === year) {
            currentTab = i;
          }
        });
        dispatch({
          type: 'SPREADSHEET_LOAD_YEARS_FROM_TEMPLATE',
          yearList,
          currentSurveyYear,
          currentTab,
        });
        dispatch(SpreadSheetAddInitialTarget());
        swal.close();
      }
    } catch (error) {
      swal.close();
      showNotification({ message: getErrorMessage(error), type: 'error' });
      Bugsnag.notify(error);
    }
  }
};

export const SpreadSheetSetCurrentTab = currentTabPosition => (dispatch) => {
  dispatch({
    type: 'SPREADSHEET_SET_CURRENT_TAB',
    currentTab: currentTabPosition,
  });
};

export const SpreadSheetAddNewTabs = Data => async (dispatch, getState) => {
  dispatch({ type: 'SPREADSHEET_ADD_NEW_TABS', Data });
  // UPDATE CUMETOTAL
  dispatch(SpreadSheetCalculateCombinedTab(Data));
};

export const SpreadSheetAddInitialTarget = () => async (dispatch, getState) => {
  const { spreadsheet, targetReducer } = getState();
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const {
    Data,
    yearList,
    targetTemplateLoaded,
    haveStructureBanner,
  } = spreadsheet;
  const { baseTarget } = targetReducer;
  const DataCopy = _.cloneDeep(Data);
  baseTarget.query = _.replace(baseTarget.query, /(!|\)|\()/g, '');
  swal({
    title: 'Loading Initial Data.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });
  swal.showLoading();

  try {
    const targetInfo = await getTargetInfo(accountName, databaseName, baseTarget);
    const targetSizes = await getTargetSizeByYears(accountName, databaseName, baseTarget, yearList);

    DataCopy.Tabs.forEach((tab) => {
      const newTarget = _.cloneDeep(targetInfo);
      newTarget.targetDataStructure = targetReducer.builder.blocks;
      calculateNewTargetValues(newTarget, tab, targetSizes, DataCopy);
      tab.Targets.push(newTarget);
    });

    if (targetTemplateLoaded) {
      const { currentTarget } = targetReducer;
      currentTarget.query = _.replace(currentTarget.query, /(!|\)|\()/g, '');
      const targetInfo = getTargetInfo(accountName, databaseName, currentTarget);
      const targetSizes = getTargetSizeByYears(accountName, databaseName, currentTarget, yearList);

      DataCopy.Tabs.forEach((tab) => {
        const newTarget = _.cloneDeep(targetInfo);
        newTarget.targetDataStructure = targetReducer.builder.blocks;
        calculateNewTargetValues(newTarget, tab, targetSizes, DataCopy);
        tab.Targets.push(newTarget);
      });
      dispatch(SpreadSheetResetTargetModal('currentTarget'));
    }

    const payload = { Data: DataCopy };
    dispatch({ type: 'SPREADSHEET_ADD_INITIAL_TARGET', payload });

    if (!haveStructureBanner) {
      SpreadSheetAddBanner()(dispatch, getState);
    }

    swal.close();
    dispatch(SpreadSheetCalculateCombinedTab(DataCopy));
  } catch (error) {
    swal.close();
    showNotification({ message: getErrorMessage(error), type: 'error' });
    Bugsnag.notify(error);
  }
};

export const SpreadSheetAddTarget = () => async (dispatch, getState) => {
  const { spreadsheet, targetReducer } = getState();
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const { Data, yearList, bannerCount, currentTab } = spreadsheet;
  const { currentTarget } = targetReducer;
  const DataCopy = _.cloneDeep(Data);

  swal({
    title: 'Adding Target.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });
  swal.showLoading();

  try {
    const targetInfo = await getTargetInfo(accountName, databaseName, currentTarget);
    const targetSizes = await getTargetSizeByYears(accountName, databaseName, currentTarget, yearList);

    DataCopy.Tabs.forEach((tab) => {
      const newTarget = _.cloneDeep(targetInfo);
      newTarget.targetDataStructure = targetReducer.builder.blocks;
      calculateNewTargetValues(newTarget, tab, targetSizes, DataCopy);
      tab.Targets.push(newTarget);
    });

    if (bannerCount > 0) {
      const response = await getTargetComplexFilterResponse(accountName, databaseName, DataCopy, targetInfo, yearList);
      const mergedRowsResult = await getMergedRowsData(accountName, databaseName, DataCopy, currentTab, targetInfo);

      DataCopy.Tabs.forEach((tab) => {
        const { targetQuantity } = _.last(tab.Targets);
        if (tab.Name !== 'Combined') {
          let i = 0;
          tab.Banners.forEach((banner) => {
            if (!banner.isMerged) {
              const bannerResponse = response[i][tab.Name];
              addAverageToBanner(banner, bannerResponse.average);

              if (isBannerSorted(banner) && !isComplexBanner(banner)) {
                sortBannerDatapoints(banner, bannerResponse);
              }
              let answerIndex = 0;
              banner.BannerDataPoints.forEach((dataPoint) => {
                const newValue = getNewDatapointValues(dataPoint, mergedRowsResult, tab, bannerResponse, targetQuantity, answerIndex);
                dataPoint.value.push(newValue);
                if (!dataPoint.mergedRow) {
                  answerIndex++;
                }
              });
              i++;
            }

          });
          tab.Banners.forEach((banner) => {
            if (banner.isMerged) {
              const mergedQuestions = banner.mergedBlockList.map((b) => b.questionCode);
              const mergedBlocks = tab.Banners.filter((b) => mergedQuestions.includes(b.questionCode));
              const BannerDataPoints = [];
              mergedBlocks.forEach((b) => {
                BannerDataPoints.push(...b.BannerDataPoints);
              });
              banner.mergedBlockList = mergedBlocks;
              banner.BannerDataPoints = BannerDataPoints;
            }
          });
        }
      });

      const payload = { Data: DataCopy };
      dispatch({ type: 'SPREADSHEET_ADD_TARGET', payload });

      if (hasCumeTotal(DataCopy.Tabs[0])) {
        dispatch(SpreadSheetCalculateCumeTotalForAllBanners(DataCopy.Tabs[0].Targets.length - 1));
      } else {
        dispatch(SpreadSheetCalculateCombinedTab(DataCopy));
      }
    }
    swal.close();
  } catch (error) {
    swal.close();
    showNotification({ message: getErrorMessage(error), type: 'error' });
    Bugsnag.notify(error);
  }
};

export const SpreadSheetEditTarget = () => async (dispatch, getState) => {
  const { spreadsheet, targetReducer } = getState();
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const {
    Data,
    yearList,
    bannerCount,
    editTargetPosition,
    currentTab,
  } = spreadsheet;
  const { currentTarget } = targetReducer;
  const DataCopy = _.cloneDeep(Data);

  swal({
    title: 'Changing Target.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });

  swal.showLoading();

  try {
    const targetInfo = await getTargetInfo(accountName, databaseName, currentTarget);
    const targetSizes = await getTargetSizeByYears(accountName, databaseName, currentTarget, yearList);

    DataCopy.Tabs.forEach((tab) => {
      const newTarget = _.cloneDeep(targetInfo);
      newTarget.targetDataStructure = targetReducer.builder.blocks;
      calculateNewTargetValues(newTarget, tab, targetSizes, DataCopy);
      tab.Targets.splice(editTargetPosition, 1, newTarget);
    });

    if (bannerCount > 0) {
      const response = await getTargetComplexFilterResponse(accountName, databaseName, DataCopy, targetInfo, yearList);
      const mergedRowsResult = await getMergedRowsData(accountName, databaseName, DataCopy, currentTab, targetInfo);

      DataCopy.Tabs.forEach((tab) => {
        const { targetQuantity } = tab.Targets[editTargetPosition];
        if (tab.Name !== 'Combined') {
          let i = 0;
          tab.Banners.forEach((banner) => {
            if (!banner.isMerged) {
              const bannerResponse = response[i][tab.Name];
              if (bannerResponse.average) {
                tab.Banners[i].average[editTargetPosition] = _.round(bannerResponse.average, 2);
              } else {
                tab.Banners[i].average[editTargetPosition] = null;
              }

              if (isBannerSorted(banner) && !isComplexBanner(banner)) {
                sortBannerDatapoints(banner, bannerResponse);
              }

              let answerIndex = 0;
              banner.BannerDataPoints.forEach((dataPoint) => {
                const newValue = getNewDatapointValues(dataPoint, mergedRowsResult, tab, bannerResponse, targetQuantity, answerIndex);
                dataPoint.value[editTargetPosition] = newValue;
                if (!dataPoint.mergedRow) {
                  answerIndex++;
                }
              });
              i++;
            }

          });

          tab.Banners.forEach((banner) => {
            if (banner.isMerged) {
              const mergedQuestions = banner.mergedBlockList.map((b) => b.questionCode);
              const mergedBlocks = tab.Banners.filter((b) => mergedQuestions.includes(b.questionCode));
              const BannerDataPoints = [];
              mergedBlocks.forEach((b) => {
                BannerDataPoints.push(...b.BannerDataPoints);
              });
              banner.mergedBlockList = mergedBlocks;
              banner.BannerDataPoints = BannerDataPoints;
            }
          });
        }
      });


      if (editTargetPosition === 0) {
        // If base target is edited, recalculate indexes for all targets
        SpreadsheetRecalculateIndex(DataCopy);
      }
      const payload = { Data: DataCopy };
      dispatch({ type: 'SPREADSHEET_ADD_TARGET', payload });

      if (hasCumeTotal(DataCopy.Tabs[0])) {
        dispatch(SpreadSheetCalculateCumeTotalForAllBanners(DataCopy.Tabs[0].Targets.length - 1));
      } else {
        dispatch(SpreadSheetCalculateCombinedTab(DataCopy));
      }

      swal.close();
    }
  } catch (error) {
    swal.close();
    showNotification({ message: getErrorMessage(error), type: 'error' });
    Bugsnag.notify(error);
  }
};

const getTargetInfo = async (accountName, databaseName, currentTarget) => {
  const targetPayload = {
    account: accountName,
    database: databaseName,
    question: currentTarget.query,
    type: 'target',
  }
  const targetResponse = await Axios.post('/api/questionInfo',targetPayload)
  const targetInfo = targetResponse.data
  targetInfo.targetName = currentTarget.title;
  return targetInfo
}

const getTargetSizeByYears = async (accountName, databaseName, currentTarget, yearList) => {
  const targetSizesResponse = await Axios.post(
    '/api/targetSize/byYears',
    {
      account: accountName,
      database: databaseName,
      query: currentTarget.query,
      year: yearList,
    },
  );
  return targetSizesResponse.data;
}

const getTargetComplexFilterResponse = async (accountName, databaseName, DataCopy, targetInfo, yearList) => {
  const bannerList = DataCopy.Tabs[0].Banners
  .filter(banner => !banner.isMerged)
  .map(banner => ({
    type: banner.bannerDataStructure.bannerType,
    data: {
      question: banner.questionCode,
    },
  }));
  const response = await Axios.post('/api/complexFilterMultipleYears', {
    target: targetInfo.questionCode,
    banner: bannerList,
    account: accountName,
    database: databaseName,
    years: yearList,
  });
  return response.data;
}

const getMergedRowsData = async (accountName, databaseName, DataCopy, currentTabIndex, targetInfo) => {
  const currentTab = DataCopy.Tabs[currentTabIndex];
  const queriesArray = allQueriesMergedRowsForAllBanner(DataCopy);
  let mergedRowsResponse = await Axios.post('/api/mergeRow/listMergeRows', {
    account: accountName,
    database: databaseName,
    target: targetInfo.questionCode,
    banner: queriesArray,
    targetIndex: currentTab.Targets.length - 1,
  });
  return mergedRowsResponse.data;
}

const calculateNewTargetValues = (newTarget, tab, targetSizes, DataCopy) => {
  if (tab.Name !== 'Combined') {
    const targetSize = targetSizes[tab.Name]
    newTarget.targetQuantity = _.round(targetSize.qty, 0);
    newTarget.targetPercentage = _.round(targetSize.percentage, 2);
    newTarget.targetThousands = _.round(targetSize.thousands, 0);
  } else {
    const selectedTabsToCombine = DataCopy.Tabs.filter(tab => tab.isVisible === true && tab.Name !== 'Combined');
    const { targetQuantity, targetPercentage } = calculateCombinedTargetValues(selectedTabsToCombine);
    newTarget.targetQuantity = targetQuantity;
    newTarget.targetPercentage = targetPercentage;
  }
}

const calculateCombinedTargetValues = (Tabs) => {
  let averageQuantity = 0;
  let avgPercentage = 0;
  let sumQuantity = 0;
  let sumPercentage = 0;
  Tabs.forEach((tab) => {
    const target = _.last(tab.Targets);
    if (target.targetQuantity > 0) {
      averageQuantity += 1;
      sumQuantity += target.targetQuantity;
    }
    if (target.targetPercentage > 0) {
      avgPercentage += 1;
      sumPercentage += target.targetPercentage;
    }
  });
  const targetQuantity = _.round(sumQuantity / averageQuantity, 0);
  const targetPercentage = _.round(sumPercentage / avgPercentage, 2);
  return { targetQuantity, targetPercentage };
}

const getNewDatapointValues = (dataPoint, mergedRowsResult, tab, bannerResponse,targetQuantity, answerIndex) => {
  let percentageCount = 0;
  let thousandsCount = 0;
  if (!dataPoint.combinedRow && dataPoint.mergedRow) {
    const mergedRow = mergedRowsResult[dataPoint.idQueryMergeRow].results[tab.Name];
    percentageCount = mergedRow.percentages[0].count;
    thousandsCount = mergedRow.thousands[0].count;
  } else {
    percentageCount = bannerResponse.percentages[answerIndex].count;
    thousandsCount = bannerResponse.thousands[answerIndex].count;
  }
  verifyCountsDataTypes(percentageCount, thousandsCount);
  const newValue = createDataPointValue(percentageCount, thousandsCount, targetQuantity, dataPoint);
  return newValue;
}

function calculateDatapointValueIndex(datapoint, percentageCount) {
  const baseTargetPercentage = _.get(datapoint, 'value[0].percentage', null);
  let index = 0;
  if (datapoint.value.length > 0 && percentageCount !== null) {
    index = parseFloat(((percentageCount / baseTargetPercentage) * 100));
  }

  if (_.isFinite(index)) {
    return index;
  }
  return NaN;
}

function isBannerSorted(banner) {
  return banner.sorting !== undefined && banner.sorting.order !== 'DEFAULT';
}

function sortBannerDatapoints(reduxBanner, serverBanner) {
  // Order Datapoint values
  const indexPercentages = [];
  const indexThousands = [];
  reduxBanner.BannerDataPoints.forEach((bannerDatapoint) => {
    if (!bannerDatapoint.mergedRow) {
      indexPercentages.push(serverBanner.percentages.findIndex(i => i.answer === bannerDatapoint.name));
      indexThousands.push(serverBanner.thousands.findIndex(i => i.answer === bannerDatapoint.name));
    }
  });
  serverBanner.thousands = serverBanner.thousands.map((thousand, i) => serverBanner.thousands[indexThousands[i]]);
  serverBanner.percentages = serverBanner.percentages.map((percentage, i) => serverBanner.percentages[indexPercentages[i]]);
}

function createDataPointValue(percentageCount, thousandsCount, targetQuantity, dataPoint) {
  return {
    percentage: percentageCount,
    thousands: thousandsCount,
    curatedPercentage: percentsToRespondans(percentageCount, targetQuantity),
    index: calculateDatapointValueIndex(dataPoint, percentageCount),
    selected: dataPoint.selected,
  };
}

function verifyCountsDataTypes(percentageCount, thousandCount) {
  if ((percentageCount !== null && typeof percentageCount !== 'number')
  || (thousandCount !== null && typeof thousandCount !== 'number')) {
    // Count can only be a number or null, if we receive a something different, it should be fixed on the backend
    console.error('Error with count value found');
    console.log('percentageCount', percentageCount, typeof percentageCount);
    console.log('thousandCount', thousandCount, typeof thousandCount);
  }
}

function percentsToRespondans(percent, targetRespondans) {
  if (percent === null) {
    return null;
  }
  const result = (targetRespondans / (100 / (percent || 0)));
  return result;
}

export const SpreadSheetAddBanner = () => async (dispatch, getState) => {
  const { spreadsheet, bannerReducer } = getState();
  const { currentTab: currentTabIndex, Data } = spreadsheet;
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const DataCopy = _.cloneDeep(Data);
  swal({
    title: 'Adding Banner.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });
  swal.showLoading();

  try {
    const newBanners = await getNewBannersData(bannerReducer, accountName, databaseName);
    addBannersToAllTabs(DataCopy.Tabs, newBanners);
    const currentTab = DataCopy.Tabs[currentTabIndex];
    if (currentTab.Targets.length > 0) {
      const bannerResults = await getBannerResults(DataCopy, bannerReducer, currentTabIndex, accountName, databaseName)
      bannerResults.forEach((bannerResponse) => {
        let resultsCount = bannerResponse.results.length;
        bannerResponse.results.forEach((result, i) => {
          DataCopy.Tabs.forEach((tab) => {
            const year = tab.Name;
            const targetQuantity = tab.Targets[bannerResponse.targetIndex].targetQuantity;
            const bannerIndex = tab.Banners.length - (resultsCount - i);
            const currentBanner = tab.Banners[bannerIndex]
            addAverageToBanner(currentBanner, result?.[year]?.average);
            if (tab.Name !== 'Combined') {
              currentBanner.BannerDataPoints.forEach((datapoint, j) => {
                const percentage = result[year].percentages[j].count;
                const thousands = result[year].thousands[j].count;
                const newValue = createDataPointValue(percentage, thousands, targetQuantity, datapoint);
                datapoint.value.push(newValue);
              });
            }
            currentBanner.BannerDataPoints.forEach((datapoint) => {
              const bannerType = currentBanner.bannerDataStructure.bannerType;
              const bannerAnswer = _.get(currentBanner, `bannerDataStructure.dataForm[${currentBanner.questionCode}].answers[${datapoint.answerCode}]`, null);
              if (bannerType !== 'complex' && _.isNil(bannerAnswer)) {
                datapoint.isVisible = false;
              }
            });
          });
        });
      });
    }
    const payload = { Data: DataCopy };
    dispatch({ type: 'SPREADSHEET_ADD_BANNER', payload });
    dispatch(SpreadSheetCalculateCombinedTab(DataCopy));
    swal.close();
  } catch (error) {
    swal.close();
    showNotification({ message: 'Error adding banner', type: 'error' });
    Bugsnag.notify(error);
    console.log(error);
  }
};

const getNewBannersData = async (bannerReducer,accountName, databaseName) => {
const bannerPayloads = bannerReducer.currentBanner.map(banner => (
    {
      account: accountName,
      database: databaseName,
      question: banner.data.question,
      type: 'banner',
      dataStructure: {
        bannerType: banner.type,
        public: false,
        shared: false,
        subType: 'QUESTION',
        title: banner.type === 'basic' ? bannerReducer.basicBuilderTitle : bannerReducer.advancedBuilderTitle || banner.data.title,
        type: 'TEMPLATE',
        isFromSpreadsheet: true,
        dataForm: banner.type === 'basic' ? bannerReducer.basicBuilder : bannerReducer.advancedBuilder,
      },
    }));
    const newBanners = []
    for (const bannerPayload of bannerPayloads) {
        const bannerResponse = await Axios.post('/api/questionInfo',bannerPayload)
        const banner = bannerResponse.data;
          const { questionCode, bannerDataStructure } = banner;
          if (bannerDataStructure.bannerType === 'basic') {
            const basicBuilderQuestion = bannerReducer.basicBuilder[questionCode];
            if (basicBuilderQuestion) {
              banner.bannerDataStructure.dataForm = {
                [questionCode]: basicBuilderQuestion,
              };
            }
          }
       
        newBanners.push(banner);
    }
    return newBanners;
}

const getBannerResults = async (DataCopy, bannerReducer,currentTabIndex, accountName, databaseName) => {
  try {
    const bannerPayloads = bannerReducer.currentBanner.map(bannerElement => ({
      type: bannerElement.type,
      data: {
        question: bannerElement.data.question,
      },
    }));
    const currentTab = DataCopy.Tabs[currentTabIndex];
      const queries = currentTab.Targets.map((target, index) => Axios.post('/api/complexFilter', {
        account: accountName,
        database: databaseName,
        target: target.questionCode,
        banner: bannerPayloads,
        targetIndex: index,
      }));
      const queryResponses = await Promise.all(queries)
      return queryResponses.map(response => response.data);
  } catch (error) {
    console.log(error);
    showNotification({ message: 'Error with calculation process', type: 'error' });
  }
}

const addBannersToAllTabs = (tabs, banners) => {
 tabs.forEach((tab) => {
    tab.Banners = _.cloneDeep(_.concat(tab.Banners, banners));
  });
}

const addAverageToBanner = (banner, average) => {
  if (!isComplexBanner(banner)) {
    if (_.isFinite(average)) {
      banner.average.push(_.round(average, 2));
    } else {
      banner.average.push(null);
    }
  } else {
    banner.average = [null];
  }
}

export const SpreadSheetAddCumeTotal = (BannerPos, BannerData) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const DataCopy = _.cloneDeep(Data);
  const cumetotalDatapointsLength = BannerData.BannerDataPoints.filter(datapoint => (
    datapoint.selectedForCumeTotal === true && !datapoint.mergedRow
  )).length;
  if (cumetotalDatapointsLength > 0) {
    DataCopy.Tabs.forEach((tab) => {
      tab.Banners[BannerPos].hasCumeTotal = true;
      BannerData.BannerDataPoints.forEach((DataPoint, i) => {
        tab.Banners[BannerPos].BannerDataPoints[i].selectedForCumeTotal = DataPoint.selectedForCumeTotal;
      });
    });
    dispatch({ type: 'SPREADSHEET_ADD_CUME_TOTAL', Data: DataCopy });
    dispatch(SpreadSheetCalculateCumeTotal(BannerPos));
  } else {
    DataCopy.Tabs.forEach((tab) => {
      tab.Banners[BannerPos].hasCumeTotal = false;
      BannerData.BannerDataPoints.forEach((DataPoint, i) => {
        tab.Banners[BannerPos].BannerDataPoints[i].selectedForCumeTotal = false;
      });
    });
    dispatch({ type: 'SPREADSHEET_ADD_CUME_TOTAL', Data: DataCopy });
  }
};

export const SpreadSheetCalculateCumeTotal = BannerPos => async (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { currentTab, Data } = spreadsheet;
  const DataCopy = _.cloneDeep(Data);
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;

  swal({
    title: 'Calculating Cume Total.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });

  swal.showLoading();

  try {
    const datapointsWithRemovedMergedRows = DataCopy
      .Tabs[currentTab].Banners[BannerPos].BannerDataPoints
      .filter(datapoint => !datapoint.mergedRow);

    const selectedRows = datapointsWithRemovedMergedRows.map((datapoint, i) => {
      if (datapoint.selectedForCumeTotal && datapoint.isVisible && !datapoint.mergedRow) {
        return (i + 1);
      }
      return undefined;
    });

    const queries = DataCopy.Tabs[currentTab].Targets.map((target, index) => Axios.post('/api/cumeTotal', {
      account: accountName,
      database: databaseName,
      target: target.questionCode,
      banner: {
        question: DataCopy.Tabs[currentTab].Banners[BannerPos].questionCode ? DataCopy.Tabs[currentTab].Banners[BannerPos].questionCode : DataCopy.Tabs[currentTab].Banners[BannerPos].mergedBlockList,
        includes: selectedRows,
      },
    }));

    const selectedYearsForCombine = [];
    DataCopy.Tabs.forEach((tab) => {
      if (tab.isVisible && tab.Name !== 'Combined') {
        selectedYearsForCombine.push(tab.Name);
      }
    });

    const queriesResult = await Promise.all(queries)
    queriesResult.forEach((queriesResultElement, i) => {
      DataCopy.Tabs.forEach((Tab) => {
        if (Tab.Name !== 'Combined') {
          const valCuratedIndex = Tab.Banners[BannerPos].cumeTotalResults.length > 0 ? parseFloat((((queriesResultElement.data[Tab.Name].totalPercent !== null ? parseFloat(queriesResultElement.data[Tab.Name].totalPercent) : 0) / Tab.Banners[BannerPos].cumeTotalResults[0].percentage) * 100)) : 0;

          Tab.Banners[BannerPos].cumeTotalResults[i] = {
            thousands: queriesResultElement.data[Tab.Name].totalThousands ? parseFloat(queriesResultElement.data[Tab.Name].totalThousands) : null,
            percentage: queriesResultElement.data[Tab.Name].totalPercent ? parseFloat(queriesResultElement.data[Tab.Name].totalPercent.toFixed(2)) : null,
            index: i !== 0 ? valCuratedIndex : null,
          };
        } else {
          let combinedCumeTotal = 0;
          let combinedCumeTotalThousands = 0;
          const countYearsForCombinedCumeTotalThousands = verifySelectedYearsCumeTotal(BannerPos, i, DataCopy, selectedYearsForCombine, 'thousands');
          const countYearsForCombinedCumeTotalPercentages = verifySelectedYearsCumeTotal(BannerPos, i, DataCopy, selectedYearsForCombine, 'percentages');

          selectedYearsForCombine.forEach((year) => {
            combinedCumeTotal += queriesResultElement.data[year].totalPercent ? parseFloat(queriesResultElement.data[year].totalPercent) : 0;
            combinedCumeTotalThousands += queriesResultElement.data[year].totalThousands ? parseFloat(queriesResultElement.data[year].totalThousands) : 0;
          });
          combinedCumeTotal /= countYearsForCombinedCumeTotalPercentages;
          combinedCumeTotalThousands /= countYearsForCombinedCumeTotalThousands;

          const valCuratedIndex = Tab.Banners[BannerPos].cumeTotalResults.length > 0 ? parseFloat((((combinedCumeTotal.toFixed(2) !== null ? parseFloat(combinedCumeTotal.toFixed(2)) : 0) / Tab.Banners[BannerPos].cumeTotalResults[0].percentage) * 100)) : 0;

          Tab.Banners[BannerPos].cumeTotalResults[i] = {
            thousands: parseFloat(combinedCumeTotalThousands.toFixed(0)),
            percentage: parseFloat(combinedCumeTotal.toFixed(2)),
            index: i !== 0 ? valCuratedIndex : null,
          };
        }
      });
    });
    dispatch({ type: 'SPREADSHEET_CALCULATE_CUME_TOTAL', Data: DataCopy });
    swal.close();
  } catch (error) {
    swal.close();
    showNotification({ message: 'Error with calculation process', type: 'error' });
    Bugsnag.notify(error);
  }
};

export const SpreadSheetCalculateCumeTotalForAllBanners = TargetPos => async (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { currentTab, Data } = spreadsheet;
  const DataCopy = { ...Data };
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;

  swal({
    title: 'Calculating Cume Total.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });

  swal.showLoading();

  try {
    const bannersConfig = rowsSelectedToCumeTotalForAllBanner(DataCopy);
    const response = await Axios.post('/api/cumeTotal/listCumeTotals', {
      account: accountName,
      database: databaseName,
      target: DataCopy.Tabs[currentTab].Targets[TargetPos].questionCode,
      banner: bannersConfig,
    }).catch((err) => {
      swal.close();
      showNotification({ message: 'Error with calculation process', type: 'error' });
    });

    const selectedYearsForCombine = [];
    DataCopy.Tabs.forEach((tab) => {
      if (tab.isVisible && tab.Name !== 'Combined') {
        selectedYearsForCombine.push(tab.Name);
      }
    });

    const queriesResultElement = await Promise.all([response]);

    DataCopy.Tabs.forEach((Tab, TabPosition) => {
      let bannerIndex = 0;
      Tab.Banners.forEach((Banner, BannerPos) => {
        if (Banner.hasCumeTotal) {
          if (Banner.bannerDataStructure.bannerType !== 'complex') {
            if (Tab.Name !== 'Combined') {
              const valCuratedIndex = Banner.cumeTotalResults.length > 0 ? parseFloat((((queriesResultElement[0].data[bannerIndex][Tab.Name].totalPercent !== null ? parseFloat(queriesResultElement[0].data[bannerIndex][Tab.Name].totalPercent) : 0) / Banner.cumeTotalResults[0].percentage) * 100)) : 0;

              Banner.cumeTotalResults[TargetPos] = {
                thousands: queriesResultElement[0].data[bannerIndex][Tab.Name].totalThousands ? parseFloat(queriesResultElement[0].data[bannerIndex][Tab.Name].totalThousands) : null,
                percentage: queriesResultElement[0].data[bannerIndex][Tab.Name].totalPercent ? parseFloat(queriesResultElement[0].data[bannerIndex][Tab.Name].totalPercent.toFixed(2)) : null,
                index: TargetPos !== 0 ? valCuratedIndex : null,
              };
            }
          }
          bannerIndex += 1;
        }
      });
    });

    dispatch({ type: 'SPREADSHEET_CALCULATE_CUME_TOTAL', Data: DataCopy });
    swal.close();
    dispatch(SpreadSheetCalculateCombinedTab(DataCopy));
  } catch (error) {
    swal.close();
    showNotification({ message: 'Error with calculation process', type: 'error' });
    Bugsnag.notify(error);
  }
};

export const SpreadSheetCalculateCumeTotalForAllBannersCombinedTotals = () => async (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { currentTab, Data } = spreadsheet;
  const DataCopy = { ...Data };
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const bannersConfig = rowsSelectedToCumeTotalForAllBanner(DataCopy);
  const response = await Axios.post('/api/cumeTotal/listCumeTotals/allTargets', {
    account: accountName,
    database: databaseName,
    target: DataCopy.Tabs[currentTab].Targets.map(target => target.questionCode),
    banner: bannersConfig,
  }).catch((err) => {
    swal.close();
    showNotification({ message: 'Error with calculation process', type: 'error' });
  });

  const selectedYearsForCombine = [];
  DataCopy.Tabs.forEach((tab) => {
    if (tab.isVisible && tab.Name !== 'Combined') {
      selectedYearsForCombine.push(tab.Name);
    }
  });

  Promise.all([response]).then((queriesResultElement) => {
    DataCopy.Tabs.forEach((Tab, TabPosition) => {
      Tab.Targets.forEach((Target, TargetPos) => {
        let bannerIndex = 0;
        Tab.Banners.forEach((Banner, BannerPos) => {
          if (Banner.hasCumeTotal) {
            if (Banner.bannerDataStructure.bannerType !== 'complex') {
              if (Tab.Name !== 'Combined') {
                const valCuratedIndex = Banner.cumeTotalResults.length > 0 ? parseFloat((((queriesResultElement[0].data[TargetPos][bannerIndex][Tab.Name].totalPercent !== null ? parseFloat(queriesResultElement[0].data[TargetPos][bannerIndex][Tab.Name].totalPercent) : 0) / Banner.cumeTotalResults[0].percentage) * 100)) : 0;

                Banner.cumeTotalResults[TargetPos] = {
                  thousands: queriesResultElement[0].data[TargetPos][bannerIndex][Tab.Name].totalThousands ? parseFloat(queriesResultElement[0].data[TargetPos][bannerIndex][Tab.Name].totalThousands) : null,
                  percentage: queriesResultElement[0].data[TargetPos][bannerIndex][Tab.Name].totalPercent ? parseFloat(queriesResultElement[0].data[TargetPos][bannerIndex][Tab.Name].totalPercent.toFixed(2)) : null,
                  index: TargetPos !== 0 ? valCuratedIndex : null,
                };
              } else {
                let combinedCumeTotal = 0;
                let combinedCumeTotalThousands = 0;
                const countYearsForCombinedCumeTotalThousands = verifySelectedYearsCumeTotal(BannerPos, TargetPos, DataCopy, selectedYearsForCombine, 'thousands');
                const countYearsForCombinedCumeTotalPercentages = verifySelectedYearsCumeTotal(BannerPos, TargetPos, DataCopy, selectedYearsForCombine, 'percentages');
                selectedYearsForCombine.forEach((year) => {
                  combinedCumeTotal += queriesResultElement[0].data[TargetPos][bannerIndex][year].totalPercent ? queriesResultElement[0].data[TargetPos][bannerIndex][year].totalPercent : 0;
                  combinedCumeTotalThousands += queriesResultElement[0].data[TargetPos][bannerIndex][year].totalThousands ? parseFloat(queriesResultElement[0].data[TargetPos][bannerIndex][year].totalThousands) : 0;
                });
                combinedCumeTotal /= countYearsForCombinedCumeTotalPercentages;
                combinedCumeTotalThousands /= countYearsForCombinedCumeTotalThousands;
                const valCuratedIndex = Banner.cumeTotalResults.length > 0 ? parseFloat((((combinedCumeTotal.toFixed(2) !== null ? parseFloat(combinedCumeTotal.toFixed(2)) : 0) / Banner.cumeTotalResults[0].percentage) * 100)) : 0;
                Banner.cumeTotalResults[TargetPos] = {
                  thousands: parseFloat(combinedCumeTotalThousands.toFixed(0)),
                  percentage: parseFloat(combinedCumeTotal.toFixed(2)),
                  index: TargetPos !== 0 ? valCuratedIndex : null,
                };
              }
            }
            bannerIndex += 1;
          }
        });
      });
    });
  }).finally((resp) => {
    dispatch({ type: 'SPREADSHEET_CALCULATE_CUME_TOTAL', Data: DataCopy });
  });
};

export const rowsSelectedToCumeTotalForAllBanner = (DataCopy) => {
  const resultArray = [];
  DataCopy.Tabs.forEach((tab, tabPosition) => {
    if (tab.Name !== 'Combined') {
      tab.Banners.forEach((Banner) => {
        if (Banner.hasCumeTotal && tabPosition === 0) {
          const cumetotalDatapointsLength = Banner.BannerDataPoints.filter(datapoint => (
            datapoint.selectedForCumeTotal === true && !datapoint.mergedRow
          )).length;
          if (cumetotalDatapointsLength > 0) {
            const datapointsWithRemovedMergedRows = Banner.BannerDataPoints
              .filter(datapoint => !datapoint.mergedRow);
            const selectedRows = datapointsWithRemovedMergedRows.map((datapoint, i) => {
              if (datapoint.selectedForCumeTotal && datapoint.isVisible && !datapoint.mergedRow) {
                return (i + 1);
              }
              return undefined;
            });

            resultArray.push({
              question: Banner.questionCode,
              includes: selectedRows,
            });
          }
        }
      });
    }
  });
  return resultArray;
};

export const SpreadSheetOpenWindowCharts = () => (dispatch) => {
  dispatch({ type: 'SPREADSHEET_OPEN_WINDOW_CHARTS', chartWindowVisible: true });
};

export const SpreadSheetCloseWindowCharts = () => (dispatch) => {
  dispatch({ type: 'SPREADSHEET_CLOSE_WINDOW_CHARTS', chartWindowVisible: false });
};

export const SpreadSheetDeleteTab = TabPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const DataCopy = _.cloneDeep(Data);
  DataCopy.Tabs.splice(TabPos, 1);
  dispatch({ type: 'SPREADSHEET_DELETE_TAB', Data: DataCopy });
};

export const SpreadSheetExportToExcel = async (spreadsheet, title) => {
  swal({
    title: 'Starting download',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
  });
  swal.showLoading();

  try {
    const response = await Axios.post('/api/export/excel', { spreadsheet, }, { responseType: 'arraybuffer' })
    const data = new Blob([response.data], { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
    const fileName = `${title}.xlsx`;
    download(data, fileName, 'blob');
    swal.close();
  } catch (error) {
    swal.close();
    showNotification({ message: 'Error with export process', type: 'error' });
    Bugsnag.notify(error);
  }
};

export const SpreadSheetCopyTarget = TargetPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const DataCopy = _.cloneDeep(Data);
  DataCopy.Tabs.forEach((tab) => {
    const TargetCopy = _.cloneDeep(tab.Targets[TargetPos]);
    tab.Targets.splice(TargetPos, 0, TargetCopy);
    tab.Banners.forEach((banner) => {
      banner.BannerDataPoints.forEach((datapoint) => {
        datapoint.value.splice(TargetPos, 0, _.cloneDeep(datapoint.value[TargetPos]));
      });
    });
  });
  dispatch({ type: 'SPREADSHEET_COPY_TARGET', Data: DataCopy });
};

export const SpreadSheetDeleteTarget = TargetPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const DataCopy = _.cloneDeep(Data);
  DataCopy.Tabs.forEach((tab) => {
    tab.Targets.splice(TargetPos, 1);
    tab.Banners.forEach((banner) => {
      banner.BannerDataPoints.forEach((datapoint) => {
        datapoint.value.splice(TargetPos, 1);
      });

      banner.average.splice(TargetPos, 1);
      banner.cumeTotalResults.splice(TargetPos, 1);
    });
  });
 
 if(TargetPos === 0){
  // If base target is deleted, recalculate the indexes
  SpreadsheetRecalculateIndex(DataCopy);
 }

  dispatch({ type: 'SPREADSHEET_DELETE_TARGET', Data: DataCopy });
};

export const loadCharts = payload => (dispatch, getState) => {
  const { spreadsheetCardReducer } = getState();
  _.forEach(payload, ({ id }) => {
    if (!spreadsheetCardReducer.spreadsheetCards[id]) {
      dispatch({ type: ADD_SPREADSHEET_CARD, payload: id });
    }
  });
  dispatch({
    type: LOAD_SPREADSHEET_CHARTS,
    payload,
  });
};

export const SpreadSheetLoadEditTarget = (editTargetPosition, title) => (dispatch, getState) => {
  document.getElementById('EditTargetButton').click();
  dispatch({ type: 'SPREADSHEET_LOAD_EDIT_TARGET', editTargetPosition });
  dispatch({ type: 'SET_TARGET_TITLE', payload: title });
};

export const SpreadSheetLoadEditBanner = (editBannerPosition, title) => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  document.getElementById('EditBannerButton').click();
  dispatch({ type: 'SPREADSHEET_LOAD_EDIT_BANNER', editBannerPosition });
  if (Data.Tabs[0].Banners[editBannerPosition].bannerDataStructure.bannerType === 'basic') {
    dispatch({ type: 'RESTORE_BASIC_BUILDER', payload: Data.Tabs[0].Banners[editBannerPosition].bannerDataStructure.dataForm });
    dispatch({ type: 'CHANGE_BASIC_BANNER_TITLE', title });
  } else {
    dispatch({ type: 'RESTORE_ADVANCED_BUILDER', payload: Data.Tabs[0].Banners[editBannerPosition].bannerDataStructure.dataForm.blocks });
    dispatch({ type: 'CHANGE_ADVANCED_BANNER_TITLE', title });
  }
};

const SpreadSheetCalculateCombinedTab = Data => (dispatch, getState) => {
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const DataCopy = _.cloneDeep(Data);

  DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners.forEach((banner) => {
    banner.BannerDataPoints.forEach((datapoint) => {
      datapoint.value = [];
    });
  });

  const selectedYearsForCombine = [];
  DataCopy.Tabs.forEach((tab) => {
    if (tab.isVisible && tab.Name !== 'Combined') {
      selectedYearsForCombine.push(tab.Name);
    }
  });

  if (DataCopy.Tabs[DataCopy.Tabs.length - 1].isVisible) {
    swal({
      title: 'Calculating Combined Tab.',
      text: 'Please wait a moment.',
      allowOutsideClick: false,
    });
    swal.showLoading();
  }
  calculationsForCombinedTab(DataCopy, accountName, databaseName, selectedYearsForCombine, dispatch);
  swal.close();
};

const calculationsForCombinedTab = (DataCopy, accountName, databaseName, selectedYearsForCombine, dispatch) => {
  let flagHasCumeTotal = false;


  let totalCombined = {
    quantity: {},
    percentage: {},
    thousand: {},
  };
  DataCopy.Tabs.forEach((tab) => {
    if (tab.isVisible && tab.Name !== 'Combined') {
      tab.Targets.forEach((target, index) => {
        const { targetQuantity, targetPercentage, targetThousands } = target;
        let quantities = [];
        let percentages = [];
        let thousands = [];
        if (totalCombined.quantity[index]) {
          quantities = [
            ...totalCombined.quantity[index],
          ];
        }
        if (totalCombined.percentage[index]) {
          percentages = [
            ...totalCombined.percentage[index],
          ];
        }
        if (totalCombined.thousand[index]) {
          thousands = [
            ...totalCombined.thousand[index],
          ];
        }
        quantities.push(targetQuantity);
        percentages.push(targetPercentage);
        thousands.push(targetThousands);
        totalCombined = {
          ...totalCombined,
          quantity: {
            ...totalCombined.quantity,
            [index]: quantities,
          },
          percentage: {
            ...totalCombined.percentage,
            [index]: percentages,
          },
          thousand: {
            ...totalCombined.thousand,
            [index]: thousands,
          },
        };
      });
    }
  });

  const allTargetsCombined = [];
  DataCopy.Tabs[DataCopy.Tabs.length - 1].Targets.map((target, index) => {
    let targetQuantity = 0;
    let targetThousands = 0;
    let targetPercentage = 0;
    totalCombined.quantity[index].forEach((quantity) => {
      targetQuantity += quantity;
    });
    totalCombined.percentage[index].forEach((percentage) => {
      targetPercentage += percentage;
    });
    totalCombined.thousand[index].forEach((thousands) => {
      targetThousands += thousands;
    });
    const percentage = (targetPercentage / totalCombined.percentage[index].length);
    const newTarget = {
      ...target,
      targetQuantity,
      targetPercentage: index === 0 ? percentage.toFixed(0) : percentage.toFixed(1),
      targetThousands: (targetThousands / totalCombined.thousand[index].length).toFixed(0),
    };
    allTargetsCombined.push(newTarget);
  });

  DataCopy.Tabs[DataCopy.Tabs.length - 1].Targets = allTargetsCombined;

  const allBannerCombinedCurated = [];
  DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners.map((banner, index) => {
    banner.average.forEach((average, indexAvg) => {
      banner.average[indexAvg] = null;
    });

    allBannerCombinedCurated.push(banner);
  });

  DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners = allBannerCombinedCurated;

  DataCopy.Tabs.forEach((Tab) => {
    selectedYearsForCombine.forEach((year, indexYear) => {
      if (Tab.Name === year) {
        Tab.Targets.forEach((Target, indexTarget) => {
          Tab.Banners.forEach((Banner, indexBanner) => {
            const countYearsForCombinedAverage = verifySelectedYearsAverage(indexBanner, indexTarget, DataCopy, selectedYearsForCombine);

            const countYearsForCombinedThousands = verifySelectedYearsThousands(indexBanner, indexTarget, DataCopy, selectedYearsForCombine);

            Banner.BannerDataPoints.forEach((dataPoint, indexDataPointForCombinedTab) => {
              const totalQuantity = calculationTotalQuantity(indexBanner, indexTarget, DataCopy, selectedYearsForCombine, indexDataPointForCombinedTab);

              if (dataPoint.mergedRowList.length > 0) {
                const percent = dataPoint.value[indexTarget].percentage;
                const targetRespondans = Target.targetQuantity;
                dataPoint.value[indexTarget].curatedPercentage = percentsToRespondans(percent, targetRespondans);
              }

              const dataPointForCombinedTab = DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners[indexBanner].BannerDataPoints[indexDataPointForCombinedTab];
              let valueDataPointForCombinedThousand = dataPointForCombinedTab.value.length === dataPoint.value.length ? dataPointForCombinedTab.value[indexTarget].thousands : 0;
              let valueDataPointForCombinedPercent = dataPointForCombinedTab.value.length === dataPoint.value.length ? dataPointForCombinedTab.value[indexTarget].curatedPercentage : 0;
              let combinedDataPointForThousands = dataPoint.value.length > 0 ? isNaN(dataPoint.value[indexTarget].thousands) ? 0 : dataPoint.value[indexTarget].thousands : 0;
              const combinedDataPointForPercentages = dataPoint.value.length > 0 ? isNaN(dataPoint.value[indexTarget].curatedPercentage) ? 0 : dataPoint.value[indexTarget].curatedPercentage : 0;

              combinedDataPointForThousands /= countYearsForCombinedThousands;

              valueDataPointForCombinedThousand += combinedDataPointForThousands;
              valueDataPointForCombinedPercent += combinedDataPointForPercentages;

              DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners[indexBanner].BannerDataPoints[indexDataPointForCombinedTab].value[indexTarget] = {
                percentage: 0,
                thousands: parseFloat(valueDataPointForCombinedThousand),
                curatedPercentage: parseFloat(valueDataPointForCombinedPercent),
                totalQuantity,
                index: 0,
                selected: false,
              };
            });

            // For Average
            const averageForCombinedTab = DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners[indexBanner];
            let valueAverageForCombined = averageForCombinedTab.average.length === Banner.average.length ? averageForCombinedTab.average[indexTarget] : 0;
            let combinedDataPointForAverage = Banner.average.length > 0 ? Banner.average[indexTarget] : 0;

            combinedDataPointForAverage /= countYearsForCombinedAverage;
            valueAverageForCombined += combinedDataPointForAverage;

            DataCopy.Tabs[DataCopy.Tabs.length - 1].Banners[indexBanner].average[indexTarget] = parseFloat(valueAverageForCombined);
          });
        });
      }
    });
  });

  // Curated Combined
  const combinedTab = _.last(DataCopy.Tabs);
  combinedTab.Targets.forEach((Target, indexTarget) => {
    combinedTab.Banners.forEach((Banner) => {
      Banner.BannerDataPoints.forEach((dataPoint) => {
        const currentValue = dataPoint.value?.[indexTarget];
        if (currentValue) {
          currentValue.thousands = currentValue.thousands || 0;
          currentValue.percentage = (currentValue.curatedPercentage * 100) / (currentValue.totalQuantity) || 0;
          currentValue.index = ((currentValue.percentage) / dataPoint.value?.[0].percentage * 100) || 0;
        }
      });
      Banner.average[indexTarget] = Banner.average[indexTarget] || null;
    });
  });

  dispatch({ type: 'SPREADSHEET_CALCULATE_COMBINED', Data: DataCopy });

  DataCopy.Tabs.forEach((tab, tabPosition) => {
    if (tabPosition === 0) {
      tab.Banners.forEach((banner, bannerPos) => {
        if (banner.hasCumeTotal && !flagHasCumeTotal) {
          flagHasCumeTotal = true;
          dispatch(SpreadSheetCalculateCumeTotalForAllBannersCombinedTotals());
        }
      });
    }
  });
};

const calculationTotalQuantity = (indexBanner, indexTarget, DataCopy, selectedYearsForCombine, indexDataPointForCombinedTab) => {
  let selectedYears = 0;
  DataCopy.Tabs.forEach((Tab) => {
    selectedYearsForCombine.forEach((year) => {
      if (Tab.Name === year) {
        const datapoint = _.get(Tab.Banners, `[${indexBanner}].BannerDataPoints[${indexDataPointForCombinedTab}]`);
        const percentage = datapoint.value?.[indexTarget]?.percentage;
        if (_.isFinite(percentage)) {
          selectedYears += Tab.Targets[indexTarget].targetQuantity;
        }
      }
    });
  });


  return selectedYears;
};

const verifySelectedYearsThousands = (indexBanner, indexTarget, DataCopy, selectedYearsForCombine) => {
  let yearsCnt = 0;
  let flag = true;
  DataCopy.Tabs.forEach((Tab) => {
    selectedYearsForCombine.forEach((year) => {
      if (Tab.Name === year) {
        flag = true;
        if (Tab.Banners[indexBanner].BannerDataPoints.length > 0) {
          Tab.Banners[indexBanner].BannerDataPoints.forEach((dataPoint, index) => {
            if (dataPoint.value.length > 0) {
              if (flag && dataPoint.value[0].thousands !== null && !isNaN(dataPoint.value[0].thousands)) {
                yearsCnt += 1;
                flag = false;
              }
            }
          });
        }
      }
    });
  });

  if (yearsCnt === 0) {
    yearsCnt = 1;
  }

  return yearsCnt;
};

const verifySelectedYearsAverage = (indexBanner, indexTarget, DataCopy, selectedYearsForCombine) => {
  let yearsCnt = 0;
  DataCopy.Tabs.forEach((Tab) => {
    selectedYearsForCombine.forEach((year) => {
      if (Tab.Name === year) {
        let cnt = 0;
        if (Tab.Banners[indexBanner].average.length !== null) {
          if (Tab.Banners[indexBanner].average[indexTarget] !== null) {
            cnt += 1;
          }
        }
        if (cnt !== 0) {
          yearsCnt += 1;
        }
      }
    });
  });

  if (yearsCnt === 0) {
    yearsCnt = 1;
  }

  return yearsCnt;
};

const verifySelectedYearsCumeTotal = (indexBanner, indexTarget, DataCopy, selectedYearsForCombine, typeCalc) => {
  let yearsCnt = 0;
  let flag = true;
  DataCopy.Tabs.forEach((Tab) => {
    selectedYearsForCombine.forEach((year) => {
      if (Tab.Name === year) {
        flag = true;
        if (Tab.Banners[indexBanner].cumeTotalResults.length > 0) {
          switch (typeCalc) {
            case 'thousands':
              if (flag && Tab.Banners[indexBanner].cumeTotalResults[0].thousands !== null && !isNaN(Tab.Banners[indexBanner].cumeTotalResults[0].thousands)) {
                yearsCnt += 1;
                flag = false;
              }
              break;
            case 'percentages':
              if (Tab.Banners[indexBanner].cumeTotalResults[indexTarget].percentage !== null && !isNaN(Tab.Banners[indexBanner].cumeTotalResults[indexTarget].percentage)) {
                yearsCnt += 1;
                flag = false;
              }
              break;
            default:
              break;
          }
        }
      }
    });
  });

  if (yearsCnt === 0) {
    yearsCnt = 1;
  }

  return yearsCnt;
};

export const SpreadSheetSaveProject = () => (dispatch, getState) => {
  const {
    AppReducer, 
    spreadsheet, 
    spreadsheetCardReducer, 
    spreadsheetChartReducer, 
    ProjectReducer,
  } = getState();
  const { user, currentDatabase, currentAccountId } = AppReducer;
  const { isLoadedFromMyProjects, currentLoadedProject, ...spreadsheetData } = spreadsheet;
  const chartData = {
    charts: spreadsheetChartReducer.charts,
    cards: spreadsheetCardReducer.cards,
    labels: spreadsheetChartReducer.chartLabels,
  };
 
  const isAdmin = user.role && user.role.name === 'admin';
  const project = {
    type: 'PROJECT',
    subType: 'SPREADSHEET',
    title: spreadsheet.Title,
    data: {
      ...spreadsheetData,
      chartData,
    },
    database: currentDatabase,
    team: isAdmin ? currentAccountId : user.teamId._id,
    user: user._id,
    shared: false,
  };

  const existingProject = _.find(ProjectReducer.projects, { title: project.title, subType: 'SPREADSHEET' });

  if (existingProject) {
    swal({
      title: 'Warning',
      text: `"${project.title}" already exists. Do you want to replace it?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#19A2C5',
      cancelButtonColor: '#F78181',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
     if(result.value){
      return updateProject(existingProject._id, project, dispatch);
     } else {
      throw result;
     }
    });
  } else {
    return createProject(project,dispatch);
  }
  
};

const createProject = (project, dispatch) => {
  return Axios.post('/api/templates/', project)
      .then((response) => {
        if (response.data.success) {
          showNotification({ message: 'Spreadsheet saved', type: 'success' });
          const { data, ...restOfTemplate } = response.data.template;
          dispatch({
            type: 'SPREADSHEET_CHANGE_SAVED_NEW_PROJECT',
            currentLoadedProject: restOfTemplate,
          });
          dispatch(getProjects());
        } else {
          const { message, type } = getErrorMessage(response.data);
          showNotification({
            message: message || 'Error saving spreadsheet',
            type,
          });
        }
      })
      .catch((error) => {
        const { message, type } = getErrorMessage(error);
        showNotification({ message, type });
      });
}

const updateProject = (id, project, dispatch) => {
 return Axios.put(`/api/templates/${id}`, project)
  .then((response) => {
    showNotification({ message: 'Spreadsheet saved', type: 'success' });
    dispatch(getProjects());
  })
  .catch((error) => {
    const { message, type } = getErrorMessage(error);
    showNotification({ message, type });
  });
}

export const SpreadSheetChangeProjectTitle = title => (dispatch, getState) => {
  dispatch({
    type: 'SPREADSHEET_CHANGE_PROJECT_TITLE',
    Title: title,
  });
};

export const SpreadSheetLoadFromMyProjects = row => (dispatch, getState) => {
  const {data, ...template} = row;
  dispatch({
    type: 'SPREADSHEET_LOAD_FROM_MYPROJECTS',
    template,
    Data: data,
  });
};

export const SpreadSheetSaveBannerTemplate = data => (dispatch, getState) => new Promise((resolve, reject) => {
  const { currentAccountId, user, currentDatabase } = getState().AppReducer;
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const spreadsheetData = [];

  Data.Tabs.forEach((year, yearIndex) => {
    const bannerData = { ...year.Banners[data.bannerPosition], isTemplate: true };

    const bannersList = [{
      ...bannerData,
      BannerDataPoints: [
        ...bannerData.BannerDataPoints.map(dataPoint => ({
          ...dataPoint,
          value: [{ ...dataPoint.value[0] }],
        })),
      ],
      average: bannerData.average.length > 0 ? [bannerData.average[0]] : [],
      cumeTotalResults: bannerData.cumeTotalResults.length > 0 ? [bannerData.cumeTotalResults[0]] : [],
    }];

    if (year.Banners[data.bannerPosition].mergedBlockList.length > 0) {
      year.Banners[data.bannerPosition].mergedBlockList.map((mergedBlock) => {
        mergedBlock.isTemplate = true;
        bannersList.push(mergedBlock);
      });
    }
    spreadsheetData.push({
      Name: year.Name,
      Targets: [],
      Banners: bannersList,
      isCurrentSurvey: year.isCurrentSurvey,
      isVisible: year.isCurrentSurvey,
    });
  });

  if (Data.Tabs.length > 0) {
    data.title = Data.Tabs[0].Banners[data.bannerPosition].BannerName;
  }

  if (data.title === null || data.title === '') data.title = 'Untitled template';

  const template = {
    ...data,
    team: currentAccountId,
    database: currentDatabase,
    user: user._id,
  };

  template.dataStructureBanner = spreadsheetData;
  Axios.post('/api/templates/', template)
    .then((response) => {
      if (response.data.success) {
        dispatch(getProjects());

        showNotification({
          message: 'Banner template saved.',
        });
        resolve(response.data);
      } else {
        reject(response.data);
      }
    })
    .catch((error) => {
      reject(error);
    });
});

export const SpreadSheetEditBanner = () => (dispatch, getState) => {
  const { spreadsheet, bannerReducer } = getState();
  const { currentTab, Data, editBannerPosition } = spreadsheet;
  const accountName = getState().AppReducer.currentFolderName;
  const databaseName = getState().AppReducer.currentDatabase;
  const DataCopy = _.cloneDeep(Data);
  swal({
    title: 'Loading Banner.',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
    onOpen: async () => {
      swal.showLoading();

      // Fetch qinfoData
      const qinfosDataPromises = bannerReducer.currentBanner.map(
        async bannerElement => Axios.post(
          '/api/questionInfo',
          {
            account: accountName,
            database: databaseName,
            question: bannerElement.data.question,
            type: 'banner',
            dataStructure: {
              bannerType: bannerElement.type,
              public: false,
              shared: false,
              subType: 'QUESTION',
              title: bannerElement.type === 'basic' ? bannerReducer.basicBuilderTitle : bannerReducer.advancedBuilderTitle,
              type: 'TEMPLATE',
              isFromSpreadsheet: true,
              dataForm: bannerElement.type === 'basic' ? bannerReducer.basicBuilder : bannerReducer.advancedBuilder,
            },
          },
        ).catch((err) => {
          swal.close();
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type });
        }),
      );
      Promise.all(qinfosDataPromises)
        .then(((qinfoResponses) => {
          const qinfosData = qinfoResponses.map(qinfo => qinfo.data);
          // Concat new Banners with new ones
          qinfosData.forEach((qTab) => {
            const { questionCode, bannerDataStructure } = qTab;
            if (bannerDataStructure.bannerType === 'basic') {
              const basicBuilderQuestion = bannerReducer.basicBuilder[questionCode];
              if (basicBuilderQuestion) {
                qTab.bannerDataStructure.dataForm = {
                  [questionCode]: basicBuilderQuestion,
                };
              }
            }
          });
          let previousBannersLength = 0;
          DataCopy.Tabs.forEach((tab) => {
            const previousBanners = tab.Banners;
            previousBannersLength = previousBanners.length;
            previousBanners.forEach((previousBannerData, indexBanner) => {
              const { questionCode } = previousBannerData;
              if (qinfosData[indexBanner]) {
                if (qinfosData[indexBanner].questionCode === questionCode) {
                  const { BannerDataPoints } = previousBannerData;
                  BannerDataPoints.forEach((datapoint, indexDatapoint) => {
                    let qDatapoint = qinfosData[indexBanner].BannerDataPoints[indexDatapoint];
                    if (qDatapoint) {
                      const { answerCode } = datapoint;
                      if (qDatapoint.answerCode === answerCode) {
                        qDatapoint = datapoint;
                      }
                    }
                  });
                }
              }
            });
            bannerReducer.currentBanner.forEach((bannerElement, indexBanner) => {
              const qBanner = qinfosData[indexBanner];
              if (qBanner) {
                const { data } = bannerElement;
                if (qBanner.questionCode === data.question) {
                  const allAnswers = [];
                  _.forEach(data.builder.answers, (value) => {
                    allAnswers.push(value.id);
                  });
                  qBanner.BannerDataPoints.forEach((datapoint, indexDatapoint) => {
                    const { answerCode } = datapoint;
                    let isVisible = false;
                    if (allAnswers.includes(answerCode)) {
                      isVisible = true;
                    }
                    qinfosData[indexBanner].BannerDataPoints[indexDatapoint].isVisible = isVisible;
                  });
                }
              }
            });
            const newBanner = _.cloneDeep(_.concat(previousBanners, qinfosData));
            tab.Banners = newBanner;
          });
          const newBannersLength = qinfosData.length;
          // Make Call for data
          const bannersData = bannerReducer.currentBanner.map(bannerElement => ({
            type: bannerElement.type,
            data: {
              question: bannerElement.data.question,
            },
          }));

          if (DataCopy.Tabs[currentTab].Targets.length > 0) {
            const queries = DataCopy.Tabs[currentTab].Targets.map((target, index) => Axios.post('/api/complexFilter', {
              account: accountName,
              database: databaseName,
              target: target.questionCode,
              banner: bannersData,
              targetIndex: index,
            }));
            Promise.all(queries).then((queriesResult) => {
              queriesResult.forEach((queriesResultElement) => {
                let queriesLength = queriesResultElement.data.results.length;
                queriesResultElement.data.results.forEach((result, i) => {
                  DataCopy.Tabs.forEach((tab) => {
                    const year = tab.Name;
                    const targetQuantity = tab.Targets[queriesResultElement.data.targetIndex].targetQuantity;

                    if (tab.Name !== 'Combined') {
                      if (tab.Banners[tab.Banners.length - queriesLength].bannerDataStructure.bannerType !== 'complex') {
                        if (result[year].average !== null && result[year].average !== undefined) {
                          tab.Banners[tab.Banners.length - queriesLength].average.push(parseFloat((result[year].average).toFixed(2)));
                        } else tab.Banners[tab.Banners.length - queriesLength].average.push(null);
                      } else {
                        tab.Banners[tab.Banners.length - queriesLength].average = [null];
                      }
                      tab.Banners[tab.Banners.length - queriesLength].BannerDataPoints.forEach((datapoint, j) => {
                        const percentage = result[year].percentages[j].count;
                        const thousands = result[year].thousands[j].count;
                        const newValue = createDataPointValue(percentage, thousands, targetQuantity, datapoint);
                        datapoint.value.push(newValue);
                      });
                    }
                    if (tab.Banners[tab.Banners.length - queriesLength].bannerDataStructure.bannerType === 'complex') {
                      tab.Banners[tab.Banners.length - queriesLength].average = [null];
                    }
                  });
                  queriesLength -= 1;
                });
              });
              DataCopy.Tabs.forEach((tab) => {
                const AddedBanners = [];
                for (let i = 0; i < tab.Banners.length; i++) {
                  if (i >= previousBannersLength) {
                    AddedBanners.push(tab.Banners[i]);
                  }
                }
                tab.Banners.splice(editBannerPosition, 1);
                for (let i = 0; i < AddedBanners.length; i++) {
                  tab.Banners.splice(editBannerPosition + i, 0, AddedBanners[i]);
                  tab.Banners.pop();
                }
              });
              const payload = { Data: DataCopy, AddedBanners: newBannersLength };
              dispatch({ type: 'SPREADSHEET_ADD_BANNER', payload });
              dispatch(SpreadSheetCalculateCombinedTab(DataCopy));
              swal.close();
            });
          } else {
            const payload = { Data: DataCopy };
            dispatch({ type: 'SPREADSHEET_ADD_BANNER', payload });
            swal.close();
          }
        }));
    },
  });
};

export const SpreadSheetReset = () => (dispatch) => {
  dispatch({ type: 'SPREADSHEET_RESET' });
};

export const SpreadSheetLoadTargetFromMyProjects = () => (dispatch) => {
  dispatch(SpreadSheetReset());
  dispatch({ type: 'SPREADSHEET_LOAD_TARGET_TEMPLATE' });
};

const SpreadSheetResetTargetModal = type => (dispatch) => {
  let data = {};
  if (type === 'baseTarget') {
    data = { title: 'All Adults', query: 'C1-1' };
  }
  if (type === 'currentTarget') {
    data = { title: '', query: '', size: 0 };
  }
  dispatch({ type: RESET_TARGET, payload: { type, data } });
  dispatch({ type: TARGET_BUILDER_RESET, payload: null });
};

export const SpreadSheetLoadBannerFromMyProjects = data => (dispatch) => {
  dispatch(SpreadSheetReset());
  dispatch({ type: 'SPREADSHEET_LOAD_BANNER_TEMPLATE', payload: data });
};

export const SpreadSheetToggleCumeTotalMode = BannerPos => (dispatch, getState) => {
  const { spreadsheet } = getState();
  const { Data } = spreadsheet;
  const DataCopy = _.cloneDeep(Data);
  DataCopy.Tabs.forEach((tab) => {
    tab.Banners[BannerPos].cumeTotalMode = false;
  });
  dispatch({ type: 'SPREADSHEET_ADD_CUME_TOTAL', Data: DataCopy });
};

export const SpreadSheetChangeBannerTitleModal = (type, payload) => {
  if (type === 'basic') {
    return { type: 'CHANGE_BASIC_BANNER_TITLE', payload };
  } if (type === 'advanced') {
    return { type: 'CHANGE_ADVANCED_BANNER_TITLE', payload };
  } if (type === 'edit') {
    return { type: 'CHANGE_EDIT_BANNER_TITLE', payload };
  }
};

export const allQueriesMergedRowsForAllBanner = (DataCopy) => {
  const queriesArray = [];
  DataCopy.Tabs.forEach((tab, tabPosition) => {
    if (tab.Name !== 'Combined') {
      tab.Banners.forEach((banner) => {
        banner.BannerDataPoints.forEach((dataPoint, j) => {
          // If we have merged data points , we find currently datapoints.
          if (!dataPoint.combinedRow) {
            if (dataPoint.mergedRow) {
              if (tabPosition === 0) {
                queriesArray.push({ question: dataPoint.idQueryMergeRow });
              }
            }
          }
        });
      });
    }
  });

  return queriesArray;
};

const isComplexBanner = (banner) => {
  return banner?.bannerDataStructure?.bannerType === 'complex';
}

const hasCumeTotal = (tab) => {
  let flagHasCumeTotal = false;
  tab.Banners.forEach((banner) => {
    if (banner.hasCumeTotal) {
      flagHasCumeTotal = true;
    }
  });
  return flagHasCumeTotal;
}

const SpreadsheetRecalculateIndex = (DataCopy) => {
  DataCopy.Tabs.forEach((tab) => {
    tab.Banners.forEach((banner) => {
      banner.BannerDataPoints.forEach((datapoint) => {
        datapoint.value.forEach((value,valueIndex) => {
            datapoint.value[valueIndex].index = calculateDatapointValueIndex(datapoint, value.percentage);
        });
      });
    });
  });
  return DataCopy;
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { restoreSavedTarget } from '../../actions/targetActions';

class BaseTarget extends Component {
  handleEditClick = () => {
    const { openModal, restoreTarget } = this.props;
    restoreTarget('baseTarget');
    openModal();
  };

  render() {
    const { baseTarget } = this.props;
    const text = baseTarget.title;
    const iconText = 'Edit';
    return (
      <EditTarget text={text} iconText={iconText} onClick={this.handleEditClick} />
    );
  }
}

const EditTarget = ({ text, iconText, onClick }) => (
  <SelectEdit onClick={onClick}>
    {text}
    <Icon>{iconText}</Icon>
  </SelectEdit>
);

const mapStateToProps = state => ({ baseTarget: state.targetReducer.baseTarget });
const mapDispatchToProps = dispatch => ({
  restoreTarget: type => dispatch(restoreSavedTarget(type)),
});

const Icon = styled.div`
  background: #C5E7F0;
  color: #31ACCB;
  width: 30px;
  float: right;
  font-size: 13px;
  text-align:center;
  padding: 6px 10px;
  border-radius: 2px;
  margin-top: -4px;
  display: none;
`;

const SelectEdit = styled.div`
  font-size: 1em;
  font-style: italic;
  font-family: 'Roboto';
  font-weight: 400;
  border-width: 1px;
  border-color: #CFD8DC;
  border-style: solid;
  border-radius: 3px;
  padding: 8px;
  color: #37474F;
  cursor: pointer;
  &:hover {
    color: #37474F;
    border-color: #3DB1CE;
    ${Icon}{
      display:block;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(BaseTarget);

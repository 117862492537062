import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Scrollbar from '../GlobalComponents/Scrollbar';
import SaveTemplateBox from './SaveTemplateBox';

class AdvancedPreview extends Component {

  getExpression({ operator, negated }) {
    let expression = '';
    if (negated) {
      expression += 'Exclude ';
    } else {
      expression += 'Include ';
    }

    if (operator === 'OR') {
      expression += 'any ';
    } else {
      expression += 'all ';
    }
    expression += 'of the following: ';
    return expression;
  }

  render() {
    const { advancedBuilder, currentTab } = this.props;
    return (
      <div style={{ position: 'relative', height: '100%' }}>
        <Container>
          <Title>Preview:</Title>
          <Summary>
            <Scrollbar>
              {_.map(advancedBuilder.blocks, (block, index) => (
                <Block key={index}>
                  <p>
                    {block.operator && `${block.operator}, `}
                    {_.size(block.questions) > 0 && `${this.getExpression(block.itemsOperator)}`}
                  </p>
                  {_.map(block.questions, (question, index) => (
                    <Question key={index}>
                      <p>{question.text}</p>
                      {_.map(question.answers, (answer, index) => <Answer key={index}>{`• ${answer.text}`}</Answer>)}
                    </Question>
                  ))}
                </Block>
              ))}
            </Scrollbar>
          </Summary>
        </Container>
        <SaveTemplateBox edit={currentTab === 1} currentTab={currentTab} />
      </div>

    );
  }

}

const Container = styled.div`
  height: 345px;
  width: 252px;
  margin: 0px;
  margin-left: 24px;
  margin-bottom: 5px;
`;

const Title = styled.h3`
  margin: 0;
  padding-top: 15px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  color: #37474F;
`;

const Summary = styled.div`
  height: 100%;
`;

const Block = styled.div`
  background-color: #F6F7F8;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  padding: 10px 17px 13px 12px;
  margin-bottom: 5px;
  p {
    color: #607D8B;
    margin: 3px 0px;
  }
`;

const Question = styled.ul`
  margin: 1px;
  color: black;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 500;
  padding: 0px;
  p {
    color: #37474F;
    margin: 0px;
    margin-bottom: 5px;
  }
`;

const Answer = styled.li`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #37474F;
  margin-bottom: 2px;
  list-style: none;
`;

export default AdvancedPreview;

import React, { Component } from 'react';
import styled from 'styled-components';
import Tooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row, ControlLabel } from '../GlobalComponents/BasicControls';
import RecentProjects from './RecentProjects/RecentProjects';
import OwnTemplates from './OwnTemplates';
import helpIcon from '../../assets/ico-helpTip.svg';
import SharedTemplates from './SharedTemplates';
import { loadQuestions } from '../../actions/categories';
import {
  restoreBasicBanner, restoreAdvancedBanner, setCurrentBannerTab, setEditingTo, setCurrentBannerTemplate, setBanner,
} from '../../actions/bannerActions';
import {
  setCurrentTargetTemplate, restoreTargetTemplate, setTargetTitle, setTargetQueryFromSavedTargets, changeTargetTemplatesMenu, toggleTargetBuilderView, copyTarget, getTargetSize,
} from '../../actions/targetActions';
import { setCurrentOverview } from '../../actions/overviewActions';
import { SpreadSheetLoadFromMyProjects, SpreadSheetLoadTargetFromMyProjects, SpreadSheetLoadBannerFromMyProjects } from '../../actions/spreadsheetActions';
import { resetSpreadSheetCharts } from '../../actions/spreadsheetChartActions';
import { resetSpreadsheetCards } from '../../actions/spreadsheetCardActions'; 

const text = 'Stowell Data only saves your <br /> 10 most recent projects.';
const MyProjects = ({ text, helpIcon, actions }) => (
  <React.Fragment>
    <Row>
      <Col size={12}>
        <PageTitle>
          Recent Projects
        </PageTitle>
        <ImageIcon src={helpIcon} alt="" data-tip={text} />
        <Tooltip
          place="right"
          effect="solid"
          type="light"
          border
          html
          className="tooltip"
        />
        <RecentProjects actions={actions} />
      </Col>
    </Row>
    <OwnTemplates actions={actions} />
  </React.Fragment>
);
class Content extends Component {
  state = {
    currentPage: 'myProjects',
  }

  componentDidMount() {
    const { loadQuestions } = this.props;
    loadQuestions();
  }

  showSharedTemplates = (show) => {
    this.setState({ currentPage: show ? 'sharedTemplates' : 'myProjects' });
  }

  setOverview = (overview) => {
    const { setCurrentOverview } = this.props;
    setCurrentOverview(overview);
    this.goToOverview();
  }

  goToOverview = () => {
    const { history } = this.props;
    history.push('/overview');
  }

  setSpreadSheet = (spreadsheet) => {
    const { SpreadSheetLoadFromMyProjects, SpreadSheetResetCharts, SpreadsheetResetCards } = this.props;
    SpreadSheetLoadFromMyProjects(spreadsheet);
    SpreadSheetResetCharts();
    SpreadsheetResetCards();
    this.goToSpreadSheet();
  }

  setSpreadSheetTarget = (target) => {
    const { setCurrentTarget, SpreadSheetLoadTargetFromMyProjects } = this.props;
    setCurrentTarget(target);
    SpreadSheetLoadTargetFromMyProjects();
    this.goToSpreadSheet();
  }

  setSpreadSheetBanner = (banner) => {
    const { setCurrentBannerTemplate, SpreadSheetLoadBannerFromMyProjects } = this.props;
    setCurrentBannerTemplate(banner);
    SpreadSheetLoadBannerFromMyProjects(banner);
    this.goToSpreadSheet();
  }

  goToSpreadSheet = () => {
    const { history } = this.props;
    history.push('/spreadsheet');
  }

  setBanner = (banner) => {
    const { setCurrentBannerTemplate } = this.props;
    setCurrentBannerTemplate(banner, true);
    this.goToOverview();
  }

  setTarget = (target) => {
    const { setCurrentTarget } = this.props;
    setCurrentTarget(target);
    this.goToOverview();
  }

  render() {
    const { currentPage } = this.state;
    const actions = {
      overview: {
        setTarget: this.setTarget,
        setBanner: this.setBanner,
        setOverview: this.setOverview,
        goToOverview: this.goToOverview,
      },
      spreadsheet: {
        setSpreadSheet: this.setSpreadSheet,
        goToSpreadSheet: this.goToSpreadSheet,
        setSpreadSheetTarget: this.setSpreadSheetTarget,
        setSpreadSheetBanner: this.setSpreadSheetBanner,
      },
    };
    return (
      <ContentBody>
        <Container>
          <Row>
            <Col size={3}>
              <Topic
                fontSize="13px"
                active={currentPage === 'myProjects'}
                onClick={() => { this.showSharedTemplates(false); }}
              >
              My Projects
              </Topic>
              <Topic
                fontSize="13px"
                active={currentPage === 'sharedTemplates'}
                onClick={() => { this.showSharedTemplates(true); }}
              >
              Shared Templates
              </Topic>
            </Col>
            <Col size={9}>
              {
                currentPage === 'myProjects'
                  ? <MyProjects actions={actions} helpIcon={helpIcon} text={text} />
                  : <SharedTemplates actions={actions} />
              }
            </Col>
          </Row>
        </Container>
      </ContentBody>
    );
  }
}


const ImageIcon = styled.img`
  padding: 2px 8px 0 5px;
  width: 17px !important;
  height: 17px !important;
  cursor: pointer;
`;

const ContentBody = styled.div`
  width: 84%;
  padding: 0px 8% 0 8%;
`;

const Container = styled.div`
  display: inline-block;
  width: 100%;
  padding: 20px 0 25px 0;
`;

const Topic = styled(ControlLabel)`
  font-weight: ${({ active }) => (active ? '500' : '400')};
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4em')};
  color: ${({ active }) => (active ? 'rgba(65, 71, 76, 1)' : '#607D8B')};
  cursor: pointer;
`;
const PageTitle = styled.p`
  font-weight: 600;
  display: inline-block;
  font-size: 1.2em;
  color: rgba(65, 71, 76, 1);
  cursor: pointer;
`;
const mapDispatchToProps = dispatch => ({
  loadQuestions: () => dispatch(loadQuestions()),
  setCurrentTarget: ({ data, title, _id }) => {
    dispatch(setCurrentTargetTemplate({ id: _id, title }));
    dispatch(restoreTargetTemplate(data));
    dispatch(setTargetTitle(title));
    dispatch(setTargetQueryFromSavedTargets(data));
    dispatch(changeTargetTemplatesMenu('edit'));
    dispatch(toggleTargetBuilderView('templates'));
    dispatch(getTargetSize('target2', data));
    dispatch(copyTarget('target2'));
  },
  setCurrentBannerTemplate(banner, autoload) {
    const {
      bannerType, data, dataStructureBanner,
    } = banner;
    if (bannerType === 'basic') {
      dispatch(setBanner(bannerType, data || dataStructureBanner[0].Banners[0].bannerDataStructure.dataForm));
    } else if (bannerType === 'advanced') {
      dispatch(setBanner(bannerType, data.blocks));
    } else if (bannerType === 'basic_block_merged') {
      dispatch(setBanner(bannerType, dataStructureBanner[0].Banners[0].mergedBlockList));
      dispatch(restoreBasicBanner(dataStructureBanner[0].Banners[0].mergedBlockList));
    }

    if (bannerType === 'basic') {
      dispatch(restoreBasicBanner(data));
    } else if (bannerType === 'advanced') {
      dispatch(restoreAdvancedBanner(data));
    }
    dispatch(setCurrentBannerTemplate(banner, autoload));
    dispatch(setEditingTo(false));
    dispatch(setCurrentBannerTab(1));
  },
  setCurrentOverview(overview) {
    dispatch(setCurrentOverview(overview));
  },
  SpreadSheetLoadFromMyProjects(spreadsheet) {
    dispatch(SpreadSheetLoadFromMyProjects(spreadsheet));
  },
  SpreadSheetLoadTargetFromMyProjects() {
    dispatch(SpreadSheetLoadTargetFromMyProjects());
  },
  SpreadSheetLoadBannerFromMyProjects(data) {

    dispatch(SpreadSheetLoadBannerFromMyProjects(data));
  },
  SpreadSheetResetCharts() {
    dispatch(resetSpreadSheetCharts());
  },
  SpreadsheetResetCards() {
    dispatch(resetSpreadsheetCards());
  },
});
export default connect(null, mapDispatchToProps)(withRouter(Content));

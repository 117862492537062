import React, { Component } from 'react';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { faChevronRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChildMenu } from './ChildMenu';


export class DropDownMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null })

  render() {
    const FontAwesomeIconStyle = {
      width: '13px',
      height: '15px',
      margin: '-1px 5px 0 2px',
      color: 'rgba(0,0,0,0.4)',
    };

    const triggerIconStyle = {
      fontSize: '13pt',
      margin: '0px 5px 0 5px',
      color: '#90A4AE',
      cursor: 'pointer',
    };

    const { anchorEl } = this.state;
    const { menuOptions, CustomButton } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div>
        {
          CustomButton
            ? (
              <CustomButton
                type="button"
                onClick={this.handleClick}
              >
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={triggerIconStyle}
                />
              </CustomButton>
            )
            : (
              <FontAwesomeIcon
                icon={faCaretDown}
                style={triggerIconStyle}
                onClick={this.handleClick}
              />
            )
        }
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {
            menuOptions.map((item, i) => (
              item.items
                ? (
                  <ChildMenu
                    key={i}
                    item={item}
                    handleParentClose={this.handleClose}
                  />
                ) : (
                  <MenuItem
                    key={i}
                    disabled={item.isDisabled}
                    style={{
                      fontSize: '13px',
                    }}
                    onClick={() => {
                      item.action();
                      this.handleClose();
                    }}
                  >
                    {
                      item.icon && (
                        item.imageIcon
                          ? <ImageIcon src={item.icon} alt="" />
                          : (
                            <FontAwesomeIcon
                              icon={item.icon}
                              style={FontAwesomeIconStyle}
                            />
                          ))}
                    {item.text}
                    {
                      item.items
                      && (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={FontAwesomeIconStyle}
                      />
                      )}
                  </MenuItem>
                )))
          }
        </Menu>
      </div>
    );
  }
}

const ImageIcon = styled.img`
  padding: 0 8px 0 0;
  width: 18px !important;
  height: 18px !important;
`;

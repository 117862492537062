import {
  SET_CURRENT_OVERVIEW,
  UPDATE_OVERVIEW_TITLE,
} from '../actionTypes/overviewTypes';

const initialStateForError = {
  error: {
    open: false,
    message: '',
    type: 'error',
  },
};

const OverviewReducer = (state = {
  currentOverview: {
    title: 'Untitled project',
  },
  ...initialStateForError,
}, action) => {
  switch (action.type) {
    case SET_CURRENT_OVERVIEW:
      return { ...state, currentOverview: action.payload };
    case UPDATE_OVERVIEW_TITLE:
      return {
        ...state,
        currentOverview: {
          ...state.currentOverview,
          title: action.payload,
        },
      };
    default:
      return state;
  }
};

export default OverviewReducer;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import propTypes from 'prop-types';

class Trigger extends Component {
  render() {
    const { title, open } = this.props;
    return (
      <Title open={open}>
        <IconWrapper>
          <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} />
        </IconWrapper>
        <Text>{title}</Text>
      </Title>
    );
  }
}

Trigger.propTypes = {
  title: propTypes.string.isRequired,
  open: propTypes.bool,
};

Trigger.defaultProps = {
  open: false,
};

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 36px;
  align-items: center;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  background-color:${({ open }) => (open ? '#E8F5F9' : 'white')};
  color: #37474F;
  padding-left: 18px;
  &:hover {
    background: #E8F5F9;
  }
`;

const IconWrapper = styled.div`
  color: #98ABB4;
  min-width: 15px;
  font-size: 1em;
  font-weight: 300;
`;

const Text = styled.p`
  margin: 0px;
  margin-left: 5px;
  padding: 5px 0;
`;

export default Trigger;

import React, { Component } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { MenuItem } from '@material-ui/core';
import {
  restoreBasicBanner, restoreAdvancedBanner, setEditingTo, setCurrentBannerTemplate,
} from '../../actions/bannerActions';

const styles = {
  item: {
    root: 'targetDropdown',
    selected: 'selected',
  },
  button: {
    root: 'applyButton',
    disabled: 'disabled',
  },
};
class TemplateDropdown extends Component {
  state = {
    open: false,
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleMenuItemClick = (e, _id) => {
    const { banners, setCurrentBannerTemplate } = this.props;
    setCurrentBannerTemplate(banners[_id]);
  }

  renderList = () => {
    const { banners } = this.props;
    const sortedBanners = _.orderBy(banners, 'createdAt', 'asc');;
    if (_.size(banners) !== 0) {
      return _.map(sortedBanners, ({
        title, _id, selected, shared, user,
      }) => (
        <MenuItem
          key={_id}
          classes={styles.item}
          selected={selected}
          onClick={(e) => { this.handleMenuItemClick(e, _id); }}
        >
          {title}
          {
            (shared && (this.props.user._id !== _.get(user,'_id')))
            && (
              <Icon>
                <FontAwesomeIcon icon={faUserFriends} />
              </Icon>
            )
          }
        </MenuItem>
      ));
    }
    return (
      <MenuItem
        classes={styles.item}
      >
      You have no saved templates.
      </MenuItem>
    );
  }

  render() {
    const { open } = this.state;
    return (
      <li
        className="tab"
        ref={(node) => {
          this.anchorEl = node;
        }}
        onClick={this.handleToggle}
      >
        <a
          style={{ textDecoration: 'none', color: '#37474F' }}
          href="/"
          className={`tab-link ${this.props.linkClassName} ${this.props.isActive ? 'active' : ''}`}
          onClick={(event) => {
            event.preventDefault();
            this.props.onClick(this.props.tabIndex);
          }}
        >
          {this.props.tabTitle}
          <Icon>
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} />
          </Icon>
        </a>
        <Popper style={{ zIndex: 1400 }} open={open} anchorEl={this.anchorEl} transition placement="bottom-start">
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList style={{ paddingBottom: '11px', width: '200' }}>
                    {
                      this.renderList()
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </li>
    );
  }
}

const Icon = styled.p`
  color: gray;
  display: inline-block;
  padding: 0px;
  margin: 0px 5px;
`;

const mapStateToProps = (store) => {
  const { sharedTemplates, projects } = store.ProjectReducer;
  const filteredProjects = _.filter(projects, p => p.subType === 'QUESTION');
  const filteredSharedTemplates = _.filter(sharedTemplates, p => p.subType === 'QUESTION');
  return {
    banners: _.keyBy([...filteredSharedTemplates, ...filteredProjects], '_id'),
    user: store.AppReducer.user,
  };
};
const mapDispatchToProps = dispatch => ({
  setCurrentBannerTemplate(banner) {
    const {
      bannerType, data,
    } = banner;
    if (bannerType === 'basic') {
      dispatch(restoreBasicBanner(data));
    } else if (bannerType === 'advanced') {
      dispatch(restoreAdvancedBanner(data));
    }
    dispatch(setCurrentBannerTemplate(banner));
    dispatch(setEditingTo(false));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TemplateDropdown);

import React, { Component } from 'react'
import styled from 'styled-components'
import { TableHeader } from './TableHeader';

class ColumnHeaderFixed extends Component {
  constructor(props) {
    super(props);
    this.fixedColumn = React.createRef();
  }

  componentDidMount() {
    const { updateRef } = this.props;
    updateRef(this.fixedColumn);
  }

  render() {
    const { children, newHeight } = this.props;
    return (
      <Wrapper
        newHeight={newHeight}
      >
        <FixedColumn>
          <Header>
            <Placeholder>
              <ColumnHead />
              <Cell />
              <Cell
                style={{
                  borderBottom: 'none'
                }}
              >
                <div>
                  Respondents Analyzed
                </div>
              </Cell>
            </Placeholder>
          </Header>
          <Body
            innerRef={(el) => this.fixedColumn = el}
          >
            {children}
          </Body>
        </FixedColumn>
      </Wrapper>
    )
  }
}

const Body = styled.div`
  margin-top: 150px;
  padding-top: 10px;
  width: 300px;
  overflow: hidden;
  position: absolute;
  height: calc(100% - 175px);
  background-color: #F6F7F8;
`;

const Placeholder = styled.div`    
  width: 300px; 
  background-color: white;   
  border-right: 2px solid  #E2E7Ea;
  display: inline-block;
`;


const ColumnHead = styled.div`
  box-sizing: border-box;
  border-bottom:  2px solid #E2E7Ea;
  border-top: 1px solid #F6F7F8;
  border-left: 1px solid #F6F7F8;
  border-right: 1px solid #F6F7F8;
  height: 27px;
  background-color: #E2E7EA;
`;

const Cell = styled.div`
  height: 60px;
  border-bottom:  2px solid #E2E7Ea;
  display: flex;
  align-items: center;
  div {
    padding-left: 62px;
    font-size: 13px;
    font-weight: 500;
    color: #37474F;
    text-align: left;
  }
`;

const Header = styled(TableHeader)`
  border-top: 2px solid #607D8B;
  height: 148px;
  max-height: 148px;
  margin-top: 0;
`
const Wrapper = styled.div`
  margin-top: -160px;
  position: absolute;
  height: ${({ newHeight }) => newHeight ? `${newHeight + 10}px` : '100%'};;
  left: ${({ newLeft }) => newLeft ? `${newLeft}px` : 0};
  background-color: #F6F7F8;
  width: 300px;
`

const FixedColumn = styled.div`  
  width: 300px;
  overflow: hidden;
  height: 148px;
`

export default ColumnHeaderFixed;
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Form, Row, FormRow, FormGroup, FormControl, ControlLabel, Title, NiceRadioButton, Button,
} from '../GlobalComponents/BasicControls';
import { addMember, updateMember } from '../../actions/teamActions';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

class MemberForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getInitialState(),
    };
  }

  componentWillMount() {
    const {
      row,
      edit,
      user,
    } = this.props;
    if (edit) {
      if (_.size(row) !== 0) {
        const {
          first_name,
          last_name,
          role,
          email,
        } = row;
        let userType = role.name;
        if (user.role.name === 'admin' && user.role) {
          userType = 'admin';
        }
        this.setState({
          edit: true,
          firstName: first_name,
          lastName: last_name,
          userType,
          email,
          row,
        });
      }
    } else {
      let userType = 'basic';
      if (user.role.name === 'admin' && user.role) {
        userType = 'admin';
      }
      this.setState({
        userType,
      });
    }
  }

  getInitialState = () => ({
    edit: false,
    firstName: '',
    lastName: '',
    email: '',
    userType: 1,
    disableOnSaving: false,
    row: {},
    teamId: '',
  });

  update = (row) => {
    this.setState({
      disableOnSaving: true,
    });
    const { _id } = row;
    const { user, updateMember } = this.props;
    const {
      firstName, lastName, email, userType,
    } = this.state;
    const data = {
      teamId: user.teamId._id,
      userId: _id,
      first_name: firstName,
      last_name: lastName,
      email,
      user_type: userType,
    };
    updateMember(data)
      .then((response) => {
        showNotification({ message: response.message, type: 'success' })
        this.setState({
          disableOnSaving: false,
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
        this.setState({
          disableOnSaving: false,
        });
      });
  }

  insert = () => {
    this.setState({ disableOnSaving: true });
    const { user, addMember } = this.props;
    const {
      firstName, lastName, email, userType,
    } = this.state;
    const data = {
      teamId: user.teamId._id,
      first_name: firstName,
      last_name: lastName,
      email,
      user_type: userType,
    };
    addMember(data)
      .then((response) => {
        if (!response.email_error) {
          showNotification({ message: response.message, type: 'success' });
        } else {
          showNotification({ message: `${response.message}, but error sending invitation`, type: 'warning' });
        }
        this.setState(this.getInitialState());
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
        this.setState({
          disableOnSaving: false,
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { edit, row } = this.state;
    if (edit) {
      this.update(row);
    } else {
      this.insert();
    }
  }

  handleInputText = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  getRadioButtonOptions = (edit, row) => {
    if (edit) {
      if (row.role.name !== 'admin') {
        if (row.role.name === 'owner') {
          return (
            <NiceRadioButton
              checked
              id="option3"
              name="userType"
              value="owner"
              onClick={e => this.handleInputText(e)}
            >
              <ControlLabel bold color="#37474F">
                Owner
              </ControlLabel>
            </NiceRadioButton>
          );
        }
        return (
          <div>

            <NiceRadioButton
              checked={row.role.name === 'basic'}
              id="option1"
              name="userType"
              value="basic"
              onClick={e => this.handleInputText(e)}
            >
              <ControlLabel
                style={{ fontWeight: 800 }}
              >
                Basic
              </ControlLabel>
              <ControlLabel
                style={{ fontSize: '12px' }}
              >
                Has access to surveys and documentation plus the ability to create Overviews, Projects, and Templates, but can not invite new users.
              </ControlLabel>
            </NiceRadioButton>

            <NiceRadioButton
              checked={row.role.name === 'manager'}
              id="option2"
              name="userType"
              value="manager"
              onClick={e => this.handleInputText(e)}
            >
              <ControlLabel bold color="#37474F">
                Manager
              </ControlLabel>
              <ControlLabel color="#37474F" style={{ fontSize: '12px' }}>
                Has full account access to all company databases plus the ability to add and remove users.
              </ControlLabel>
            </NiceRadioButton>
          </div>
        );
      }
      return (
        <NiceRadioButton
          checked
          id="option3"
          name="userType"
          value="admin"
          onClick={e => this.handleInputText(e)}
        >
          <ControlLabel bold color="#37474F">
            Admin
          </ControlLabel>
        </NiceRadioButton>
      );
    }
    if (!edit) {
      return (
        <div>
          <NiceRadioButton
            checked
            id="option1"
            name="userType"
            value="basic"
            onClick={e => this.handleInputText(e)}
          >
            <ControlLabel bold color="#37474F">
              Basic
            </ControlLabel>
            <ControlLabel color="#37474F">
              Has access to surveys and documentation plus the ability to create Overviews, Projects, and Templates, but can not invite new users.
            </ControlLabel>
          </NiceRadioButton>
          <NiceRadioButton
            id="option2"
            name="userType"
            value="manager"
            onClick={e => this.handleInputText(e)}
          >
            <ControlLabel bold color="#37474F">
              Manager
            </ControlLabel>
            <ControlLabel color="#37474F">
              Has full account access to all company databases plus the ability to add and remove users.
            </ControlLabel>
          </NiceRadioButton>
        </div>
      );
    }
  }

  render() {
    const { handleForm, edit, user } = this.props;
    const {
      row,
      firstName,
      lastName,
      email,
      disableOnSaving,
    } = this.state;
    return (
      <div>
        <Header>
          <div style={{ display: 'flex' }}>
            <FontAwesomeIcon
              style={{ color: '#90A4AE' }}
              icon={faAngleLeft}
              right={8}
            />
            <LinkLabel
              onClick={handleForm}
            >
              Back to account users
            </LinkLabel>
          </div>
        </Header>
        <Body>
          <Title>
            {
              edit
                ? 'Edit a User'
                : 'Invite a User'
            }
          </Title>
          <Form onSubmit={e => this.handleSubmit(e)}>
            <Row>
              <FormRow width="445px">
                <FormGroup>
                  <ControlLabel>
                    First name
                  </ControlLabel>
                  <FormControl
                    autoComplete="off"
                    autoFocus
                    required
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={e => this.handleInputText(e)}
                  />
                </FormGroup>
              </FormRow>
            </Row>
            <Row>
              <FormRow width="445px">
                <FormGroup>
                  <ControlLabel>
                    Last name
                  </ControlLabel>
                  <FormControl
                    autoComplete="off"
                    required
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={e => this.handleInputText(e)}
                  />
                </FormGroup>
              </FormRow>
            </Row>
            <Row>
              <FormRow width="445px">
                <FormGroup
                  style={{ fontSize: '13px' }}
                >
                  <ControlLabel>
                    Email address
                  </ControlLabel>
                  {edit
                    ? email
                    : (
                      <FormControl
                        autoComplete="off"
                        required
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => this.handleInputText(e)}
                      />
                    )
                  }
                </FormGroup>
              </FormRow>
            </Row>
            <RadioAndButtons>
              <FormRow width="445px">
                <FormGroup>
                  <ControlLabel>
                    User Type
                  </ControlLabel>
                  {
                    (user.role.name === 'admin' && user.role)
                      ? (
                        <NiceRadioButton
                          checked
                          id="option3"
                          name="userType"
                          value="admin"
                          onClick={e => this.handleInputText(e)}
                        >
                          <ControlLabel
                            style={{ fontWeight: 800 }}
                          >
                      Admin
                          </ControlLabel>
                        </NiceRadioButton>
                      )
                      : this.getRadioButtonOptions(edit, row)
                              }
                </FormGroup>
              </FormRow>
              <div>
                <Button
                  tColor="#607D8B"
                  bgColor="#CFD8DC"
                  onClick={handleForm}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  disabled={disableOnSaving}
                  tColor="#FFFFFF"
                  bgColor="#607D8B"
                >
                  {
                    edit
                      ? 'Save'
                      : 'Invite'
                  }
                </Button>
              </div>
            </RadioAndButtons>
          </Form>
        </Body>
      </div>
    );
  }
}

const Header = styled.div`
  width: 98%;
  height: 25px;
  padding: 10px 0 0 2%;
`;

const RadioAndButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkLabel = styled.button`
  font-family: 'Roboto';
  color: #19A2C5;
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

const Body = styled.div`
  width: 90%;
  padding: 40px 0 40px 10%;
`;

MemberForm.propTypes = {
  handleForm: propTypes.func,
  edit: propTypes.bool,
  row: propTypes.object,
};

MemberForm.defaultProps = {
  // row: {}
};

const mapStateToProps = store => ({
  user: store.AppReducer.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addMember: data => dispatch(addMember(data)),
  updateMember: data => dispatch(updateMember(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberForm);

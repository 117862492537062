import React from 'react';
import styled from 'styled-components';

export default ({ children, width }) => (
  <Background>
    <Container width={width}>
      <Title>
        <p>
          <b>STOWELL</b>
          &nbsp;DATA
        </p>
      </Title>
      <Body>
        {children}
      </Body>
    </Container>
  </Background>
)


const Container = styled.div`
  width: ${({ width }) => width || '456'}px;
  margin-top: 100px;
`;

const Title = styled.div`
  background-color: #2F3E48;
  color:white;
  font-size: 20px;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
  border-radius: 4px 4px 0px 0px;
  height: 60px;
  p {
    padding: 19px 0px 13px 29px;
  }
  p > b {
    font-weight: 800;
  }
`;

const Body = styled.div`
  background-color: white;
  padding: 43px 30px 36px 29px;
  border-radius: 0px 0px 4px 4px;
`;

const Background = styled.div`
    background-color:#19A2C5;
    display: flex;
    position: absolute;
    justify-content: center;
    width:100%;
    height:100%;
`;
import React, { Component } from 'react';
import styled from 'styled-components';
import angleDown from '../../../assets/down-arrow-angle.svg';

class TitleBar extends Component {
  handleChange = (e) => {
    const view = e.target.value;
    const { builder, target, templates } = this.props.actions;
    if (view === 'builder') {
      templates.changeMenu('list');
      target.setTitle('');
    }
    builder.toggleView(view);
    builder.reset();
  };

  render() {
    const { view } = this.props;
    return (
      <Container>
        <Title>
          Add Target
        </Title>
        <Select value={view} onChange={this.handleChange}>
          <option value="builder">build from scratch</option>
          <option value="templates">from template</option>
        </Select>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  padding: 15px 32px;
  align-items: flex-end;
`;

const Title = styled.div`
  color: #37474F;
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
`;

const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: 1px solid #CFD8DC;
  border-radius: 2px;
  width: 200px;
  height: 32px;
  padding: 6px 48px 6px 6px;
  margin: 0 20px;
  background-image: url(${angleDown});
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 90%;
`;

export default TitleBar;
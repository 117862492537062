import { GET_USER, UPDATE_USER } from '../actionTypes/profileTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const initialState = {
  currentUser: {},
};

const ProfileReducer = (state = initialState, action) => {
  if (action.type === GET_USER) {
    return {
      ...state,
      currentUser: action.payload,
    };
  } if (action.type === UPDATE_USER) {
    return {
      ...state,
      currentUser: action.payload,
    };
  } if (action.type === LOG_OUT) {
    return initialState;
  }

  return state;
};

export default ProfileReducer;

import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faUsers, faDatabase, faSignOutAlt, faChevronRight, faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LogOutAction } from '../../actions/appActions';

class UserDropdown extends Component {
  state = {
    isAdminPage: false,
  };

  componentWillReceiveProps(props) {
    const { path } = props.match;
    if (path.includes('account')) {
      this.setState({ isAdminPage: true });
    } else {
      this.setState({ isAdminPage: false });
    }
  }

  logOut = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('currentDatabase');
    localStorage.removeItem('currentAccountFolder');
    localStorage.removeItem('currentAccountId');
    const { logOut } = this.props;
    logOut();
  }

  goTo = (url) => {
    const { history } = this.props;
    history.push(url);
  }

  getAdminButton = () => {
    const { isAdminPage } = this.state;
    if (isAdminPage) {
      return null;
    }
    return (
      <DropdownOption onClick={() => { this.goTo('/accounts'); }}>
        <Icon icon={faUserTie} />
          Stowell Admin
        <Icon floatright="true" icon={faChevronRight} />
      </DropdownOption>
    );
  }

  render() {
    const { showingUserDropDown, innerRef, user } = this.props;
    const isAdminUser = user.role.name === 'admin';
    const isOwnerOrManagerUser = user.role.name === 'manager' || user.role.name === 'owner';
    let hasMoreThanOneDb = false;
    if (user.teamId.databases.length > 1 || isAdminUser) {
      hasMoreThanOneDb = true;
    }
    const permissions = {};
    if (isAdminUser || isOwnerOrManagerUser) {
      permissions.canSeeManageTeam = true;
      permissions.canSeeChangeDb = true;
    }
    if (isAdminUser) {
      permissions.canSeeAdminButton = true;
      permissions.canSeeChangeDb = true;
    }
    return (
      <Dropdown innerRef={innerRef} showingUserDropDown={showingUserDropDown}>
        {/* <DropdownTitle onClick={() => { this.goTo('/profile'); }}>{`${user.first_name} ${user.last_name}`}</DropdownTitle> */}
        <DropdownOption onClick={() => { this.goTo('/profile'); }}>
          <Icon icon={faUser} />
          My Profile
        </DropdownOption>
        {
          permissions.canSeeManageTeam
          && (
          <DropdownOption onClick={() => { this.goTo('/team/members'); }}>
            <Icon icon={faUsers} />
            Manage Team
          </DropdownOption>
          )}
        {
          permissions.canSeeAdminButton
          && this.getAdminButton()
        }
        {
          permissions.canSeeChangeDb
          && (
          <DropdownOption disabled={!hasMoreThanOneDb} bordered onClick={() => { this.goTo('/database-selector'); }}>
            <div>
              <Icon icon={faDatabase} />
              Sign in to other database
            </div>
            <Icon floatright="true" icon={faChevronRight} />
          </DropdownOption>
          )}

        <DropdownOption onClick={this.logOut}>
          <Icon icon={faSignOutAlt} />
          Sign Out
        </DropdownOption>
      </Dropdown>
    );
  }
}

const Dropdown = styled.div`
  top: 60px;
  right: 16px;
  width: 230px;
  position: absolute;
  display: flex;
  overflow: hidden;
  ${({ showingUserDropDown }) => (!showingUserDropDown && 'max-height: 0px;')};
  ${({ showingUserDropDown }) => (showingUserDropDown && 'box-shadow: 0px 2px 5px 1px #CCC;')};
  justify-content: space-around;
  flex-direction: column;
  border-radius: 0px 0px 6px 6px;
  background-color: white;
  transition: all 500ms cubic-bezier(0, 1, 0.5, 1);
  z-index: 1;
`;
const DropdownOption = styled.div`
  height: 32px;
  display: flex;
  padding-left: 16px;
  color: #37474F;
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  ${({ bordered }) => bordered && `
    border-top: 0.5px solid #DDD; 
    border-bottom: 0.5px solid #DDD;
    justify-content: space-between;
  `}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.5;'};
  &:hover {
    background-color: rgba(0,0,0,0.05)
  }
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 16px;
  /* ${({ floatright }) => floatright && 'float: right; margin-right: 30px;'} */
  color: #AAA;
`;

const mapDispatchToProps = dispatch => ({
  logOut() {
    dispatch(LogOutAction());
  },
});
export default connect(null, mapDispatchToProps)(withRouter(UserDropdown));

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import { restoreSavedTarget } from '../../actions/targetActions';

class Target2 extends Component {
  handleEditClick = () => {
    const { target2, openModal, restoreTarget } = this.props;
    const hasTarget = !_.isEmpty(target2);
    if (hasTarget) {
      restoreTarget('target2');
    }
    openModal();
  };

  render() {
    const { target2, openModal } = this.props;
    const text = target2.title || '+ add Target';
    const hasTarget = !_.isEmpty(target2);
    const iconText = hasTarget ? 'Edit' : 'Add';
    return (
      <Fragment>
        {!hasTarget
          ? <AddTarget text={text} iconText={iconText} onClick={openModal} />
          : <EditTarget text={text} iconText={iconText} onClick={this.handleEditClick} />
        }
      </Fragment>
    );
  }
}

const AddTarget = ({ text, iconText, onClick }) => (
  <SelectAdd onClick={onClick}>
    {text}
    <Icon>{iconText}</Icon>
  </SelectAdd>
);

const EditTarget = ({ text, iconText, onClick }) => (
  <SelectEdit onClick={onClick}>
    {text}
    <Icon>{iconText}</Icon>
  </SelectEdit>
);

const mapStateToProps = state => ({ target2: state.targetReducer.target2 });
const mapDispatchToProps = dispatch => ({
  restoreTarget: type => dispatch(restoreSavedTarget(type)),
});

const Icon = styled.div`
  background: #C5E7F0;
  color: #19A2C5;
  width: 30px;
  float: right;
  font-size: 13px;
  text-align:center;
  padding: 6px 10px;
  border-radius: 2px;
  margin-top: -4px;
  display: none;
`;

const SelectAdd = styled.div`
  font-size: 1em;
  font-style: italic;
  font-family: 'Roboto';
  font-weight: 400;
  border-width: 1px;
  border-color: #CFD8DC;
  border-style: dashed;
  border-radius: 3px;
  padding: 8px;
  color: rgba(38,50,56,0.45);
  cursor: pointer;
  &:hover {
    color: #3DB1CE;
    border-color: #3DB1CE;
    ${Icon}{
      display:block;
    }
  }
`;

const SelectEdit = styled(SelectAdd)`
  border-color: #CFD8DC;
  border-style: solid;
  color: #37474F;
  &:hover {
    color: #37474F;
    border-color: #3DB1CE;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(Target2);

import React, { Component } from 'react';
import styled from 'styled-components';
import NavigationBar from '../../GlobalComponents/NavigationBar';
import TitleBar from '../../GlobalComponents/TitleBar';
import AddUserContent from './AddAccountContent';

class AddUser extends Component {
  componentDidMount() {
    document.title = 'Stowell Data | Add Account';
  }

  render() {
    return (
      <Container>
        <NavigationBar />
        <TitleBar>
          Add New Account
        </TitleBar>
        <AddUserContent history={this.props.history} />
      </Container>
    );
  }
}

const Container = styled.div`
  background: rgba(0,0,0,0.03);
  position: absolute;
  width: 100%;
  height: all !important;
  min-height: 100% !important;
`;

export default AddUser;

import React, { Component } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import './Card.css';

const styles = {
  root: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '13px',
    color: '#37474F',
    marginRight: '1px',
  },
  icon: {
    fill: '#90A4AE',
  },
  selectMenu: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '13px',
    color: '#37474F',
  },
};

class DisplayedUnitMenu extends Component {
  render() {
    const {
      id, currentUnit, currentTarget, changeUnit, type, classes, currentType,
    } = this.props;
    return (
      <Select
        value={currentUnit}
        disableUnderline
        classes={{
          root: classes.root,
          icon: classes.icon,
          selectMenu: classes.selectMenu,
        }}
        style={{ flex: 1 }}
        IconComponent={KeyboardArrowDown}
        onChange={e => changeUnit(id, e.target.value, type)}
      >
        <MenuItem value="percentages" className="menu-item">%</MenuItem>
        <MenuItem value="thousands" className="menu-item" disabled={currentType === 'pie'}>000s</MenuItem>
        <MenuItem value="index" className="menu-item" disabled={currentTarget !== 1}>Index</MenuItem>
        <MenuItem value="all" className="menu-item" disabled={currentType !== 'table'}>All</MenuItem>
      </Select>
    );
  }
}

DisplayedUnitMenu.propTypes = {
  id: propTypes.string.isRequired,
  currentUnit: propTypes.string.isRequired,
  currentTarget: propTypes.number.isRequired,
  changeUnit: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
};

export default withStyles(styles)(DisplayedUnitMenu);

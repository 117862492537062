import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { getTargetSize } from '../../../actions/targetActions';


class TargetSize extends Component {
  state = {
    loading: false,
    hasFetched: false,
  }

  handleClick = () => {
    const { blocks, getSize, type } = this.props;
    this.setState({ loading: true });
    getSize(blocks, type === 'spreadsheet').then(() => this.setState({ loading: false, hasFetched: true }));
  }

  render() {
    const { blocks, size, type } = this.props;
    const { loading, hasFetched } = this.state;
    const disabled = isEmpty(blocks[0].questions);
    return (
      <Size id="targetSizeButton" onClick={this.handleClick} disabled={disabled} size={size} hasFetched={hasFetched}>
        <FontAwesomeIcon icon={faSyncAlt} spin={loading} />
        &nbsp;&nbsp;Target Size (n=): {size || 0}
      </Size>
    );
  }
}

const mapStateToProps = state => ({ size: state.targetReducer.currentTarget.size });
const mapDispatchToProps = dispatch => ({
  getSize: (blocks, isFromSpreadsheet) => dispatch(getTargetSize('currentTarget', blocks, isFromSpreadsheet)),
});

const Size = styled.button`
  display: flex;
  justify-content: flex-start;
  width: 264px;
  height: 32px;
  background: white;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  padding: 8px;
  margin-left: 24px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
  outline: none;
  color: ${({ size, hasFetched }) => (size < 50 && hasFetched ? '#A3070E' : '#19A2C5')};
  &:hover {
    background: #F6F7F8;
  }
  &:active {
    position: relative;
    top: 1px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(TargetSize);

import React from 'react';
import PropTypes from 'prop-types';
import faTrash from '../../../assets/ico-remove.svg';
import { ToolbarButton } from './ToolbarButton';


const ToolbarButtonRemove = (props) => {
  const { functions, canRemoveRows } = props;
  return (
    <ToolbarButton
      onClick={functions.deleteRow}
      disabled={!canRemoveRows}
      leftIcon={{
        icon: faTrash,
        type: 'img',
        style: {
          height: '17px',
          marginTop: '-3%',
        },
      }}
      text="Remove"
    />
  );
};

ToolbarButtonRemove.propTypes = {
  functions: PropTypes.instanceOf(Object).isRequired,
  canRemoveRows: PropTypes.bool.isRequired,
};

export default ToolbarButtonRemove;

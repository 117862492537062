import _ from 'lodash';
import {
  LOAD_OVERVIEW_CHARTS,
  ADD_OVERVIEW_CHARTS,
  DELETE_OVERVIEW_CHARTS,
  DELETE_OVERVIEW_CHART,
  UPDATE_OVERVIEW_CHARTS,
  UPDATE_OVERVIEW_CHART,
  SET_USED_OVERVIEW_BANNER,
  SET_EDITING_OVERVIEW_CHART,
  RESTORE_OVERVIEW_CHARTS,
  MOVE_OVERVIEW_CHARTS,
  CLEAR_OVERVIEW_CHARTS,
} from '../actionTypes/overviewChartTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const defaultState = {
  charts: [],
  editingChart: null,
  usedBanner: {},
  chartLabels: {},
};
const chartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_OVERVIEW_CHARTS:
      return { ...state, charts: action.payload };
    case ADD_OVERVIEW_CHARTS:
      return { ...state, charts: [...state.charts, ...action.payload] };
    case UPDATE_OVERVIEW_CHARTS:
      return { ...state, charts: action.payload };
    case DELETE_OVERVIEW_CHARTS:
      return deleteCharts(state, action.payload);
    case UPDATE_OVERVIEW_CHART:
      return updateChart(state, action.payload);
    case DELETE_OVERVIEW_CHART:
      return deleteChart(state, action.payload);
    case SET_EDITING_OVERVIEW_CHART:
      return { ...state, editingChart: action.payload };
    case SET_USED_OVERVIEW_BANNER:
      return { ...state, usedBanner: action.payload };
    case RESTORE_OVERVIEW_CHARTS:
      return { ...state, charts: action.payload };
    case MOVE_OVERVIEW_CHARTS:
      return { ...state, charts: action.payload };
    case LOG_OUT:
      return defaultState;
    case CLEAR_OVERVIEW_CHARTS:
      return defaultState;
    default:
      return state;
  }
};

function updateChart(state, updatedChart){
  const charts = _.cloneDeep(state.charts);
  const index = _.findIndex(charts,chart =>chart.id === updatedChart.id);
  charts[index] = updatedChart;
  return {...state,charts };
};

function deleteChart(state, id) {
  const charts = _.cloneDeep(state.charts);
  const newCharts = charts.filter(chart => chart.id !== id);
  return { ...state, charts: newCharts };
}

function deleteCharts(state, amount) {
  const charts = _.cloneDeep(state.charts);
  const chartsSlice = _.take(charts,charts.length - amount);
  return {...state, charts: chartsSlice};
}

export default chartReducer;

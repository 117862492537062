import {
  LOG_OUT, LOGIN_SUCCESS, LOGIN_ERROR, ALREADY_LOGGED, SET_CURRENT_USER, SET_DATABASE, UPDATE_CURRENT_USER, SET_ADMIN_DATABASES, SET_ACCOUNT_FOLDERNAME, SET_ACCOUNT_ID,
} from '../actionTypes/appTypes';

const initialState = {
  loginCredentials: {},
  loginError: {},
  logged: false,
  user: {},
  currentDatabase: null,
  currentFolderName: null,
  currentAccountId: null,
  adminDatabases: [],
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, loginCredentials: action.loginCredentials, logged: true };
    case LOGIN_ERROR:
      return { ...state, loginError: action.loginError };
    case ALREADY_LOGGED: {
      return { ...state, logged: true };
    }
    case LOG_OUT: {
      return initialState;
    }
    case SET_CURRENT_USER: {
      return { ...state, user: action.payload };
    }
    case SET_DATABASE: {
      return { ...state, currentDatabase: action.payload };
    }
    case SET_ACCOUNT_FOLDERNAME: {
      return { ...state, currentFolderName: action.payload };
    }
    case UPDATE_CURRENT_USER: {
      return { ...state, user: { ...state.user, ...action.payload } };
    }
    case SET_ACCOUNT_ID: {
      return { ...state, currentAccountId: action.payload };
    }
    case SET_ADMIN_DATABASES: {
      return { ...state, adminDatabases: action.payload };
    }
    default:
      return state;
  }
};

export default AppReducer;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {
  ClickAwayListener, Popper, Grow, Paper,
} from '@material-ui/core';


export default class DownloadButton extends Component {
  state = {
    open: false,
  }

  downloadPresentation = (screenSize) => {
    const { download } = this.props;
    download(screenSize);
  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>
        <Button
          innerRef={(node) => {
            this.anchorEl = node;
          }}
          open={open}
          onClick={this.handleToggle}
          anchorEl={this.anchorEl}
        >
        Download{' '}<FontAwesomeIcon style={{ color: '#607D8B' }} icon={faAngleDown} />
        </Button>
        <Popper style={{ zIndex: 1400 }} open={open} anchorEl={this.anchorEl} transition placement="bottom-start">
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleToggle}>
                  <MenuList style={{ paddingBottom: '0px', width: '200' }}>
                    <MenuItem onClick={() => this.downloadPresentation('4x3')}>
                      <Screen /> as Microsoft PowerPoint Standard (4:3)
                    </MenuItem>
                    <MenuItem onClick={() => this.downloadPresentation('WIDE')}>
                      <Screen wide /> as Microsoft PowerPoint Widescreen (16:9)
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

const Screen = styled.div`
  width: ${({ wide }) => (wide ? '14px' : '14px')};
  height: ${({ wide }) => (wide ? '8px' : '12px')};
  border: 1px solid #CCCCCC;
  margin-right: 8px;
`;

const Button = styled.button`
  height: 35px;
  font-weight: 500;
  margin-left: 15px;
  width: 100px;
  border: none;
  font-family: 'Roboto';
  font-size: 13px;
  border-radius: 3px;
  color: #37474F;
  background: #CFD8DC;
  cursor: pointer;
  ${({ shadow }) => shadow && 'box-shadow: 0px 5px 10px #CCCCCC;'}
  ${({ disabled }) => disabled && 'opacity: 0.4;'}
  &:hover{
      opacity:0.8;
  }
`;

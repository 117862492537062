import React, { Component } from 'react';
import withBreakpoints from 'react-breakpoints/lib/withBreakpoints';
import { withRouter } from 'react-router-dom';
import { HiddenContent } from './ResponsiveViews/HiddenContent';

class ScreenValidation extends Component {
  getView = () => {
    const { breakpoints, location } = this.props;
    const { innerWidth } = window;
    if (innerWidth <= breakpoints.tabletLandscape && location.pathname.includes('login')) {
      return <HiddenContent />;
    }
    return (
      <div style={{ minWidth: 1000 }}>
        {this.props.children}
      </div>
    );
  }

  render() {
    return (
      this.getView()
    );
  }
}

export default withBreakpoints(withRouter(ScreenValidation));

import React, { Component } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

class Header extends Component {
  render() {
    const { onClickButton, src, buttonDisabled } = this.props;
    return (
      <Wrapper>
        <Title>
          Documents
        </Title>
        <Button href={src} onClick={onClickButton} buttonDisabled={buttonDisabled}>
        View current survey
        </Button>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  height: 78px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  border-bottom: 2px solid #DDDDDD;
`;

const Title = styled.div`
  font-family: 'Roboto';
  margin: 30px 0px 18px 0px;
  font-size: 24px;
  color: #37474F;
  line-height: 32px;
`;


const Button = styled.a`
  height: 24px;
  width: 124px;
  padding: 8px 10px 0px 10px;
  border-radius: 2px;
  background-color: #607D8B;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: white;
  text-align: center;
  margin: 30px 0px 18px 0px;
  cursor: pointer;
  &:hover{
    opacity:0.9;
  }
  ${({ buttonDisabled }) => buttonDisabled && `
    pointer-events: none;
    opacity: 0.5;
    `}
`;

Header.propTypes = {
  onClickButton: propTypes.func.isRequired,
};

export default Header;

import React from 'react';
import styled from 'styled-components';

const Title = styled.p`
  font-family: 'Roboto';
  width: 99%;
  min-height: 15px;
  padding: 0px;
  margin-bottom: 21px;
  font-size: 16px;
  font-weight: 500;
  color: #37474F;
`;


const Form = styled.form`
  display: inline-block;
  width: 90%;
`;

const FormControl = styled.input`
  border: 1px solid #CFD8DC;
  color: #37474F;
  font-family: 'Roboto';
  border-radius: 2px;
  outline: none;
  width: ${({ width }) => width || '98%'};
  height: 28px;
  font-size: 13px;
  padding-left: 2%;
`;

const FormGroup = styled.div`
  width: 100%;
  ${''}
`;

const Row = styled.div`
  width: 100%;
  min-height: 20px;
  display: inline-flex;
  ${''}
`;

export const FormRow = styled.div`
  margin-bottom: 15px;
  width: ${({ width }) => width || '100%'};
`;

const ControlLabel = styled.p`
  font-family: 'Roboto';
  width: 99%;
  min-height: 15px;
  padding: 2px 0 0 0;
  margin: 2px 0 5px 0;
  font-size: 13px;
  color: ${({ color }) => color || '#607D8B'};
  font-weight: ${({ bold }) => bold && '500'};
`;

const Button = props => (
  <Btn {...props}>
    { props.children }
  </Btn>
);

const Btn = styled.button`
  display: inline-block;
  min-width: 45px;
  width: auto;
  height: 32px;
  font-size: 13px;
  background-color: ${props => (props.bgColor ? props.bgColor : 'rgba(0, 0, 0, 0.2)')};
  color: ${props => (props.tColor ? props.tColor : 'rgba(0,0,0,0.5)')};
  font-weight: 550;
  padding: 2px 15px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin-left: ${({ marginLeft }) => marginLeft || '15px'};
  &:hover {
    opacity: 0.9;
  };
  &:disabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: default;
  }
`;

const Col = (props) => {
  let newSize = 100;
  let newXOffset = 1;
  const { size, xOffset } = props;
  if (size > 0 && size < 6) {
    newSize = size * 8;
  } else if (size > 6 && size < 12) {
    newSize = 50 + ((size - 6) * 8);
  } else if (size === 12) {
    newSize = 100;
  } else {
    newSize = 49;
  }

  if (xOffset > 0 && xOffset < 6) {
    newXOffset = xOffset * 8;
  } else if (xOffset > 6 && xOffset < 12) {
    newXOffset = 50 + ((xOffset - 6) * 8);
  } else if (xOffset === 12) {
    newXOffset = 100;
  } else if (xOffset === 6) {
    newXOffset = 49;
  }
  return (
    <div
      style={{
        width: `${newSize}%`,
        minHeight: 20,
        // marginTop: '-50px !important',
        display: 'inline-block',
        padding: `0 1% 20px ${newXOffset}%`,
        clear: 'both !important',
        // border: '1px solid red',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};

// Radio Button
const NiceRadioButton = props => (
  <List>
    <Radio
      defaultChecked={props.checked}
      onClick={props.onClick}
      required
      id={props.id}
      type="radio"
      name={props.name}
      value={props.value}
    />
    <Label htmlFor={props.id}>
      { props.children }
    </Label>
    <CircleButton className="check" />
  </List>
);

const List = styled.li`
  display: block;
  position: relative;
  float: left;
  width: 100%;
  min-height: 20px;
  height: auto;
	${''}
  ${''}
`;

const Radio = styled.input`
  position: absolute;
  opacity: 0;
  top: 8px;
  left: 5px;
  &:checked ~ .check {
    border: 1px solid #4d79ff;
  };
  &:checked ~ .check::before{
  background: #4d79ff;
  }
`;

const Label = styled.label`
  ${''}
  display: block;
  position: relative;
  ${''}
  ${''}
  padding: 5px 1% 5px 40px;
  ${''}
  min-height: 30px;
  height: auto;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
`;

const CircleButton = styled.div`
  display: block;
  position: absolute;
  border: 1px solid #AAAAAA;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  top: 8px;
  left: 5px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
  &::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 12px;
    width: 12px;
    top: 2px;
    left: 2px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
`;

// End Radio Button

const DropDownButton = styled.button`
  ${''}
  text-overflow: clip !important;
  background-color: #FFF;
  height: 35px;
  max-height: 35px;
  min-width: 100px;
  width: auto;
  padding: 1px 15px;
  outline: none;
  margin-top: 5px;
  font-family: 'Roboto';
  border: none;
  font-size: 0.9em;
  color: rgba(0,0,0,0.85);
  cursor: pointer;
  :hover {
    background-color: rgba(0,0,0,0.1);
  }
`;

export {
  Form,
  FormControl,
  FormGroup,
  Row,
  Col,
  ControlLabel,
  Title,
  NiceRadioButton,
  Button,
  DropDownButton,
};

import axios from 'axios';
import _ from 'lodash';
import download from 'downloadjs';
import swal from 'sweetalert2';
import showNotification from '../helpers/showNotification';
import { getErrorMessage } from './appActions';

function getSelectionYears(DataCopy) {
  let selectedYearsForCombine = '';
  DataCopy.Tabs.forEach((tab) => {
    if (tab.isVisible && tab.Name !== 'Combined') {
      selectedYearsForCombine += `${tab.Name},`;
    }
  });
  return selectedYearsForCombine.substring(0, selectedYearsForCombine.length - 1);
}

export const downloadPresentation = (type, size, title = '') => async (dispatch, getState) => {
  const accountId = getState().AppReducer.currentAccountId;
  const { yearList, currentTab, Data } = getState().spreadsheet;
  const currentSurveyYear = yearList.length - 1 >= currentTab ? yearList[currentTab] : getSelectionYears(Data);
  const { charts } = getState().spreadsheetChartReducer;
  const cards = getState().spreadsheetCardReducer.spreadsheetCards;
  const base = getState().targetReducer.baseTarget.title;
  const target2 = getState().targetReducer.target2.title || 'Target2';
  const targetNames = [base, target2];
  const labels = getState().spreadsheetChartReducer.chartLabels;
  const data = {
    accountId,
    charts,
    cards,
    size,
    labels,
    targetNames,
    type,
    currentSurveyYear,
  };

  swal({
    title: 'Starting download',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
    onOpen: async () => {
      swal.showLoading();
      await axios.post('/api/export/powerpoint/spreadsheet', data, { responseType: 'blob' }).then((response) => {
        download(response.data, `${title.replace(/[ ]/gi, '_')}.pptx`, 'blob');
        swal.close();
      }).catch((err) => {
        swal.close();
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
    },
  });
};

export const downloadSavedOverview = (overview, type, size) => async (dispatch, getState) => {
  const { charts, cards } = overview.data;
  let { title } = overview;
  if (!title) {
    title = '';
  }
  if (charts && cards) {
    const { labels } = getState().targetReducer;

    const data = {
      charts,
      cards,
      size,
      labels,
    };

    swal({
      title: 'Starting download',
      text: 'Please wait a moment.',
      allowOutsideClick: false,
      onOpen: async () => {
        swal.showLoading();
        await axios.post('/api/export/powerpoint/spreadsheet', data, { responseType: 'blob' }).then((response) => {
          download(response.data, `${title.replace(/[ ]/gi, '_')}.pptx`, 'blob');
          swal.close();
        }).catch((err) => {
          swal.close();
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type });
        });
      },
    });
  } else {
    showNotification({ message: 'Error downloading presentation', type: 'error' });
  }
};

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateBanner,
  setBanner,
  setEditingTo,
  resetModal,
  copyBanner,
  setCurrentBannerTemplate,
} from '../../actions/bannerActions';
import { loadCharts } from '../../actions/overviewChartActions';
import { changeAllCardTarget2AndBase } from '../../actions/overviewCardActions';

class FooterBar extends Component {
  basicApply = () => {
    const {
      close,
      actions,
      data,
      type,
      currentTab,
      resetModal,
      currentTemplate,
      setCurrentBannerTemplate,
      basicBuilderTitle,
      changeAllCardTarget2AndBase,
    } = this.props;
    const { banner, charts, spreadsheet } = actions;
    const { basic } = data.builders;
    const { basicBuilder } = currentTemplate;
    if (currentTab === 1) {
      charts.setUsedBanner({ builder: basicBuilder, title: basicBuilderTitle, bannerType: 'basic' });
      banner.setBanner('basic', basicBuilder);
    } else {
      setCurrentBannerTemplate({ ...basic, title: basicBuilderTitle });
      charts.setUsedBanner({ builder: basic, title: basicBuilderTitle, bannerType: 'basic' });
      banner.setBanner('basic', basic);
    }
    if (type === 'spreadsheet') {
      spreadsheet.addBanner();
    } else if (type === 'spreadsheetEditBanner') {
      spreadsheet.editBanner();
    }
    close();
    if (type !== 'spreadsheet') {
      if (type === 'overview-add') {
        charts.add();
      } else if (type === 'overview-edit') {
        charts.update();
      } else {
        charts.load();
        changeAllCardTarget2AndBase();
      }
    }
    copyBanner('basic', 'editBasicBuilder');
    resetModal();
  };

  advancedApply = () => {
    const {
      close,
      actions,
      data,
      type,
      currentTemplate,
      currentTab,
      resetModal,
      advancedBuilderTitle,
      setCurrentBannerTemplate,
      changeAllCardTarget2AndBase,
    } = this.props;
    const { banner, charts, spreadsheet } = actions;
    const { advanced } = data.builders;
    const { advancedBuilder } = currentTemplate;
    if (currentTab === 1) {
      banner.setBanner('advanced', advancedBuilder.blocks);
      charts.setUsedBanner({ builder: advancedBuilder, title: currentTemplate.title, bannerType: 'advanced' });
    } else {
      charts.setUsedBanner({ builder: advanced, title: advancedBuilderTitle, bannerType: 'advanced' });
      setCurrentBannerTemplate({ data: advanced, title: advancedBuilderTitle });
      banner.setBanner('advanced', advanced.blocks);
    }
    if (type === 'spreadsheet') {
      spreadsheet.addBanner();
    } else if (type === 'spreadsheetEditBanner') {
      spreadsheet.editBanner();
    }
    close();
    if (type !== 'spreadsheet') {
      if (type === 'overview-add') {
        charts.add();
      } else if (type === 'overview-edit') {
        charts.update();
      } else {
        charts.load();
        changeAllCardTarget2AndBase();
      }
    }
    resetModal();
  };

  editClick = async (updatingBanner) => {
    const {
      currentTemplate, updateBanner, loadData, setBanner, setEditingTo, close, type,
    } = this.props;
    const { editing, basicBuilder, advancedBuilder } = currentTemplate;
    if (editing) {
      const banner = {};
      if (!_.isEmpty(basicBuilder)) {
        banner.data = basicBuilder;
        banner.type = 'basic';
      } else if (!_.isEmpty(advancedBuilder)) {
        banner.data = advancedBuilder.blocks;
        banner.type = 'advanced';
      }
      await updateBanner();
      await setBanner(banner.type, banner.data);
      if (updatingBanner && _.isString(updatingBanner)) {
        this[`${updatingBanner}Apply`]();
      }
      loadData();
      close();
    } else {
      setEditingTo();
    }
  }

  close = () => {
    const { close, resetModal } = this.props;
    close();
    resetModal();
  }

  getButtons = () => {
    const { data, currentTemplate, user } = this.props;
    const { basicBuilder, advancedBuilder } = currentTemplate;
    const { currentTab } = data;
    const { basic, advanced } = data.builders;
    let updatingBanner;
    let size;
    if (currentTab === 0) {
      size = _.size(basic);
    } else if (currentTab === 1) {
      if (!_.isEmpty(basicBuilder)) {
        updatingBanner = 'basic';
        size = _.size(basicBuilder);
      } else if (!_.isEmpty(advancedBuilder.blocks)) {
        updatingBanner = 'advanced';
        size = _.size(advancedBuilder.blocks);
      }
    } else if (currentTab === 2) {
      size = _.size(advanced.blocks);
    }
    let Buttons;
    if (currentTab === 0) {
      Buttons = (
        <React.Fragment>
          <CancelButton onClick={this.close}>Cancel</CancelButton>
          <ApplyButton onClick={this.basicApply}>Add ({size}) Questions</ApplyButton>
        </React.Fragment>
      );
    } else if (currentTab === 1) {
      if (currentTemplate.banner.user) {
        Buttons = (
          <React.Fragment>
            <CancelButton onClick={this.close}>Cancel</CancelButton>
            {
              currentTemplate.banner.user._id === user._id
              && (
                currentTemplate.editing
                  ? <UpdateButton onClick={() => this.editClick(updatingBanner)}>Update &amp; Apply</UpdateButton>
                  : <EditButton onClick={this.editClick}>Edit</EditButton>
              )
            }
            <ApplyButton onClick={this[`${updatingBanner}Apply`]}>Apply</ApplyButton>
          </React.Fragment>
        );
      } else {
        Buttons = (
          <React.Fragment>
            <CancelButton onClick={this.close}>Cancel</CancelButton>
            {
              currentTemplate.editing
                ? <UpdateButton onClick={this.editClick}>Update &amp; Apply</UpdateButton>
                : <EditButton onClick={this.editClick}>Edit</EditButton>
            }
            <ApplyButton onClick={this[`${updatingBanner}Apply`]}>Apply</ApplyButton>
          </React.Fragment>
        );
      }
    } else if (currentTab === 2) {
      Buttons = (
        <React.Fragment>
          <CancelButton onClick={this.close}>Cancel</CancelButton>
          <ApplyButton onClick={this.advancedApply}>Add Custom Block</ApplyButton>
        </React.Fragment>
      );
    }
    return Buttons;
  }

  render() {
    return (
      <Container>
        {this.getButtons()}
      </Container>
    );
  }
}

FooterBar.propTypes = {
  close: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  actions: propTypes.object.isRequired,
  data: propTypes.object.isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

const CancelButton = styled.button`
  flex: 1;
  height: 32px;
  background: #CFD8DC;
  color: #607D8B;
  border: none;
  border-radius: 2px;
  font-family: 'Roboto';
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 20px;
  font-weight: 500;
  cursor:pointer;
  margin: 0 10px;
`;

const ApplyButton = styled.button`
  flex: 1;
  height: 32px;
  background: #607D8B;
  color: white;
  border: none;
  border-radius: 3px; 
  font-family: 'Roboto';
  font-weight: 500;
  cursor: pointer;
  margin: 0 10px;
  &:disabled{
    color: #B0BEC5;
  }
`;

const EditButton = styled(ApplyButton)`
  flex: 1;
  height: 32px;
`;
const UpdateButton = styled(ApplyButton)`
  flex: 2;
  height: 32px;
`;

const mapStateToProps = store => ({
  currentTemplate: store.bannerReducer.currentTemplate,
  user: store.AppReducer.user,
  currentTab: store.bannerReducer.currentTab,
  basicBuilderTitle: store.bannerReducer.basicBuilderTitle,
  advancedBuilderTitle: store.bannerReducer.advancedBuilderTitle,
});
const mapDispatchToProps = dispatch => ({
  updateBanner: () => dispatch(updateBanner()),
  setBanner: (type, data) => dispatch(setBanner(type, data)),
  loadData: () => dispatch(loadCharts()),
  setEditingTo: () => dispatch(setEditingTo(true)),
  resetModal: () => dispatch(resetModal()),
  setCurrentBannerTemplate: (banner) => {
    dispatch(setCurrentBannerTemplate(banner));
  },
  copyBanner: (type, key) => dispatch(copyBanner(type, key)),
  changeAllCardTarget2AndBase: () => dispatch(changeAllCardTarget2AndBase()),
});
export default connect(mapStateToProps, mapDispatchToProps)(FooterBar);

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';

const iconStyle = {
  color: '#90A4AE',
  width: '12px',
  height: '16px',
  marginRight: '5px',
};

const AddQuestion = ({ openModal }) => (
  <Button type="button" onClick={openModal}>
    <FontAwesomeIcon style={iconStyle} icon={faPlus} /> Add Question
  </Button>
);

AddQuestion.propTypes = {
  openModal: propTypes.func.isRequired,
};

const Button = styled.button`
  height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 16px;
  color: #37474F;
  margin-right: 8px;
  cursor: pointer;
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.5;'}
  &:hover {
    background: #E2E7EA;
    border-radius: 2px;
  }
`;

export default AddQuestion;

import React, { Component } from 'react';
import styled from 'styled-components';
import angleDown from '../../../assets/down-arrow-angle.svg';

class SelectOperations extends Component {
  handleChange = (e) => {
    const operator = e.target.value;
    const { actions, blockId } = this.props;
    actions.changeItemsOperator({ blockId, operator });
  }

  render() {
    const { itemsOperator } = this.props;
    const { operator, negated } = itemsOperator;
    let value = '';
    value += operator;
    if (negated) value += ' NOT';
    return (
      <Select name="operations" value={value} onChange={this.handleChange}>
        <option value="OR">INCLUDE ANY</option>
        <option value="AND">INCLUDE ALL</option>
        <option value="OR NOT">EXCLUDE ANY</option>
        <option value="AND NOT">EXCLUDE ALL</option>
      </Select>
    );
  }
}

const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding: 7px 28px 7px 7px;
  margin-right: 5px;
  background-image: url(${angleDown});
  background-repeat: no-repeat;
  background-size: 10px 10px;
  background-position: 90%;
  border: 1px solid #CFD8DC;
  font-size: 13px;
  font-family: 'Roboto';
  color: #37474F;
  &:focus{
    outline: none;
  }
`;

export default SelectOperations;

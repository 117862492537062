import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Checkbox from '../../GlobalComponents/FormComponents/CheckBox';

class SaveTemplate extends Component {
  state ={
    checked: false,
    saved: false,
  }

  saveTemplate = (event) => {
    event.preventDefault();
    const { checked, saved } = this.state;
    if (!checked && !saved) {
      const { actions: { save }, target: { title } } = this.props;
      const { blocks } = this.props;
      this.setState({
        checked: true,
        saved: true,
      }, () => {
        save(title, blocks)
      });
    }
  };

  render() {
    const { checked, saved } = this.state;
    const { title } = this.props.target;
    const { blocks } = this.props;
    const disabled = !title || _.isEmpty(blocks[0].questions);
    return (
      <Save>
        <Checkbox
          id="saveTarget"
          name="saveTarget"
          onClick={this.saveTemplate}
          disabled={disabled || saved}
          checked={checked}
        />
        <label htmlFor="saveTarget">Save Target as template</label>
      </Save>
    );
  }
}

const Save = styled.div`
  flex: 1;
  margin-bottom: 8px;
  margin-left: 15px;
  white-space: nowrap;
  font-size: 13px;
  label {
    color: #37474F;
    margin-left: 8px;
  }
`;

export default SaveTemplate;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Chart from '../Charts';
import './Sortable.css';
import { moveChart } from '../../../actions/overviewChartActions';


const SortableItem = SortableElement(({ value }) => (<li><div>{value}</div></li>));

const SortableList = SortableContainer(({ items }) => (
  <List>
    {items.map((item, index) => (
      <SortableItem key={`item-${item.value}`} index={index} value={item.component} />
    ))}
  </List>
));

class SortableComponent extends Component {
  getAllCharts = () => {
    const { charts, labels } = this.props;
    return charts.map((chart, index) => ({
      value: index,
      component: <Chart labels={labels} chartData={chart} />,
    }));
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { charts, moveChart } = this.props;
    moveChart(arrayMove(charts, oldIndex, newIndex));
  }

  render() {
    return (
      <SortableList
        distance={50}
        axis="xy"
        items={this.getAllCharts()}
        onSortEnd={this.onSortEnd}
        helperClass="sortable"
        useDragHandle
      />
    );
  }
}

SortableComponent.propTypes = {
  charts: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  moveChart: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  charts: state.overviewChartReducer.charts,
  labels: state.targetReducer.labels,
});

const mapDispatchToProps = dispatch => ({
  moveChart: newArray => dispatch(moveChart(newArray)),
});

const List = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 25px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SortableComponent);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { SpreadSheetReset } from '../../../actions/spreadsheetActions';
import { Col, Row, ControlLabel } from '../../GlobalComponents/BasicControls';

class NoProjects extends Component {
  render() {
    const { resetSpreadsheet } = this.props;
    return (
      <Row>
        <Col size={12}>
          <Topic
            fontSize="0.95em"
          >
            You don't have any projects yet!
          </Topic>
          <ControlLabel
            style={{ fontSize: '0.9em' }}
          >
            Get started on a new
            {' '}
            <HighLight to="/overview">Overview</HighLight>
            {' '}
            or
            {' '}
            <HighLight to="/spreadsheet" onClick={resetSpreadsheet}>Spreadsheet</HighLight>
            {' '}
            project today.
          </ControlLabel>
        </Col>
      </Row>
    );
  }
}

NoProjects.propTypes = {
  resetSpreadsheet: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  resetSpreadsheet: () => dispatch(SpreadSheetReset()),
});

export default connect(null, mapDispatchToProps)(NoProjects);

const Topic = styled(ControlLabel)`
  ${''}
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4em')};
  color: rgba(65, 71, 76, 1);
`;

const HighLight = styled(Link)`
  color: rgb(93, 151, 200);
  font-weight: 400;
  text-decoration: none;
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

export default class ModalTrigger extends Component {
  render() {
    const { openModal } = this.props;
    return (
      <Container onClick={openModal}>
        Create a custom overview &nbsp;
        <FontAwesomeIcon icon={faAngleRight} />
      </Container>
    );
  }
}

const Container = styled.div`
  margin-top: 10px;
  font-family: 'Roboto';
  font-size: 14px;
  color: #19A2C5;
  cursor: pointer;
`;


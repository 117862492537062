import React, { Component } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import BarChart from './BarChart';
import ColumnChart from './ColumnChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import TableChart from './TableChart';
import Average from './Average';

class ChartContainer extends Component {
  parseData = (chartData, target, unit) => {
    if (unit === 'all') {
      const data = chartData[target];
      data.length = 3; // quick fix to pass array length validation
      return data;
    }
    const currentData = chartData[target][unit];
    const data = currentData.map((dataSet) => {
      const labels = dataSet.map(answer => answer.label);
      const values = dataSet.map(answer => answer.value);
      return { labels, values };
    });
    return data;

  };

  render() {
    const {
      data,
      labels,
      target,
      type,
      unit,
    } = this.props;
    const { average } = data[target];
    const parsedData = this.parseData(data, target, unit);
    const grouped = parsedData.length > 1;
    const packedData = {
      average,
      data: parsedData,
      grouped,
      labels,
      target,
      unit,
    };
    return (
      <>
        {type === 'bar' && <BarChart {...packedData} />}
        {type === 'column' && <ColumnChart {...packedData} />}
        {type === 'line' && <LineChart {...packedData} />}
        {type === 'pie' && <PieChart {...packedData} />}
        {type === 'table' && <TableChart {...packedData} />}
        {type !== 'table' && <Average average={average} />}
      </>
    );
  }
}

ChartContainer.propTypes = {
  type: propTypes.string.isRequired,
  unit: propTypes.string.isRequired,
  target: propTypes.number.isRequired,
  data: propTypes.array.isRequired,
};

export default ChartContainer;

import React, { Component } from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { DragHandle } from './DragHandle';

class Preloader extends Component {
  render() {
    return (
      <Paper style={{ margin: '10px' }} elevation={0}>
        <Block>
          <Header>
            <DragHandle />
            <Title>
              <div className="loader" />
              <div className="loader" />
            </Title>
          </Header>
          <Chart />
        </Block>
      </Paper>
    );
  }
}
const Block = styled.div`
    width: 410px;
    height: 400px;
    background: #ffffff;
    border: 1px solid #CFD8DC;
    border-radius: 4px;
`;

const Header = styled.div`
  border-bottom: 2px solid #dee1e3;
  width: 100%;
  height:50px;
  padding: 8px;
  position:relative;
  z-index: 2;
  box-sizing: border-box;
`;

const Title = styled.div`
    font-family: 'Roboto';
    color: #303e49;
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;
    font-size:10pt;
    min-width: 305px;
`;

const Chart = styled.div`
    height: 292px;
    border-bottom: 2px solid #dee1e3;
`;

export default Preloader;

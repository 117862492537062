import { isEmpty, cloneDeep, size } from 'lodash';
import {
  ADD_TARGET_BLOCK,
  REMOVE_TARGET_BLOCK,
  ADD_TARGET_QUESTION,
  REMOVE_TARGET_QUESTION,
  ADD_TARGET_DATAPOINT,
  REMOVE_TARGET_DATAPOINT,
  CHANGE_TARGET_ITEMS_OPERATOR,
  TARGET_BUILDER_RESET,
  LOAD_TARGET_TEMPLATES,
  SAVE_TARGET_TEMPLATE,
  TOGGLE_TARGET_BUILDER_VIEW,
  CHANGE_TARGET_TEMPLATES_MENU,
  SET_CURRENT_TARGET_TEMPLATE,
  UPDATE_TARGET_TEMPLATE_TITLE,
  SET_TARGET_TITLE,
  SET_TARGET_QUERY,
  RESTORE_TARGET_TEMPLATE,
  RESET_MODAL,
  GET_TARGET_SIZE,
  COPY_TARGET,
  RESET_TARGET,
  RESTORE_SAVED_TARGET,
  SET_TARGET_ID,
  SET_BASE_TARGET,
  CLEAR_TARGETS,
} from '../actionTypes/targetTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const defaultState = {
  view: 'builder',
  baseTarget: {
    title: 'All Adults',
    query: 'C1-1',
    builder: {
      0: {
        id: 0,
        operator: null,
        itemsOperator: { operator: 'OR', negated: false },
        questions: {
          C1: {
            id: 'C1',
            text: 'Total Market',
            answers: { 'C1-1': { id: 'C1-1', index: 1, text: 'Adults 18+' } },
            path: 'All Adults / Total Market',
          },
        },
      },
    },
  },
  target2: {},
  currentTarget: { title: '', query: '', size: 0 },
  templates: {
    menu: 'list',
    currentTemplate: {},
    list: [],
  },
  builder: {
    counter: 0,
    blocks: {
      0: {
        id: 0,
        operator: null,
        itemsOperator: { operator: 'OR', negated: false },
        questions: {},
      },
    },
  },
  labels: {
    0: {
      id: 0,
      name: 'Base Target',
      labels: ['Base'],
      shortLabels: ['Base'],
      singleTarget: true,
      hasIndex: false,
    },
    1: {
      id: 1,
      name: 'Target 2',
      labels: ['Target 2'],
      shortLabels: ['Tar 2'],
      singleTarget: true,
      hasIndex: true,
    },
    2: {
      id: 2,
      name: 'Base & Target 2',
      labels: ['Base', 'Target 2'],
      shortLabels: ['Base', 'Tar 2'],
      singleTarget: false,
      hasIndex: false,
    },
  },
};

const targetReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_TARGET_BLOCK:
      return addBlock(state, action.payload);
    case REMOVE_TARGET_BLOCK:
      return removeBlock(state, action.payload);
    case ADD_TARGET_QUESTION:
      return addQuestion(state, action.payload);
    case REMOVE_TARGET_QUESTION:
      return removeQuestion(state, action.payload);
    case ADD_TARGET_DATAPOINT:
      return addDataPoint(state, action.payload);
    case REMOVE_TARGET_DATAPOINT:
      return removeDataPoint(state, action.payload);
    case CHANGE_TARGET_ITEMS_OPERATOR:
      return changeItemsOperator(state, action.payload);
    case RESTORE_TARGET_TEMPLATE:
      return restoreTargetTemplate(state, action.payload);
    case TARGET_BUILDER_RESET:
      return builderReset(state);
    case LOAD_TARGET_TEMPLATES:
      return loadTargetTemplates(state, action.payload);
    case SAVE_TARGET_TEMPLATE:
      return saveTargetTemplate(state, action.payload);
    case TOGGLE_TARGET_BUILDER_VIEW:
      return { ...state, view: action.payload };
    case CHANGE_TARGET_TEMPLATES_MENU:
      return { ...state, templates: { ...state.templates, menu: action.payload } };
    case SET_CURRENT_TARGET_TEMPLATE:
      return { ...state, templates: { ...state.templates, currentTemplate: action.payload } };
    case UPDATE_TARGET_TEMPLATE_TITLE:
      return updateTemplateTitle(state, action.payload);
    case SET_TARGET_TITLE:
      return { ...state, currentTarget: { ...state.currentTarget, title: action.payload } };
    case SET_TARGET_QUERY:
      return { ...state, currentTarget: { ...state.currentTarget, query: action.payload } };
    case SET_TARGET_ID:
      return { ...state, currentTarget: { ...state.currentTarget, id: action.payload } };
    case GET_TARGET_SIZE:
      return { ...state, [action.payload.type]: { ...state[action.payload.type], size: action.payload.size } };
    case COPY_TARGET:
      return { ...state, [action.payload.type]: action.payload.target };
    case RESET_TARGET:
      return { ...state, [action.payload.type]: action.payload.data };
    case SET_BASE_TARGET:
      return { ...state, baseTarget: action.payload };
    case RESTORE_SAVED_TARGET:
      return {
        ...state,
        builder: action.payload.builder,
        currentTarget: action.payload.currentTarget,
      };
    case RESET_MODAL:
      return resetModal(state);
    case CLEAR_TARGETS:
      return defaultState;
    case LOG_OUT:
      return defaultState;
    default:
      return state;
  }
};

function builderReset(state) {
  const defaultBuilderState = {
    counter: 0,
    blocks: {
      0: {
        id: 0,
        operator: null,
        itemsOperator: { operator: 'OR', negated: false },
        questions: {},
      },
    },
  };
  return { ...state, builder: defaultBuilderState };
}

function addBlock(state, operator) {
  const newState = cloneDeep(state);
  newState.builder.counter += 1;
  const { counter } = newState.builder;
  newState.builder.blocks[counter] = {
    id: counter,
    operator,
    itemsOperator: { operator: 'OR', negated: false },
    questions: {},
  };
  return newState;
}

function removeBlock(state, payload) {
  const { blockId } = payload;
  const newState = cloneDeep(state);
  delete newState.builder.blocks[blockId];
  return newState;
}

function changeItemsOperator(state, payload) {
  const newState = cloneDeep(state);
  const { blockId, operator } = payload;
  newState.builder.blocks[blockId].itemsOperator = operator;
  return newState;
}

function addQuestion(state, payload) {
  const newState = cloneDeep(state);
  const { blockId, question } = payload;
  newState.builder.blocks[blockId].questions[question.id] = question;
  return newState;
}

function removeQuestion(state, payload) {
  const { blockId, question } = payload;
  const newState = cloneDeep(state);
  if (newState.builder.blocks[blockId].questions.hasOwnProperty(question.id)) {
    delete newState.builder.blocks[blockId].questions[question.id];
  }
  return newState;
}

function addDataPoint(state, payload) {
  const {
    block, question, answer, path,
  } = payload;
  const newState = cloneDeep(state);
  if (state.builder.blocks[block].questions.hasOwnProperty(question.id)) {
    newState.builder.blocks[block].questions[question.id].answers[answer.id] = answer;
  } else {
    newState.builder.blocks[block].questions[question.id] = {
      id: question.id,
      text: question.text,
      answers: { [answer.id]: answer },
      path,
    };
  }
  return newState;
}

function removeDataPoint(state, payload) {
  const { block, question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.builder.blocks[block].questions.hasOwnProperty(question.id)) {
    delete newState.builder.blocks[block].questions[question.id].answers[answer.id];
    if (isEmpty(newState.builder.blocks[block].questions[question.id].answers)) {
      delete newState.builder.blocks[block].questions[question.id];
    }
  }
  return newState;
}

function restoreTargetTemplate(state, payload) {
  const counter = size(payload);
  const newState = cloneDeep(state);
  newState.builder.counter = counter;
  newState.builder.blocks = payload;
  return newState;
}

function resetModal(state) {
  return {
    ...state,
    currentTarget: { title: '', query: '', size: 0 },
    templates: defaultState.templates,
    builder: defaultState.builder,

  };
}


function loadTargetTemplates(state, payload) {
  const newState = cloneDeep(state);
  newState.templates.list = payload;
  return newState;
}

function saveTargetTemplate(state, payload) {
  const newState = cloneDeep(state);
  newState.templates.list = [...state.templates, payload];
  return newState;
}

function updateTemplateTitle(state, payload) {
  const newState = cloneDeep(state);
  newState.templates.currentTemplate.title = payload;
  return newState;
}

export default targetReducer;

import _ from 'lodash';
import {
  GET_ACCOUNTS,
  DEACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  CREATE_ACCOUNT,
  SET_USERS,
  SET_ACCOUNT,
  CANCEL_INVITATION_FROM_ACCOUNTS,
  UPDATE_ACCOUNT,
  ADD_USER_TO_ACCOUNT_USERS,
  EDIT_DATABASES,
  DELETE_DATABASE,
  EDIT_ACCOUNT_DETAILS,
} from '../actionTypes/accountTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const initialState = {
  accounts: {},
  editingAccount: {},
  account: {},
  users: {},
};

const AccountReducer = (state = initialState, action) => {
  if (action.type === GET_ACCOUNTS) {
    return {
      ...state,
      accounts: action.payload,
    };
  } if (action.type === CREATE_ACCOUNT) {
    const team = action.payload;
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [team._id]: team,
      },
    };
  } if (action.type === DEACTIVATE_ACCOUNT) {
    const team = action.payload;
    // const accounts = _.filter(state.accounts, ac => ac._id !== teamId);
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [team._id]: team,
      },
    };
  }
  if (action.type === DELETE_ACCOUNT) {
    const team = action.payload;
    const filterAccount = _.filter(state.accounts, ac => ac._id !== team._id);
    const accounts = _.keyBy(filterAccount, '_id');
    return {
      ...state,
      accounts,
    };
  }
  if (action.type === SET_ACCOUNT) {
    return {
      ...state,
      account: action.payload,
    };
  }
  if (action.type === EDIT_DATABASES) {
    const response = action.payload;
    const { team } = response;
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [team._id]: team,
      },
      account: action.payload,
    };
  }
  if (action.type === EDIT_ACCOUNT_DETAILS) {
    const response = action.payload;
    const { team } = response;
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [team._id]: team,
      },
      account: {
        ...state.account,
        team,
      },
    };
  }
  if (action.type === EDIT_DATABASES) {
    return {
      ...state,
      account: action.payload,
    };
  }
  if (action.type === DELETE_DATABASE) {
    return {
      ...state,
      account: action.payload,
    };
  }
  if (action.type === SET_USERS) {
    return { ...state, users: action.payload };
  }
  if (action.type === CANCEL_INVITATION_FROM_ACCOUNTS) {
    const newMembers = _.cloneDeep(state.users);
    action.payload.forEach((id) => {
      newMembers[id].status = 'CANCELLED';
    });
    return {
      ...state,
      users: {
        ...newMembers,
      },
    };
  } if (action.type === UPDATE_ACCOUNT) {
    return {
      ...state,
      accounts: {
        ...state.accounts,
        [action.payload._id]: action.payload,
      },
    };
  }
  if (action.type === ADD_USER_TO_ACCOUNT_USERS) {
    return {
      ...state,
      users: {
        ...state.users,
        [action.payload._id]: action.payload,
      },
    };
  }
  if (action.type === LOG_OUT) {
    return initialState;
  }

  return state;
};

export default AccountReducer;

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import surveyIcon from '../../../assets/ico-survey.svg';
import { getSurveys } from '../../../actions/documentActions';
import BuildBannerModal from '../../BuildBannerModal';
import AddQuestion from './AddQuestion';

class Toolbar extends Component {
  state = { url: '' }

  componentDidMount() {
    const { getSurveys } = this.props;
    getSurveys();
  }

  componentWillReceiveProps({ survey }) {
    const url = window.URL.createObjectURL(survey.file);
    this.setState({ url });
  }

  openSurvey = () => {
    const { survey } = this.props;
    const file = new Blob([survey.file], { type: 'application/pdf' });
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL, survey.fileName);
  }

  render() {
    const { survey } = this.props;
    return (
      <Container>
        <BuildBannerModal trigger={AddQuestion} type="overview-add" />
        <Button disabled={_.isEmpty(survey)} type="button" onClick={this.openSurvey}>
          <img src={surveyIcon} alt="survey" /> View Current Survey
        </Button>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 24px;
  padding: 8px;
`;

const Button = styled.button`
  height: 24px;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Roboto';
  font-size: 12px;
  line-height: 16px;
  color: #37474F;
  cursor: pointer;
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.5;'}
  &:hover {
    background: #E2E7EA;
    border-radius: 2px;
  }
  img {
    width: 12px;
    height: 16px;
    margin-right: 5px;
  }
`;

const mapStateToProps = store => ({
  survey: store.DocumentReducer.currentSurvey,
});
const mapDispatchToProps = dispatch => ({
  getSurveys: () => dispatch(getSurveys()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);

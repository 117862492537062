import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';

class Header extends Component {
  render() {
    const { addAction } = this.props;
    return (
      <Wrapper>
        <Title />
        <AddActionButton
          onClick={addAction}
        >
          <FontAwesomeIcon icon={faPlus} />
          {' '}
          Add New Account
        </AddActionButton>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.5% 0px 0.5%;
    width: 99%;
`;

const Title = styled.div`
    font-family: 'Roboto';
    font-size: 1.2em;
    font-weight: 500;
    color: rgb(45, 76, 103);
`;

const AddActionButton = styled.button`
  font-family: 'Roboto';
  background: rgba(45, 76, 103, 1);
  color: rgba(255,255,255,0.9);
  padding: 10px 15px 10px 15px;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  border-radius: 3%;
  &:hover{
    opacity:0.9;
  }

`;

Header.propTypes = {
  addAction: propTypes.func,
};

export default Header;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavigationBar from '../GlobalComponents/NavigationBar';
import Overview from './Overview';
import SelectionArea from './SelectionArea';
import ChartPage from './ChartPage';
import { getCurrentSurvey } from '../../actions/marketActions';
import { loadCategories } from '../../actions/categories';
import { setDefaultBaseTarget } from '../../actions/targetActions';

class OverviewPage extends Component {

  componentDidMount() {
    document.title = 'Stowell Data | Overview';
    const { loadCategories, getCurrentSurvey, setDefaultBaseTarget } = this.props;

    getCurrentSurvey().then(() => {
      loadCategories().then(() => {
        setDefaultBaseTarget();
      });
    });
  }

  render() {
    return (
      <div>
        <NavigationBar />
        <Overview />
        <SelectionArea />
        <ChartPage />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  error: store.OverviewReducer.error,
});

const mapDispatchToProps = dispatch => ({
  loadCategories: () => dispatch(loadCategories()),
  getCurrentSurvey: () => dispatch(getCurrentSurvey()),
  setDefaultBaseTarget: () => dispatch(setDefaultBaseTarget()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Select from 'react-select';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {
  Col,
  Row,
  ControlLabel,
} from '../GlobalComponents/BasicControls';
import {
  getProjects,
  duplicateProject,
  deleteProject,
  updateProject,
} from '../../actions/projectActions';
import {
  newSpreedSheetIcon,
  newOverviewIcon,
  targetIcon,
  duplicateIcon,
  teamShareIcon,
  unShareIcon,
  faTrash,
  newProjectIcon,
  questionIcon,
} from '.';
import DataTable from '../GlobalComponents/DataTable/DataTable';
import { getUser } from '../../actions/profileActions';
import { DropDownMenu } from '../GlobalComponents/DropDown/DropDownMenu';
import BuildTargetModal from '../BuildTargetModal';
import BuildBannerModal from '../BuildBannerModal';
import { getErrorMessage } from '../../actions/appActions';
import { updateCharts } from '../../actions/overviewChartActions';
import { changeAllCardTarget2AndBase as updateCardTarget2AndBase } from '../../actions/overviewCardActions';
import { getCurrentSurvey } from '../../actions/marketActions';
import showNotification from '../../helpers/showNotification';

const newTemplateStyle = {
  control: (base, { isFocused }) => {
    const style = {
      ...base,
      backgroundColor: '#607D8B',
      color: '#FFFFFF',
      border: 'none',
      'box-shadow': 'none',
      'border-radius': '2px',
      'font-size': '14px',
      height: '36px',
      minWidth: '185px',
      width: '185px',
    };
    if (isFocused) {
      style.backgroundColor = '#455A64';
    }
    return style;
  },
  dropdownIndicator: base => ({
    ...base,
    color: 'white !important',
    border: '0px',
    display: 'absolute',
  }),
  placeholder: base => ({
    ...base,
    color: 'white',
  }),
  singleValue: base => ({
    ...base,
    color: 'white',
  }),
  menu: base => ({
    ...base,
    color: '#37474F',
  }),
  option: (base, { isFocused, isSelected }) => {
    const style = {
      ...base,
      color: '#37474F',
      fontSize: '14px',
    };
    if (isFocused) {
      style.backgroundColor = '#E8F5F9';
    } else {
      style.backgroundColor = 'white';
    }
    if (isSelected) {
      style.backgroundColor = '#E8F5F9';
    }
    return style;
  },
};

const customStyles = {
  control: (base, { isFocused }) => {
    let style = {
      ...base,
      background: 'white',
      borderColor: 'transparent',
      border: '0 !important',
      outline: '1px solid rgba(0,0,0,0.2)',
      'border-radius': '0.5px',
      'font-size': '14px',
    };
    if (isFocused) {
      style = {
        ...style,
        boxShadow: '0 0 0 1px #19A2C5',
        outline: '1px solid #19A2C5',
      };
    }
    return style;
  },
  menu: base => ({
    ...base,
    color: '#37474F',
  }),
  option: (base, { isFocused, isSelected, isDisabled }) => {
    const style = {
      ...base,
      color: '#37474F',
      fontSize: '14px',
      backgroundColor: isDisabled ? null : isSelected ? '#BCD1D8' : isFocused ? '#E8F5F9' : null,
      ':active': {
        backgroundColor: !isDisabled && (!isSelected && '#B3D4FF'),
      },
    };
    return style;
  },
};


class OwnTemplates extends Component {
  state = {
    templates: [],
    filteredTemplates: [],
    sortOptionSelected: {},
    templateOptionSelected: [],
    rowCopiedOrRemoved: {},
    copied: false,
    sort: {
      sorted: false,
      sortBy: '',
      order: '',
    },
    keepSortingOnChange: true,
    gettingData: {
      gettingData: true,
      errorOnGetting: false,
    },
  };

  targetModalTriggerRef = React.createRef();

  bannerModalTriggerRef = React.createRef();

  componentWillMount() {
    const { getUser, getProjects } = this.props;
    getUser();
    getProjects()
      .then(() => {
        this.assingTemplatesToState(this.props);
        const { templates } = this.props;
        this.setDefaultOptionSelected(templates);
        this.setState({
          gettingData: {
            gettingData: false,
            errorOnGetting: false,
          },
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
        this.setState({
          gettingData: {
            errorOnGetting: true,
          },
        });
      });
  }

  componentDidMount() {
    const elements = document.getElementsByClassName('css-d8oujb');
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
    const { fetchCurrentSurvey } = this.props;
    fetchCurrentSurvey();
  }

  componentWillReceiveProps(props) {
    const { sort } = this.state;
    const { sortBy, order } = sort;
    const { templates } = props;
    this.sortByType(sortBy, order, templates);
  }

  setDefaultOptionSelected = (data) => {
    if (data && data.length > 0) {
      const option = this.getSelectOptions().sortOptions[0];
      this.handleSortChange(option);
    }
  }

  assingTemplatesToState = (props) => {
    const { templates } = props;
    this.setState({
      filteredTemplates: templates,
    });
  }

  setTemplate = template => () => {
    const { useTarget, useBanner } = this.props.actions.overview;
    const { updateOverviewCharts, changeAllCardTarget2AndBase } = this.props;
    if (template.subType === 'TARGET') {
      useTarget(template);
      updateOverviewCharts();
      changeAllCardTarget2AndBase();
    } else if (template.subType === 'QUESTION') {
      useBanner(template);
      updateOverviewCharts();
      changeAllCardTarget2AndBase();
    }
  }
  
  setSpreadSheetTemplate = template => () => {
    const { setSpreadSheetTarget, setSpreadSheetBanner } = this.props.actions.spreadsheet;
    if (template.subType === 'TARGET') {
      setSpreadSheetTarget(template);
    } else if (template.subType === 'QUESTION') {
      setSpreadSheetBanner(template);
    }
  }

  setDropdowMenu = (cell, row, index) => {
    const isShared = !!row.shared;

    const menuOptions = [
      {
        text: 'Use in New Project',
        imageIcon: true,
        icon: newProjectIcon,
        items: [
          {
            text: 'Spreadsheet',
            imageIcon: true,
            icon: newSpreedSheetIcon,
            action: this.setSpreadSheetTemplate(row),
          },
          {
            text: 'Overview',
            imageIcon: true,
            icon: newOverviewIcon,
            action: this.setTemplate(row),
          },
        ],
      },
      {
        text: 'Duplicate',
        imageIcon: true,
        icon: duplicateIcon,
        action: () => this.duplicateRow(row),
      },
      {
        text: isShared ? 'Stop Sharing' : 'Share',
        imageIcon: true,
        icon: isShared ? unShareIcon : teamShareIcon,
        action: () => this.handleSharing(row, isShared),
      },
      {
        text: 'Remove',
        icon: faTrash,
        action: () => this.deleteRow(row),
      },
    ];
    return (
      <DropDownMenu
        menuOptions={menuOptions}
      />
    );
  }

  projectFormatterTitle = (cell, row) => {
    let icon;
    switch (row.subType) {
      case 'TARGET': {
        icon = targetIcon;
        break;
      }
      case 'QUESTION': {
        icon = questionIcon;
        break;
      }

      default:
        icon = questionIcon;
        break;
    }
    return (
      <RowTitle>
        <img src={icon} alt="" width="20" height="20" style={{ margin: '5px 8px 5px 0px' }} />
        <LinkText>{row.title}</LinkText>
        {
          row.shared
          && (
          <SharedIcon>
            <FontAwesomeIcon icon={faUserFriends} />
          </SharedIcon>
          )
        }
      </RowTitle>
    );
  }

  setFormatDate = (cell, row) => (row.createdAt ? moment(row.createdAt).format('MM/DD/YYYY') : null)

  getTableColumns = () => [
    {
      dataField: 'title',
      columnStyle: {
        width: '60%',
      },
      text: 'Name',
      sort: true,
      field: 'title',
      formatter: this.projectFormatterTitle,
      style: {
        border: 'none !important',
      },
    },
    {
      text: 'Modified',
      dataField: 'createdAt',
      field: 'createdAt',
      formatter: this.setFormatDate,
      sort: true,
      columnStyle: {
        width: '30%',
      },
    },
    {
      formatter: this.setDropdowMenu,
      columnStyle: {
        width: '10%',
      },
    },
  ]

  sortByType = (sortBy, order, newTemplatesFromProps) => {
    const { filteredTemplates } = this.state;
    const newTemplates = _.orderBy(newTemplatesFromProps || filteredTemplates, [sortBy, 'createdAt'], order);
    this.setState({
      keepSortingOnChange: false,
      sort: {
        sorted: true,
        sortBy,
        order,
      },
      filteredTemplates: newTemplates,
    });
  }

  getSelectOptions = () => {
    const sortOptions = [
      {
        value: 'None',
        label: 'None',
        sortBy: 'createdAt',
        order: 'desc',
        action: this.sortByType,
        sort: 'ALL',
        style: {
          color: 'red',
        },
      },
      {
        value: 'Target Templates',
        label: 'Target Templates',
        sortBy: 'subType',
        order: 'desc',
        action: this.sortByType,
        sort: 'TARGET',
      },
      {
        value: 'Questions Templates',
        label: 'Questions Templates',
        sortBy: 'subType',
        order: 'asc',
        action: this.sortByType,
        sort: 'QUESTION',
      },
    ];

    const newTemplateOptions = [
      {
        value: 'target',
        label: 'Target Templates',
      },
      {
        value: 'question',
        label: 'Questions Templates',
      },
    ];

    return {
      sortOptions,
      newTemplateOptions,
    };
  }

  handleSortChange = (option) => {
    const { templates } = this.props;
    this.setState({
      sortOptionSelected: option,
      filteredTemplates: [..._.filter(templates, tmp => tmp.subType === option.sort), ..._.filter(templates, tmp => (tmp.subType !== option.sort))],
    }, () => {
      option.action(option.sortBy, option.order);
    });
  }

  handleTemplateChange = (option) => {
    if (option.value === 'target') {
      this.targetModalTriggerRef.current.click();
    } else if (option.value === 'question') {
      this.bannerModalTriggerRef.current.click();
    }
  }

  onUndo = () => {
    const { copied } = this.state;
    if (copied) {
      this.undoDuplication();
    } else {
      this.undoDeleting();
    }
  }

  duplicateRow = (row) => {
    const information = {
      ...row,
      title: `${row.title} Copy`,
    };
    const { duplicateProject } = this.props;
    duplicateProject(information)
      .then((response) => {
        showNotification({ message: `Created "${response.title}"`, type: 'info' })
        this.setState({
          rowCopiedOrRemoved: response,
          copied: true,
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  undoDuplication = () => {
    const { rowCopiedOrRemoved } = this.state;
    const {
      _id,
    } = rowCopiedOrRemoved;
    const data = {
      id: _id,
    };
    const { deleteProject } = this.props;
    deleteProject(data)
      .then(() => {
        showNotification({ message: 'Action undone', type: 'info' })
        this.setState({
          rowCopiedOrRemoved: {},
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  handleSharing = (row, share) => {
    const data = {
      id: row._id,
      shared: !row.shared,
    };
    this.shareProject(data);
  }

  shareProject = (data) => {
    const { shareProject } = this.props;
    shareProject(data)
      .then((response) => {
        const isShared = !!response.shared;
        showNotification({message: `Template "${response.title}" has been ${isShared ? 'shared' : 'unshared'} successfully!`, type: 'success'})

      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  deleteRow = (row) => {
    const {
      title,
      _id,
    } = row;
    const data = {
      id: _id,
    };
    this.setState({
      rowCopiedOrRemoved: row,
    }, () => {
      const { deleteProject } = this.props;
      deleteProject(data)
        .then(() => {
          showNotification({ message: `Template "${title}" has been removed successfully!`, type: 'success' })
            this.setState({
              copied: false,
            });
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type })
        });
    });
  }

  undoDeleting = () => {
    const { rowCopiedOrRemoved } = this.state;
    const { duplicateProject } = this.props;
    duplicateProject(rowCopiedOrRemoved)
      .then(() => {
        showNotification({ message: 'Action undone', type: 'info'})
        this.setState({
          rowCopiedOrRemoved: {},
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type })
      });
  }

  targetModalTrigger = ({ openModal }) => (<button ref={this.targetModalTriggerRef} onClick={openModal} hidden />)

  bannerModalTrigger = ({ openModal }) => (<button ref={this.bannerModalTriggerRef} onClick={openModal} hidden />)

  render() {
    const {
      sortOptionSelected,
      templateOptionSelected,
      keepSortingOnChange,
      gettingData,
      filteredTemplates,
    } = this.state;
    const { templates } = this.props;
    const templatesLength = templates.length;
    return (
      <Row>
        <BuildTargetModal trigger={this.targetModalTrigger} type="projects" />
        <BuildBannerModal trigger={this.bannerModalTrigger} type="projects" />
        <Col size={12}>
          <Row>
            <Col style={{ padding: '0' }} size={12}>
              <Wrapper>
                <Topic fontSize="20px">
                    My Templates
                </Topic>
                <div style={{ width: '100%' }}>
                  <Wrapper>
                    <div style={{
                      width: 'auto', fontSize: '13px', color: '#37474F', marginRight: '10px',
                    }}
                    >
                      {
                        templatesLength !== 0
                        && 'Sort:'
                      }
                    </div>
                    <div style={{ width: '75%' }}>
                      {
                        templatesLength !== 0
                        && (
                        <Select
                          value={sortOptionSelected}
                          onChange={e => this.handleSortChange(e)}
                          options={this.getSelectOptions().sortOptions}
                          styles={customStyles}
                          placeholder=""
                          isSearchable={false}
                        />
                        )}
                    </div>
                  </Wrapper>
                </div>
                <div style={{ width: '410px' }}>
                  <Select
                    value={templateOptionSelected}
                    onChange={e => this.handleTemplateChange(e)}
                    options={this.getSelectOptions().newTemplateOptions}
                    styles={newTemplateStyle}
                    isSearchable={false}
                    placeholder="Create New Template"
                    classNamePrefix="my-projects-dropdown"
                  />
                </div>
              </Wrapper>
            </Col>
          </Row>
          <Row>
            <Col style={{ padding: '0' }} size={12}>
              <Container>
                {
                  templatesLength !== 0
                    ? (
                      <DataTable
                        keepSortingOnChange={keepSortingOnChange}
                        data={filteredTemplates}
                        columns={this.getTableColumns()}
                      />
                    )
                    : gettingData.gettingData
                      ? (
                        <Row>
                          <Col style={{ padding: '0' }} size={12}>
                            <ControlLabel
                              style={{ fontSize: '0.9em' }}
                            >
                            Retrieving data...
                            </ControlLabel>
                          </Col>
                        </Row>
                      )
                      : gettingData.errorOnGetting
                        ? (
                          <Row>
                            <Col style={{ padding: '0' }} size={12}>
                              <ControlLabel
                                style={{ fontSize: '0.9em', color: 'red' }}
                              >
                              Error to connect to server
                              </ControlLabel>
                            </Col>
                          </Row>
                        ) : (
                          <ControlLabel>
                          You don't have any saved templates yet.
                          </ControlLabel>
                        )}
              </Container>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const Topic = styled(ControlLabel)`
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4em')};
  color: #37474F;
`;

const Container = styled.div`
  padding: 0px 0 0 0;
`;

const RowTitle = styled.div`
  display: inline-flex;
  align-items: center;
`;
const LinkText = styled.p`
  color: #19A2C5;
  margin: 0 10px 0 0;
  padding: 0;
  font-size: 13px;
  &:hover{
    color: #117189;
  }
`;
const Wrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const mapStateToProps = state => ({
  templates: _.filter((state.ProjectReducer.projects), p => p.type === 'TEMPLATE'),
  currentUser: state.ProfileReducer.currentUser,
});

const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(getProjects()),
  duplicateProject: data => dispatch(duplicateProject(data)),
  deleteProject: data => dispatch(deleteProject(data)),
  getUser: () => dispatch(getUser()),
  shareProject: data => dispatch(updateProject(data)),
  updateOverviewCharts: () => dispatch(updateCharts()),
  changeAllCardTarget2AndBase: () => dispatch(updateCardTarget2AndBase()),
  fetchCurrentSurvey: () => dispatch(getCurrentSurvey()),
});
const SharedIcon = styled.p`
  color: gray;
  display: inline-block;
  padding: 0px;
`;
export default connect(mapStateToProps, mapDispatchToProps)(OwnTemplates);

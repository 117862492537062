import { isEmpty } from 'lodash';
import { GET_MARKET_INFO, GET_CURRENT_SURVEY } from '../actionTypes/marketTypes';

const defaultState = {
  market: {},
  currentSurvey: { sample: 0, population: 0, year: 0 },
};

const marketReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_MARKET_INFO:
      return { ...state, market: action.payload };
    case GET_CURRENT_SURVEY:
      return getCurrentSurvey(state, action.payload);
    default:
      return state;
  }
};

function getCurrentSurvey(state, surveyData) {
  if (isEmpty(surveyData)) {
    return defaultState;
  }
  return { ...state, currentSurvey: surveyData };
}

export default marketReducer;

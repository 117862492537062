export const LOAD_OVERVIEW_CHARTS = 'LOAD_OVERVIEW_CHARTS';
export const ADD_OVERVIEW_CHARTS = 'ADD_OVERVIEW_CHARTS';
export const DELETE_OVERVIEW_CHARTS = 'DELETE_OVERVIEW_CHARTS';
export const DELETE_OVERVIEW_CHART = 'DELETE_OVERVIEW_CHART';
export const UPDATE_OVERVIEW_CHARTS = 'UPDATE_OVERVIEW_CHARTS';
export const UPDATE_OVERVIEW_CHART = 'UPDATE_OVERVIEW_CHART';
export const SET_EDITING_OVERVIEW_CHART = 'SET_EDITING_OVERVIEW_CHART';
export const RESTORE_OVERVIEW_CHARTS = 'RESTORE_OVERVIEW_CHARTS';
export const MOVE_OVERVIEW_CHARTS = 'MOVE_OVERVIEW_CHARTS';
export const CLEAR_OVERVIEW_CHARTS = 'CLEAR_OVERVIEW_CHARTS';
export const SET_USED_OVERVIEW_BANNER = 'SET_USED_OVERVIEW_BANNER';

//Target codes to display charts data
export const BOTH = 0;
export const BASE = 1;
export const TARGET2 = 2;

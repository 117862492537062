import { cloneDeep, isEmpty, size } from 'lodash';
import {
  ADD_BASIC_BANNER_QUESTION,
  REMOVE_BASIC_BANNER_QUESTION,
  ADD_BASIC_BANNER_DATAPOINT,
  REMOVE_BASIC_BANNER_DATAPOINT,
  ADD_BANNER_BLOCK,
  REMOVE_BANNER_BLOCK,
  ADD_BANNER_QUESTION,
  REMOVE_BANNER_QUESTION,
  ADD_BANNER_DATAPOINT,
  REMOVE_BANNER_DATAPOINT,
  SET_BANNER_CURRENT_TAB,
  CHANGE_BANNER_ITEMS_OPERATOR,
  SET_BANNER,
  SAVE_ALL_BANNERS,
  RESTORE_BASIC_BANNER_TEMPLATE,
  RESTORE_ADVANCED_BANNER_TEMPLATE,
  EDIT_ADD_BASIC_BANNER_QUESTION,
  EDIT_REMOVE_BASIC_BANNER_QUESTION,
  EDIT_ADD_BASIC_BANNER_DATAPOINT,
  EDIT_REMOVE_BASIC_BANNER_DATAPOINT,
  EDIT_ADD_BANNER_BLOCK,
  EDIT_REMOVE_BANNER_BLOCK,
  EDIT_ADD_BANNER_QUESTION,
  EDIT_REMOVE_BANNER_QUESTION,
  EDIT_ADD_BANNER_DATAPOINT,
  EDIT_REMOVE_BANNER_DATAPOINT,
  EDIT_CHANGE_BANNER_ITEMS_OPERATOR,
  TOGGLE_EDITING_BANNER,
  RESET_MODAL,
  CHANGE_BASIC_BANNER_TITLE,
  CHANGE_ADVANCED_BANNER_TITLE,
  CHANGE_EDIT_BANNER_TITLE,
  SET_BASIC_MODAL_SAVED,
  SET_ADVANCED_MODAL_SAVED,
  SET_CURRENT_BANNER_TEMPLATE,
  COPY_BANNER,
  RESTORE_BASIC_BUILDER,
  RESTORE_ADVANCED_BUILDER,
  CLEAR_BANNERS,
} from '../actionTypes/bannerTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const defaultState = {
  currentBanner: [],
  allCurrentBanners: [],
  basicBuilder: {},
  editBasicBuilder: {},
  basicBuilderTitle: '',
  basicBuilderSaved: false,
  currentTab: 0, // 0 = basic, 1 = templates, 2 = advanced
  currentTemplate: {
    editing: false,
    basicBuilder: {},
    advancedBuilder: {},
    banner: {},
    title: '',
  },
  advancedBuilderTitle: '',
  advancedBuilderSaved: false,
  advancedBuilder: {
    counter: 0,
    blocks: {
      0: {
        id: 0,
        operator: null,
        itemsOperator: { operator: 'OR', negated: false },
        questions: {},
      },
    },
  },
};
const bannerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_BASIC_BANNER_QUESTION:
      return addBasicQuestion(state, action.payload);
    case REMOVE_BASIC_BANNER_QUESTION:
      return removeBasicQuestion(state, action.payload);
    case ADD_BASIC_BANNER_DATAPOINT:
      return addBasicDataPoint(state, action.payload);
    case REMOVE_BASIC_BANNER_DATAPOINT:
      return removeBasicDataPoint(state, action.payload);
    case EDIT_ADD_BASIC_BANNER_QUESTION:
      return editAddBasicQuestion(state, action.payload);
    case EDIT_REMOVE_BASIC_BANNER_QUESTION:
      return editRemoveBasicQuestion(state, action.payload);
    case EDIT_ADD_BASIC_BANNER_DATAPOINT:
      return editAddBasicDataPoint(state, action.payload);
    case EDIT_REMOVE_BASIC_BANNER_DATAPOINT:
      return editRemoveBasicDataPoint(state, action.payload);
    case ADD_BANNER_BLOCK:
      return addBlock(state, action.payload);
    case REMOVE_BANNER_BLOCK:
      return removeBlock(state, action.payload);
    case ADD_BANNER_QUESTION:
      return addQuestion(state, action.payload);
    case REMOVE_BANNER_QUESTION:
      return removeQuestion(state, action.payload);
    case ADD_BANNER_DATAPOINT:
      return addDataPoint(state, action.payload);
    case REMOVE_BANNER_DATAPOINT:
      return removeDataPoint(state, action.payload);
    case CHANGE_BANNER_ITEMS_OPERATOR:
      return changeItemsOperator(state, action.payload);
    case EDIT_ADD_BANNER_BLOCK:
      return editAddBlock(state, action.payload);
    case EDIT_REMOVE_BANNER_BLOCK:
      return editRemoveBlock(state, action.payload);
    case EDIT_ADD_BANNER_QUESTION:
      return editAddQuestion(state, action.payload);
    case EDIT_REMOVE_BANNER_QUESTION:
      return editRemoveQuestion(state, action.payload);
    case EDIT_ADD_BANNER_DATAPOINT:
      return editAddDataPoint(state, action.payload);
    case EDIT_REMOVE_BANNER_DATAPOINT:
      return editRemoveDataPoint(state, action.payload);
    case EDIT_CHANGE_BANNER_ITEMS_OPERATOR:
      return editChangeItemsOperator(state, action.payload);
    case SET_BANNER:
      return { ...state, currentBanner: action.payload };
    case SAVE_ALL_BANNERS:
      return { ...state, allCurrentBanners: action.payload };
    case SET_BANNER_CURRENT_TAB:
      return { ...state, currentTab: action.payload };
    case LOG_OUT:
      return defaultState;
    case RESTORE_BASIC_BANNER_TEMPLATE:
      return restoreBasicBuilderBanner(state, action.payload);
    case RESTORE_ADVANCED_BANNER_TEMPLATE:
      return restoreAdvancedBuilderBanner(state, action.payload);
    case SET_CURRENT_BANNER_TEMPLATE: {
      const { payload } = action;
      if (action.payload.bannerType === 'basic') {
        const basicBuilder = payload.dataStructureBanner ? payload.dataStructureBanner[0].Banners[0].bannerDataStructure.dataForm : payload.builder || payload.data;
        return {
          ...state,
          basicBuilder,
          currentTemplate: {
            ...state.currentTemplate,
            basicBuilder,
            banner: action.payload,
            title: action.payload.title,
            autoload: action.payload.autoload,
          },
        };
      } if (action.payload.bannerType === 'advanced') {
        const advancedBuilder = payload.builder || payload.data;
        return {
          ...state,
          advancedBuilder,
          currentTemplate: {
            ...state.currentTemplate,
            banner: action.payload,
            advancedBuilder,
            title: action.payload.title,
            autoload: action.payload.autoload,
          },
        };
      } if (action.payload.bannerType === 'basic_block_merged') {
        const basicBuilder = payload.dataStructureBanner ? addDataForm(payload.dataStructureBanner[0].Banners[0].mergedBlockList) : payload.builder || payload.data;
        return {
          ...state,
          basicBuilder,
          currentTemplate: {
            ...state.currentTemplate,
            basicBuilder,
            banner: action.payload,
            title: action.payload.title,
            autoload: action.payload.autoload,
          },
        };
      }
      return state;
    }
    case TOGGLE_EDITING_BANNER:
      return { ...state, currentTemplate: { ...state.currentTemplate, editing: action.payload } };
    case CHANGE_BASIC_BANNER_TITLE:
      return { ...state, basicBuilderTitle: action.payload };
    case CHANGE_ADVANCED_BANNER_TITLE:
      return { ...state, advancedBuilderTitle: action.payload };
    case CHANGE_EDIT_BANNER_TITLE:
      return { ...state, currentTemplate: { ...state.currentTemplate, title: action.payload } };
    case SET_BASIC_MODAL_SAVED:
      return { ...state, basicBuilderSaved: true };
    case SET_ADVANCED_MODAL_SAVED:
      return { ...state, advancedBuilderSaved: true };
    case RESET_MODAL:
      return resetModal(state);
    case COPY_BANNER:
      return { ...state, [action.payload.key]: action.payload.builder };
    case RESTORE_BASIC_BUILDER:
      return { ...state, basicBuilder: action.payload };
    case RESTORE_ADVANCED_BUILDER:
      return { ...state, advancedBuilder: { ...state.advancedBuilder, blocks: action.payload }, currentTemplate: state.currentTemplate };
    case CLEAR_BANNERS:
      return defaultState;
    default:
      return state;
  }
};


function addDataForm(banner) {
  const data = [];
  banner.map((bannerMerge) => {
    data.push(bannerMerge.bannerDataStructure.dataForm);
  });
  return data;
}


// BASIC

function restoreBasicBuilderBanner(state, data) {
  const newState = cloneDeep(state);
  newState.currentTemplate.basicBuilder = data;
  newState.currentTemplate.advancedBuilder = {};
  return newState;
}

function addBasicQuestion(state, question) {
  return { ...state, basicBuilder: { ...state.basicBuilder, [question.id]: question } };
}

function removeBasicQuestion(state, question) {
  const newState = cloneDeep(state);
  if (newState.basicBuilder.hasOwnProperty(question.id)) {
    delete newState.basicBuilder[question.id];
  }
  return newState;
}

function addBasicDataPoint(state, payload) {
  const { question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.basicBuilder.hasOwnProperty(question.id)) {
    newState.basicBuilder[question.id].answers[answer.id] = answer;
  } else {
    newState.basicBuilder[question.id] = {
      id: question.id,
      text: question.text,
      answers: { [answer.id]: answer },
    };
  }
  return newState;
}

function removeBasicDataPoint(state, payload) {
  const { question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.basicBuilder.hasOwnProperty(question.id)) {
    delete newState.basicBuilder[question.id].answers[answer.id];
    if (isEmpty(newState.basicBuilder[question.id].answers)) {
      delete newState.basicBuilder[question.id];
    }
  }
  return newState;
}
// ADVANCED

function restoreAdvancedBuilderBanner(state, data) {
  const counter = size(data.blocks);
  const newState = cloneDeep(state);
  newState.currentTemplate.advancedBuilder.counter = counter;
  newState.currentTemplate.advancedBuilder.blocks = data.blocks;
  newState.currentTemplate.basicBuilder = {};
  return newState;
}

function addBlock(state, operator) {
  const newState = cloneDeep(state);
  newState.advancedBuilder.counter += 1;
  const { counter } = newState.advancedBuilder;
  newState.advancedBuilder.blocks[counter] = {
    id: counter,
    operator,
    itemsOperator: { operator: 'OR', negated: false },
    questions: {},
  };
  return newState;
}

function removeBlock(state, payload) {
  const { blockId } = payload;
  const newState = cloneDeep(state);
  delete newState.advancedBuilder.blocks[blockId];
  return newState;
}

function changeItemsOperator(state, payload) {
  const newState = cloneDeep(state);
  const { blockId, operator } = payload;
  newState.advancedBuilder.blocks[blockId].itemsOperator = operator;
  return newState;
}

function addQuestion(state, payload) {
  const newState = cloneDeep(state);
  const { blockId, question } = payload;
  newState.advancedBuilder.blocks[blockId].questions[question.id] = question;
  return newState;
}

function removeQuestion(state, payload) {
  const { blockId, question } = payload;
  const newState = cloneDeep(state);
  if (newState.advancedBuilder.blocks[blockId].questions.hasOwnProperty(question.id)) {
    delete newState.advancedBuilder.blocks[blockId].questions[question.id];
  }
  return newState;
}

function addDataPoint(state, payload) {
  const {
    block, question, answer, path,
  } = payload;
  const newState = cloneDeep(state);
  if (state.advancedBuilder.blocks[block].questions.hasOwnProperty(question.id)) {
    newState.advancedBuilder.blocks[block].questions[question.id].answers[answer.id] = answer;
  } else {
    newState.advancedBuilder.blocks[block].questions[question.id] = {
      id: question.id,
      text: question.text,
      answers: { [answer.id]: answer },
      path,
    };
  }
  return newState;
}

function removeDataPoint(state, payload) {
  const { block, question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.advancedBuilder.blocks[block].questions.hasOwnProperty(question.id)) {
    delete newState.advancedBuilder.blocks[block].questions[question.id].answers[answer.id];
    if (isEmpty(newState.advancedBuilder.blocks[block].questions[question.id].answers)) {
      delete newState.advancedBuilder.blocks[block].questions[question.id];
    }
  }
  return newState;
}


// Edit Basic Banner
function editAddBasicQuestion(state, question) {
  return {
    ...state,
    currentTemplate: {
      ...state.currentTemplate,
      basicBuilder: { ...state.currentTemplate.basicBuilder, [question.id]: question },
    },
  };
}

function editRemoveBasicQuestion(state, question) {
  const newState = cloneDeep(state);
  if (newState.currentTemplate.basicBuilder.hasOwnProperty(question.id)) {
    delete newState.currentTemplate.basicBuilder[question.id];
  }
  return newState;
}

function editAddBasicDataPoint(state, payload) {
  const { question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.currentTemplate.basicBuilder.hasOwnProperty(question.id)) {
    newState.currentTemplate.basicBuilder[question.id].answers[answer.id] = answer;
  } else {
    newState.currentTemplate.basicBuilder[question.id] = {
      id: question.id,
      text: question.text,
      answers: { [answer.id]: answer },
    };
  }
  return newState;
}

function editRemoveBasicDataPoint(state, payload) {
  const { question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.currentTemplate.basicBuilder.hasOwnProperty(question.id)) {
    delete newState.currentTemplate.basicBuilder[question.id].answers[answer.id];
    if (isEmpty(newState.currentTemplate.basicBuilder[question.id].answers)) {
      delete newState.currentTemplate.basicBuilder[question.id];
    }
  }
  return newState;
}
// Edit Advanced Banner
function editAddBlock(state, operator) {
  const newState = cloneDeep(state);
  newState.currentTemplate.advancedBuilder.counter += 1;
  const { counter } = newState.currentTemplate.advancedBuilder;
  newState.currentTemplate.advancedBuilder.blocks[counter] = {
    id: [counter],
    operator,
    itemsOperator: { operator: 'OR', negated: false },
    questions: {},
  };
  return newState;
}

function editRemoveBlock(state, payload) {
  const { blockId } = payload;
  const newState = cloneDeep(state);
  delete newState.currentTemplate.advancedBuilder.blocks[blockId];
  return newState;
}

function editChangeItemsOperator(state, payload) {
  const newState = cloneDeep(state);
  const { blockId, operator } = payload;
  newState.currentTemplate.advancedBuilder.blocks[blockId].itemsOperator = operator;
  return newState;
}

function editAddQuestion(state, payload) {
  const newState = cloneDeep(state);
  const { blockId, question } = payload;
  newState.currentTemplate.advancedBuilder.blocks[blockId].questions[question.id] = question;
  return newState;
}

function editRemoveQuestion(state, payload) {
  const { blockId, question } = payload;
  const newState = cloneDeep(state);
  if (newState.currentTemplate.advancedBuilder.blocks[blockId].questions.hasOwnProperty(question.id)) {
    delete newState.currentTemplate.advancedBuilder.blocks[blockId].questions[question.id];
  }
  return newState;
}

function editAddDataPoint(state, payload) {
  const { block, question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.currentTemplate.advancedBuilder.blocks[block].questions.hasOwnProperty(question.id)) {
    newState.currentTemplate.advancedBuilder.blocks[block].questions[question.id].answers[answer.id] = answer;
  } else {
    newState.currentTemplate.advancedBuilder.blocks[block].questions[question.id] = {
      id: question.id,
      text: question.text,
      answers: { [answer.id]: answer },
    };
  }
  return newState;
}

function editRemoveDataPoint(state, payload) {
  const { block, question, answer } = payload;
  const newState = cloneDeep(state);
  if (state.currentTemplate.advancedBuilder.blocks[block].questions.hasOwnProperty(question.id)) {
    delete newState.currentTemplate.advancedBuilder.blocks[block].questions[question.id].answers[answer.id];
    if (isEmpty(newState.currentTemplate.advancedBuilder.blocks[block].questions[question.id].answers)) {
      delete newState.currentTemplate.advancedBuilder.blocks[block].questions[question.id];
    }
  }
  return newState;
}

function resetModal(state) {
  const {
    editBasicBuilder, currentTemplate, currentBanner, allCurrentBanners,
  } = state;
  return {
    ...defaultState,
    // currentTemplate,
    editBasicBuilder,
    currentBanner,
    allCurrentBanners,
  };
}

export default bannerReducer;

import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import _ from 'lodash';
import propTypes from 'prop-types';
import Trigger from './QuestionTrigger';
import Answers from './Answers';

class Question extends Component {
  state = {
    checked: false,
  }

  toggleCheck = () => {
    this.setState(state => ({ checked: !state.checked }));
  }

  normalize = answers => _.keyBy(answers, 'index');

  render() {
    const {
      actions,
      activeCollapsibles,
      index,
      blockId,
      handlers,
      path,
      question,
    } = this.props;
    if (!question) return null;
    const { question: activeQuestion } = activeCollapsibles;
    const open = activeQuestion === question.text;
    question.answers = this.normalize(question.answers);
    const newPath = { ...path, question: question.text };
    const { checked } = this.state;
    return (
      <Wrapper key={index}>
        <Collapsible
          accordionPosition={question.text}
          key={index}
          open={open}
          handleTriggerClick={open ? handlers.question.close : handlers.question.open}
          trigger={(
            <Trigger
              blockId={blockId}
              question={{ id: question.id, text: question.text, path: newPath }}
              checked={checked}
              toggle={this.toggleCheck}
              actions={actions.question}
            />
          )}
          triggerWhenOpen={(
            <Trigger
              blockId={blockId}
              question={{ id: question.id, text: question.text, path: newPath }}
              checked={checked}
              toggle={this.toggleCheck}
              actions={actions.question}
              open
            />
          )}
        >
          <Answers
            path={newPath}
            question={{ id: question.id, title: question.text }}
            answers={question.answers}
            blockId={blockId}
            actions={actions.answer}
          />
        </Collapsible>
      </Wrapper>
    );
  }
}

Question.propTypes = {
  actions: propTypes.object.isRequired,
  activeCollapsibles: propTypes.object.isRequired,
  blockId: propTypes.number.isRequired,
  handlers: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
  path: propTypes.object.isRequired,
  question: propTypes.object.isRequired,
};

const Wrapper = styled.div`
  border-top: 1px #DEE1E3 solid;
`;

export default Question;

import React, { Component } from 'react';
import styled from 'styled-components';
import Toolbar from './Toolbar';
import SortableComponent from './Sortable';

class ChartPage extends Component {
  render() {
    return (
      <Container>
        <Toolbar />
        <SortableComponent />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  background-color: #F6F7F8;
  min-height: 70vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-top: 2px solid #AAABAB;
`;

export default ChartPage;

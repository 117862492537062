import axios from 'axios';
import _ from 'lodash';
import builderDataToQuery from './builderFunctions';

import {
  ADD_BANNER_BLOCK,
  REMOVE_BANNER_BLOCK,
  ADD_BANNER_QUESTION,
  REMOVE_BANNER_QUESTION,
  ADD_BANNER_DATAPOINT,
  REMOVE_BANNER_DATAPOINT,
  SET_BANNER,
  SAVE_ALL_BANNERS,
  SET_BANNER_CURRENT_TAB,
  ADD_BASIC_BANNER_QUESTION,
  REMOVE_BASIC_BANNER_QUESTION,
  ADD_BASIC_BANNER_DATAPOINT,
  REMOVE_BASIC_BANNER_DATAPOINT,
  CHANGE_BANNER_ITEMS_OPERATOR,
  RESTORE_BASIC_BANNER_TEMPLATE,
  RESTORE_ADVANCED_BANNER_TEMPLATE,
  EDIT_ADD_BASIC_BANNER_QUESTION,
  EDIT_REMOVE_BASIC_BANNER_QUESTION,
  EDIT_ADD_BASIC_BANNER_DATAPOINT,
  EDIT_REMOVE_BASIC_BANNER_DATAPOINT,
  EDIT_ADD_BANNER_BLOCK,
  EDIT_REMOVE_BANNER_BLOCK,
  EDIT_ADD_BANNER_QUESTION,
  EDIT_REMOVE_BANNER_QUESTION,
  EDIT_ADD_BANNER_DATAPOINT,
  EDIT_REMOVE_BANNER_DATAPOINT,
  EDIT_CHANGE_BANNER_ITEMS_OPERATOR,
  TOGGLE_EDITING_BANNER,
  RESET_MODAL,
  CHANGE_ADVANCED_BANNER_TITLE,
  CHANGE_BASIC_BANNER_TITLE,
  CHANGE_EDIT_BANNER_TITLE,
  SET_BASIC_MODAL_SAVED,
  SET_ADVANCED_MODAL_SAVED,
  SET_CURRENT_BANNER_TEMPLATE,
  COPY_BANNER,
  RESTORE_BASIC_BUILDER,
  RESTORE_ADVANCED_BUILDER,
  CLEAR_BANNERS,
} from '../actionTypes/bannerTypes';
import showNotification from '../helpers/showNotification';
import { getProjects } from './projectActions';

export const setEditingTo = status => (dispatch, getState) => {
  const { editing } = getState().bannerReducer.currentTemplate;
  if (editing !== status) {
    dispatch({ type: TOGGLE_EDITING_BANNER, payload: status });
  }
};
export const setCurrentBannerTab = payload => ({ type: SET_BANNER_CURRENT_TAB, payload });
export const setCurrentBannerTemplate = (payload, autoload) => ({ type: SET_CURRENT_BANNER_TEMPLATE, payload: { ...payload, autoload } });

export const changeBannerTitle = (type, payload) => {
  if (type === 'basic') {
    return { type: CHANGE_BASIC_BANNER_TITLE, payload };
  } if (type === 'advanced') {
    return { type: CHANGE_ADVANCED_BANNER_TITLE, payload };
  } if (type === 'edit') {
    return { type: CHANGE_EDIT_BANNER_TITLE, payload };
  }
};
export const resetModal = () => ({ type: RESET_MODAL });
export const updateBanner = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const { currentTemplate } = getState().bannerReducer;
  const { basicBuilder, advancedBuilder, title } = currentTemplate;
  const template = {
    title,
  };
  if (!_.isEmpty(basicBuilder)) {
    template.data = currentTemplate.basicBuilder;
  } else if (!_.isEmpty(advancedBuilder)) {
    template.data = currentTemplate.advancedBuilder;
  }
  axios.put(`/api/templates/${currentTemplate.banner._id}`, template)
    .then(() => {
      dispatch(getProjects());
      resolve();
      showNotification({ message: `Banner ${title} was updated.`, type: 'success' });
    })
    .catch((error) => {
      reject();
      showNotification({ message: error.message, type: 'error' });
    });
});

// Basic
export const restoreBasicBanner = payload => ({ type: RESTORE_BASIC_BANNER_TEMPLATE, payload });
export const addBasicQuestion = data => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const question = questions[data.question.id];
  dispatch({ type: ADD_BASIC_BANNER_QUESTION, payload: { ...question, path: data.question.path } });
};

export const removeBasicQuestion = data => ({
  type: REMOVE_BASIC_BANNER_QUESTION, payload: data.question,
});

export const addBasicDataPoint = (blockId, dataPoint) => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  if (questions && _.size(questions) > 0) {
    const [questionId, answerId] = dataPoint.split('-');
    const question = questions[questionId];
    const answer = question.answers[`${questionId}-${answerId}`];
    const payload = {
      question: { id: question.id, text: question.text },
      answer,
    };
    dispatch({ type: ADD_BASIC_BANNER_DATAPOINT, payload });
  }
};

export const removeBasicDataPoint = (blockId, dataPoint) => {
  const [questionId] = dataPoint.split('-');
  const payload = {
    question: { id: questionId },
    answer: { id: dataPoint },
  };
  return { type: REMOVE_BASIC_BANNER_DATAPOINT, payload };
};

// edit basic
export const editAddBasicQuestion = data => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const question = questions[data.question.id];
  dispatch({ type: EDIT_ADD_BASIC_BANNER_QUESTION, payload: question });
};

export const editRemoveBasicQuestion = data => ({
  type: EDIT_REMOVE_BASIC_BANNER_QUESTION, payload: data.question,
});

export const editAddBasicDataPoint = (blockId, dataPoint) => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const [questionId, answerId] = dataPoint.split('-');
  const question = questions[questionId];
  const answer = question.answers[`${questionId}-${answerId}`];
  const payload = {
    question: { id: question.id, text: question.text },
    answer,
  };
  dispatch({ type: EDIT_ADD_BASIC_BANNER_DATAPOINT, payload });
};

export const editRemoveBasicDataPoint = (blockId, dataPoint) => {
  const [questionId] = dataPoint.split('-');
  const payload = {
    question: { id: questionId },
    answer: { id: dataPoint },
  };
  return { type: EDIT_REMOVE_BASIC_BANNER_DATAPOINT, payload };
};

// Advanced
export const restoreAdvancedBanner = payload => ({ type: RESTORE_ADVANCED_BANNER_TEMPLATE, payload });

export const addBlock = operator => ({ type: ADD_BANNER_BLOCK, payload: operator });
export const removeBlock = data => ({ type: REMOVE_BANNER_BLOCK, payload: data });
export const addQuestion = data => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const question = questions[data.question.id];
  dispatch({ type: ADD_BANNER_QUESTION, payload: { blockId: data.blockId, question: { ...question, path: data.question.path } } });
};

export const removeQuestion = data => ({ type: REMOVE_BANNER_QUESTION, payload: data });

export const addDataPoint = (blockId, dataPoint, path) => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const [questionId, answerId] = dataPoint.split('-');
  const question = questions[questionId];
  const answer = question.answers[`${questionId}-${answerId}`];
  const payload = {
    block: blockId,
    question: { id: question.id, text: question.text },
    answer,
    path,
  };
  dispatch({ type: ADD_BANNER_DATAPOINT, payload });
};

export const removeDataPoint = (blockId, dataPoint) => {
  const [questionId] = dataPoint.split('-');
  const payload = {
    block: blockId,
    question: { id: questionId },
    answer: { id: dataPoint },
  };
  return { type: REMOVE_BANNER_DATAPOINT, payload };
};
export const saveBannerTemplate = data => (dispatch, getState) => new Promise((resolve, reject) => {
  const { currentAccountId, user, currentDatabase } = getState().AppReducer;
  const template = {
    ...data,
    team: currentAccountId,
    database: currentDatabase,
    user: user._id,
  };
  if (data.subType === 'QUESTION') {
    template.bannerType = data.bannerType;
  }
  if (template.isFromSpreadsheet) {
    template.data = template.dataForm;
    template.bannerData = template.bannerData;
  } else if (template.bannerType === 'basic') {
    template.data = getState().bannerReducer.basicBuilder;
  } else if (template.bannerType === 'advanced') {
    template.data = getState().bannerReducer.advancedBuilder;
  }
  if ((!_.isEmpty(template.data))) {
    axios.post('/api/templates/', template)
      .then((response) => {
        if (response.data.success) {
          dispatch(getProjects());
          if (template.bannerType === 'basic') {
            dispatch({ type: SET_BASIC_MODAL_SAVED });
          } else if (template.bannerType === 'advanced') {
            dispatch({ type: SET_ADVANCED_MODAL_SAVED });
          }
          showNotification({
            message: 'Banner template saved.',
          });
          resolve(response.data);
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  } else {
    reject({ message: 'You should have data in your banner.' });
  }
});
export const changeItemsOperator = (data) => {
  let operator = {};
  switch (data.operator) {
    case 'OR':
      operator = { operator: 'OR', negated: false };
      break;
    case 'AND':
      operator = { operator: 'AND', negated: false };
      break;
    case 'OR NOT':
      operator = { operator: 'OR', negated: true };
      break;
    case 'AND NOT':
      operator = { operator: 'AND', negated: true };
      break;
    default:
      operator = { operator: 'OR', negated: false };
  }
  return { type: CHANGE_BANNER_ITEMS_OPERATOR, payload: { blockId: data.blockId, operator } };
};

// Edit Advanced Banner
export const editAddBlock = operator => ({ type: EDIT_ADD_BANNER_BLOCK, payload: operator });
export const editRemoveBlock = data => ({ type: EDIT_REMOVE_BANNER_BLOCK, payload: data });
export const editAddQuestion = data => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const question = questions[data.question.id];
  dispatch({ type: EDIT_ADD_BANNER_QUESTION, payload: { blockId: data.blockId, question } });
};

export const editRemoveQuestion = data => ({ type: EDIT_REMOVE_BANNER_QUESTION, payload: data });

export const editAddDataPoint = (blockId, dataPoint) => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const [questionId, answerId] = dataPoint.split('-');
  const question = questions[questionId];
  const answer = question.answers[`${questionId}-${answerId}`];
  const payload = {
    block: blockId,
    question: { id: question.id, text: question.text },
    answer,
  };
  dispatch({ type: EDIT_ADD_BANNER_DATAPOINT, payload });
};

export const editRemoveDataPoint = (blockId, dataPoint) => {
  const [questionId] = dataPoint.split('-');
  const payload = {
    block: blockId,
    question: { id: questionId },
    answer: { id: dataPoint },
  };
  return { type: EDIT_REMOVE_BANNER_DATAPOINT, payload };
};

export const editChangeItemsOperator = (data) => {
  let operator = {};
  switch (data.operator) {
    case 'OR':
      operator = { operator: 'OR', negated: false };
      break;
    case 'AND':
      operator = { operator: 'AND', negated: false };
      break;
    case 'OR NOT':
      operator = { operator: 'OR', negated: true };
      break;
    case 'AND NOT':
      operator = { operator: 'AND', negated: true };
      break;
    default:
      operator = { operator: 'OR', negated: false };
  }
  return { type: EDIT_CHANGE_BANNER_ITEMS_OPERATOR, payload: { blockId: data.blockId, operator } };
};
/* ------------------------------------ */
export const setBanner = (type, data) => (dispatch, getState) => {
  let banners = [];
  if (type === 'basic') {
    const basicBannerQuery = createBasicBannerQuery(data);
    banners = basicBannerQuery;
  } else if (type === 'advanced') {
    const complexBanner = createAdvancedBannerQuery(data);
    banners.push(complexBanner);
  } else if (type === 'basic_block_merged') {
    const basicBannerQuery = createBasicBannerQuery(data);
    banners = { ...basicBannerQuery };
  }
  dispatch({ type: SET_BANNER, payload: banners });
};

export const copyBanner = (type, key) => (dispatch, getState) => {
  let builder;
  if (type === 'basic') {
    builder = getState().bannerReducer.basicBuilder;
  } else if (type === 'advanced') {
    builder = getState().bannerReducer.advancedBuilder;
  }
  dispatch({ type: COPY_BANNER, payload: { key, builder } });
};

export const restoreBuilder = (type, data) => (dispatch) => {
  if (type === 'advanced') {
    setTimeout(() => {
      dispatch({ type: SET_BANNER_CURRENT_TAB, payload: 2 });
    }, 200);
    dispatch({ type: RESTORE_ADVANCED_BUILDER, payload: data });
  }
  dispatch({ type: RESTORE_BASIC_BUILDER, payload: { [data.id]: data } });
};

export const clearBanners = () => ({ type: CLEAR_BANNERS, payload: {} });

function createBasicBannerQuery(questions) {
  const banners = _.map(questions, (question) => {
    const questionData = question.answers ? question : question.bannerDataStructure.dataForm[question.questionCode];
    const banner = {
      type: 'basic',
      data: {
        title: questionData.text,
        question: questionData.id,
        includes: [],
        builder: questionData,
        builderType: 'basic',
      },
    };
    _.map(questionData.answers, answer => banner.data.includes.push(answer.index));
    return banner;
  });
  return banners;
}

function createAdvancedBannerQuery(builderData) {
  const query = builderDataToQuery(builderData);
  return {
    type: 'complex',
    data: {
      title: 'Custom Banner',
      question: query,
      includes: [1],
      builder: builderData,
      builderType: 'advanced',
    },
  };
}

import React from 'react';
import styled from 'styled-components';


const AverageDataCell = ({ percentage }) => (
  <Cell>
    <Container>
      <Data>
        {percentage || 0}
      </Data>
      <Data />
      <Data
        style={{ paddingRight: '15px' }}
      />
    </Container>
  </Cell>
);

const Container = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  jusify-content: flex-start;
  align-items: center;
`;

const Cell = styled.div`
    height: 32px;
    border-bottom: 2px solid #E2E7EA;    
    font-size: 13px;
    font-weight: 400;
    color: #37474F; 
    margin-bottom: 0px;
    background-color:white;
    color: #19A2C5; 
    width: 300px;
    display: inline-block;
    border-right: 2px solid #E2E7Ea;
`;

const Data = styled.div`
    width:33%;
    text-align: right;
`;


export default AverageDataCell;

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Scrollbar from '../GlobalComponents/Scrollbar';
import SaveTemplateBox from './SaveTemplateBox';

class Preview extends Component {

  render() {
    const { basicBuilder, currentTab } = this.props;

    return (
      <div>
        <Container>
          <Title>
            Preview:
          </Title>
          <Summary>
            <Scrollbar>
              <div style={{ padding: '10px' }}>
                {_.map(basicBuilder, (question, index) => (
                  <Block key={index}>
                    <Question>
                      <p>{question.text}</p>
                      {_.map(question.answers, (answer, index) => <Answer key={index}>{`• ${answer.text}`}</Answer>)}
                    </Question>
                  </Block>
                ))}
              </div>
            </Scrollbar>
          </Summary>
        </Container>
        <SaveTemplateBox edit={currentTab === 1} currentTab={currentTab} />
      </div>
    );
  }
}


const Container = styled.div`
  height: 100%;
  position: relative;
  width: 252px;
  padding: 4px 24px;
`;

const Title = styled.p`
  margin: 0;
  height: 24px;
  width: 130px;
  color: #37474F;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 24px;
`;

const Summary = styled.div`
  width: 100%;
  height: 400px;
`;

const Block = styled.div`
  background-color: #F6F7F8;
  color: #7E96A1;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  padding: 10px 17px 13px 12px;
  margin-bottom: 5px;
`;

const Question = styled.ul`
  margin: 1px;
  color: black;
  font-family: 'Roboto';
  font-size: 13px;
  color: #37474F;
  font-weight: 500;
  padding: 0px;
  p {
    margin: 0px;
    margin-bottom: 5px;
  }
`;

const Answer = styled.li`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  color: #37474F;
  list-style: none;
  margin-bottom: 2px;
`;

export default Preview;

import Axios from 'axios';
import _ from 'lodash';
import {
  GET_ACCOUNTS,
  DEACTIVATE_ACCOUNT,
  CREATE_ACCOUNT,
  EDIT_ACCOUNT_DETAILS,
  SET_USERS,
  SET_ACCOUNT,
  ADD_USER_TO_ACCOUNT_USERS,
  EDIT_DATABASES,
  DELETE_DATABASE,
  DELETE_ACCOUNT,
} from '../actionTypes/accountTypes';
import showNotification from '../helpers/showNotification';

const getAccountsAction = payload => ({
  type: GET_ACCOUNTS,
  payload,
});

const setAccountAction = payload => ({
  type: SET_ACCOUNT,
  payload,
});

const deactivateAccountAction = payload => ({
  type: DEACTIVATE_ACCOUNT,
  payload,
});

const deleteAccountAction = payload => ({
  type: DELETE_ACCOUNT,
  payload,
});

const createAccountAction = payload => ({
  type: CREATE_ACCOUNT,
  payload,
});

const EditAccountDetailsAction = payload => ({
  type: EDIT_ACCOUNT_DETAILS,
  payload,
});

const setUsers = payload => ({
  type: SET_USERS,
  payload,
});

const addContactToAccountUsers = payload => ({
  type: ADD_USER_TO_ACCOUNT_USERS,
  payload,
});

const uploadAndEditDatabasesAction = payload => ({
  type: EDIT_DATABASES,
  payload,
});

const deleteDatabaseAction = payload => ({
  type: DELETE_DATABASE,
  payload,
});

export const getAllUsers = () => (dispatch, getState) => new Promise((resolve, reject) => {
  Axios.get('/api/users')
    .then((response) => {
      const users = _.keyBy(response.data, '_id');
      dispatch(setUsers(users));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
});

const getAccounts = forceFetch => (dispatch, getState) => new Promise((resolve, reject) => {
  const sizeOfAccounts = _.size(getState().AccountReducer.accounts);
  if ((sizeOfAccounts === 0) || forceFetch) {
    Axios.get('/api/team')
      .then((response) => {
        const accounts = _.keyBy(response.data, '_id');
        dispatch(getAccountsAction(accounts));
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    resolve();
  }
});

const getAccountById = async accountId => {
 try {
  const response =  await Axios.get(`/api/team/${accountId}`);
  return response.data;
 } catch (error) {
  showNotification({ message: 'Error retrieving account', type: 'error' });
 }
};

const deactivateAccount = team => dispatch => new Promise((resolve, reject) => {
  Axios.put(`/api/team/${team.id}/deactivate`)
    .then((response) => {
      dispatch(deactivateAccountAction(response.data.team));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err.message);
    });
});

const activateAccount = team => dispatch => new Promise((resolve, reject) => {
  Axios.put(`/api/team/${team.id}/activate`)
    .then((response) => {
      dispatch(deactivateAccountAction(response.data.teamDB));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err.message);
    });
});

const deleteAccount = team => dispatch => new Promise((resolve, reject) => {
  Axios.delete(`/api/team/${team.id}`)
    .then((response) => {
      dispatch(deleteAccountAction(response.data));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err.message);
    });
});

const uploadDocuments = (data) => {
  const formData = new FormData();
  data.forEach((value, index) => {
    formData.append(`documents[${index}][name]`, value.name);
    formData.append(`documents[${index}][description]`, value.description);
    formData.append(`documents[${index}][file]`, value.file);
  });
  /* formData.append(`modified[${index}][name]`, value.name);
  formData.append(`modified[${index}][deleted]`, value.deleted); */
  return Axios.post('/api/document/multiple/from-browser', formData);
};

const uploadDatabases = (data, teamId, teamName) => {
  const formData = new FormData();
  const { databases } = data;
  for (let index = 0; index < databases.length; index++) {
    const element = databases[index];
    _.map(element, (info, key) => {
      if (key === 'database') {
        _.map(info, (d, i, file) => {
          formData.append(`databases[${index}][${key}][${i}]`, d);
        });
      } else {
        formData.append(`databases[${index}][${key}]`, info);
      }
    });
  }
  formData.append('teamId', teamId);
  formData.append('teamFolderName', teamName);
  return Axios.post('/api/document/databases/from-browser', formData);
};

const uploadAndEditDatabases = (data, teamId, teamName) => dispatch => new Promise((resolve, reject) => {
  const formData = new FormData();
  const { databases } = data;
  for (let index = 0; index < databases.length; index++) {
    const element = databases[index];
    _.map(element, (info, key) => {
      if (key === 'database' || key === 'currentStoraged' || key === 'olderStoraged') {
        _.map(info, (d, i, file) => {
          formData.append(`databases[${index}][${key}][${i}]`, d);
        });
      } else if (key === 'olderStoraged' || key === 'storagedDatabases') {
        _.map(info, (d, i, file) => {
          const fileInfo = file[i];
          formData.append(`databases[${index}][${key}][${i}][fileName]`, fileInfo.fileName);
          formData.append(`databases[${index}][${key}][${i}][deleted]`, fileInfo.deleted);
        });
      } else {
        formData.append(`databases[${index}][${key}]`, info);
      }
    });
  }
  // formData.append('teamId', teamId);
  // formData.append('teamName', teamName);
  Axios.put(`/api/team/${teamId}/databases`, formData)
    .then((response) => {
      dispatch(getAccounts(true));
      dispatch(uploadAndEditDatabasesAction(response.data));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
});

const findIfEmailsExist = data => dispatch => new Promise((resolve, reject) => {
  let accountAlreadyExists = false;
  Axios.get(`/api/users/findIfExists/${data[0].email}`)
    .then((response) => {
      if (response.data.exists.length > 0) {
        accountAlreadyExists = true;
      } else if (data[1] !== undefined) {
        Axios.get(`/api/users/findIfExists/${data[1].email}`)
          .then((response2) => {
            if (response2.data.exists.length > 0) {
              accountAlreadyExists = true;
            }
          });
      }
    }).then(() => {
      if (accountAlreadyExists) {
        reject({ message: 'error' });
      } resolve({ message: 'success' });
    });
});

const uploadLogo = (file, teamId) => {
  const formData = new FormData();
  formData.append('logoFile', file);
  formData.append('teamId', teamId);
  return Axios.post(`/api/document/team/${teamId}/logo`, formData);
};

const editLogo = (file, teamId) => {
  const formData = new FormData();
  formData.append('logoFile', file);
  formData.append('teamId', teamId);
  return Axios.put(`/api/document/team/${teamId}/logo`, formData);
};

const createAccount = data => dispatch => new Promise((resolve, reject) => {
  let teamId = null;
  Axios.post('/api/team/', data)
    .then((response) => {
      teamId = response.data._id;
      return uploadDatabases(data, response.data._id, response.data.folderName);
    })
    .then(() => {
      const { logoFile } = data;
      return uploadLogo(logoFile, teamId);
    })
    .then(() => {
      if (data.documents.length > 0) {
        return uploadDocuments(data.documents);
      }
      return {
        data: [],
      };
    })
    .then((result) => {
      const information = {
        documents: result.data.map(doc => doc._id),
      };
      return Axios.put(`/api/team/${teamId}`, information);
    })
    .then((response) => {
      dispatch(getAccounts(true));
      dispatch(createAccountAction(response.data.team));
      dispatch(addContactToAccountUsers({ ...response.data.team.contact[0], teamId: response.data.team }));
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});


const EditAccountDetails = (Id, data) => dispatch => new Promise((resolve, reject) => {
  Axios.put(`/api/team/accountDetails/${Id}`, data)
    .then((response) => {
      dispatch(EditAccountDetailsAction(response.data));
      resolve(response);
    })
    .catch((err) => {
      reject(err.response);
    });
});

const EditAccountContacts = (data, teamId) => dispatch => new Promise((resolve, reject) => {
  Axios.put('/api/users/update/contacts', data)
    .then(() => {
      dispatch(getAccounts(true));
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

const editAccountDocuments = (files, teamId) => dispatch => new Promise((resolve, reject) => {
  const filesToUpdate = files.filter(file => file.deleted || file.updated);
  if (filesToUpdate.length !== 0) {
    const formData = new FormData();
    const filesToDelete = files.filter(file => file.deleted);
    const filesToAdd = files.filter(file => file.updated);
    if (filesToDelete.length !== 0) {
      filesToDelete.forEach((value, index) => {
        formData.append(`deleted[${index}]`, value.id);
      });
    }
    if (filesToAdd.length !== 0) {
      filesToAdd.forEach((value, index) => {
        formData.append(`modified[${index}][name]`, value.name);
        formData.append(`modified[${index}][description]`, value.description);
        formData.append(`modified[${index}][file]`, value.file);
      });
    }
    formData.append('teamId', teamId);
    Axios.post('/api/document/update/multiple/from-browser', formData)
      .then(() => Axios.get(`/api/team/${teamId}`))
      .then((response) => {
        dispatch(setAccountAction(response.data));
        resolve();
      })
      .catch((err) => {
        reject(err.response.data.error);
      });
  } else {
    resolve();
  }
});

const deleteDatabaseFromLocalFiles = (teamId, data) => dispatch => new Promise((resolve, reject) => {
  const { folderName } = data;
  Axios.delete(`/api/team/${teamId}/databases/${folderName}`)
    .then((response) => {
      dispatch(deleteDatabaseAction(response.data));
      resolve();
    })
    .catch((err) => {
      reject(err.response.data.error);
    });
});

const createUsersInEditing = (data, teamId) => dispatch => new Promise((resolve, reject) => {
  Axios.post(`/api/team/${teamId}/create/users/`, data)
    .then(() => {
      dispatch(getAccounts(true));
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

const deleteUserbyEmail = (contact, teamId) => dispatch => new Promise((resolve, reject) => {
  Axios.delete(`/api/users/${contact.id}`)
    .then(() => {
      dispatch(getAccounts(true));
      return Axios.get(`/api/team/${teamId}`);
    })
    .then((response) => {
      dispatch(setAccountAction(response.data));
      resolve(response.data);
    })
    .catch(err => reject(err.response.data.error));
});

const joinContactToTeam = (data, teamId) => dispatch => new Promise((resolve, reject) => {
  Axios.put('/api/users/update/contacts', data)
    .then((response) => {
      const users = response.data.map(user => user._id);
      const usersData = {
        users,
      };
      return Axios.put(`/api/team/${teamId}/contacts`, usersData);
    })
    .then(() => {
      dispatch(getAccounts(true));
      resolve();
    })
    .catch(err => reject(err.response.data.error));
});

const updateContactRole = (data, teamId) => dispatch => new Promise((resolve, reject) => {
  Axios.put(`/api/team/${teamId}/update/contact_role`, data)
    .then((response) => {
      dispatch(setAccountAction(response.data));
      resolve();
    })
    .catch(err => reject(err.response.data.error));
});

const removeContact = (data, teamId) => new Promise((resolve, reject) => {
  Axios.put(`/api/team/${teamId}/remove/contact`, data)
    .then(() => {
      resolve();
    })
    .catch(err => reject(err.response.data.error));
});

export {
  getAccounts,
  deactivateAccount,
  activateAccount,
  deleteAccount,
  createAccount,
  getAccountById,
  setAccountAction,
  uploadAndEditDatabases,
  EditAccountDetails,
  EditAccountContacts,
  editAccountDocuments,
  deleteDatabaseFromLocalFiles,
  findIfEmailsExist,
  createUsersInEditing,
  deleteUserbyEmail,
  updateContactRole,
  joinContactToTeam,
  removeContact,
  editLogo,
};

import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown, faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { getProjects, getSharedTemplates } from '../../actions/projectActions';
import {
  setCurrentTargetTemplate, copyTarget, setTargetTitle, setTargetQueryFromSavedTargets, restoreTargetTemplate, changeTargetTemplatesMenu, toggleTargetBuilderView, getTargetSize,
} from '../../actions/targetActions';
import { updateCharts } from '../../actions/overviewChartActions';
import { changeAllCardTarget2AndBase } from '../../actions/overviewCardActions'

const styles = {
  item: {
    root: 'targetDropdown',
    selected: 'selected',
  },
  button: {
    root: 'applyButton',
    disabled: 'disabled',
  },
};

class TargetDropdown extends React.Component {
  state = {
    open: false,
    selected: {},
  };


  componentDidMount() {
    const { getProjects, getSharedTemplates } = this.props;
    getProjects();
    getSharedTemplates();
  }

  componentWillReceiveProps({ targets }) {
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };


  handleMenuItemClick = (e, _id) => {
    const { targets } = this.props;
    const { selectOption } = this.props;
    const target = targets[_id];
    this.setState({
      selected: target,
    });
    selectOption(target);
  }

  applyClick = () => {
    const { selected } = this.state;
    const { setCurrentTarget } = this.props;
    this.setState({ open: false });
    setCurrentTarget(selected);
  }

  renderList = () => {
    const { selected } = this.state;
    const { targets } = this.props;
    const sortedTargets = _.orderBy(targets, 'createdAt', 'asc');
    if (_.size(targets) !== 0) {
      return _.map(sortedTargets, ({
        title, _id, shared, user,
      }) => (
        <MenuItem
          key={_id}
          classes={styles.item}
          selected={selected._id === _id}
          onClick={(e) => { this.handleMenuItemClick(e, _id); }}
        >
          {title}
          {
            ((this.props.user._id !== user._id) && shared)
            && (
              <SharedIcon>
                <FontAwesomeIcon icon={faUserFriends} />
              </SharedIcon>
            )
          }
        </MenuItem>
      ));
    }
    return (
      <MenuItem
        classes={styles.item}
      >
      You have no saved targets.
      </MenuItem>
    );
  }

  render() {
    const { open } = this.state;
    const { targets } = this.props;
    const disableButton = _.size(targets) === 0;
    return (
      <div>
        <TextTrigger
          innerRef={(node) => {
            this.anchorEl = node;
          }}
          onClick={this.handleToggle}
        >
          Use a saved target
          {' '}
          <FontAwesomeIcon icon={faAngleDown} />
        </TextTrigger>
        <Popper style={{ zIndex: 5 }} open={open} anchorEl={this.anchorEl} transition placement="bottom-start">
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList style={{ paddingBottom: '0px', width: '200' }}>
                    {
                      this.renderList()
                    }
                    <Divider />
                    <ApplyOption>
                      <Button disabled={disableButton} onClick={this.applyClick} classes={styles.button}>
                        Apply
                      </Button>
                    </ApplyOption>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { sharedTemplates, projects } = store.ProjectReducer;
  const allTemplates = { ...projects, ...sharedTemplates };
  const filteredTemplates = _.filter(allTemplates, p => p.subType === 'TARGET');
  const keyed = _.keyBy(filteredTemplates, '_id');
  return {
    targets: keyed,
    user: store.AppReducer.user,
  };
};
const mapDispatchToProps = dispatch => ({
  getProjects: () => dispatch(getProjects()),
  getSharedTemplates() {
    return dispatch(getSharedTemplates());
  },
  setCurrentTargetTemplate(target) {
    dispatch(setTargetQueryFromSavedTargets(target.data));
  },
  selectOption(template) {
    dispatch(restoreTargetTemplate(template.data));
    dispatch(changeTargetTemplatesMenu('selected'));
    dispatch(setCurrentTargetTemplate({ id: template._id, title: template.title }));
  },
  setCurrentTarget({ data, title, _id }) {
    dispatch(setTargetTitle(title));
    dispatch(setTargetQueryFromSavedTargets(data));
    dispatch(changeTargetTemplatesMenu('edit'));
    dispatch(toggleTargetBuilderView('templates'));
    dispatch(getTargetSize('target2', data));
    dispatch(copyTarget('target2'));
    dispatch(changeAllCardTarget2AndBase());
    dispatch(updateCharts());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TargetDropdown);

const TextTrigger = styled.div`
  color: #19A2C5;
  font-size: 14px;
  margin-top: 10px;
  font-family: Roboto;
  cursor: pointer;
`;

const SharedIcon = styled.p`
  color: #90A4AE;
  display: inline-block;
  padding: 0px;
  margin-left: 10px;
`;
const ApplyOption = styled.div`
  display: flex;
  justify-content: flex-end;
`;

import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserFriends, faAngleDown, faAngleUp, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import { getProjects, getSharedTemplates } from '../../actions/projectActions';
import {
  restoreBasicBanner,
  restoreAdvancedBanner,
  setCurrentBannerTemplate,
  setEditingTo,
  setCurrentBannerTab,
  setBanner,
} from '../../actions/bannerActions';
import { loadCharts } from '../../actions/overviewChartActions';

const styles = {
  item: {
    root: 'targetDropdown',
    selected: 'selected',
  },
  button: {
    root: 'applyButton',
    disabled: 'disabled',
  },
};

const iconStyle = {
  color: '#90A4AE',
};

class BannerDropdown extends React.Component {
  state = {
    open: false,
    loading: true,
    selected: {
      title: 'Select a banner',
    },
  };

  componentDidMount() {
    const { getProjects, getSharedTemplates } = this.props;
    Promise.all([getProjects, getSharedTemplates]).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillReceiveProps({ currentTemplate }) {
    this.setState({
      selected: currentTemplate.banner,
    }, () => {
      if (currentTemplate.autoload) {
        const { loadData, setCurrentBannerTemplate } = this.props;
        const { selected } = this.state;
        this.setState({ open: false });
        setCurrentBannerTemplate(selected);
        loadData();
      }
    });
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };


  handleMenuItemClick = (e, _id) => {
    const { banners, setBanner, setCurrentBannerTemplate } = this.props;
    const banner = banners[_id];
    this.setState({
      selected: banner,
    });
    if (banner.bannerType === 'basic') {
      setBanner(banner.bannerType, banner.data);
    } else if (banner.bannerType === 'advanced') {
      setBanner(banner.bannerType, banner.data.blocks);
    }
    setCurrentBannerTemplate(banner);
  }

  applyClick = () => {
    const { selected } = this.state;
    const { setCurrentBannerTemplate, loadData } = this.props;
    this.setState({ open: false });
    setCurrentBannerTemplate(selected);
    loadData();
  }

  renderList = () => {
    const { banners } = this.props;
    const { selected } = this.state;
    if (_.size(banners) !== 0) {
      return _.map(banners, ({
        title, _id, shared, user,
      }) => (
        <MenuItem
          key={_id}
          classes={styles.item}
          selected={selected._id === _id}
          onClick={(e) => { this.handleMenuItemClick(e, _id); }}
        >
          {title}
          {
            ((this.props.user._id !== _.get(user,'_id')) && shared)
            && (
              <SharedIcon>
                <FontAwesomeIcon icon={faUserFriends} style={iconStyle} />
              </SharedIcon>
            )
          }
        </MenuItem>
      ));
    }
    return (
      <MenuItem
        classes={styles.item}
      >
        You have no saved templates.
      </MenuItem>
    );
  }

  render() {
    const { open, loading } = this.state;
    const { banners, currentTemplate } = this.props;
    const disableButton = _.size(banners) === 0;
    return (
      <div>{
        loading
          ? (
            <div>
              <BannerContainer>
                <p style={{ color: 'rgba(38,50,56,0.45)' }}>Loading templates...</p>
                <p>
                  <FontAwesomeIcon icon={faSpinner} style={iconStyle} spin />
                </p>
              </BannerContainer>
            </div>
          ) : (
            <div style={{ width: '100%', position: 'relative' }}>
              <BannerContainer
                innerRef={(node) => {
                  this.anchorEl = node;
                }}
                onClick={this.handleToggle}
              >
                {currentTemplate.title ? <p>{currentTemplate.title}</p> : <p style={{ color: 'rgba(38,50,56,0.45)' }}>Select template</p> }
                <p>
                  <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} style={iconStyle} />
                </p>
              </BannerContainer>
              <Dropdown open={open} anchorEl={this.anchorEl}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {
                this.renderList()
              }
                    <Divider />
                    <ApplyOption>
                      <Button disabled={disableButton} onClick={this.applyClick} classes={styles.button}>
                  Apply
                      </Button>
                    </ApplyOption>
                  </MenuList>
                </ClickAwayListener>
              </Dropdown>
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  const { sharedTemplates, projects } = store.ProjectReducer;
  const allTemplates = { ...projects, ...sharedTemplates };
  const bannerTemplates = _.filter(allTemplates, (tmp => tmp.subType === 'QUESTION'));
  return {
    banners: _.keyBy(bannerTemplates, '_id'),
    user: store.AppReducer.user,
    currentTemplate: store.bannerReducer.currentTemplate,
  };
};

const mapDispatchToProps = dispatch => ({
  loadData: () => dispatch(loadCharts()),
  getProjects: () => dispatch(getProjects()),
  getSharedTemplates: () => dispatch(getSharedTemplates()),
  setBanner: (type, data) => dispatch(setBanner(type, data)),
  setCurrentBannerTemplate(banner) {
    const {
      bannerType, data,
    } = banner;
    if (bannerType === 'basic') {
      dispatch(restoreBasicBanner(data));
    } else if (bannerType === 'advanced') {
      dispatch(restoreAdvancedBanner(data));
    }
    dispatch(setCurrentBannerTemplate(banner));
    dispatch(setEditingTo(false));
    dispatch(setCurrentBannerTab(1));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(BannerDropdown);

const Dropdown = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  z-index: 5;
  background-color: white;
  position: absolute;
  top: 36px;
  left: 1;
  width: 100%;
  height: auto;
  border: 1px solid #DDDDDD;
  width: 100%;
  ul {
    padding: 0;
  }
  transition: all 1s ease-in-out;
`;
const BannerContainer = styled.div`
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  font-family: Roboto;
  border: 1px solid #DDDDDD;
  cursor: pointer;
  p {
    margin: 0px 15px;
  }
`;

const SharedIcon = styled.p`
  color: #90A4AE;
  display: inline-block;
  padding: 0px;
  margin-left: 10px;
`;
const ApplyOption = styled.div`
  display: flex;
  justify-content: flex-end;
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import CheckBoxComponent from './CheckBoxModal';

class CumeTotalModal extends Component {
  constructor(props) {
    super(props);
    const { BannerData } = this.props;
    this.state = {
      lastBannerData: null,
      selectedAll: false,
      removeCumeChecked: false,
      BannerData,
    };
  }

  handleCheckbox = (a, b, {
    answerCode, mergedRow, mergedRowListCodes, idQueryMergeRow,
  }) => {
    const { removeCumeChecked, BannerData } = this.state;
    if (!removeCumeChecked) {
      const immutableBannerData = _.cloneDeep(BannerData);
      const copy = _.cloneDeep(BannerData).BannerDataPoints.map((datapoint) => {
        const immutableDataPoint = _.cloneDeep(datapoint);
        const condition = mergedRow
          ? mergedRowListCodes.includes(immutableDataPoint.answerCode)
          : immutableDataPoint.answerCode === answerCode;
        if (mergedRow && immutableDataPoint.mergedRow
            && immutableDataPoint.idQueryMergeRow === idQueryMergeRow) {
          immutableDataPoint.selectedForCumeTotal = !immutableDataPoint.selectedForCumeTotal;
        }
        if (condition) {
          immutableDataPoint.selectedForCumeTotal = !immutableDataPoint.selectedForCumeTotal;
        }
        return immutableDataPoint;
      });
      immutableBannerData.BannerDataPoints = copy;
      this.setState({
        BannerData: immutableBannerData,
      });
    }
  }

    handleSelectAllCheckBox = () => {
      const { BannerData, selectedAll, removeCumeChecked } = this.state;
      if (!removeCumeChecked) {
        let copy = _.cloneDeep(BannerData);
        copy.BannerDataPoints.forEach((datapoint) => {
          datapoint.selectedForCumeTotal = !selectedAll;
        })
        this.setState({
          BannerData: copy,
          selectedAll: !selectedAll,
          removeCumeChecked: selectedAll ? false : removeCumeChecked,
        });
      }
    }

    handleCalculateCumeTotal = () => {
      const { functions, closeCumeTotalModal, position } = this.props;
      const { BannerData } = this.state;
      functions.addCumeTotal(position, BannerData);
      closeCumeTotalModal();
    }

  handleRemoveCumeTotalFromBlock = (pos, event) => {
    const { BannerData } = this.state;
    const copy = _.cloneDeep(BannerData);
    const { checked } = event.target;
    const lastBannerDataCopy = _.cloneDeep(BannerData);
    if (checked) {
      copy.BannerDataPoints.forEach((datapoint) => {
        datapoint.selectedForCumeTotal = false;
      });
      this.setState({
        lastBannerData: lastBannerDataCopy,
        BannerData: copy,
        selectedAll: false,
        removeCumeChecked: checked,
      });
    } else {
      const { lastBannerData } = this.state;
      if (lastBannerData) {
        this.setState({
          lastBannerData: null,
          BannerData: lastBannerData,
          selectedAll: false,
          removeCumeChecked: checked,
        });
      } else {
        this.setState({
          lastBannerData: null,
          BannerData: copy,
          selectedAll: false,
          removeCumeChecked: checked,
        });
      }
    }
  };

  getDataPointsCodeMerged = (data = []) => {
    const dataPointCodes = [];
    data.forEach((dt) => {
      if (dt.answerCode) {
        dataPointCodes.push(dt.answerCode);
      } else if (dt.mergedRow && dt.mergedRowList && (dt.mergedRowList.length > 0)) {
        const d = this.getDataPointsCodeMerged(dt.mergedRowList);
        dataPointCodes.push(d);
      }
    });
    return _.flattenDeep(dataPointCodes);
  }

  getDatapointsCheckBoxes = () => {
    const { BannerData } = this.state;
    return BannerData.BannerDataPoints.map((datapoint, i) => {
      if (datapoint.combinedRow === false
          && datapoint.isVisible === true) {
        return (
          <tr key={i}>
            <td>
              <CheckBoxComponent
                handleCheckbox={this.handleCheckbox}
                pos={i}
                mergedRow={datapoint.mergedRow}
                answerCode={datapoint.answerCode}
                checkBoxText={datapoint.name}
                idQueryMergeRow={datapoint.idQueryMergeRow}
                isChecked={datapoint.selectedForCumeTotal}
                mergedRowListCodes={this.getDataPointsCodeMerged(datapoint.mergedRowList)}
              />
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  render() {
    const { editCumeTotal } = this.props;
    const { removeCumeChecked } = this.state;
    return (
      <ModalContainer>
        <ModalHeader>
          {
            editCumeTotal
              ? 'Edit Cume Total'
              : 'Select Data-Points for Cume Total'
          }
        </ModalHeader>
        <ModalBody>
          <br/>
          <table style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td>
                  <CheckBoxComponent handleCheckbox={this.handleSelectAllCheckBox} checkBoxText="Select All" isChecked={this.state.selectedAll}/>
                </td>
              </tr>
              {this.getDatapointsCheckBoxes()}
            </tbody>
          </table>
          {
            editCumeTotal
              && (
              <div>
                <Line />
                <CheckBoxComponent
                  handleCheckbox={this.handleRemoveCumeTotalFromBlock}
                  pos="remove"
                  checkBoxText="Remove Cume Total from Block"
                  isChecked={removeCumeChecked}
                />
              </div>
              )
          }
        </ModalBody>
        {this.props.type === 1 ? <ModalBody2><CheckBoxComponent handleCheckbox={this.handleIsCombined} checkBoxText="Combine selected in single sheet" /></ModalBody2> : null}
        <ModalFooter>
          <AddButton onClick={this.handleCalculateCumeTotal}>
            {
              editCumeTotal
                ? 'Update'
                : 'Calculate'
            }
          </AddButton>
          <CancelButton onClick={this.props.closeCumeTotalModal}>
            Cancel
          </CancelButton>
        </ModalFooter>
      </ModalContainer>
    )
  }
}

const ModalContainer = styled.div`
    width:400px;
    font-size:20px;  
    font-family: Roboto;
    font-weight:500;
    color: #37474F;
    background-color:white;
    height: 520px;
    border-radius: 10px;
`;

const ModalHeader = styled.div`   
    padding: 10px;     
    border-bottom: 1pt solid #607D8B;
    height:30px;
`;

const ModalBody = styled.div`
    margin-bottom:10px;
    padding:10px;
    height:390px;
    overflow-y:auto;
`;

const ModalBody2 = styled.div`
    border-top: 1pt solid #607D8B;
    padding:10px;
`;

const ModalFooter = styled.div`
    border-top: 1pt solid #607D8B;
    background-color: #F6F7F8;
    height:50px;
    margin-top:10px;
    border-radius: 0px 0px 10px 10px;
`;

const CancelButton = styled.button`
    height:32px;
    color: #607D8B;   
    background-color:#CFD8DC;
    padding: 0px 15px 0px 15px;
    border-radius: 2px;
    border: 1pt solid #CFD8DC;
    cursor: pointer;
    margin: 10px 15px 0px 0px;
    float: right;
`;

const AddButton = styled.button`
    height:32px;
    color: #FFFFFF;   
    background-color:#607D8B;
    padding: 0px 15px 0px 15px;
    border-radius: 2px;
    border: 1pt solid #607D8B;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
    float: right;
`;

const Line = styled.hr`
  border: none;
  border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export default CumeTotalModal;
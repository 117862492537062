import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Category from './Category';
import {cloneDeep} from 'lodash';
import {
  openCategory,
  closeCategory,
  openSubcategory,
  closeSubcategory,
  openQuestion,
  closeQuestion,
} from '../../../actions/breadcrumbActions';

class CategoryTree extends Component {
  render() {
    const {
      blockId,
      categories,
      actions,
      activeCollapsibles,
      openCategory,
      closeCategory,
      openSubcategory,
      closeSubcategory,
      openQuestion,
      closeQuestion,
    } = this.props;
    const clonedCategories = cloneDeep(categories);
    const handlers = {
      category: { open: openCategory, close: closeCategory },
      subcategory: { open: openSubcategory, close: closeSubcategory },
      question: { open: openQuestion, close: closeQuestion },
    };
    return (
      <Container>
        {clonedCategories.map((cat, index) => (
          <Category
            key={index}
            category={cat}
            blockId={blockId}
            actions={actions}
            activeCollapsibles={activeCollapsibles}
            handlers={handlers}
          />
        ))}
      </Container>
    );
  }
}

CategoryTree.propTypes = {
  blockId: PropTypes.number.isRequired,
  categories: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  activeCollapsibles: PropTypes.object.isRequired,
  openCategory: PropTypes.func.isRequired,
  closeCategory: PropTypes.func.isRequired,
  openSubcategory: PropTypes.func.isRequired,
  closeSubcategory: PropTypes.func.isRequired,
  openQuestion: PropTypes.func.isRequired,
  closeQuestion: PropTypes.func.isRequired,
};

const Container = styled.div`
  background: white;
  width: 100%;
  position: relative;
`;

const mapStateToProps = state => ({
  categories: state.categoriesReducer.categories,
  activeCollapsibles: state.breadcrumbReducer,
});

const mapDispatchToProps = dispatch => ({
  openCategory: id => dispatch(openCategory(id)),
  closeCategory: id => dispatch(closeCategory(id)),
  openSubcategory: id => dispatch(openSubcategory(id)),
  closeSubcategory: id => dispatch(closeSubcategory(id)),
  openQuestion: id => dispatch(openQuestion(id)),
  closeQuestion: id => dispatch(closeQuestion(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTree);

import React, { Component } from 'react';
import DocumentViewer from './DocumentViewer';
import NavigationBar from '../GlobalComponents/NavigationBar';

class Documents extends Component {

  componentDidMount() {
    document.title = 'Stowell Data | Documents';
  }

  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <DocumentViewer />
      </React.Fragment>
    );
  }
}

export default Documents;

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOG_ID = 'LOG_IN';
export const ALREADY_LOGGED = 'ALREADY_LOGGED';
export const LOG_OUT = 'LOG_OUT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const SET_DATABASE = 'SET_DATABASE';
export const SET_ACCOUNT_FOLDERNAME = 'SET_ACCOUNT_FOLDERNAME';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const SET_ADMIN_DATABASES = 'SET_ADMIN_DATABASES';
export const SET_ACCOUNT_ID = 'SET_ACCOUNT_ID';

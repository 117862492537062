import React, { Component } from 'react';
import './CheckBoxStyle.css';
import { faLock, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Axios from 'axios';
import {
  FormControl, ControlLabel, FormGroup, Form, Row, Col,
} from '../GlobalComponents/BasicControls';
import {
  Login, setUser, setDatabase, setAccountFoldername, setAccountId,
} from '../../actions/appActions';

const checkBoxLabelStyle = {
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: 400,
  color: '#37474F',
};
const buttonStyle = {
  backgroundColor: '#607D8B',
  border: 'none',
  color: '#FFFFFF',
  height: '32px',
  padding: '0 15px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '13px',
  margin: '4px 2px',
  cursor: 'pointer',
  fontFamily: 'Roboto',
  fontWeight: '500',
  borderRadius: '2px',
  maxHeight: '32px',
};

const buttonStyleLoading = {
  ...buttonStyle,
  backgroundColor: '#7ba0b2',
};

class BodyContainerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      password: '',
      errors: this.getInitialStateForErrors(),
    };

    // This binding is necessary to make `this` work in the callback
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  getInitialStateForErrors = () => ({
    top: {
      open: false,
      message: 'Error',
    },
    email: {
      open: false,
      message: 'Error',
    },
    password: {
      open: false,
      message: 'Error',
    },
  })

  handleChangePassword(evt) {
    this.setState({ password: evt.target.value });
  }

  saveDatabaseLocally = (db) => {
    this.props.setDatabase(db.folderName);
    localStorage.setItem('currentDatabase', db.folderName);
  }

  saveFoldernameLocally = (folderName) => {
    this.props.setAccountFoldername(folderName);
    localStorage.setItem('currentAccountFolder', folderName);
  }

  saveAccountIdLocally = (id) => {
    this.props.setAccountId(id);
    localStorage.setItem('currentAccountId', id);
  }

  validateUserEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  hideALlErrorMessages = () => {
    this.setState({
      errors: this.getInitialStateForErrors(),
    });
  }

  setErrorMessage = (data) => {
    const { errors } = this.state;
    this.setState({
      loading: false,
      errors: {
        ...errors,
        ...data,
      },
    });
  }

   handleClick = async (e) => {
     this.hideALlErrorMessages();
     this.setState({ loading: true });
     e.preventDefault();
     const { email, password, errors } = this.state;
     const isAValidEmail = this.validateUserEmail(email);
     if (isAValidEmail) {
       const resp = await this.props.dispatchLogin({
         email,
         password,
       });
       if (resp.success) {
         const response = await Axios.get('/api/users/current');
         const currentUser = response.data.user;
         this.props.setUser(currentUser);
         if (currentUser.role.name !== 'admin') {
           this.saveFoldernameLocally(currentUser.teamId.folderName);
           this.saveAccountIdLocally(currentUser.teamId._id);
           if (currentUser.teamId.databases.length === 0) alert('You do not have any database to work for');
           if (currentUser.teamId.databases.length < 2) {
             if (currentUser.teamId.databases.length === 1) {
               this.saveDatabaseLocally(currentUser.teamId.databases[0]);
               this.props.history.push('/projects');
             }
           } else {
             this.props.history.push('/database-selector');
           }
         } else {
           this.props.history.push('/accounts');
         }
       } else {
         const { type, message, unknown } = resp;
         if (type) {
           if (type === 'email') {
             const data = {
               email: {
                 open: true,
                 message,
               },
             };
             this.setErrorMessage(data);
           }
           if (type === 'password') {
             const data = {
               password: {
                 open: true,
                 message,
               },
             };
             this.setErrorMessage(data);
           }
         }
         if (unknown && !type) {
           const data = {
             top: {
               open: true,
               message,
             },
           };
           this.setErrorMessage(data);
         }
       }
     } else {
       this.setState({
         loading: false,
         errors: {
           ...errors,
           email: {
             open: true,
             message: 'Please enter a valid email address.',
           },
         },
       });
     }
   }

   handleChangeEmail(evt) {
     this.setState({ email: evt.target.value });
   }

   render() {
     const { loading, errors } = this.state;
     return (
       <Form
         onSubmit={this.handleClick}
         style={{
           width: '100%',
         }}
       >
         {
          errors.top.open
          && (
            <Span
              style={{
                marginBottom: '12px',
              }}
            >{errors.top.message}
            </Span>
          )
        }
         <BodyContainerTitle>
           Sign In
         </BodyContainerTitle>
         <P>Sign into your account to access your company's Stowell Data database.</P>
         <Row>
           <Col
             size={12}
             style={{
               padding: '0 1% 10px 0',
             }}
           >
             <FormGroup>
               <Label>
                 Email address
               </Label>
               <InputText
                 required
                 id="email"
                 type="text"
                 onChange={this.handleChangeEmail}
               />
             </FormGroup>
             {
               errors.email.open
               && (
                 <Span>{errors.email.message}</Span>
               )
             }
           </Col>
         </Row>
         <Row>
           <Col
             size={12}
             style={{
               padding: '0 1% 9px 0',
             }}
           >
             <FormGroup>
               <Label>
                 Password (must include at least one number)
               </Label>
               <InputText
                 required
                 id="password"
                 type="password"
                 onChange={this.handleChangePassword}
               />
             </FormGroup>
             {
               errors.password.open
               && (
                 <Span>{errors.password.message}</Span>
               )
             }
           </Col>
         </Row>
         <Row>
           <Col
             size={12}
             style={{
               padding: '0 1% 10px 0',
             }}
           >
             <label className="container">
               <span style={checkBoxLabelStyle}>
                 &nbsp;Keep me signed in
               </span>
               <input type="checkbox" />
               <CheckMark
                 id="checkmark"
                 className="checkmark"
               />
             </label>
             <NewLink to="/password-recovery">Forgot password?</NewLink>
           </Col>
         </Row>
         <Row>
           <Col
             size={12}
             style={{
               padding: '0 1% 15px 0',
             }}
           >
             <button type="submit" disabled={loading} style={loading ? buttonStyleLoading : buttonStyle}>
               <span>
                 <FontAwesomeIcon
                   icon={loading ? faSync : faLock}
                   spin={loading}
                   style={{
                     marginRight: '10px',
                   }}
                 />
                 {loading ? 'Signing in' : 'Sign in'}
               </span>
             </button>
           </Col>
         </Row>
       </Form>
     );
   }
}

const Label = styled(ControlLabel)`
  color: #607D8b;
  font-size: 13px;
`;

const InputText = styled(FormControl)`
  font-size: 13px;
`;

const P = styled.p`
  padding: 0;
  font-size: 13px;
  margin-top: 0;
`;

const Span = styled.p`
  height: 20px;
  color: #A31023;
  font-family: Roboto;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin: 2px 0 0px 0;
`;

const NewLink = styled(Link)`
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #19A2C5;
  display: inline-block;
  float: right;
  text-decoration: none;
  &:hover {
    color: #117189;
  }
`;

const BodyContainerTitle = styled.div`
    font-size:20px;    
    font-family: 'Roboto', sans-serif;
    font-weight:500;
    color: #37474F;    
    margin-bottom:5px;
    ${''}
`;

const CheckMark = styled.span`
  border: 0.5px solid #90A4AE !important;
`;

const mapStateToProps = store => ({
  user: store.AppReducer.user,
});

const mapDispatchToProps = {
  dispatchLogin: Login,
  setUser,
  setDatabase,
  setAccountFoldername,
  setAccountId,
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BodyContainerForm));

import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Title,
  Button,
} from '../../../GlobalComponents/BasicControls';
import showNotification from '../../../../helpers/showNotification';

export class AccountDetails extends Component {
  state = {
    disableOnSaving: false,
  }

  handleInputText = (e) => {
    const { value, name } = e.target;
    this.props.setState({
      account: {
        ...this.props.content.account,
        details: {
          ...this.props.state,
          [name]: value,
        },
      },
    });
  }

  editAccountDetails = (e) => {
    this.setState({ disableOnSaving: true });
    const { name, city, state } = this.props.state;
    const data = {
      name,
      city,
      state,
    };
    this.props.handleEditAccountDetails(this.props.Id, data, e)
      .then(() => {
        showNotification({message: 'Account updated successfully', type: 'success'});
        this.setState({
          disableOnSaving: true,
        });
        this.props.setState({
          selectedItemNav: 6,
        });
      })
      .catch(() => {
        showNotification({message: 'Error updating account', type: 'error'});
        this.setState({
          disableOnSaving: true,
        });
      });
  }

  handleSubmit = (e, edit) => {
    e.preventDefault();
    edit === 'edit'
      ? this.editAccountDetails(e)
      : this.props.handleSubmit(e);
  }

  onCancelCreatingAndEditing = () => {
    const { type, onCancelCreating, onCancelEditing } = this.props;
    if (type === 'edit') {
      onCancelEditing();
    } else {
      onCancelCreating();
    }
  }

  render() {
    const { name, city, state } = this.props.state;
    const { type } = this.props;
    const { disableOnSaving } = this.state;
    return (
      <Body>
        <Form onSubmit={e => this.handleSubmit(e, type)}>
          <Title>
            Account Details
          </Title>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                  Account name
                </ControlLabel>
                <FormControl
                  autoComplete="off"
                  autoFocus
                  required
                  type="text"
                  name="name"
                  value={name}
                  onChange={e => this.handleInputText(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col size={9}>
              <FormGroup>
                <ControlLabel>
                  City
                </ControlLabel>
                <FormControl
                  autoComplete="off"
                  required
                  type="text"
                  name="city"
                  value={city}
                  onChange={e => this.handleInputText(e)}
                />
              </FormGroup>
            </Col>
            <Col size={3}>
              <FormGroup>
                <ControlLabel>
                  State
                </ControlLabel>
                <FormControl
                  autoComplete="off"
                  required
                  type="text"
                  name="state"
                  value={state}
                  onChange={e => this.handleInputText(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col size={2}>
              <Button
                onClick={this.onCancelCreatingAndEditing}
                type="button"
              >
                Cancel
              </Button>
            </Col>
            <Col size={2}>
              <Button
                disabled={disableOnSaving}
                tColor="white"
                bgColor="#455A64"
              >
                {this.props.type === 'edit' ? 'Save' : 'Continue'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Body>
    );
  }
}

const Body = styled.div`
  ${''}
  width: 47%;
  padding: 0 3%;
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import Proptypes from 'prop-types';
import { ToolbarButton } from './ToolbarButtons/ToolbarButton';

import ToolBarButtonMerge from './ToolbarButtons/ToolbarButtonMerge';
import ToolbarButtonRemove from './ToolbarButtons/ToolbarButtonRemove';
import ToolbarButtonPercentage from './ToolbarButtons/ToolbarButtonPercentage';
import ToolbarButtonThousands from './ToolbarButtons/ToolbarButtonThousands';
import ToolbarButtonIndex from './ToolbarButtons/ToolbarButtonIndex';
import ToolbarButtonChart from './ToolbarButtons/ToolbarButtonChart';
import CurrentSurveyIcon from '../../assets/currentSurvey.png';

import { getSurveys } from '../../actions/documentActions';


class ToolBar extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const { getSurveys } = this.props;
    getSurveys();
  }


  openSurvey = () => {
    const { survey } = this.props;
    if (!_.isEmpty(survey)) {
      const file = new Blob([survey.file], { type: 'application/pdf' });
      const fileURL = window.URL.createObjectURL(file);
      window.open(fileURL, `${survey.fileName}`);
    }
  }

  render() {
    const {
      showPercentage,
      showIndex,
      functions,
      Data,
      showThousands,
      currentTab,
      DataBlocksSelected,
      canMergeRows,
      canUnmergeBlock,
      canUnmergeRow,
      canMergeBlocks,
      canRemoveRows,
    } = this.props;
    return (
      <ToolBarContainer>
        <div className="button-actions">
          <ToolbarButtonPercentage
            showPercentage={showPercentage}
            functions={functions}
          />
          <ToolbarButtonThousands
            showThousands={showThousands}
            functions={functions}
          />
          <ToolbarButtonIndex
            showIndex={showIndex}
            functions={functions}
            Data={Data}
            currentTab={currentTab}
          />
          <Separator />
          <ToolBarButtonMerge
            functions={functions}
            DataBlocksSelected={DataBlocksSelected}
            canMergeRows={canMergeRows}
            canUnmergeBlock={canUnmergeBlock}
            canUnmergeRow={canUnmergeRow}
            canMergeBlocks={canMergeBlocks}
          />
          <ToolbarButtonRemove
            functions={functions}
            canRemoveRows={canRemoveRows}
          />
          <Separator />
          <ToolbarButtonChart
            functions={functions}
            Data={Data}
            currentTab={currentTab}
          />
        </div>
        <div className="button-survey">
          <ToolbarButton
            leftIcon={{
              icon: CurrentSurveyIcon,
              type: 'img',
            }}
            text="View Current Survey"
            onClick={this.openSurvey}
          />
        </div>
      </ToolBarContainer>
    );
  }
}

ToolBar.propTypes = {
  getSurveys: Proptypes.func.isRequired,
  survey: Proptypes.object.isRequired,
  showPercentage: Proptypes.bool.isRequired,
  showIndex: Proptypes.bool.isRequired,
  functions: Proptypes.object.isRequired,
  Data: Proptypes.object.isRequired,
  showThousands: Proptypes.bool.isRequired,
  currentTab: Proptypes.number.isRequired,
  DataBlocksSelected: Proptypes.number.isRequired,
  canMergeRows: Proptypes.bool.isRequired,
  canUnmergeBlock: Proptypes.bool.isRequired,
  canUnmergeRow: Proptypes.bool.isRequired,
  canMergeBlocks: Proptypes.bool.isRequired,
  canRemoveRows: Proptypes.bool.isRequired,

};

const ToolBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #DEE1E3;
  border-bottom: 1px solid #DEE1E3;
  height: 30px;
  padding: 3px 5px;
  .button-actions {
    height: 25px;
    display: flex;
  }
  .button-survey {
    height: 25px;
  }
`;

const Separator = styled.span`
  border-right: 2px solid #CFD8DC;    
`;


const mapStateToProps = store => ({
  survey: store.DocumentReducer.currentSurvey,
});
const mapDispatchToProps = dispatch => ({
  getSurveys: () => dispatch(getSurveys()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);

import axios from 'axios';
import _ from 'lodash';
import { cloneDeep, uniqueId } from 'lodash';
import {
  LOAD_OVERVIEW_CHARTS,
  ADD_OVERVIEW_CHARTS,
  UPDATE_OVERVIEW_CHARTS,
  DELETE_OVERVIEW_CHARTS,
  CLEAR_OVERVIEW_CHARTS,
  DELETE_OVERVIEW_CHART,
  MOVE_OVERVIEW_CHARTS,
  RESTORE_OVERVIEW_CHARTS,
  SET_EDITING_OVERVIEW_CHART,
  SET_USED_OVERVIEW_BANNER,
  UPDATE_OVERVIEW_CHART,
} from '../actionTypes/overviewChartTypes';
import {
  ADD_OVERVIEW_CARD,
  DELETE_OVERVIEW_CARD,
  CHANGE_OVERVIEW_CARD_TARGET,
} from '../actionTypes/overviewCardTypes';
import showNotification from '../helpers/showNotification';

export const deleteCharts = amount => ({
  type: DELETE_OVERVIEW_CHARTS,
  payload: amount,
});

const fetchChartsData = async (dispatch, getState, charts, currentBanner, callback) => {
  const { targetReducer, AppReducer, marketReducer } = getState();
  const { baseTarget, target2 } = targetReducer;
  const { currentSurvey: { year } } = marketReducer;
  const account = AppReducer.currentFolderName;
  const database = AppReducer.currentDatabase;
  const requests = [];
  const baseRequest = {
    account,
    database,
    target: baseTarget.query,
    targetIndex: 1,
    targetName: 'Base Target',
    banner: currentBanner,
    year,
  };
  return axios
    .post('/api/complexFilterByYear', baseRequest)
    .then((baseResponse) => {
      requests.push(baseResponse.data);
      if (target2.query) {
        const target2Request = {
          account,
          database,
          target: target2.query,
          banner: currentBanner,
          year,
        };
        return axios
          .post('/api/complexFilterByYear', target2Request);
      }
      return Promise.resolve(null);
    })
    .then((target2Response) => {
      if (target2Response) {
        requests.push(target2Response.data);
      }
      const params = {
        charts,
        requests,
        dispatch,
        year,
      };
      return callback(params, false);
    })
    .catch((err) => {
      const params = {
        charts,
        requests,
        dispatch,
        year,
      };
      return callback(params, err);
    });
};


export const loadCharts = () => async (dispatch, getState) => {
  const { currentBanner, currentTemplate } = getState().bannerReducer;
  const { target2 } = getState().targetReducer;
  const haveStructureBanner = (currentTemplate.banner.dataStructureBanner);
  const charts = [];
  if (!haveStructureBanner) {
    currentBanner.forEach((banner) => {
      let { title } = banner.data;
      if (banner.type === 'complex') {
        title = currentTemplate.title;
      }
      const chart = {
        id: uniqueId(),
        title,
        data: [],
        banner,
      };
      dispatch({ type: ADD_OVERVIEW_CARD, payload: chart.id });
      dispatch({
        type: CHANGE_OVERVIEW_CARD_TARGET,
        payload: { id: chart.id, target: target2.query ? 2 : 0 },
      });
      charts.push(chart);
    });
    dispatch({ type: LOAD_OVERVIEW_CHARTS, payload: charts });
    const chartsClone = cloneDeep(charts);
    fetchChartsData(dispatch, getState, chartsClone, currentBanner, (params, err) => {

      if (!err) {
        const newCharts = generateCharts(params);
        dispatch({ type: LOAD_OVERVIEW_CHARTS, payload: newCharts });
      } else {
        showNotification({ message: err.message, type: 'error' })
        dispatch(deleteCharts(charts.length));
      }


    });
  } else {
    const curatedBannersData = currentTemplate.banner.bannerType === 'basic_block_merged' ? createBasicBannerQuery(addDataForm(currentTemplate.banner.dataStructureBanner[0].Banners[0].mergedBlockList)) : createBasicBannerQuery(currentTemplate.banner.dataStructureBanner[0].Banners[0].bannerDataStructure.dataForm);
    const overviewData = currentTemplate.banner.dataStructureBanner[0];
    const dataPoints = getDataPoints(overviewData);
    const banners = getBanners(overviewData);
    const bannersData = getBannersData(overviewData);
    const targets = getTargets(overviewData);
    const chart = {
      id: uniqueId(),
      title: currentTemplate.banner.title,
      data: [],
      curatedBannersData,
    };
    dispatch({ type: ADD_OVERVIEW_CARD, payload: chart.id });
    dispatch({
      type: CHANGE_OVERVIEW_CARD_TARGET,
      payload: { id: chart.id, target: target2.query ? 2 : 0 },
    });
    charts.push(chart);

    if (targets.length === 1) {
      const chartData = getGroupedCharts(targets, dataPoints, banners, bannersData, curatedBannersData, chart.id);
      dispatch({ type: LOAD_OVERVIEW_CHARTS, payload: chartData });
    }
  }
};

export const addCharts = () => (dispatch, getState) => {
  const { currentBanner, currentTemplate } = getState().bannerReducer;
  const { target2 } = getState().targetReducer;
  const charts = [];
  currentBanner.forEach((banner) => {
    let { title } = banner.data;
    if (banner.type === 'complex') {
      title = currentTemplate.title;
    }
    const chart = {
      id: uniqueId(),
      title,
      data: [],
      banner,
    };
    dispatch({ type: ADD_OVERVIEW_CARD, payload: chart.id });
    dispatch({
      type: CHANGE_OVERVIEW_CARD_TARGET,
      payload: { id: chart.id, target: target2.query ? 2 : 0 },
    });
    charts.push(chart);
  });
  dispatch({ type: ADD_OVERVIEW_CHARTS, payload: charts });
  // const chartsClone = cloneDeep(charts);
  fetchChartsData(dispatch, getState, charts, currentBanner, (params, err) => {
    if (!err) {
      const charts = generateCharts(params);
      dispatch(deleteCharts(charts.length));
      dispatch({ type: ADD_OVERVIEW_CHARTS, payload: charts });
    } else {
      showNotification({ message: err.message, type: 'error' })
      dispatch(deleteCharts(charts.length));
    }
  });
};

export const updateCharts = () => (dispatch, getState) => {
  const { charts } = getState().overviewChartReducer;
  const banners = [];
  const newCharts = charts.map((chart) => {
    chart.data = [];
    banners.push(chart.banner);
    return chart;
  });
  dispatch({ type: UPDATE_OVERVIEW_CHARTS, payload: charts });

  fetchChartsData(dispatch, getState, newCharts, banners, (params, err) => {
    if (!err) {
      const charts = generateCharts(params);
      dispatch({ type: UPDATE_OVERVIEW_CHARTS, payload: charts });
    } else {
      showNotification({ message: err.message, type: 'error' })
    }
  });
};

export const updateChart = () => (dispatch, getState) => {
  const { editingChart, charts } = getState().overviewChartReducer;
  const { currentBanner } = getState().bannerReducer;
  const [banner] = currentBanner;
  const [chart] = cloneDeep(charts.filter(chart => chart.id === editingChart));
  chart.data = [];
  chart.banner = banner;
  dispatch({ type: UPDATE_OVERVIEW_CHART, payload: chart });
  fetchChartsData(dispatch, getState, [chart], [banner], (params, err) => {
    if (!err) {
      const [chart] = generateCharts(params);
      dispatch({ type: UPDATE_OVERVIEW_CHART, payload: chart });
    } else {
      const [chart] = charts.filter(chart => chart.id === editingChart);
      dispatch({
        type: UPDATE_OVERVIEW_CHART,
        payload: chart,
      });
      showNotification({ message: err.message, type: 'error' })
    }
  });
};

export const deleteChart = id => (dispatch) => {
  dispatch({ type: DELETE_OVERVIEW_CHART, payload: id });
  dispatch({ type: DELETE_OVERVIEW_CARD, payload: id });
};

export const setEditingChart = id => ({ type: SET_EDITING_OVERVIEW_CHART, payload: id });
export const setUsedBanner = payload => ({ type: SET_USED_OVERVIEW_BANNER, payload });
export const setCharts = charts => ({ type: RESTORE_OVERVIEW_CHARTS, payload: charts });
export const moveChart = payload => ({ type: MOVE_OVERVIEW_CHARTS, payload });
export const clearCharts = () => ({ type: CLEAR_OVERVIEW_CHARTS });

function generateCharts(params) {
  const {
    charts,
    requests,
    year,
  } = params;
  charts.forEach((chart, index) => {
    const { banner: { data: { includes } } } = chart;
    const baseData = requests[0][index][year];
    const baseChart = getChartData(baseData, includes);
    chart.data.push(baseChart);
    if (requests.length > 1) {
      const target2Data = requests[1][index][year];
      const target2Chart = getChartData(target2Data, includes);
      const groupedChart = getGroupedChartData(baseChart, target2Chart);
      const indexData = calculateIndex(baseChart, target2Chart);
      target2Chart.index = indexData;
      groupedChart.index = indexData;
      chart.data.push(target2Chart);
      chart.data.push(groupedChart);
    }
  });
  return charts;
}

function getChartData(data, included) {
  let { average, percentages, thousands } = data;
  percentages = percentages.filter((element, index) => included.includes(index + 1));
  thousands = thousands.filter((element, index) => included.includes(index + 1));

  const newPercentages = percentages.map(element => ({
    label: element.answer,
    value: Number(element.count.toFixed(2)),
  }));
  const newThousands = thousands.map(element => ({
    label: element.answer,
    value: Number(element.count.toFixed(0)),
  }));

  if (average && !Number.isNaN(average)) {
    average = Number(average.toFixed(2));
  } else {
    average = '';
  }

  const chartData = {
    type: 'single',
    percentages: [newPercentages],
    thousands: [newThousands],
    average,
  };
  return chartData;
}

function getGroupedChartData(baseChart, target2Chart) {
  const chartData = {
    type: 'grouped',
    percentages: [],
    thousands: [],
    index: [],
    average: [],
  };

  chartData.percentages[0] = baseChart.percentages[0];
  chartData.percentages[1] = target2Chart.percentages[0];
  chartData.thousands[0] = baseChart.thousands[0];
  chartData.thousands[1] = target2Chart.thousands[0];

  if (baseChart.average) chartData.average[0] = baseChart.average;
  if (target2Chart.average) chartData.average[1] = target2Chart.average;

  return chartData;
}

function calculateIndex(baseChart, target2Chart) {
  const [basePercentages] = baseChart.percentages;
  const [target2Percentages] = target2Chart.percentages;
  const index = [];
  for (let i = 0; i < basePercentages.length; i += 1) {
    const { label } = basePercentages[i];
    let indexValue = (target2Percentages[i].value / basePercentages[i].value) * 100;
    indexValue = Number(indexValue.toFixed(0));
    index.push({ label, value: indexValue });
  }
  return [index];
}

function getGroupedCharts(targets, dataPoints, banners, bannersData, curatedBannersData, idChart) {
  let data = [];
  let average;
  if (targets.length !== 0) {
    data = banners.map((banner, index) => {
      // const labels = dataPoints[index].dataPoints.map(dp => dp.name);
      const newData = [];
      const percentages = _.reverse(dataPoints[index].dataPoints.map((dp) => {
        const label = dp.name;
        const value = dp.value[0].percentage ? dp.value[0].percentage.toFixed(2): dp.value[0].percentage;
        return {
          label,
          value,
        };
      }));

      const thousands = _.reverse(dataPoints[index].dataPoints.map((dp) => {
        const label = dp.name;
        const value = dp.value[0].thousands ? dp.value[0].thousands.toFixed(0) : dp.value[0].thousands;
        return {
          label,
          value,
        };
      }));

      const indexData = _.reverse(dataPoints[index].dataPoints.map((dp) => {
        const label = dp.name;
        const value = dp.value[0].index ? dp.value[0].index.toFixed(0) : dp.value[0].index;
        return {
          label,
          value,
        };
      }));

      if (Array.isArray(bannersData[index].average)) {
        average = _.compact(bannersData[index].average.map((avg) => {
          if (avg) {
            return avg.toFixed(2);
          }
          return avg;
        }));
      }

      const key = {
        type: 'single',
        average,
        percentages:
          [percentages],
        thousands:
          [thousands],
        index:
          [indexData],
      };

      newData.push(key);
      const chartData = {
        id: idChart,
        title: banner,
        data: newData,
        banner: curatedBannersData[0],
      };
      return chartData;
    });
  }
  return data;
}


function getBanners(spreadSheetData) {
  const data = spreadSheetData;
  const banners = data.Banners;
  const visibleBanners = banners.filter(banner => banner.Visible && !banner.combinedBanner);
  const bannerNames = visibleBanners.map(bann => bann.BannerName);
  return bannerNames;
}

function getBannersData(spreadSheetData) {
  const data = spreadSheetData;
  const banners = data.Banners;
  const visibleBanners = banners.filter(banner => banner.Visible && !banner.combinedBanner);
  return visibleBanners;
}


function getDataPoints(spreadSheetData) {
  const data = spreadSheetData;
  const banners = data.Banners;
  const visibleBanners = banners.filter(banner => banner.Visible && !banner.combinedBanner);
  const completeDataPoints = visibleBanners.map(banner => banner.BannerDataPoints
    .filter(d => d.isVisible && !d.combinedRow));
  const dataPoints = completeDataPoints.map(dp => ({
    dataPoints: dp.map((dataP) => {
      const { name, value } = dataP;
      return {
        name,
        value,
      };
    }),
  }));
  return dataPoints;
}

function getTargets(spreadSheetData) {
  return ['Base Target'];
}

function getSingleTargetMenu(targets) {
  let payload = {};
  targets.forEach((label, index) => {
    payload = {
      ...payload,
      [index]: {
        id: index,
        name: label,
        labels: [label],
        singleTarget: true,
      },
    };
  });
  return payload;
}

function createBasicBannerQuery(questions) {
  const banners = _.map(questions, (question) => {
    const questionData = question.answers ? question : question.bannerDataStructure.dataForm[question.questionCode];
    const banner = {
      type: 'basic',
      data: {
        title: questionData.text,
        question: questionData.id,
        includes: [],
        builder: questionData,
        builderType: 'basic',
      },
    };
    _.map(questionData.answers, answer => banner.data.includes.push(answer.index));
    return banner;
  });
  return banners;
}

function addDataForm(banner) {
  const data = [];
  banner.map((bannerMerge) => {
    data[bannerMerge.questionCode] = { ...bannerMerge.bannerDataStructure.dataForm[bannerMerge.questionCode] };
  });
  return data;
}

import React, { Component } from 'react';
import styled from 'styled-components';
import {
  faTrash, faCopy, faEdit, faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import sortUpIcon from '../../assets/sort-ascending.svg';
import sortDownIcon from '../../assets/sort-descending.svg';
import { DropDownMenu } from './DropDownMenu/DropDownMenu';


class BannerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      anchorEl2: null,
      openSortAscMenu: false,
      openSortDescMenu: false,
    };
  }

    handleDeleteBlock = () => {
      this.props.functions.deleteBlock(this.props.position);
    }

    handleSortBlockAsc = (TargetPos, type) => {
      this.props.functions.sortBlockAsc(this.props.position, TargetPos, type);
    }

    handleSortBlockDesc = (TargetPos, type) => {
      this.props.functions.sortBlockDesc(this.props.position, TargetPos, type);
    }

    handleCopyBlock = () => {
      this.props.functions.copyBlock(this.props.position);
    }

    handleClick = (event) => {
      event.preventDefault();

      this.setState({
        open: true,
        anchorEl: event.currentTarget,
      });
    }

    handleOpenSortAscMenu = (event) => {
      event.preventDefault();
      this.setState({
        openSortAscMenu: true,
        anchorEl2: event.currentTarget,
      });
    }

    handleRequestClose = () => {
      this.setState({
        open: false,
      });
    }

    handleCloseSortAscMenu = () => {
      this.setState({
        openSortAscMenu: false,
        anchorEl2: null,
      });
    };

    handleSaveBannerTemplate = () => {
      // Here save block data
      if(this.props.BannerData.bannerDataStructure){
        const data = _.cloneDeep(this.props.BannerData.bannerDataStructure);

        data.questionLabel = this.props.BannerData.questionCode;
        data.bannerPosition =  this.props.position;     
        this.props.functions.saveBannerTemplateFromSpreadsheet(data);

      } else {
        const data = {
          bannerPosition : this.props.position,
          bannerType: "basic_block_merged",
          title: this.props.BannerData.BannerName,
          type: "TEMPLATE",
          subType:"QUESTION",
          shared: false,
          public:"false"
        }
        this.props.functions.saveBannerTemplateFromSpreadsheet(data);
      }

    };

    handleEditBlock = () => {
      this.props.functions.loadEditBanner(this.props.BannerData.bannerDataStructure, this.props.position, this.props.BannerData.bannerDataStructure.title);
    }


    render() {
      const menuOptionsSortAsc = this.props.TargetData.map((element, i) => ({
        text: i === 0 ? 'Base Target' : `Target ${i + 1}`,
        items: [
          { text: 'Percentage', action: () => this.handleSortBlockAsc(i, 'percentage'), icon: null },
          { text: 'Thousands', action: () => this.handleSortBlockAsc(i, 'thousands'), icon: null },
          { text: 'Index', action: () => this.handleSortBlockAsc(i, 'index'), icon: null },
        ],
        icon: null,
      }));

      const menuOptionsSortDesc = this.props.TargetData.map((element, i) => ({
        text: i === 0 ? 'Base Target' : `Target ${i + 1}`,
        items: [
          { text: 'Percentage', action: () => this.handleSortBlockDesc(i, 'percentage'), icon: null },
          { text: 'Thousands', action: () => this.handleSortBlockDesc(i, 'thousands'), icon: null },
          { text: 'Index', action: () => this.handleSortBlockDesc(i, 'index'), icon: null },
        ],
        icon: null,

      }));

      const menuOptions = [
        {
          text: 'Edit Block',
          icon: faEdit,
          action: () => this.handleEditBlock(),
        },
        {
          text: 'Duplicate Block',
          icon: faCopy,
          action: () => this.handleCopyBlock(),
        },
        {
          text: 'Sort 1-9',
          imageIcon: true,
          icon: sortUpIcon,
          items: menuOptionsSortAsc,
        },
        {
          text: 'Sort 9-1',
          imageIcon: true,
          icon: sortDownIcon,
          items: menuOptionsSortDesc,
        },
        {
          text: 'Save Block',
          icon: faCloudUploadAlt,
          action: () => this.handleSaveBannerTemplate(),
        },
        {
          text: 'Remove Block',
          icon: faTrash,
          action: () => this.handleDeleteBlock(),
        },
      ];

      return (
        <IconMenuSpace>
          <DropDownMenu
            menuOptions={menuOptions}
          />
        </IconMenuSpace>
      );
    }
}


const IconMenuSpace = styled.div`
  margin-top: 8px;
`;


export default BannerMenu;

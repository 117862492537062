import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({
  icon, text, onClick, type = 'button', disabled, backgroundColor, width,
}) => (
  <Button
    backgroundColor={backgroundColor}
    disabled={disabled}
    type={type}
    width={width}
    onClick={onClick || null}

  >
    <FontAwesomeIcon
      icon={icon}
      style={{ marginRight: '7px', fontSize: '13px', color: disabled ? '#A7B7BF' : '#FFFFFF' }}
    />
    {text}
  </Button>
);

const Button = styled.button`
  cursor: pointer;
  height: 32px;
  border: none;
  padding: 0px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 4px 2px;
  font-family: Roboto;
  /* font-weight: 500; */
  border-radius: 2px;
  color: ${({ fontColor }) => (fontColor || '#FFFFFF')};;
  background-color: ${({ backgroundColor }) => backgroundColor || '#607D8B'};
  ${({ disabled }) => (disabled && 'color: #A7B7BF; pointer-events: none;')};
  width: ${({ width }) => width || 'auto'};
  &:hover {
    filter: brightness(1.1);
  }
`;

import React, { Component } from 'react';
import styled from 'styled-components';

class Average extends Component {
  render(){
    let {average} = this.props;
    average = typeof average === 'object' ? average[1] : average;
    return (
      average 
      ? <AverageContainer>
          <p className="averageTitle">Average:</p>
          <AverageInner>
            <Text>{average}</Text>
          </AverageInner>
        </AverageContainer>
      : null
    )
  }
}

const AverageContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  left: 15px;

  .averageTitle {
    margin-left: 12px;
    font-family: Roboto;
    font-weight: 400;
    color: #37474F;
    font-size: 13px;
  }
`;

const AverageInner = styled.div`
  padding: 10px;
  color: #37474F;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin: 0;
  padding: 3px;
  font-size: 13px;
  color: #37474F;
`;

export default Average;
import Axios from 'axios';

import {
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  GET_TEAM_DOCUMENTS,
  SET_CURRENT_SURVEY,
  SET_OLDER_SURVEY,
  CLEAN_DOCUMENTS,
} from '../actionTypes/documentTypes';

const addDocumentAction = payload => ({
  type: ADD_DOCUMENT,
  payload,
});

const updateDocumentAction = payload => ({
  type: UPDATE_DOCUMENT,
  payload,
});

const deleteDocumentAction = payload => ({
  type: DELETE_DOCUMENT,
  payload,
});
const getTeamDocumentsAction = payload => ({
  type: GET_TEAM_DOCUMENTS,
  payload,
});

const setCurrentSurveyAction = payload => ({
  type: SET_CURRENT_SURVEY,
  payload,
});

const setOlderSurveyAction = payload => ({
  type: SET_OLDER_SURVEY,
  payload,
});

const getTeamDocuments = teamId => dispatch => new Promise((resolve, reject) => {
  Axios.get(`/api/document/${teamId}`)
    .then(async ({ data }) => {
      if (data.success) {
        const documentsPromise = data.documents.map(async (document) => {
          return document;
        });
        const documents = await Promise.all(documentsPromise);
        dispatch(getTeamDocumentsAction(documents));
        resolve(data);
      } else {
        reject();
      }
    });
});

const getSurveys = () => (dispatch, getState) => new Promise((resolve, reject) => {
  const { AppReducer } = getState();
  const account = AppReducer.currentFolderName;
  const database = AppReducer.currentDatabase;
  Axios.get(`/api/document/surveys/current/${account}/${database}`, { responseType: 'blob' })
    .then(({ data }) => {
      if (data.type === 'application/pdf') {
        const file = new Blob([data]);
        dispatch(setCurrentSurveyAction({ file, fileName: `${database}_current_survey.pdf` }));
        resolve();
      } else {
        resolve();
      }
    })
    .catch((error) => {
      reject(error);
    });
  Axios.get(`/api/document/surveys/older/${account}/${database}`, { responseType: 'blob' })
    .then(({ data }) => {
      if (data.type === 'application/pdf') {
        const file = new Blob([data]);
        const fileSize = file.size / 1024 / 1024;
        dispatch(setOlderSurveyAction({ file, fileName: `${database}_older_survey.pdf`, size: fileSize.toFixed(2) }));
        resolve();
      } else {
        resolve();
      }
    })
    .catch((error) => {
      reject(error);
    });
});

const addDocument = data => dispatch => new Promise((resolve, reject) => {
  Axios.post('/api/document/from-browser/', data)
    .then((response) => {
      dispatch(addDocumentAction(response.data));
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});

const updateDocument = (documentId, data) => dispatch => new Promise((resolve, reject) => {
  Axios.put(`/api/document/${documentId}/from-browser/`, data)
    .then((response) => {
      dispatch(updateDocumentAction(response.data.document));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
});

const deleteDocument = data => dispatch => new Promise((resolve, reject) => {
  Axios.delete(`/api/document/${data.id}/`)
    .then((response) => {
      dispatch(deleteDocumentAction(data.id));
      resolve(response.data);
    })
    .catch((err) => {
      reject(err);
    });
});

const clearDocuments = () => ({ type: CLEAN_DOCUMENTS, payload: {} });

export {
  getSurveys,
  getTeamDocuments,
  addDocument,
  updateDocument,
  deleteDocument,
  clearDocuments,
};

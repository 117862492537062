import React, { Component } from 'react';
import styled from 'styled-components';
import NavigationBar from '../../GlobalComponents/NavigationBar';
import TitleBar from '../../GlobalComponents/TitleBar';
import EditAccountContent from './EditAccountContent';

class EditAccount extends Component {

  state = {
    headerTitle: '',
  }

  componentDidMount() {
    document.title = 'Stowell Data | Edit Account';
  }

  updateMainTitle = (headerTitle) => {
    this.setState({
      headerTitle,
    });
  }

  render() {
    const { headerTitle } = this.state;
    const { history } = this.props;
    return (
      <Container>
        <NavigationBar />
        <TitleBar>
          {headerTitle}
        </TitleBar>
        <EditAccountContent
          updateMainTitle={this.updateMainTitle}
          history={history}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  background: rgba(0,0,0,0.03);
  position: absolute;
  width: 100%;
  height: all !important;
  min-height: 100% !important;
`;

export default EditAccount;

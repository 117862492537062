import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSync } from '@fortawesome/free-solid-svg-icons';
import { DropDownMenu } from './DropDownMenu/DropDownMenu';
import spreadsheetIcon from '../../assets/ico-spreadsheetFile.svg';


class MainBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      InputValue: this.props.Title,
      loading: false,
      saved: false,
      textChanged: false,
    };
  }

    handleInputTitle = (event) => {
      const { target } = event;
      this.setState({
        InputValue: target.value,
        textChanged: true,
      });
    }

    selectAllText = (evt) => {
      evt.target.select();
    }

    handleExport = () => {
      this.props.functions.exportToExcel();
    }

    changeProjectTitle = () => {
      this.props.functions.changeProjectTitle(this.state.InputValue);
    }

    saveProject = async () => {
      this.setState({ loading: true, saved: false });
      try {
        await this.props.functions.saveProject();
        this.setState({ loading: false, saved: true });
      } catch (error) {
        console.error(error);
        this.setState({ loading: false, saved: false });
      }
    }

    getDisableStateOption = () => {
      const { charts, chartWindowVisible } = this.props;
      let chartsWithData = [];
      if (charts.length !== 0) {
        chartsWithData = charts.filter(chart => (chart.data && chart.data.length !== 0));
      }
      return (chartsWithData.length === 0 || !chartWindowVisible);
    }

    download = async (size) => {
      const { InputValue } = this.state;
      const { downloadPresentation } = this.props.functions;
      try {
        downloadPresentation(InputValue, size);
      } catch (error) {
        console.error(error);
      }
    }

    getDownloadOptions = () => {
      const { chartsLoaded, chartWindowVisible } = this.props;
      const menuOptions = [
        {
          text: 'Excel',
          imageIcon: true,
          icon: spreadsheetIcon,
          action: this.handleExport,
        },
      ];
      if (chartsLoaded) {
        menuOptions.push({
          text: <React.Fragment><ScreenIcon />{' '}as Microsoft PowerPoint Standard (4:3)</React.Fragment>,
          action: () => { this.download('4x3'); },
          closeOnClick: false,
          isDisabled:!chartWindowVisible,
        });
        menuOptions.push({
          text: <React.Fragment><ScreenIcon wide />{' '}as Microsoft PowerPoint Widescreen (16:9)</React.Fragment>,
          action: () => { this.download('WIDE'); },
          closeOnClick: false,
          isDisabled:!chartWindowVisible,
        });
      }
      return {
        menuOptions,
      };
    }

    getButtonText = () => {
      const { loading, saved } = this.state;
      if (loading) return 'Saving';
      if (!loading && saved) return 'Saved';
      if (!loading) return 'Save';
    }

    render() {
      const { menuOptions } = this.getDownloadOptions();
      const { loading, saved, textChanged } = this.state;
      return (
        <MainBarContainer>
          <InputStyle changed={textChanged} type="text" placeholder="Title" onClick={this.selectAllText} onChange={this.handleInputTitle} onBlur={this.changeProjectTitle} value={this.state.InputValue} />
          <Buttons>
            <SaveButton onClick={this.saveProject} disabled={loading} shadow>
              {
                (loading || saved)
                && <FontAwesomeIcon icon={saved ? faCheck : faSync} spin={loading} style={{ marginRight: '10px' }} />
              }
              {this.getButtonText()}
            </SaveButton>
            <div style={{ display: 'flex' }}>
              <DownloadButton
                onClick={() => this.handleExport()}
              >
                Download
              </DownloadButton>
                <DropDownMenu
                  CustomButton={DropdownButton}
                  menuOptions={menuOptions}
                />
            </div>
          </Buttons>
        </MainBarContainer>
      );
    }
}

const Buttons = styled.div`
  display: flex;
  margin: 30px 16px 18px 0px;
`;

const DropdownButton = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  background-color: #CFD8DC;
  color: #607D8B;
  border-radius: 0px 2px 2px 0px;
  border-left: 1px solid #B0BEC5;
  cursor: pointer;
  div {
    margin-left: 5px;
    padding: 0px 5px;
  }
`;

const ScreenIcon = styled.div`
  width: ${({ wide }) => (wide ? '15px' : '12px')};
  height: 10px;
  border: 1px solid #CCCCCC;
  margin-right: ${({ wide }) => (wide ? '5px' : '10px')};
  margin-left: ${({ wide }) => (wide ? '0px' : '0px')};
`;

const MainBarContainer = styled.div`   
  height: 80px;
  display: flex;
  justify-content: space-between;
`;

const InputStyle = styled.input`
  font-size:24px;
  width: 70%;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-weight:400;
  border: 1px solid white;
  color: ${({ changed }) => !changed ? '#607D8B' : '#37474F'};
  font-style: ${({ changed }) => !changed ? 'italic' : 'normal'};;
  margin: 30px 0px 18px 16px;    
  caret-color: #4A90E2; 
  ::placeholder {
      color: #607D8B;
      font-style: italic;
  }; 
  &:hover {
      border: 1px #CFD8DC solid;
  };

  &:focus {
      outline: none !important;
      border: 1pt #19A2C5 solid;  
      ::selection{
          background: rgba(74,144,226,0.25);
      }  
  }
`;

const SaveButton = styled.button`
    display: inline;
    height: 32px;
    font-size:13px;  
    font-family: Roboto;
    font-weight:500;
    color: #FFFFFF;   
    background-color:#607D8B;
    padding: 0px 20px;
    border-radius: 2px;
    border: 1pt solid #607D8B;
    cursor:pointer;
    ${({ shadow }) => shadow && 'box-shadow: 0px 5px 10px #CCCCCC;'}
    ${({ disabled }) => disabled && 'opacity: 0.4;'}
    &:hover{
      opacity:0.8;
    }
`;

const DownloadButton = styled(SaveButton)`
  border: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #CFD8DC;
  color: #37474F;
  margin-left: 15px;
`;

export default MainBar;

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setTargetTitleError } from '../../../actions/UIManagerActions';

class TargetTitle extends Component {
  updateTitle = (e) => {
    const { actions: { setTitle }, error, setTitleError } = this.props;
    setTitle(e.target.value);
    if (error) setTitleError(false);
  }

  render() {
    const { target: { title }, error } = this.props;
    return (
      <div>
        <Title error={error}>
          <label htmlFor="targetTitle">Target Title (Required)</label>
          <input 
            type="text"
            id="targetTitle"
            name="targetTitle"
            value={title}
            onChange={this.updateTitle}
          />
        </Title>
        {error && <ErrorMessage>Please give your target a title</ErrorMessage>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  error: state.UIManagerReducer.target.missingTitle,
});

const mapDispatchtoProps = dispatch => ({
  setTitleError: state => dispatch(setTargetTitleError(state)),
});

const Title = styled.div`
  flex: 2;
  label {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-bottom: 5px;
    color: #607D8B;
  }
  input {
    border: 1px solid;
    border-color: ${({ error }) => (error ? '#A3070E' : '#5EBED6')};
    outline: none;
    padding-left: 4px;
    font-size: 13px;
    color: #37474F;
    width: 370px;
    height: 32px;
    border-radius: 2px;
    margin-top: 5px;
  }
`;

const ErrorMessage = styled.p`
  position: absolute;
  top: 120px;
  left: 32px;
  margin: 2px;
  color: #A3070E;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Roboto';
`;

export default connect(mapStateToProps, mapDispatchtoProps)(TargetTitle);

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { DragHandle } from './DragHandle';
import ChartTypeMenu from './ChartTypeMenu';
import DisplayedUnitMenu from './DisplayedUnitMenu';
import ChartContainer from './ChartContainer';
import {
  changeCardType, changeCardUnit, changeCardTarget,
} from '../../../actions/spreadsheetCardActions';

import './Card.css';

class Card extends Component {
  parseChartsData = (data) => {
    const sizeData = data.length;
    const sizeArrayThousands = data[0].thousands.keys[0].values.length;
    const sizeArrayPercentages = data[0].percentages.keys[0].values.length;
    let parseData;

    if (sizeData > 2) {
      parseData = ({
        typeTarget: 'Base & Target 2',
        onlyBaseTarget: false,
      });
    }

    if (sizeArrayThousands > 1 && sizeArrayPercentages > 1) {
      parseData = ({
        typeTarget: 'Base & Target 2',
        onlyBaseTarget: false,
      });
    } else {
      // only baseTarget
      parseData = ({
        typeTarget: 'Base',
        onlyBaseTarget: true,
      });
    }
    return parseData;
  }

  render() {
    const {
      id,
      title,
      data,
      labels,
      cards,
      changeType,
      changeUnit,
      type,
      targets,
    } = this.props;

    const { chartType, unit, target } = cards[id];

    const hasIndex = (data[0].index.keys[0].values.length > 1 ? 1 : 0);


    return (
      <Container className="card">
        <Header>
          <DragHandle />
          <Title>
            {title}
          </Title>
        </Header>
        <ChartContainerStyles>
          <ChartContainer
            labels={labels}
            type={chartType}
            unit={unit}
            target={target}
            data={data}
            chartsData={this.parseChartsData(data)}
            targets={targets}
          />
        </ChartContainerStyles>
        <Footer>
          <ChartTypeMenu
            type={type}
            id={id}
            currentType={chartType}
            currentUnit={unit}
            changeType={changeType}
            grouped={target === 0}
            chartsData={this.parseChartsData(data)}
          />
          <DisplayedUnitMenu
            type={type}
            id={id}
            currentUnit={unit}
            currentTarget={hasIndex}
            currentType={chartType}
            changeUnit={changeUnit}
          />
        </Footer>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    labels: state.spreadsheetChartReducer.chartLabels,
    cards: state.spreadsheetCardReducer.spreadsheetCards,
  };
  return props;
};

const mapDispatchToProps = dispatch => ({
  changeType: (id, type) => dispatch(changeCardType(id, type)),
  changeUnit: (id, unit) => dispatch(changeCardUnit(id, unit)),
  changeTarget: (id, target) => dispatch(changeCardTarget(id, target)),
});

const Container = styled.div`
  width: 410px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #CFD8DC;
`;

const Header = styled.div`
  border-bottom: 2px solid #dee1e3;
  width: 100%;
  height:50px;
  padding: 8px;
  position:relative;
  z-index: 2;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-family: 'Roboto';
  color: #37474F;
  display: table-cell;
  vertical-align: top;
  font-weight: 500;
  font-size:13px;
  min-width: 305px;
  padding-left: 5px;
`;

const ChartContainerStyles = styled.div`
  border-bottom: 2px solid #dee1e3;
  height: 285px;
  padding: 0px 0px 10px 0px;
  position: relative;
`;

const Footer = styled.div`
  padding: 8px;
  height: 48px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Card);

import React, { Component } from 'react';
import styled from 'styled-components';
import TargetTitle from './TargetTitle';
import SaveTemplate from '../Templates/SaveTemplate';
import Builder from '../../GlobalComponents/Builder';
import Scrollbar from '../../GlobalComponents/Scrollbar';

class TargetBuilder extends Component {
  render() {
    const { actions, data } = this.props;
    return (
      <Container>
        <TitleSection>
          <TargetTitle
            actions={actions.target}
            target={data.target.currentTarget}
          />
          <SaveTemplate
            actions={actions.templates}
            target={data.target.currentTarget}
            blocks={data.builder.blocks}
          />
        </TitleSection>
        <BuilderSection>
          <Scrollbar>
            <div style={{ paddingRight: '25px' }}>
              <Builder actions={actions} blocks={data.builder.blocks} />
            </div>
          </Scrollbar>
        </BuilderSection>
      </Container>
    );
  }
}

const Container = styled.div`
  height: 100%;
`;

const BuilderSection = styled.div`
  padding: 0 32px;
  margin-bottom: 20px;
  width: 590px;
  height: 460px;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 32px;
  margin-bottom: 20px;
`;


export default TargetBuilder;

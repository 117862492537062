import React, { Component } from 'react';
import styled from 'styled-components';

class TitleBar extends Component {
  render() {
    const { RightComponent } = this.props;
    return (
      <Bar>
        <Title>
          {this.props.children}
        </Title>
        {RightComponent}
      </Bar>
    );
  }
}

const Bar = styled.div`
  height: 78px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #DEE1E3;
  background-color: #FFFFFF !important;
  align-items: center;
`;

const Title = styled.div`
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 400;
  color: #37474F;
  border: 0;
  padding: 30px 16px 18px 16px;
`;

export default TitleBar;

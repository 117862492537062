import React, { Component } from 'react';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import TabsButton from './TabsButton';
import AddTabModal from './AddTabModal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';


//MODAL
import Modal from 'react-responsive-modal';



class TabsBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            OpenAddTabModal: false,
            OpenAddTabMenu: false,
            modalType:null
        };  

    }

    OpenTabMenu = (event) => {
        event.preventDefault();
        this.setState({OpenAddTabMenu: true, anchorEl: event.currentTarget})
    }

    CloseTabMenu = () => {
        this.setState({OpenAddTabMenu: false})
    }

    OpenSurveyTabModal = () => {
        this.setState({OpenAddTabModal: true, OpenAddTabMenu:false, modalType:1})
    }

    OpenTrendTabModal = () => {
        this.setState({OpenAddTabModal: true, OpenAddTabMenu:false, modalType:2})
    }

    closeTabModal = () => {
        this.setState({OpenAddTabModal: false})
    }


    render() {

        const { OpenAddTabModal, modalType } = this.state;

        return (
            <TabsBarContainer>
                    <ButtonContainer onClick={this.OpenSurveyTabModal}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </ButtonContainer>
                <Menu
                    open={this.state.OpenAddTabMenu}
                    anchorEl={this.state.anchorEl}
                    onClose={this.CloseTabMenu}
                >
                    <MenuItem onClick={this.OpenSurveyTabModal} leftIcon={<FontAwesomeIcon icon={faChartLine} />} > Show Surveys </MenuItem>
                </Menu>
                <Modal
                    open={OpenAddTabModal}                    
                    onClose={this.closeTabModal}
                    classNames={{ overlay: 'overlay', modal: 'modal', closeIcon: 'closeButton' }}
                    center
                    closeIconSize={20}
                    styles={{overlay: {
                        background: 'rgba(0, 0, 0, 0.5)'
                      },
                      modal: {
                        width: 'auto',
                        height: 'auto',
                        borderRadius: '12px',
                        margin: 'auto',
                        padding: 0,
                      },
                      
                      closeButton: {
                        top: '5px',
                        right: '10px',
                        width: '20px',
                        height: '20px',
                        fill: 'gray'
                      }}}
                >
                    <AddTabModal Data={this.props.Data} currentTab={this.props.currentTab} type={modalType} closeTabModal={this.closeTabModal} functions={this.props.functions} />
                </Modal>
                {this.props.Data.Tabs.map((element, i) =>
                    element.isVisible ? 
                    <span key={i}>
                        <TabsButton 
                            functions={this.props.functions} 
                            tabYear={element.Name} 
                            position={i}
                            currentTab={this.props.currentTab} />
                    </span>
                    : null
                )}                          
            </TabsBarContainer>
        )
    }
}

const TabsBarContainer = styled.div`
    height:36px;    
    padding: 0px 5px 0px 5px;
    white-space: nowrap;
    background-color: #F6F7F8;

`;

const ButtonContainer = styled.button`
    height: 30px;
    font-size:13px;  
    font-family: Roboto;
    font-weight:400;
    color: #19A2C5;
    &:hover {
      color: #117189;
    };
    cursor: pointer;    
    border:none;
    background-color: white;
    padding: 0px 15px 0px 15px;
`;

export default TabsBar;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { openBreadcrumb, closeBreadcrumb } from "../../../actions/breadcrumbActions";

const formatString = (string) => {
  const maxTextLength = 25;
  if (string && string.length > maxTextLength) {
    return `${string.substring(0, maxTextLength)}...`;
  }
  return string;
};

const formatBreadcrumbPath = (path) => {
  const formattedPath = {};
  formattedPath.category = formatString(path.category);
  formattedPath.subcategory = formatString(path.subcategory);
  formattedPath.question = formatString(path.question);
  return formattedPath;
};

class Breadcrumb extends Component {
  state = {
    open: false,
  }

  handleClick = () => {
    const { open } = this.state;
    const {
      blockId,
      openBreadcrumb,
      closeBreadcrumb,
      toggleShowTree,
      path,
    } = this.props;
    if (open) {
      closeBreadcrumb();
    } else {
      openBreadcrumb(path);
      toggleShowTree(blockId);
    }
    this.setState({ open: !open });
  }

  render() {
    const { path } = this.props;
    const formattedPath = formatBreadcrumbPath(path);
    return (
      <BreadcrumbContainer onClick={this.handleClick}>
        {formattedPath.category}
        {formattedPath.category && <BreadcrumbSlash> / </BreadcrumbSlash>}
        {formattedPath.subcategory}
        {formattedPath.subcategory && <BreadcrumbSlash> / </BreadcrumbSlash>}
        {formattedPath.question}
      </BreadcrumbContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openBreadcrumb: path => dispatch(openBreadcrumb(path)),
  closeBreadcrumb: () => dispatch(closeBreadcrumb()),
});

Breadcrumb.propTypes = {
  openBreadcrumb: PropTypes.func.isRequired,
  closeBreadcrumb: PropTypes.func.isRequired,
  path: PropTypes.object.isRequired,
};

const BreadcrumbContainer = styled.div`
  padding-left: 10px;
  font-size: 13px;
  margin: 0;
  cursor: pointer;
`;

const BreadcrumbSlash = styled.p`
  padding: 0 5px 0 5px;
  color: gray;
  display: inline-block;
  font-size: 13px;
  margin: 0;
`;

export default connect(null,mapDispatchToProps)(Breadcrumb);

import React, { Component } from 'react';

class CheckBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };  
    }

    handleCheckbox = () => {
        switch (this.props.functionType) {
            case 1:
                this.props.functions.selectAll(this.props.position);
                break;
            case 2:
                this.props.functions.selectRow(this.props.position, this.props.rowNum - 1);
                break;
            default:
                break;
        }
    }

    render() {        
        let checkBoxLabelStyle={
            fontSize:'12px',  
            fontFamily: 'Roboto',
            fontWeight:400,
            color: '#37474F',
            width: '20px'   
        }        
        return (
            <label className="container">
                <span style={checkBoxLabelStyle}>                
                    {this.props.checkBoxText}
                </span>
                <input type="checkbox" onChange={this.handleCheckbox} checked={this.props.isChecked}/>
                <span className="checkmark"></span>
            </label>   
        )
    }
}
export default CheckBox;
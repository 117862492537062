import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import axios from 'axios';
import App from './App';
import ErrorPage from './components/GlobalComponents/ErrorPage';
import store from './store';
import './helpers/fonts-import';

import * as Sentry from "@sentry/react";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { API_URL } from './constants';

document.title = 'Stowell Data';

axios.defaults.baseURL = API_URL;

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://75e107bb81d7083ef7d6658d8d2f1cc7@o4506520150081536.ingest.sentry.io/4506520223547392",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/stowelldata\.com\/api/],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (process.env.NODE_ENV === 'production') {
  Sentry.addIntegration(new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
  }),)
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY })



ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<ErrorPage text='An error has occurred' />}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
    </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

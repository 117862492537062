import React, { Component } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import {
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Title,
  Button,
} from '../../../GlobalComponents/BasicControls';
import showNotification from '../../../../helpers/showNotification';

export class Resources extends Component {
  state = {
    disableOnSaving: false,
  }

  handleInputText = (e) => {
    const { value } = e.target;
    this.props.setState({
      account: {
        ...this.props.content.account,
        resources: value,
        },
    });
  }

  editAccountDetails = (e) => {
    this.setState({ disableOnSaving: true });
    const resources = this.props.state;
    const data = {
      resources
    };
    this.props.handleEditAccountDetails(this.props.Id, data, e)
      .then(() => {
        showNotification({ message: 'Account updated successfully', type: 'success' })
        this.setState({
          disableOnSaving: true,
        });
        this.props.setState({
          selectedItemNav: 6,
        });
      })
      .catch((err) => {
        showNotification({ message: 'Error updating account', type: 'error' })
        this.setState({
          disableOnSaving: true,
        });
        Sentry.captureException(err);
      });
  }

  handleSubmit = (e, edit) => {
    e.preventDefault();
    edit === 'edit'
      ? this.editAccountDetails(e)
      : this.props.handleSubmit(e);
  }

  onCancelCreatingAndEditing = () => {
    const { type, onCancelCreating, onCancelEditing } = this.props;
    if (type === 'edit') {
      onCancelEditing();
    } else {
      onCancelCreating();
    }
  }

  render() {
    const  resources  = this.props.state;
    const { type } = this.props;
    const { disableOnSaving } = this.state;
    return (
      <Body>
        <Form onSubmit={e => this.handleSubmit(e, type)}>
          <Title>
            Resources
          </Title>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                Folder link
                </ControlLabel>
                <FormControl
                  autoComplete="off"
                  autoFocus
                  type="text"
                  name="resources"
                  value={resources}
                  onChange={e => this.handleInputText(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col size={2}>
              <Button
                onClick={this.onCancelCreatingAndEditing}
                type="button"
              >
                Cancel
              </Button>
            </Col>
            <Col size={2}>
              <Button
                disabled={disableOnSaving}
                tColor="white"
                bgColor="#455A64"
              >
                {this.props.type === 'edit' ? 'Save' : 'Continue'}
              </Button>
            </Col>
          </Row>
        </Form>
      </Body>
    );
  }
}

const Body = styled.div`
  ${''}
  width: 47%;
  padding: 0 3%;
`;

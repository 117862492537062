import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCurrentBannerTab } from '../../../actions/bannerActions';
/**
|--------------------------------------------------
| 0 - BASIC
| 1 - TEMPLATES
/ 2 - ADVANCED
|--------------------------------------------------
*/
class Tabs extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: this.props.currentTab,
    };
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(tabIndex) {
    const { setCurrentTab } = this.props;
    setCurrentTab(tabIndex);
  }

  renderChildrenWithTabsApiAsProps() {
    return React.Children.map(this.props.children, (child, index) => React.cloneElement(child, {
      onClick: this.handleTabClick,
      tabIndex: index,
      isActive: index === this.props.currentTab,
    }));
  }

  renderActiveTabContent() {
    const { children, currentTab } = this.props;
    if (children[currentTab]) {
      return children[currentTab].props.children;
    }
  }

  render() {
    return (
      <div className="tabs">
        <ul className="tabs-nav">
          {this.renderChildrenWithTabsApiAsProps()}
        </ul>
        <div className="tabs-active-content">
          {this.renderActiveTabContent()}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  defaultActiveTabIndex: PropTypes.number,
};

Tabs.defaultProps = {
  defaultActiveTabIndex: 0,
};
const mapStateToProps = store => ({
  currentTab: store.bannerReducer.currentTab,
});

const mapDispatchToProps = dispatch => ({
  setCurrentTab(tab) {
    dispatch(setCurrentBannerTab(tab));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Tabs);

import _ from 'lodash';

import {
  GET_MEMBERS, DELETE_MEMBER, ADD_MEMBER, UPDATE_MEMBER, CANCEL_MEMBER,
} from '../actionTypes/teamTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const initialState = {
  members: {},
};

const teamReducer = (state = initialState, action) => {
  if (action.type === GET_MEMBERS) {
    return {
      ...state,
      members: action.payload,
    };
  } if (action.type === DELETE_MEMBER) {
    const member = action.payload;
    return {
      ...state,
      members: {
        ...state.members,
        [member._id]: member,
      },
    };
  } if (action.type === ADD_MEMBER) {
    const member = action.payload;

    return {
      ...state,
      members: {
        ...state.members,
        [member._id]: member,
      },
    };
  } if (action.type === UPDATE_MEMBER) {
    const member = action.payload;
    return {
      ...state,
      members: {
        ...state.members,
        [member._id]: member,
      },
    };
  } if (action.type === CANCEL_MEMBER) {
    const newMembers = _.cloneDeep(state.members);
    action.payload.forEach((id) => {
      newMembers[id].status = 'CANCELLED';
    });
    return {
      ...state,
      members: {
        ...newMembers,
      },
    };
  } if (action.type === LOG_OUT) {
    return initialState;
  }
  return state;
};

export default teamReducer;

import React, { Component } from 'react';
import styled from 'styled-components';
import {
  faTrash, faCopy, faEdit, faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';
import { DropDownMenu } from './DropDownMenu/DropDownMenu';


class TargetCellContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

CopyTarget = () => {
  this.props.functions.copyTarget(this.props.position);
};

DeleteTarget = () => {
  this.props.functions.deleteTarget(this.props.position);
}

SaveTarget = () => {
  this.props.functions.saveTargetTemplate(this.props.targetName, this.props.targetDataStructure);
}

loadEditTarget = () => {
  this.props.functions.loadEditTarget(this.props.targetDataStructure, this.props.position, this.props.targetName);
}

render() {
  const targetNameStyle = {
    fontSize: '13px',
    fontWeight: 500,
    color: '#37474F',
    textAlign: 'left',
    marginLeft: '25px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '275px',
  };

  const placeHolderStyle = {
    fontSize: '13px',
    fontWeight: 500,
    color: '#37474F',
    textAlign: 'center',
  };

  const infoHeaderStyle = {
    fontSize: '12px',
    fontWeight: 400,
    color: '#607D8B',
    textAlign: 'right',
  };

  const infoQuantitySTyle = {
    fontSize: '12px',
    fontWeight: 400,
    color: '#607D8B',
    textAlign: 'left',
    marginLeft: '25px',
  };

  const infoQuantitySTyleError = {
    fontSize: '12px',
    fontWeight: 400,
    color: '#A3070E',
    textAlign: 'left',
    marginLeft: '25px',
  };

  const infoNumberStyle = {
    fontSize: '13px',
    fontWeight: 500,
    color: '#37474F',
    textAlign: 'Right',
  };

  const menuOptions = [
    {
      text: 'Edit Target',
      icon: faEdit,
      action: () => this.loadEditTarget(),
    },
    {
      text: 'Duplicate Target',
      icon: faCopy,
      action: () => this.CopyTarget(),
    },
    {
      text: 'Save Target',
      icon: faCloudUploadAlt,
      action: () => this.SaveTarget(),
    },
    {
      text: 'Remove Target',
      icon: faTrash,
      action: () => this.DeleteTarget(),
    },
  ];


  return (
    <Placeholder>
      <ColumnHead>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td style={{ width: '90%' }}>
                <ColumnHeadTitle>
                  {this.props.position !== undefined ? (this.props.position === 0 ? 'Base Target' : `Target ${this.props.position + 1}`) : null}
                </ColumnHeadTitle>
              </td>
              <td>
                {this.props.position !== undefined ? (
                  <DropDownMenu
                    menuOptions={menuOptions}
                  />
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </ColumnHead>
      <Cell>
        {this.props.isPlaceHolder ? null : <div title={this.props.targetName} style={targetNameStyle}> {this.props.targetName} </div>}
      </Cell>
      <Cell>
        <SubCell>
          {this.props.isPlaceHolder ? null : <div style={this.props.targetQuantity <= 50 ? infoQuantitySTyleError : infoQuantitySTyle}> n = {this.props.targetQuantity} </div>}
        </SubCell>
        <SubCell>
          {this.props.isPlaceHolder
            ? (
              <div style={placeHolderStyle}>
                Respondents Analyzed
              </div>
            )
            : (
              <span style={infoHeaderStyle}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '33%' }}>
                        {this.props.showPercentage ? '(%)' : null}
                      </td>
                      <td style={{ width: '33%' }}>
                        {this.props.showThousands ? '000s' : null}
                      </td>
                      <td style={{ width: '33%', paddingRight: '15px' }}>
                        {this.props.showIndex && this.props.position > 0 ? 'Index' : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
            )
                        }
        </SubCell>
        <SubCell>
          <table style={{ width: '100%' }}>
            {this.props.isPlaceHolder
              ? null
              : (
                <tbody style={infoNumberStyle}>
                  <tr>
                    <td style={{ width: '33%' }}>
                      {this.props.showPercentage ? this.props.targetPercentage : null}
                    </td>
                    <td style={{ width: '33%' }}>
                      {this.props.showThousands ? this.props.targetThousands : null}
                    </td>
                    <td style={{ width: '33%' }}>
                      {this.props.showIndex && this.props.position > 0 ? '-' : null}
                    </td>
                  </tr>
                </tbody>
              )
            }
          </table>
        </SubCell>
      </Cell>
    </Placeholder>
  );
}
}

const Placeholder = styled.div`    
  width: 300px;
  background-color: white;   
  border-right: 2px solid  #E2E7Ea;
  display: inline-block;
  border-top: 2px solid #607D8B;
`;


const ColumnHead = styled.div`
  height:28px;
  background-color: #E2E7EA;
  border-bottom:  2px solid #E2E7Ea;
  border-top: 1px solid #F6F7F8;
  border-left: 1px solid #F6F7F8;
  border-right: 1px solid #F6F7F8;
`;

const Cell = styled.div`
    height:60px;
    border-bottom:  2px solid #E2E7Ea;        
`;

const SubCell = styled.div`
    height:19px;
`;

const ColumnHeadTitle = styled.div`
  font-weight: bold;
  margin: 0px 0px 0px 20px;
  font-size: 13px;
  font-family: Roboto;
  color: rgb(55, 71, 79);
`;

export default TargetCellContainer;

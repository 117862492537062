import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import moveIcon from '../../../assets/ico-move.svg';

export const DragHandle = SortableHandle(() => (
  <DragHandleStyles className="handle">
    <MoveIcon img={moveIcon} alt="move-icon" />
  </DragHandleStyles>
));

const DragHandleStyles = styled.div`
    width: 32px;
    height: 32px;
    cursor: move;
    color: #828692;
    display: table-cell;
    vertical-align: middle;
    border-radius: 2px;
    &:hover {
      background: #EAEEF0;
    }
`;

const MoveIcon = styled.div`
  width: 20px;
  height: 20px;
  margin: 6px;
  background-image: url(${({ img }) => img});
  background-repeat: no-repeat;
  background-position: top center;
`;

import axios from 'axios';
import _, { cloneDeep, size, replace } from 'lodash';
import builderDataToQuery from './builderFunctions';
import {
  ADD_TARGET_BLOCK,
  REMOVE_TARGET_BLOCK,
  ADD_TARGET_QUESTION,
  REMOVE_TARGET_QUESTION,
  ADD_TARGET_DATAPOINT,
  REMOVE_TARGET_DATAPOINT,
  CHANGE_TARGET_ITEMS_OPERATOR,
  TARGET_BUILDER_RESET,
  RESTORE_TARGET_TEMPLATE,
  SET_TARGET_TITLE,
  SET_TARGET_QUERY,
  LOAD_TARGET_TEMPLATES,
  UPDATE_TARGET_TEMPLATE,
  UPDATE_TARGET_TEMPLATE_TITLE,
  TOGGLE_TARGET_BUILDER_VIEW,
  CHANGE_TARGET_TEMPLATES_MENU,
  SET_CURRENT_TARGET_TEMPLATE,
  SAVE_TARGET_TO_PROJECTS,
  GET_TARGET_SIZE,
  COPY_TARGET,
  RESET_TARGET,
  RESTORE_SAVED_TARGET,
  RESTORE_BASE_TARGET,
  SET_TARGET_ID,
  SET_BASE_TARGET,
  CLEAR_TARGETS,
  RESET_MODAL
} from '../actionTypes/targetTypes';
import showNotification from '../helpers/showNotification';

export const addBlock = operator => ({ type: ADD_TARGET_BLOCK, payload: operator });
export const removeBlock = data => ({ type: REMOVE_TARGET_BLOCK, payload: data });
export const setBaseTarget = data => ({ type: SET_BASE_TARGET, payload: data });
export const setDefaultBaseTarget = () => (dispatch, getState) => {
  const {baseTarget} = getState().targetReducer;
  const {categories} = getState().categoriesReducer;
  const baseCategory = _.find(categories, category => _.includes(_.map(category.questions, question => question.id), 'C1'));
  const question = _.find(baseCategory.questions, q => q.id === 'C1');
  const newBaseTarget = _.cloneDeep(baseTarget);
  const newQuestion = _.cloneDeep(question);
  newQuestion.answers[0].index = 1;
  newBaseTarget.title = newQuestion.answers[0].text;
  newBaseTarget.builder[0].questions.C1 = newQuestion;
  dispatch(setBaseTarget(newBaseTarget));
};
export const resetModal = () => ({ type: RESET_MODAL });

export const addQuestion = data => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const question = questions[data.question.id];
  dispatch({
    type: ADD_TARGET_QUESTION,
    payload: {
      blockId: data.blockId,
      question: { ...question, path: data.question.path },
    },
  });
};

export const removeQuestion = data => ({ type: REMOVE_TARGET_QUESTION, payload: data });

export const addDataPoint = (blockId, dataPoint, path) => (dispatch, getState) => {
  const { questions } = getState().categoriesReducer;
  const [questionId, answerId] = dataPoint.split('-');
  const question = questions[questionId];
  const answer = question.answers[`${questionId}-${answerId}`];
  const payload = {
    block: blockId,
    question: { id: question.id, text: question.text },
    answer,
    path,
  };
  dispatch({ type: ADD_TARGET_DATAPOINT, payload });
};

export const removeDataPoint = (blockId, dataPoint) => {
  const [questionId] = dataPoint.split('-');
  const payload = {
    block: blockId,
    question: { id: questionId },
    answer: { id: dataPoint },
  };
  return { type: REMOVE_TARGET_DATAPOINT, payload };
};

export const changeItemsOperator = (data) => {
  let operator = {};
  switch (data.operator) {
    case 'OR':
      operator = { operator: 'OR', negated: false };
      break;
    case 'AND':
      operator = { operator: 'AND', negated: false };
      break;
    case 'OR NOT':
      operator = { operator: 'OR', negated: true };
      break;
    case 'AND NOT':
      operator = { operator: 'AND', negated: true };
      break;
    default:
      operator = { operator: 'OR', negated: false };
  }
  return { type: CHANGE_TARGET_ITEMS_OPERATOR, payload: { blockId: data.blockId, operator } };
};

export const targetBuilderReset = () => ({ type: TARGET_BUILDER_RESET, payload: null });
export const restoreTargetTemplate = data => ({ type: RESTORE_TARGET_TEMPLATE, payload: data });
export const restoreBaseTarget = data => ({ type: RESTORE_BASE_TARGET, payload: data });

export const setTargetTitle = title => ({ type: SET_TARGET_TITLE, payload: title });
export const setTargetId = id => ({ type: SET_TARGET_ID, payload: id });
export const setTargetQuery = () => (dispatch, getState) => {
  const { blocks } = getState().targetReducer.builder;
  const query = builderDataToQuery(blocks);
  dispatch({ type: SET_TARGET_QUERY, payload: query });
};

export const setTargetQueryFromSavedTargets = blocks => (dispatch) => {
  const query = builderDataToQuery(blocks);
  dispatch({ type: SET_TARGET_QUERY, payload: query });
};

export const loadTargetTemplates = () => dispatch => axios.get('/api/templates')
  .then(response => dispatch({ type: LOAD_TARGET_TEMPLATES, payload: response.data.templates }))
  .catch(error => console.log(error));

export const saveTargetTemplate = (title, data) => (dispatch, getState) => {
  const { currentAccountId, user, currentDatabase } = getState().AppReducer;
  const template = {
    title,
    data,
    database: currentDatabase,
    type: 'TEMPLATE',
    subType: 'TARGET',
    team: currentAccountId,
    user: user._id,
  };
  return axios.post('/api/templates/', template)
    .then((response) => {
      dispatch({ type: SAVE_TARGET_TO_PROJECTS, payload: response.data.template });
      showNotification({ message: 'Successfully saved template!' });
    });
};

export const updateTargetTemplate = () => (dispatch, getState) => {
  const { currentTemplate } = getState().targetReducer.templates;
  const { blocks } = getState().targetReducer.builder;
  const template = { ...currentTemplate, data: blocks };
  return axios.put(`/api/templates/${currentTemplate.id}`, template)
    .then((response) => {
      dispatch({ type: UPDATE_TARGET_TEMPLATE, payload: response.data });
      dispatch(setTargetTitle(response.data.title));
      dispatch({ type: SAVE_TARGET_TO_PROJECTS, payload: response.data });
      showNotification({ message: 'Successfully updated template!' });
    });
};

export const updateTargetTemplateTitle = title => ({
  type: UPDATE_TARGET_TEMPLATE_TITLE,
  payload: title,
});

export const toggleTargetBuilderView = data => ({
  type: TOGGLE_TARGET_BUILDER_VIEW,
  payload: data,
});

export const changeTargetTemplatesMenu = data => ({
  type: CHANGE_TARGET_TEMPLATES_MENU,
  payload: data,
});

export const setCurrentTargetTemplate = data => ({
  type: SET_CURRENT_TARGET_TEMPLATE,
  payload: data,
});

const getSelectedYears = (tabs = []) => tabs
  .filter(t => t.isVisible && t.Name !== 'Combined')
  .map(t => t.Name);

export const getTargetSize = (type, blocks, isFromSpreadsheet) => (dispatch, getState) => {
  let account;
  const { user, currentFolderName } = getState().AppReducer;
  if (user.role.name === 'admin') {
    account = currentFolderName;
  } else {
    account = getState().AppReducer.user.teamId.folderName;
  }
  const database = getState().AppReducer.currentDatabase;
  const { year } = getState().marketReducer.currentSurvey;
  const { currentTab, Data } = getState().spreadsheet;
  let query = builderDataToQuery(blocks);
  let yearSelected = year;
  let route = '/api/targetSize/byYear';
  const currentSelectedYearInView = Data.Tabs[currentTab].Name;
  if (isFromSpreadsheet) {
    yearSelected = currentSelectedYearInView;
    if (currentSelectedYearInView === 'Combined') {
      route = '/api/targetSize/byYears';
      query = replace(query, /(!|\)|\()/g, '');
      yearSelected = getSelectedYears(Data.Tabs);
    }
  }
  return axios.post(route, {
    account, database, query, year: yearSelected,
  })
    .then((response) => {
      let targetSizeFromResponse = response.data.targetSize;
      if (isFromSpreadsheet && currentSelectedYearInView === 'Combined') {
        let targetSizeTotal = 0;
        yearSelected.forEach((yearInArray) => {
          targetSizeTotal += response.data[yearInArray].qty;
        });
        targetSizeFromResponse = parseFloat(targetSizeTotal.toFixed(0));
      }
      dispatch({ type: GET_TARGET_SIZE, payload: { size: targetSizeFromResponse, type } });
    }).catch(error => showNotification({ message: 'Error getting target size', type: 'error' }));
};

export const copyTarget = type => (dispatch, getState) => {
  const { currentTarget, builder } = getState().targetReducer;
  const target = { ...currentTarget, builder: builder.blocks };
  const targetCopy = cloneDeep(target);
  dispatch({ type: COPY_TARGET, payload: { type, target: targetCopy } });
};

export const resetTarget = (type) => {
  let data = {};
  if (type === 'baseTarget') {
    data = { title: 'All Adults', query: 'C1-1' };
  }
  if (type === 'currentTarget') {
    data = { title: '', query: '', size: 0 };
  }
  return { type: RESET_TARGET, payload: { type, data } };
};

export const restoreSavedTarget = type => (dispatch, getState) => {
  const target = getState().targetReducer[type];
  const { builder, ...targetwithoutBuilder } = target;
  const counter = size(builder);
  const data = { builder: { counter, blocks: builder }, currentTarget: targetwithoutBuilder };
  const payload = cloneDeep(data);
  dispatch({ type: RESTORE_SAVED_TARGET, payload });
};

export const clearTargets = () => ({ type: CLEAR_TARGETS, payload: {} });

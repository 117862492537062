import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Dropdown from 'react-dropdown';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import 'react-dropdown/style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Proptypes from 'prop-types';
import Button from '../GlobalComponents/FormComponents/Button';
import StowellBox from '../GlobalComponents/StowellBox';
import {
  setDatabase, getAdminDatabases, setAccountFoldername, setAccountId, getErrorMessage,
} from '../../actions/appActions';
import { clearCharts } from '../../actions/overviewChartActions';
import { clearTargets } from '../../actions/targetActions';
import { clearBanners } from '../../actions/bannerActions';
import { clearDocuments } from '../../actions/documentActions';
import { cleanProjects } from '../../actions/projectActions';
import { getAccountById, setAccountAction } from '../../actions/accountActions';
import showNotification from '../../helpers/showNotification';

class DatabaseSelector extends Component {
  state = {
    saveAsDefault: true,
    loadingDatabases: false,
    selectedDatabase: null,
  };

  componentWillMount() {
    document.title = 'Stowell Data | Select Database';
    const { databases, user, getDatabases } = this.props;
    this.setSelectedDB(this.props);
    if (user.role.name === 'admin') {
      this.setState({ loadingDatabases: true });
      getDatabases()
        .then(() => {
          this.setState({ loadingDatabases: false });
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({ message, type })
          this.setState({
            loadingDatabases: false,
          });
        });
    } else if (databases.length === 1) {
      this.goBack();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setSelectedDB(nextProps);
  }

  setSelectedDB = ({ currentDatabase, databases, user }) => {
    const selectedDatabase = this.getSelectedDB(currentDatabase, databases)[0];
    if (user.role.name === 'admin') {
      if (selectedDatabase) {
        this.setState({ selectedDatabase });
      }
    } else if (selectedDatabase) {
      this.setState({ selectedDatabase });
    }
  }

  // eslint-disable-next-line max-len
  getSelectedDB = (currentDatabase, databases) => _.filter(databases, db => db.folderName === currentDatabase)

  handleChange = () => {
    const { saveAsDefault } = this.state;
    this.setState({ saveAsDefault: !saveAsDefault });
  }

  handleChangeDropdown = (e) => {
    this.setState({
      selectedDatabase: e,
    });
  }

  saveDatabaseLocally = (folderName) => {
    const { setDatabase } = this.props;
    setDatabase(folderName);
    localStorage.setItem('currentDatabase', folderName);
  }

  saveFoldernameLocally = (folderName) => {
    const { setAccountFoldername } = this.props;
    setAccountFoldername(folderName);
    localStorage.setItem('currentAccountFolder', folderName);
  }

  saveAccountIdLocally = (id) => {
    const { setAccountId } = this.props;
    setAccountId(id);
    localStorage.setItem('currentAccountId', id);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedDatabase } = this.state;
    const {
      user,
      databases,
      emptyAllCharts,
      emptyAllTargets,
      emptyAllBanners,
      emptyAllDocuments,
      emptyProjects,
    } = this.props;

    const keyedDbs = _.keyBy(databases, 'value');
    const db = keyedDbs[selectedDatabase.value];
    this.saveDatabaseLocally(db.folderName);
    this.saveFoldernameLocally(db.accountFolderName);
    this.saveAccountIdLocally(db.teamId);
    if (user.role.name === 'admin') {
      getAccountById(db.teamId).then((res) => {
        this.props.setAccount(res);
      });
    }
    emptyAllCharts();
    emptyAllTargets();
    emptyAllBanners();
    emptyAllDocuments();
    emptyProjects();
      this.goTo('/projects');
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  goTo = (url) => {
    const { history } = this.props;
    history.push(url);
  }

  isDatabaseSelected = () => {
    const { selectedDatabase } = this.state;
    return !selectedDatabase;
  }

  logOut = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('currentDatabase');
    localStorage.removeItem('currentAccountFolder');
    localStorage.removeItem('currentAccountId');
    this.goTo('/login');
  }

  render() {
    const { loadingDatabases, selectedDatabase } = this.state;
    const { user, databases } = this.props;
    return (
      <StowellBox>
        <form onSubmit={this.handleSubmit}>
          <Text bold fontSize={20}>
            Welcome,
            {' '}
            {user.first_name}
          </Text>
          <Text marginTop={10} lineHeight={1.5}>
            {' '}
            {user.teamId ? user.teamId.name : ''}
            {' '}
            has more than one database.
            Please select the database you would like to work with today.
          </Text>
          <Text marginTop={20} marginBottom={10} color="#607D8B">Select database</Text>
          <div>
            {
              loadingDatabases ? (
                <LoaderContainer>
                  <p>Loading databases...</p>
                  <p>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </p>
                </LoaderContainer>
              ) : (
                <CustomLoader loading={loadingDatabases}>
                  <Dropdown
                    className="custom-dropdown-root"
                    controlClassName="custom-dropdown-control"
                    placeholderClassName="custom-dropdown-placeholder"
                    menuClassName="custom-dropdown-menu"
                    options={databases}
                    onChange={this.handleChangeDropdown}
                    value={selectedDatabase}
                    placeholder="Select..."
                  />
                </CustomLoader>
              )
            }
          </div>
          <div style={{ marginTop: '10px' }} />
          <ButtonContainer>
            <Button width="84px" disabled={this.isDatabaseSelected()} type="submit" text="Select" icon={faLock} />
          </ButtonContainer>
          <LinkButton onClick={this.logOut}>Back to Sign In</LinkButton>
        </form>
      </StowellBox>
    );
  }
}

DatabaseSelector.propTypes = {
  databases: Proptypes.array.isRequired,
  user: Proptypes.object.isRequired,
  getDatabases: Proptypes.func.isRequired,
  setDatabase: Proptypes.func.isRequired,
  setAccountFoldername: Proptypes.func.isRequired,
  setAccountId: Proptypes.func.isRequired,
  emptyAllTargets: Proptypes.func.isRequired,
  emptyAllBanners: Proptypes.func.isRequired,
  emptyAllDocuments: Proptypes.func.isRequired,
  emptyProjects: Proptypes.func.isRequired,
  emptyAllCharts: Proptypes.func.isRequired,
  history: Proptypes.array.isRequired,
};

const getDatabaseSelectorObjectForDropdown = (databases, user) => {
  const isAdminUser = user.role.name === 'admin';
  const dbs = _.map(databases, (db) => {
    const database = {
      teamId: db.teamId || user.teamId._id,
      label: isAdminUser ? `${db.account} - ${db.name}` : db.name,
      value: db._id,
      folderName: db.folderName,
    };
    if (isAdminUser) {
      database.accountFolderName = db.accountFolderName;
    } else if (user.role.name === 'owner') {
      database.accountFolderName = user.teamId.folderName;
    }
    return database;
  });
  return _.sortBy(dbs, db => db.label);
};

const mapStateToProps = (store) => {
  let databases = [];
  const { user, adminDatabases } = store.AppReducer;
  if (!_.isEmpty(user)) {
    if (user.role.name === 'admin') {
      databases = getDatabaseSelectorObjectForDropdown(
        _.filter(adminDatabases, db => !!db.folderName), user,
      );
    } else {
      databases = getDatabaseSelectorObjectForDropdown(
        _.filter(user.teamId.databases, db => !!db.folderName), user,
      );
    }
  }
  return {
    databases,
    user,
    currentDatabase: store.AppReducer.currentDatabase,
  };
};

const mapDispatchToProps = dispatch => ({
  setDatabase(database) {
    return dispatch(setDatabase(database));
  },
  setAccountFolder(accountName) {
    if (localStorage.getItem('currentAccountFolder')) {
      localStorage.removeItem('currentAccountFolder');
    }
    localStorage.setItem('currentAccountFolder', accountName);
    return dispatch(setAccountFoldername(accountName));
  },
  setAccountId(id) {
    return dispatch(setAccountId(id));
  },
  getDatabases() {
    return dispatch(getAdminDatabases());
  },
  setAccountFoldername(folderName) {
    dispatch(setAccountFoldername(folderName));
  },
  setAccount: data => dispatch(setAccountAction(data)),
  emptyAllCharts() {
    dispatch(clearCharts());
  },
  emptyAllTargets() {
    dispatch(clearTargets());
  },
  emptyAllBanners() {
    dispatch(clearBanners());
  },
  emptyAllDocuments() {
    dispatch(clearDocuments());
  },
  emptyProjects() {
    dispatch(cleanProjects());
  },
});

const fadingLoading = keyframes`
  0%: {
    opacity: 1;
  }
  50%: {
    opacity: 0.5;
  }
  100%: {
    opacity: 1;
  }
`;

const CustomLoader = styled.div`
  ${({ loading }) => loading && `animation: ${fadingLoading} 500ms ease-in-out infinite;`}
`;

const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;

const LoaderContainer = styled.div`
  height: 33px;
  width: 397px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: Roboto;
  border: 1px solid #ccc;
  cursor: pointer;
  p {
    margin: 0px 15px;
  }
`;

const LinkButton = styled.p`
  color: #19A2C5;
  font-family: Roboto;
  font-size: 13px;
  font-size: 13px;
  margin: 0;
  margin-top: 18px;
  cursor: pointer;
`;

const Text = styled.p`
  font-family: Roboto;
  margin: 0px;
  padding: 0px;
  font-size: ${({ fontSize }) => (fontSize || '14')}px;
  font-weight: ${({ bold }) => bold && 'bold'};
  color: ${({ color }) => color || '#37474F'};
  margin-top: ${({ marginTop }) => marginTop || '0'}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}em` : 'normal')};
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DatabaseSelector));

import swal from 'sweetalert2';
import {
  CHANGE_SPREADSHEET_CARD_TYPE,
  CHANGE_SPREADSHEET_CARD_UNIT,
  CHANGE_SPREADSHEET_CARD_TARGET,
  RESET_SPREADSHEET_CARDS,
} from '../actionTypes/spreadsheetCardTypes';

export const changeCardType = (id, type) => (dispatch, getState) => {
  const { charts } = getState().spreadsheetChartReducer;
  const { spreadsheetCards } = getState().spreadsheetCardReducer;
  const verifyTarget = spreadsheetCards[id].target;
  let sumePercentages = 0;

  charts.forEach((chart) => {
    if (chart.id === id) {
      chart.data[verifyTarget].percentages.keys.forEach((percentage) => {
        sumePercentages += percentage.values[0];
      });
    }
  });

  if (type === 'pie' && sumePercentages > 100) {
    swal({
      title: 'Warning',
      text: "You've selected Pie Chart for a banner block that has multiple mentions. Note that this could generate a pie chart that adds up to more than 100%, do you want to proceed? ",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#19A2C5',
      cancelButtonColor: '#F78181',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        dispatch({ type: CHANGE_SPREADSHEET_CARD_TYPE, payload: { id, type } });
      }
    });
  } else {
    dispatch({ type: CHANGE_SPREADSHEET_CARD_TYPE, payload: { id, type } });
  }
};

export const changeCardUnit = (id, unit) => ({ type: CHANGE_SPREADSHEET_CARD_UNIT, payload: { id, unit } });

export const changeCardTarget = (id, target) => ({
  type: CHANGE_SPREADSHEET_CARD_TARGET,
  payload: { id, target },
});

export const resetSpreadsheetCards = () => ({ type: RESET_SPREADSHEET_CARDS });

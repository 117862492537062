import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import './style.css';
import menuIcon from '../../../assets/moreDots.png';


const toogleDropdown = (e) => {
  const index = e.currentTarget.value;
  document.getElementById(`myDropdown${index}`).classList.toggle('show');
  const elements = document.getElementsByClassName('dropdown-content');
  for (let i = 0; i < elements.length; i++) {
    const openDropdown = elements[i];
    if (openDropdown.classList.contains('show') && openDropdown.id !== `myDropdown${index}`) {
      openDropdown.classList.remove('show');
    }
  }
};

const DropdownMenu = (props) => (
    <Dropdown>
      <Button
        value={props.idDropdown}
        onClick={(e) => toogleDropdown(e)}
        className="dropbtn"
        style={{
          backgroundImage: `url(${menuIcon})`,
          backgroundRepeat: 'no-repeat',
          backgroundPositionY: '5px',
          ...props.buttonStyle,
        }}>
      </Button>
      <Panel
        style={{ ...props.style }}
        id={`myDropdown${props.idDropdown}`}
        className="dropdown-content">
        {props.children}
      </Panel>
    </Dropdown>
  );

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const Panel = styled.div`
  min-width: 160px;
  width: auto;
  height: all;
  left: -15px;
  margin-top: 0px;
  position: absolute;
  background-color: white;
  box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.5);
  float: right !important;
  display: none;
  z-index: 1;
`;

const Button = styled.button`
  background-color: transparent;
  color: rgba(0,0,0,0.8);
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  outline: none;
`;

const NavLink = styled.div`
  width: 100%;
  display: block;
  font-family: 'Roboto';
`;

const ButtonLink = styled.button`
  padding: 10px 15px 12px 15px;
  min-width: 100%;
  text-align: left;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: white;
  font-size: 0.8em;
  font-weight: 400;
  color: rgba(0,0,0,0.7);
  &:hover{
    background-color: rgba(0,0,0,0.1);
  };
`;

const Icon = props => (
  <FontAwesomeIcon
    style={{
      paddingRight: props.right,
      paddingLeft: props.left,
    }}
    icon={props.icon} 
  />
);

export {
  DropdownMenu,
  Panel,
  NavLink,
  ButtonLink,
  Icon,
};

import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faHandPaper } from '@fortawesome/free-solid-svg-icons';

function ErrorPage({ text, type = 'error', }) {
  return (
    <Container>
      <Text>
        <FontAwesomeIcon icon={type === 'forbidden' ? faHandPaper : faExclamationTriangle } />
      </Text>
      <Text>{text}</Text>
    </Container>
  )
}

ErrorPage.propTypes = {
  text: propTypes.string.isRequired,
  type: propTypes.oneOf(['error', 'forbidden']),
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #19A2C5;
  padding-top: 200px;
`;

const Text = styled.div`
  font-size: 3em;
  font-weight: 600;
  text-align: center;
`;

export default ErrorPage
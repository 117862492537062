import {
  SET_CURRENT_OVERVIEW, UPDATE_OVERVIEW_TITLE,
} from '../actionTypes/overviewTypes';
import {
  CLEAR_BANNERS,
} from '../actionTypes/bannerTypes';
import {
  CLEAR_TARGETS,
} from '../actionTypes/targetTypes';
import {
  restoreTargetTemplate, copyTarget, setCurrentTargetTemplate, setTargetTitle, setTargetQueryFromSavedTargets, changeTargetTemplatesMenu, toggleTargetBuilderView, getTargetSize, setBaseTarget,
} from './targetActions';
import {
  restoreAdvancedBanner, restoreBasicBanner, setBanner, setCurrentBannerTemplate, setEditingTo, setCurrentBannerTab,
} from './bannerActions';
import { setCharts } from './overviewChartActions';
import { setCards } from './overviewCardActions';


function setOverviewTarget(dispatch, { title, builder, _id }) {
  dispatch(setCurrentTargetTemplate({ id: _id, title }));
  dispatch(restoreTargetTemplate(builder));
  dispatch(setTargetTitle(title));
  dispatch(setTargetQueryFromSavedTargets(builder));
  dispatch(changeTargetTemplatesMenu('edit'));
  dispatch(toggleTargetBuilderView('templates'));
  dispatch(getTargetSize('target2', builder));
  dispatch(copyTarget('target2'));
}

function setOverviewBanner(dispatch, banner) {
  const {
    bannerType, builder, data,
  } = banner;
  if (bannerType === 'basic') {
    dispatch(setBanner(bannerType, builder || data));
  } else if (bannerType === 'advanced') {
    dispatch(setBanner(bannerType, builder.blocks || data.blocks));
  }
  if (bannerType === 'basic') {
    dispatch(restoreBasicBanner(builder || data));
  } else if (bannerType === 'advanced') {
    dispatch(restoreAdvancedBanner(builder || data));
  }
  dispatch(setCurrentBannerTemplate(banner));
  dispatch(setEditingTo(false));
  dispatch(setCurrentBannerTab(1));
}
const setOverview = payload => ({
  type: SET_CURRENT_OVERVIEW,
  payload,
});
export const setCurrentOverview = overview => (dispatch) => {
  const {
    banner, target2, charts, baseTarget, cards,
  } = overview.data;
  setOverviewBanner(dispatch, banner);
  if (target2) {
    setOverviewTarget(dispatch, target2);
  }
  dispatch(setBaseTarget(baseTarget));
  if (charts) {
    dispatch(setCharts(charts));
  }
  if (cards) {
    dispatch(setCards(cards));
  }
  dispatch(setOverview(overview));
};

export const updateTitle = title => (dispatch) => {
  dispatch({
    type: UPDATE_OVERVIEW_TITLE,
    payload: title,
  });
};

export const resetTargetAndBannerDispatch = () => (dispatch) => {
  dispatch({ type: CLEAR_BANNERS });
  dispatch({ type: CLEAR_TARGETS });
};

import axios from 'axios';
import download from 'downloadjs';
import swal from 'sweetalert2';
import showNotification from '../helpers/showNotification';
import { getErrorMessage } from './appActions';

export const downloadPresentation = (size, title = '') => async (dispatch, getState) => {
  const accountId = getState().AppReducer.currentAccountId;
  const { charts } = getState().overviewChartReducer;
  const { currentSurvey } = getState().marketReducer;
  const { cards } = getState().overviewCardReducer;
  const { labels } = getState().targetReducer;
  const base = getState().targetReducer.baseTarget.title;
  const target2 = getState().targetReducer.target2.title || 'Target 2';
  const targetNames = [base, target2];

  const data = {
    accountId,
    charts,
    cards,
    size,
    labels,
    targetNames,
    currentSurveyYear: currentSurvey.year,
  };

  swal({
    title: 'Starting download',
    text: 'Please wait a moment.',
    allowOutsideClick: false,
    onOpen: async () => {
      swal.showLoading();
      await axios.post('/api/export/powerpoint/overview', data, { responseType: 'blob' }).then((response) => {
        download(response.data, `${title.replace(/[ ]/gi, '_')}.pptx`, 'blob');
        swal.close();
      }).catch((err) => {
        swal.close();
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
    },
  });
};

export const downloadSavedOverview = (overview, type, size) => async (dispatch, getState) => {
  const { charts, cards } = overview.data;
  let { title } = overview;
  if (!title) {
    title = '';
  }
  if (charts && cards) {
    const labels = getState().targetReducer.labels;

    const data = {
      charts,
      cards,
      size,
      labels,
    };

    swal({
      title: 'Starting download',
      text: 'Please wait a moment.',
      allowOutsideClick: false,
      onOpen: async () => {
        swal.showLoading();
        await axios.post('/api/export/powerpoint/overview', data, { responseType: 'blob' }).then((response) => {
          download(response.data, `${title.replace(/[ ]/gi, '_')}.pptx`, 'blob');
          swal.close();
        }).catch((err) => {
          swal.close();
          showNotification({ message: 'Error downloading presentation', type: 'error' });
        });
      },
    });
  } else {
    showNotification({ message: 'Error downloading presentation', type: 'error' });
  }
};

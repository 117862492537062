import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { faTimes, faFile, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from "@sentry/react";
import {
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Title,
  Button,
} from '../../../GlobalComponents/BasicControls';
import ConfirmDialog from '../../../GlobalComponents/BlueConfirmDialog';
import { UserTypes } from './UserTypeList';
import { getErrorMessage } from '../../../../actions/appActions';
import showNotification from '../../../../helpers/showNotification';

export class DatabasesAndSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      databaseForms: [],
      disableOnSaving: false,
      openModal: false,
      databaseToDelete: null,
    };
  }

  componentWillMount() {
    const { databaseForms } = this.props.content.account;
    this.setState({
      databaseForms,
    });
  }

  componentWillReceiveProps(props) {
    const { databaseForms } = props.content.account;
    this.setState({
      databaseForms,
    });
  }

  handleInput = (e) => {
    const index = e.currentTarget.getAttribute('id');
    let { value, name } = e.target;
    value = name === 'database' ? e.target.files : value;
    const { type, state } = this.props;
    const databases = state;
    if (type === 'edit') {
      if (name === 'database') {
        const fileNamesAded = [];
        Array.from(value).forEach(file => fileNamesAded.push(file.name));
        const filteredDB = databases[index].storagedDatabases.filter(d => !d.deleted);
        const nameExists = _.flatten(filteredDB.map(d => fileNamesAded.filter(f => (f === d.fileName || f === d.fileName.name)))).length !== 0;
        if (!nameExists) {
          databases[index].storagedDatabases = [
            ...databases[index].storagedDatabases,
            ..._.map(value, d => ({
              fileName: d,
              deleted: false,
              new: true,
            })),
          ];
          this.props.updateParentDatabaseState({
            [index]: {
              ...databases[index],
            },
          });
        } else {
          showNotification({message: 'New file selected already exists in the list', type: 'warning'})
        }
      } else {
        this.props.updateParentDatabaseState({
          [index]: {
            ...databases[index],
            [name]: value,
          },
        });
      }
    } else {
      let valueForState = value;
      if (name === 'database') {
        const fileNamesAded = [];
        Array.from(value).forEach(file => fileNamesAded.push(file.name));
        const files = _.values(databases[index].database);
        const nameExists = _.flatten(files.map(d => fileNamesAded.filter(f => f === d.name))).length !== 0;
        if (!nameExists) {
          _.map(value, d => files.push(d));
          let gatheredFiles = {};
          for (let i = 0; i < files.length; i++) {
            const element = files[i];
            gatheredFiles = {
              ...gatheredFiles,
              [i]: element,
            };
          }
          valueForState = gatheredFiles;
          this.props.updateParentDatabaseState({
            [index]: {
              ...databases[index],
              [name]: valueForState,
            },
          });
        } else {
          showNotification({message: 'New file selected already exists in the list', type: 'warning'})
        }
      } else {
        this.props.updateParentDatabaseState({
          [index]: {
            ...databases[index],
            [name]: valueForState,
          },
        });
      }
    }
  }

  addForm = () => {
    const { databaseForms } = this.props.content.account;
    const index = databaseForms.length;
    const databases = this.props.state;
    let dbInfo = {};
    const { type } = this.props;
    if (type === 'edit') {
      dbInfo = {
        name: '',
        folderName: '',
        storagedDatabases: [],
        _type: 2,
        database: {},
        survey: {
          currentStoraged: null,
          olderStoraged: [],
          current: '',
          older: '',
        },
      };
    } else {
      dbInfo = {
        name: '',
        folderName: '',
        _type: 2,
        database: {},
        survey: {
          current: '',
          older: '',
        },
      };
    }
    this.props.setState({
      ...this.props.content,
      account: {
        ...this.props.content.account,
        databaseForms: [
          ...databaseForms,
          {
            databaseName: '',
            accesses: ['User', 'Manager', 'Business Owner'],
          },
        ],
        databases: {
          ...databases,
          [index]: dbInfo,
        },
      },
    });
  }

  handleInputFile = (e) => {
    const index = e.currentTarget.getAttribute('id');
    const { files, name } = e.target;
    const databases = this.props.state;
    const { type } = this.props;
    const newFileExtension = files[0].name.split('.');
    const extensionAccepted = newFileExtension[newFileExtension.length - 1] === 'pdf';
    if (extensionAccepted) {
      if (type === 'edit') {
        if (name === 'older') {
          databases[index].survey.olderStoraged = [{
            new: true,
            fileName: files[0],
            deleted: false,
          }];
          this.props.updateParentDatabaseState({
            [index]: {
              ...databases[index],
            },
          });
        } else if (name === 'current') {
          databases[index].survey.currentStoraged = {
            new: true,
            fileName: files[0],
            deleted: false,
          };
          this.props.updateParentDatabaseState({
            [index]: {
              ...databases[index],
            },
          });
        }
      } else {
        this.props.updateParentDatabaseState({
          [index]: {
            ...databases[index],
            survey: {
              ...databases[index].survey,
              [name]: files[0],
            },
          },
        });
      }
    } else {
      showNotification({message: 'Please select a pdf file', type: 'warning'})
    }
  }

  openModal = () => {
    this.setState({
      openModal: true,
    });
  }

  removeKeyFromAddedDatabases = (databases, value) => {
    const { databaseForms } = this.props.content.account;
    const newDatasbaseForms = databaseForms.filter((d, index) => index !== parseInt(value, 10));
    delete databases[value];
    const newDatabases = _.filter(databases, (d, key) => key !== 'edit');
    const newValues = newDatabases.map((d, index) => ({
      index,
      ...d,
    }));
    this.props.setState({
      ...this.props.content,
      account: {
        ...this.props.content.account,
        databaseForms: [
          ...newDatasbaseForms,
        ],
        databases: {
          ..._.keyBy(newValues, 'index'),
          edit: databases.edit,
        },
      },
    });
  }

  deleteSectionAdded = (e) => {
    const { type } = this.props;
    const { value } = e.currentTarget;
    const databases = this.props.state;
    if (type === 'edit') {
      const { folderName } = databases[value];
      if (folderName) {
        this.setState({
          databaseToDelete: folderName,
        }, () => this.openModal());
      } else {
        this.removeKeyFromAddedDatabases(databases, value);
      }
    } else {
      this.removeKeyFromAddedDatabases(databases, value);
    }
  }

  deleteDatabaseFromLocalFiles = () => {
    const { databaseToDelete } = this.state;
    const { deleteDatabaseFromLocalFiles, Id, setState } = this.props;
    const data = {
      folderName: databaseToDelete,
    };
    showNotification({message: 'Please, wait...', type: 'warning'})
    this.setState({
      disableOnSaving: true,
    });
    deleteDatabaseFromLocalFiles(Id, data)
      .then(() => {
        showNotification({message: 'Database removed successfully', type: 'success'})
        this.setState({
          openModal: false,
        });
        setState({
          selectedItemNav: 6,
        });
      })
      .catch((err) => {
        showNotification({message: 'Error removing database', type: 'error'})
        this.setState({
          disableOnSaving: false,
          openModal: false,
        });
        Sentry.captureException(err);
      });
  }


  deleteStoragedDatabase = (e) => {
    const { state, updateParentDatabaseState } = this.props;
    const keys = e.currentTarget.getAttribute('id');
    const indexes = keys.split(',');
    const databaseIndex = indexes[0];
    const databaseKey = indexes[1] === 'database' ? 'storagedDatabases' : 'survey';
    const databasesKeyIndex = indexes[2];
    const databases = state;
    const surveyKey = indexes[1] === 'current' ? 'currentStoraged' : 'olderStoraged';
    const dbInformation = databases[databaseIndex][databaseKey];
    if (indexes[1] === 'dbInCreate') {
      const data = _.values(databases[databaseIndex].database).filter((d, i) => i !== parseInt(databasesKeyIndex, 10));
      let filteredFiles = {};
      for (let j = 0; j < data.length; j++) {
        const element = data[j];
        filteredFiles = {
          ...filteredFiles,
          [j]: element,
        };
      }
      updateParentDatabaseState({
        [databaseIndex]: {
          ...databases[databaseIndex],
          database: filteredFiles,
        },
      });
    } else if (indexes[1] === 'database') {
      dbInformation[databasesKeyIndex] = {
        ...dbInformation[databasesKeyIndex],
        deleted: true,
      };
      updateParentDatabaseState({
        [databaseIndex]: {
          ...databases[databaseIndex],
          [databaseKey]: [
            ...databases[databaseIndex][databaseKey],
          ],
        },
      });
    } else if (indexes[1] === 'current') {
      dbInformation[surveyKey] = {
        ...dbInformation[surveyKey],
        deleted: true,
      };
      updateParentDatabaseState({
        [databaseIndex]: {
          ...databases[databaseIndex],
          [databaseKey]: [
            ...databases[databaseIndex][databaseKey],
          ],
          survey: {
            ...databases[databaseIndex].survey,
            [surveyKey]: {
              ...dbInformation[surveyKey],
            },
          },
        },
      });
    } else {
      dbInformation[surveyKey][databasesKeyIndex] = {
        ...dbInformation[surveyKey][databasesKeyIndex],
        deleted: true,
      };
      updateParentDatabaseState({
        [databaseIndex]: {
          ...databases[databaseIndex],
          [databaseKey]: [
            ...databases[databaseIndex][databaseKey],
          ],
          survey: {
            ...databases[databaseIndex].survey,
          },
        },
      });
    }
  }

  setBrandLabel = (icon, text, index, parentIndex, prefix) => (
    <BrandLabel key={index}>
      <ButtonDelete
        id={`${parentIndex},${prefix},${index}`}
        onClick={this.deleteStoragedDatabase}
      >
        <FontAwesomeIcon icon={faTimes} />
      </ButtonDelete>
      <FontAwesomeIcon
        style={{
          color: 'rgba(0,0,0,0.4)',
          margin: '10px 0 0 10px',
        }}
        icon={icon}
      />
      <p>
        {text}
      </p>
    </BrandLabel>
  )

  getDatabaseUploadSection = () => {
    const { databaseForms } = this.state;
    const { state, type } = this.props;
    const { edit } = state;
    return databaseForms.map((data, index) => {
      const {
        name, database, survey, storagedDatabases, folderName,
      } = state[index];

      const { databaseName, accesses } = data;
      let dbSize = _.size(database);
      if (type === 'edit') {
        const fileteredDeletedDatabases = storagedDatabases.filter(d => !d.deleted);
        dbSize = fileteredDeletedDatabases.length;
      }
      const requireInputFile = (name && edit ? (!!(name && dbSize === 0)) : (name ? (dbSize === 0) : (dbSize === 0 && edit ? (!!name) : false)));
      const requireInputText = edit ? (dbSize !== 0) : (index === 1);
      const showDeleteIcon = index === 0 ? (type === 'edit' ? (folderName !== 'none') : false) : index !== 1;
      return (
        <div key={index}>
          <Row>
            <Col size={12}>
              <CustomTitle>
                Databases
                {' '}
                {index + 1}
                :
                {' '}
                {databaseName}
                {
                  (showDeleteIcon)
                  && (
                  <div style={{ marginTop: '-30px' }}>
                    <LinkButton
                      noClick
                      value={index}
                      style={{
                        color: 'red', fontSize: '1.4em', marginLeft: '98%', marginTop: '-30px',
                      }}
                      type="button"
                      onClick={(e) => {
                        this.deleteSectionAdded(e);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTimes}
                      />
                    </LinkButton>
                    <ReactTooltip
                      place="right"
                      effect="solid"
                      type="light"
                      border
                      html
                    />
                  </div>
                  )}
              </CustomTitle>
              <Topic
                fontSize="0.85em"
              >
                Who has access
              </Topic>
              <Items>
                <ul>
                  {
                    accesses.map((access, indexAccess) => <li key={indexAccess}>{access}</li>)
                  }
                </ul>
              </Items>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                  Database Name (as it appears at sign in)
                </ControlLabel>
                <FormControl
                  autoComplete="off"
                  autoFocus={index === 0}
                  required={requireInputText}
                  type="text"
                  name="name"
                  id={index}
                  value={name}
                  onChange={e => this.handleInput(e)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                  Upload Database
                </ControlLabel>
                <FileControl
                  multiple
                  autoComplete="off"
                  required={requireInputFile}
                  type="file"
                  name="database"
                  id={index}
                  value={dbSize === 0 ? null : ''}
                  onChange={e => this.handleInput(e)}
                />
                {
                  type === 'edit'
                    ? storagedDatabases
                      && storagedDatabases.map((d, i) => !d.deleted && this.setBrandLabel(faFile, (!d.new ? d.fileName : d.fileName.name), i, index, 'database'))
                    : database
                      && _.map(database, (d, i) => this.setBrandLabel(faFile, d.name, i, index, 'dbInCreate'))
                }
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                  Upload CURRENT Survey
                </ControlLabel>
                <FileControl
                  autoComplete="off"
                  type="file"
                  name="current"
                  id={index}
                  accept="application/pdf"
                  value=""
                  onChange={e => this.handleInputFile(e)}
                />
                {
                  type === 'edit'
                    ? survey.currentStoraged
                      && !survey.currentStoraged.deleted && this.setBrandLabel(faFilePdf, (!survey.currentStoraged.new ? survey.currentStoraged.fileName : survey.currentStoraged.fileName.name), 0, index, 'current')
                    : survey.current
                    && (
                      <ControlLabel>
                      * File selected:
                        {survey.current.name}
                      </ControlLabel>
                    )}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col size={12}>
              <FormGroup>
                <ControlLabel>
                  Upload OLDER Survey
                </ControlLabel>
                <FileControl
                  autoComplete="off"
                  type="file"
                  name="older"
                  id={index}
                  accept="application/pdf"
                  value=""
                  onChange={e => this.handleInputFile(e)}
                />
                {
                  type === 'edit'
                    ? survey.olderStoraged
                    && survey.olderStoraged.map((d, i) => !d.deleted && this.setBrandLabel(faFilePdf, (!d.new ? d.fileName : d.fileName.name), i, index, 'older'))
                    : survey.older
                    && (
                    <ControlLabel>
                        * File selected:
                      {survey.older.name}
                    </ControlLabel>
                    )}
              </FormGroup>
            </Col>
          </Row>
          <Line />
        </div>
      );
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      handleSubmit, state, validateDatabasesFilesRequired, toogleCustomConfirmDialog,
    } = this.props;
    const { type } = this.props;
    if (type === 'edit') {
      const canContinue = validateDatabasesFilesRequired();
      if (!canContinue) {
        toogleCustomConfirmDialog();
      } else {
        this.editDatabases(state);
      }
    } else {
      handleSubmit(e);
    }
  }

  editDatabases = () => {
    showNotification({message: 'Please, wait...', type: 'warning'})
    this.setState({
      disableOnSaving: true,
    });
    const { state } = this.props;
    const databases = state;
    _.map(databases, (value, i) => {
      if (value.storagedDatabases) {
        const addedFiles = value.storagedDatabases.filter(d => (d.new && !d.deleted));
        if (addedFiles.lenght !== 0) {
          databases[i].database = {
            ...databases[i].database,
            ...addedFiles.map(d => d.fileName),
          };
        }
      }
      if (value.survey) {
        if (value.survey.currentStoraged) {
          databases[i].survey.current = value.survey.currentStoraged.new ? value.survey.currentStoraged.fileName : '';
        } else {
          databases[i].survey.current = '';
        }
        if (value.survey.olderStoraged) {
          databases[i].survey.older = value.survey.olderStoraged[0] ? (value.survey.olderStoraged[0].new ? value.survey.olderStoraged[0].fileName : '') : '';
        } else {
          databases[i].survey.older = '';
        }
      }
    });
    const newDatabases = _.values(databases);
    const data = {
      databases: _.compact(newDatabases.filter(value => value !== true)).map((databaseInformation) => {
        const {
          name, survey, database, storagedDatabases, folderName,
        } = databaseInformation;
        return {
          name,
          folderName: folderName || 'none',
          database,
          storagedDatabases: storagedDatabases.filter(d => !d.new),
          currentStoraged: survey.currentStoraged,
          olderStoraged: survey.olderStoraged[0] ? survey.olderStoraged[0] : [],
          _type: databaseInformation._type,
          current_survey: survey.current ? survey.current : '',
          older_survey: survey.older ? survey.older : '',
        };
      }),
    };
    const { uploadAndEditDatabases, Id, setState } = this.props;
    uploadAndEditDatabases(data, Id)
      .then(() => {
        showNotification({message: 'Files updated successfully', type: 'success'})
        setState({
          selectedItemNav: 6,
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({message, type})
        this.setState({
          disableOnSaving: false,
        });
      });
  }

  closeModal = () => {
    this.setState({
      openModal: false,
    });
  }

  onCancelCreatingAndEditing = () => {
    const { type, onCancelCreating, onCancelEditing } = this.props;
    if (type === 'edit') {
      onCancelEditing();
    } else {
      onCancelCreating();
    }
  }

  render() {
    const { disableOnSaving, openModal } = this.state;
    const { type } = this.props;
    return (
      <Body>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col size={6}>
              <Row>
                <Col size={12} style={{ paddingBottom: 0 }}>
                  <Title>
                    Upload Databases & Surveys
                  </Title>
                </Col>
              </Row>
              {this.getDatabaseUploadSection()}
              <Row>
                <Col size={6}>
                  <LinkButton
                    onClick={this.addForm}
                    type="button"
                  >
                    Add another database
                  </LinkButton>
                </Col>
              </Row>
              <Row>
                <Col size={2}>
                  <Button
                    onClick={this.onCancelCreatingAndEditing}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col size={2}>
                  <Button
                    disabled={type === 'edit' ? disableOnSaving : false}
                    tColor="white"
                    bgColor="#455A64"
                  >
                    {type === 'edit' ? 'Save' : 'Continue'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col size={5} xOffset={1}>
              <UserTypes />
            </Col>
          </Row>
        </Form>
        <ConfirmDialog
          show={openModal}
          title="Delete Database?"
          message="Are you sure you want to delete this database?
                  note: prior unsaved changes will be lost"
          onConfirm={this.deleteDatabaseFromLocalFiles}
          onCancel={this.closeModal}
        />
      </Body>
    );
  }
}

const Body = styled.div`
  ${''}
  width: 98%;
  padding: 0 2%;
`;

const Topic = styled(ControlLabel)`
  ${''}
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4em')};
  color: rgba(65, 71, 76, 1);
`;

const Line = styled.hr``;

const CustomTitle = styled(Title)`
  padding: 2px 0 0px 0;
`;
const Items = styled.div`
  padding: 0;
  color: rgba(65, 71, 76, 1);
  ul {
    margin: 0;
    padding: 0 0 0 20px;
    font-size: 0.85em;

    li {
      padding: 1px 0 5px 0;
    }
  }
`;

const FileControl = styled(FormControl)`
  border: 0;
  padding-left: 0;
  &:focus {
    border: none;
  }
`;
const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 0.87em;
  color: rgb(33, 151, 250);
  cursor: pointer;
  outline: none;
  padding: 0;
`;

const BrandLabel = styled.div`
  border: 1px solid rgba(0,0,0,0.05);
  width: 100%;
  height: 35px;
  background-color: #FFF;
  p {
    font-size: 0.9em;
    font-family: 'Roboto';
    color: rgba(0,0,0,0.8);
    max-height: 20px;
    height: 20px;
    margin-top: -18px;
    margin-left: 30px;
    width: 85%;
  }
`;
const ButtonDelete = styled.div`
  cursor: pointer;
  float: right;
  margin-right: 10px;
  margin-top: 10px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background-color: rgba(0,0,0,0.4);
  text-align: center;
  color: #FFF;
  font-size: 0.6em;
  line-height: 16px;
`;

import React from 'react';
import { size } from 'lodash';
import PropTypes from 'prop-types';

import { ToolbarButton } from './ToolbarButton';

const ToolbarButtonIndex = (props) => {
  const {
    Data, currentTab, showIndex, functions,
  } = props;
  const disabled = size(Data.Tabs[currentTab].Targets) === 1;
  return (
    <ToolbarButton
      disabled={disabled}
      onClick={functions.showIndex}
      active={showIndex}
      text="Index"
    />
  );
};

ToolbarButtonIndex.propTypes = {
  Data: PropTypes.instanceOf(Object).isRequired,
  functions: PropTypes.instanceOf(Object).isRequired,
  showIndex: PropTypes.bool,
  currentTab: PropTypes.number.isRequired,
};

ToolbarButtonIndex.defaultProps = {
  showIndex: false,
};

export default ToolbarButtonIndex;

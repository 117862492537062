import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getMembers, sendInvitations, cancelInvitation } from '../../actions/teamActions';
import Datatable from '../GlobalComponents/DataTable/DataTable';
import { Button, Row, Col } from '../GlobalComponents/BasicControls';
import { getUser } from '../../actions/profileActions';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

class PendingInvites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      teamId: '',
      rows: {},
      disableButtons: false,
    };
  }

  componentWillMount() {
    const { getUser, getMembers } = this.props;
    getUser()
      .then(() => {
        const { user } = this.props;
        const { teamId } = user;
        getMembers(teamId._id)
          .then(() => {
            this.assignMembersToState(this.props);
          })
          .catch((err) => {
            const { message, type } = getErrorMessage(err);
            showNotification({message, type});
          });
      });
  }

  componentDidMount() {
    if (_.size(this.props.members) !== 0) {
      this.assignMembersToState(this.props);
    }
  }

  componentWillReceiveProps(props) {
    this.assignMembersToState(props);
  }

  assignMembersToState = (props) => {
    const { user } = props;
    const filteredMembers = _.values(props.members).filter(member => member.status === 'INVITED');
    const newMembers = filteredMembers.map(member => ({
      ...member,
      accepted: member.accepted,
      user_type: member.user_type,
    }));
    let members = newMembers;
    if (user.role.name === 'admin' && user.role) {
      members = newMembers.filter(member => member.role.name === 'admin');
    }
    this.setState({
      members,
    });
  }

  handleSelection = (e, row) => {
    if (e.currentTarget.checked) {
      const { rows } = this.state;
      this.setState({
        rows: {
          ...rows,
          [row._id]: row,
        },
      });
    } else {
      const { rows } = this.state;
      const newRows = _.filter(rows, o => o._id !== row._id);
      this.setState({
        rows: _.keyBy(newRows, '_id'),
      });
    }
  }

  selectRow = (cell, row, index) => (
    <input
      key={index}
      className="checkbox-in-table"
      onChange={e => this.handleSelection(e, row)}
      name="selected"
      type="checkbox"
    />
  )

  uncheckInputs = () => {
    const checkboxes = document.getElementsByClassName('checkbox-in-table');
    for (let i = 0; i < checkboxes.length; i++) {
      const checkbox = checkboxes[i];
      checkbox.checked = false;
    }
  }

  resendInvitation = () => {
    const { rows } = this.state;
    const sizeOfMembers = _.size(rows);
    if (sizeOfMembers === 0) {
      showNotification({message: 'Please, select a receiver', type:'warning'});
    } else {
      this.setState({ disableButtons: true });
      const { rows } = this.state;
      const { user, sendInvitations } = this.props;
      const data = {
        teamId: user.teamId._id,
        rows,
      };
      sendInvitations(data)
        .then((response) => {
          const errorInvites = response.sent.filter(result => result.accepted.length > 0);
          if (errorInvites.length === 0) {
            this.setState({
              disableButtons: false,
              rows: {},
            }, () => {
              this.uncheckInputs();
            });
            showNotification({message: 'Some invitations were not sent', type:'warning'});
          } else {
            this.setState({
              disableButtons: false,
              rows: {},
            }, () => {
              this.uncheckInputs();
            });
            showNotification({message: 'Invitations were sent successfully', type:'success'});
          }
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({message, type});
          this.setState({
            disableButtons: false,
          });
        });
    }
  }

  cancelInvitation = () => {
    const { rows } = this.state;
    const sizeOfMembers = _.size(rows);
    if (sizeOfMembers === 0) {
      showNotification({message: 'Please, select a team member', type:'warning'});
    } else {
      const { rows } = this.state;
      const { user, cancelInvitation } = this.props;

      const data = {
        teamId: user.teamId._id,
        rows,
      };
      cancelInvitation(data)
        .then((response) => {
          this.setState({
            rows: {},
          }, () => {
            this.uncheckInputs();
          });
          showNotification({message: 'Invitations were canceled successfully', type:'success'});
        })
        .catch((err) => {
          const { message, type } = getErrorMessage(err);
          showNotification({message, type});
        });
    }
  }

  render() {
    const columns = [
      {
        dataField: 'first_name',
        text: 'First Name',
        sort: true,
        columnStyle: {
          width: '29%',
        },
      },
      {
        dataField: 'last_name',
        text: 'Last Name',
        sort: true,
        columnStyle: {
          width: '28%',
        },
      },
      {
        dataField: 'email',
        text: 'Email',
        columnStyle: {
          width: '36%',
        },
      },
      {
        dataField: 'first_name',
        text: '',
        columnStyle: {
          width: '5%',
          padding: '0 0 0 0',
          textAlign: 'center',
        },
        formatter: this.selectRow,
      },
    ];

    const { members, disableButtons } = this.state;
    return (
      <Wrapper>
        <Title>
          Pending Invites
        </Title>
        <Content>
          <DataTableContainer>
            <Datatable showHeader={false} columns={columns} data={members} />
          </DataTableContainer>
          <Options>
            <Row>
              <Col size={4}>
                <Button
                  disabled={disableButtons}
                  onClick={this.resendInvitation}
                  type="button"
                  bgColor="#CFD8DC"
                  tColor="#37474F"
                >
                Resend
                </Button>
              </Col>
              <Col size={8}>
                <Button
                  disabled={disableButtons}
                  onClick={this.cancelInvitation}
                  type="button"
                  bgColor="#CFD8DC"
                  tColor="#37474F"
                >
                Cancel Invitation
                </Button>
              </Col>
            </Row>
          </Options>
        </Content>
      </Wrapper>
    );
  }
}

const Options = styled.div`
  position: absolute;
  right: 0;
`;


const Wrapper = styled.div`
    height: 80px;
    width: 100%
`;

const Title = styled.div`
    font-family: 'Roboto';
    font-size: 16px;
    color: #37474F;
    line-height: 24px;
    font-weight: 500;
`;

const DataTableContainer = styled.div`
  width: 541px;
`;

const Content = styled.div`
  position: relative;
  margin: 10px 0 0 0;
  width: 100%;
  padding: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
`;

const mapStateToProps = state => ({
  members: state.teamReducer.members,
  user: state.AppReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getUser: () => dispatch(getUser()),
  getMembers: teamId => dispatch(getMembers(teamId)),
  sendInvitations: data => sendInvitations(data),
  cancelInvitation: data => dispatch(cancelInvitation(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingInvites);

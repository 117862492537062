import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Scrollbar from '../../GlobalComponents/Scrollbar';
import TargetSize from './TargetSize';
import Buttons from './Buttons';

class TargetSummary extends Component {
  static getExpression({ operator, negated }) {
    let expression = '';
    if (negated) {
      expression += 'Exclude ';
    } else {
      expression += 'Include ';
    }

    if (operator === 'OR') {
      expression += 'any ';
    } else {
      expression += 'all ';
    }
    expression += 'of the following: ';
    return expression;
  }

  render() {
    const { blocks, view, close, data, type, actions } = this.props;
    const titleText = view === 'builder' ? 'Target Summary' : 'Template Summary';
    return (
      <Container>
        <Title>{titleText}</Title>
        <Summary>
          <Scrollbar>
            {_.map(blocks, (block, index) => (
              <Block key={index}>
                {block.operator && `${block.operator}, `}
                {_.size(block.questions) > 0 && <p>{`${TargetSummary.getExpression(block.itemsOperator)}`}</p>}
                {_.map(block.questions, (question, index) => (
                  <Question key={index}>
                    <p>{question.text}</p>
                    {_.map(question.answers, (answer, index) => <Answer key={index}>&bull;{' '}{answer.text}</Answer>)}
                  </Question>
                ))}
              </Block>
            ))}
          </Scrollbar>
        </Summary>
        <TargetSize blocks={blocks} type={type} />
        <Buttons close={close} actions={actions} data={data} type={type} />
      </Container>
    );
  }

}

const Container = styled.div`
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
  font-family: Roboto;
  color: #37474F;
  margin-top: 64px;
  margin-left: 24px;
`;

const Summary = styled.div`
  width: 275px;
  height: 420px;
  margin-bottom: 10px;
  margin-left: 24px;
`;

const Block = styled.div`
  margin-top: 7px;
  background-color: #F6F7F8;
  width: 230px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
  padding: 12px 10px 17px 14px;
  p {
    color: #607D8B;
    margin: 0px;
  }
`;

const Question = styled.ul`
  margin-top: 6px;
  padding: 0px;
  p {
    margin-bottom: 7px;
    color: #37474F;
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 500;
  }
`;

const Answer = styled.li`
  list-style: none;
  color: #37474F;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 13px;
`;

export default TargetSummary;

import _ from 'lodash';

function builderDataToQuery(blocks) {
  let query = '';

  // Skip block if its empty (usually the last one)
  const filteredBlocks = _.filter(blocks, block => _.size(block.questions) > 0);

  _.map(filteredBlocks, (block) => {
    if (block.operator) query += `-${block.operator}-`;
    if (block.itemsOperator.negated) query += '!';
    query += blockToQuery(block);
  });
  return query;
}

function blockToQuery(block) {
  const questionsSize = _.size(block.questions);
  let qIndex = 0;
  let query = '';
  query += '(';
  _.map(block.questions, (question) => {
    const answersSize = _.size(question.answers);
    let aIndex = 0;
    _.map(question.answers, (answer) => {
      if ((questionsSize - 1 === qIndex) && (answersSize - 1 === aIndex)) {
        query += `${question.id}-${answer.index}`;
      } else {
        query += `${question.id}-${answer.index}_${block.itemsOperator.operator}_`;
      }
      aIndex += 1;
    });
    qIndex += 1;
  });
  query += ')';
  return query;
}

export default builderDataToQuery;

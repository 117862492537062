import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { deleteChart } from '../../../actions/overviewChartActions';
import BuildBannerModal from '../../BuildBannerModal';
import EditQuestion from './EditQuestion';
import arrowDown from '../../../assets/ico-arrow-down.svg';
import arrowUp from '../../../assets/ico-arrow-up.svg';

const Trigger = ({ openModal }) => (
  <div id="edit-question-trigger" onClick={openModal} style={{ display: 'none' }}>
    edit
  </div>
);

class QuestionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleRemove = () => {
    const { id, deleteChart } = this.props;
    this.setState({ anchorEl: null });
    deleteChart(id);
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { id, banner } = this.props;
    const iconStyle = {
      style: {
        width: '15px',
        height: '15px',
        margin: '5px 12px',
        color: '#90A4AE',
      },
    };
    return (
      <Container>
        <Button
          aria-owns={anchorEl ? 'question-menu' : null}
          aria-haspopup="true"
          style={{ float: 'right' }}
          onClick={this.handleClick}
        >
          <DropDownIcon src={anchorEl ? arrowUp : arrowDown} alt="arrow-down" />
        </Button>
        <BuildBannerModal type="overview-edit" trigger={Trigger} />
        <Menu
          id="question-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          transformOrigin={{ vertical: -36, horizontal: 120 }}
        >
          <EditQuestion
            id={id}
            banner={banner}
            handleClose={this.handleClose}
          />
          <MenuItem onClick={this.handleRemove} style={{ paddingLeft: 5 }}>
            <FontAwesomeIcon icon={faTrashAlt} {...iconStyle} />
            Remove Question
          </MenuItem>
        </Menu>
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteChart: id => dispatch(deleteChart(id)),
});

const Container = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

const DropDownIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export default connect(null, mapDispatchToProps)(QuestionMenu);

import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

const filterOptions = (option, input) => {
  const label = option.label.toUpperCase();
  const search = input.toUpperCase();
  return label.includes(search);
};
const customStyles = {
  control: base => ({
    ...base,
    background: 'white',
    outline: 0,
    border: 'none',
    'box-shadow': 'none',
    'font-size': '13px',
    'font-style': 'italic',
    'font-color': '#90A4AE',
  }),
  dropdownIndicator: base => ({
    ...base,
    display: 'none',
  }),
  multiValue: base => ({
    ...base,
    display: 'none',
  }),
};

class SearchBar extends Component {
  handleChange = (selectedOptions) => {
    const { blockId, actions } = this.props;
    const { addDataPoint } = actions;
    selectedOptions.forEach((option) => {
      addDataPoint(blockId, option.value, option.path);
    });
  }

  render() {
    const { options } = this.props;
    return (
      <Select
        onChange={this.handleChange}
        options={options}
        isMulti
        styles={customStyles}
        placeholder="+ add keywords or phrases"
        maxMenuHeight={250}
        filterOption={filterOptions}
      />
    );
  }
}

const mapStateToProps = state => ({
  options: state.categoriesReducer.selectData,
});

export default connect(mapStateToProps)(SearchBar);

import swal from 'sweetalert2';
import {
  ADD_OVERVIEW_CARD,
  ADD_OVERVIEW_CARDS,
  DELETE_OVERVIEW_CARD,
  CHANGE_OVERVIEW_CARD_TYPE,
  CHANGE_OVERVIEW_CARD_UNIT,
  CHANGE_OVERVIEW_CARD_TARGET,
  CHANGE_OVERVIEW_ALL_CARD_TARGET,
} from '../actionTypes/overviewCardTypes';

export const addCard = id => (dispatch, getState) => {
  const { target2 } = getState().targetReducer;

  dispatch({ type: ADD_OVERVIEW_CARD, payload: id });
  dispatch({
    type: CHANGE_OVERVIEW_CARD_TARGET,
    payload: { id, target: target2.query ? 2 : 0 },
  });
};
export const setCards = cards => ({ type: ADD_OVERVIEW_CARDS, payload: cards });
export const deleteCard = id => ({ type: DELETE_OVERVIEW_CARD, payload: id });

export const changeCardType = (id, type) => (dispatch, getState) => {
  const { charts } = getState().overviewChartReducer;
  const { cards } = getState().overviewCardReducer;
  const verifyTarget = cards[id].target < 2 ? cards[id].target : 0;
  let sumePercentages = 0;

  charts.forEach((chart) => {
    if (chart.id === id) {
      chart.data[verifyTarget].percentages[0].forEach((percentage) => {
        sumePercentages += percentage.value;
      });
    }
  });

  if (type === 'pie' && sumePercentages > 100) {
    swal({
      title: 'Warning',
      text: "You've selected Pie Chart for a banner block that has multiple mentions. Note that this could generate a pie chart that adds up to more than 100%, do you want to proceed? ",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#19A2C5',
      cancelButtonColor: '#F78181',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        dispatch({ type: CHANGE_OVERVIEW_CARD_TYPE, payload: { id, type } });
      }
    });
  } else {
    dispatch({ type: CHANGE_OVERVIEW_CARD_TYPE, payload: { id, type } });
  }
};

export const changeCardUnit = (id, unit) => ({ type: CHANGE_OVERVIEW_CARD_UNIT, payload: { id, unit } });

export const changeCardTarget = (id, target) => (dispatch, getState) => {
  const { charts } = getState().overviewChartReducer;
  const { cards } = getState().overviewCardReducer;
  const verifyTarget = cards[id].target < 2 ? cards[id].target : 0;
  let sumePercentages = 0;
  charts.forEach((chart) => {
    if (chart.id === id) {
      chart.data[verifyTarget].percentages[0].forEach((percentage) => {
        sumePercentages += percentage.value;
      });
    }
  });

  if (cards[id].chartType === 'pie' && sumePercentages > 100) {
    swal({
      title: 'Warning',
      text: "You've selected Pie Chart for a banner block that has multiple mentions. Note that this could generate a pie chart that adds up to more than 100%, do you want to proceed? ",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#19A2C5',
      cancelButtonColor: '#F78181',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        dispatch({
          type: CHANGE_OVERVIEW_CARD_TARGET,
          payload: { id, target },
        });
      }
    });
  } else {
    dispatch({
      type: CHANGE_OVERVIEW_CARD_TARGET,
      payload: { id, target },
    });
  }
};

export const changeAllCardTarget2AndBase = () => (dispatch, getState) => {
  const { target2 } = getState().targetReducer;

  dispatch({
    type: CHANGE_OVERVIEW_ALL_CARD_TARGET,
    payload: { target: target2.query ? 2 : 0 },
  });
};

export const changeTypeTargetToNewCard = () => (dispatch, getState) => {
  const { target2 } = getState().targetReducer;
  const { charts } = getState().overviewChartReducer;
  const id = charts[charts.length - 1].id;

  dispatch({
    type: CHANGE_OVERVIEW_CARD_TARGET,
    payload: { id, target: target2.query ? 2 : 0 },
  });
};

import _ from 'lodash';
import {
  GET_PROJECTS, DUPLICATE_PROJECT, DELETE_PROJECT, SHARE_PROJECT, SET_SHARED_TEMPLATES, ADD_SHARED_TEMPLATE, UNSHARE_TEMPLATE, DELETE_SHARED_TEMPLATE, CLEAN_PROJECTS,
} from '../actionTypes/projectTypes';
import { SAVE_TARGET_TO_PROJECTS } from '../actionTypes/targetTypes';
import { LOG_OUT } from '../actionTypes/appTypes';
import { SAVE_BANNER_TEMPLATE_TO_PROJECTS } from '../actionTypes/bannerTypes';

const initialState = {
  projects: {},
  sharedTemplates: {},
};
const ProjectReducer = (state = initialState, action) => {
  if (action.type === CLEAN_PROJECTS) {
    return initialState;
  } if (action.type === GET_PROJECTS) {
    return {
      ...state,
      projects: action.payload,
    };
  } if (action.type === DUPLICATE_PROJECT) {
    const addedProject = action.payload;
    return {
      ...state,
      projects: {
        ...state.projects,
        [addedProject._id]: addedProject,
      },
    };
  } if (action.type === DELETE_PROJECT) {
    const projectId = action.payload;
    const newProjects = _.filter(state.projects, o => o._id !== projectId);
    return {
      ...state,
      projects: newProjects,
    };
  } if (action.type === SHARE_PROJECT) {
    const project = action.payload;
    return {
      ...state,
      projects: {
        ...state.projects,
        [project._id]: project,
      },
    };
  } if (action.type === SET_SHARED_TEMPLATES) {
    const templates = action.payload;
    return {
      ...state,
      sharedTemplates: templates,
    };
  } if (action.type === ADD_SHARED_TEMPLATE) {
    return {
      ...state,
      sharedTemplates: { ...state.sharedTemplates, [action.payload._id]: action.payload },
      projects: {
        ...state.projects,
        [action.payload._id]: action.payload,
      },
    };
  } if (action.type === UNSHARE_TEMPLATE) {
    const templateId = action.payload._id;
    const newState = {
      ...state,
      sharedTemplates: { ...state.sharedTemplates },
      projects: {
        ...state.projects,
        [action.payload._id]: action.payload,
      },
    };
    delete newState.sharedTemplates[templateId];
    return newState;
  } if (action.type === DELETE_SHARED_TEMPLATE) {
    const templateId = action.payload;
    const newState = {
      ...state,
      sharedTemplates: { ...state.sharedTemplates },
    };
    delete newState.sharedTemplates[templateId];
    return newState;
  } if ((action.type === SAVE_TARGET_TO_PROJECTS) || action.type === SAVE_BANNER_TEMPLATE_TO_PROJECTS) {
    const newState = { ...state };
    newState.projects = { ...state.projects, [action.payload._id]: action.payload };
    if (action.payload.shared) {
      newState.sharedTemplates = { ...state.sharedTemplates, [action.payload._id]: action.payload };
    }
    return newState;
  } if (action.type === LOG_OUT) {
    return initialState;
  }
  return state;
};

export default ProjectReducer;

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import TextInput from '../GlobalComponents/FormComponents/TextInput';
import CheckBox from '../GlobalComponents/FormComponents/CheckBox';
import { Label } from '../GlobalComponents/FormComponents/StyledComponents';
import { changeBannerTitle, setEditingTo, saveBannerTemplate } from '../../actions/bannerActions';
import showNotification from '../../helpers/showNotification';

class SaveTemplateBox extends Component {
  state = {
    modalOpen: false,
    checked: false,
    templateSaved: false,
  }

  templateNameRef = React.createRef();

  handleChange = (e) => {
    const { checked } = this.state;
    const { type, value } = e.target;
    const { changeBannerTitle, currentTab } = this.props;
    if (type === 'checkbox') {
      if (!checked) {
        this.saveBanner();
      } else {
        this.setState({ checked: false });
      }
    } else if (type === 'text') {
      let bannerType;
      if (currentTab === 0) {
        bannerType = 'basic';
      } else if (currentTab === 1) {
        bannerType = 'edit';
      } else if (currentTab === 2) {
        bannerType = 'advanced';
      }
      changeBannerTitle(bannerType, value);
    }
  }

  closeModal = () => {
    this.setState({ modalOpen: false });
  }

  unCheckCheckbox = () => {
    this.setState({ checked: false });
  }

  saveBanner = () => {
    const {
      saveBannerTemplate, currentTab, currentTemplate, basicBuilderTitle, advancedBuilderTitle,
    } = this.props;
    let bannerType;
    let title;
    if (currentTab === 0) {
      bannerType = 'basic';
      title = basicBuilderTitle;
    } else if (currentTab === 1) {
      title = currentTemplate.title;
    } else if (currentTab === 2) {
      bannerType = 'advanced';
      title = advancedBuilderTitle;
    }
    const banner = {
      title,
      shared: false,
      type: 'TEMPLATE',
      subType: 'QUESTION',
      bannerType,
      public: false,
    };
    saveBannerTemplate(banner)
      .then(() => {
        this.setState({ successMessage: 'Banner added' });
        if (currentTab === 0) {
          // changeBannerTitle('basic', '');
        } else if (currentTab === 1) {
          // changeBannerTitle('edit', '');
        } else if (currentTab === 2) {
          // changeBannerTitle('advanced', '');
        }
        showNotification({ message: 'Successfully saved template!' });
      })
      .catch((e) => {
        this.setState({ errorMessage: e.message });
        showNotification({ message: e.message, type: 'error' });
        this.focusInput();
      });
  }

  focusInput = () => {
    if (this.templateNameRef.current) {
      this.templateNameRef.current.focus();
    }
  }

  isValid = (type) => {
    const { basicBuilderTitle, advancedBuilderTitle } = this.props;
    if (type === 'basic') {
      return !basicBuilderTitle;
    }
    return !advancedBuilderTitle;
  }

  bannerHasTitle = (type) => {
    const { basicBuilderTitle, advancedBuilderTitle } = this.props;
    if (type === 'basic') {
      return !basicBuilderTitle;
    }
    return !advancedBuilderTitle;
  }

  formStates = () => {
    const {
      currentTab, currentTemplate, basicBuilderTitle, advancedBuilderTitle, basicBuilderSaved, advancedBuilderSaved,
    } = this.props;

    const isBasicPage = currentTab === 0;
    const isEditPage = currentTab === 1;
    const isAdvancedPage = currentTab === 2;

    let textBoxText;
    let checkBoxDisabled;
    let textboxDisabled;
    let checkboxChecked;

    if (isEditPage) {
      textBoxText = currentTemplate.title;
      checkBoxDisabled = true;
    } else if (isBasicPage) {
      if (basicBuilderSaved) {
        textBoxText = basicBuilderTitle;
        checkBoxDisabled = true;
        textboxDisabled = true;
        checkboxChecked = true;
      } else {
        if (this.bannerHasTitle('basic')) {
          checkBoxDisabled = true;
        } else {
          checkBoxDisabled = false;
        }
        textBoxText = basicBuilderTitle;
      }
    } else if (isAdvancedPage) {
      if (advancedBuilderSaved) {
        textBoxText = advancedBuilderTitle;
        textboxDisabled = true;
        checkBoxDisabled = true;
        checkboxChecked = true;
      } else {
        if (this.bannerHasTitle('advanced')) {
          checkBoxDisabled = true;
        } else {
          checkBoxDisabled = false;
        }
        textBoxText = advancedBuilderTitle;
      }
    }
    return {
      textBoxText, checkBoxDisabled, isEditPage, checkboxChecked, textboxDisabled,
    };
  }

  render() {
    const { textBoxText, checkBoxDisabled, isEditPage, checkboxChecked, textboxDisabled } = this.formStates();
    return (
      <Bottom>
        <div style={{ margin: '5px 0px 15px 0px' }}>
          <Label>Custom Block Title (required)</Label>
          <TextInput
            onChange={this.handleChange}
            innerRef={this.templateNameRef}
            value={textBoxText}
            name="templateName"
            type="text"
            disabled={textboxDisabled}
          />
        </div>
        {
          !isEditPage
          && (
          <CheckBox
            label="Save as template."
            id="saveBanner"
            onChange={this.handleChange}
            checked={checkboxChecked}
            disabled={checkBoxDisabled}
          />
          )
        }

      </Bottom>
    );
  }
}
const Bottom = styled.div`
  position: absolute;
  bottom: 10px;
  width: 90%;
  padding: 0% 5%;
  border-top: 2px solid #DDDDDD; 
`;

const mapStateToProps = (store) => {
  const {
    currentTemplate, basicBuilderTitle, advancedBuilderTitle, currentTab, basicBuilderSaved, advancedBuilderSaved,
  } = store.bannerReducer;
  return {
    currentTab,
    currentTemplate,
    basicBuilderTitle,
    advancedBuilderTitle,
    basicBuilderSaved,
    advancedBuilderSaved,
  };
};

const mapDispatchToProps = dispatch => ({
  changeBannerTitle: (type, text) => {
    dispatch(changeBannerTitle(type, text));
    if (type === 'edit') {
      dispatch(setEditingTo(true));
    }
  },
  saveBannerTemplate(data) {
    return dispatch(saveBannerTemplate(data));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SaveTemplateBox);

import React, { Component } from 'react';
import styled from 'styled-components';
import Checkbox from '../FormComponents/CheckBox';

class Answer extends Component {
  handleClick = (e) => {
    const { blockId, path } = this.props;
    const { addDataPoint, removeDataPoint } = this.props.actions;
    if (e.target.checked) {
      addDataPoint(blockId, e.target.value, path);
    } else {
      removeDataPoint(blockId, e.target.value);
    }
  };

  render() {
    const { answer, blockId, question, questionInBuilder } = this.props;
    const answersInBuilder = questionInBuilder ? Object.keys(questionInBuilder.answers) : [];
    const answerId = `${question.id}-${answer.index + 1}`;
    const checked = answersInBuilder.includes(answerId);
    return (
      <Wrapper>
        <Label htmlFor={`${blockId}-${answerId}`}>
          {`${answer.index + 1}. ${answer.text}`}
        </Label>
        <CheckboxContainer>
          <Checkbox
            id={`${blockId}-${answerId}`}
            value={answerId}
            name={`${blockId}-${question.id}`}
            title={question.title}
            onChange={e => this.handleClick(e)}
            checked={checked}
          />
        </CheckboxContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  min-height: 36px;
  align-items: center;
  padding: 0px 0px 0px 40px;
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 400;
  color: #37474F;
  border-top: 1px #DEE1E3 solid;
  position: relative;
  &:first-child {
    border-top: 1px #DEE1E3 solid;
  }
`;

const Label = styled.label`  
  display: block; 
  width: 100%;
  height: 100%;
  padding: 2px;
  font-size: 13px;
  color: #37474F;
`;
const CheckboxContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  transform: translateY(-50%);
`;

export default Answer;

import React, { Component } from 'react';
import styled from 'styled-components';

class DataCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }


  getIndexValue = () => {
    const {
      showIndex,
      position,
      indexData,
    } = this.props;
    if (showIndex && position > 0) {
      if (isNaN(indexData)) {
        return '-';
      }
      return indexData ? parseFloat(Math.round(indexData.toFixed(0) * 100) / 100) : 0;
    }
    return null;
  }

  roundingPercentData = (percentData) => percentData ? parseFloat(Math.round(percentData.toFixed(2) * 100) / 100) : 0

  roundingThousandsData = (thousandsData) => thousandsData ? parseFloat(Math.round(thousandsData.toFixed(0) * 100) / 100) : 0

  render() {
    const {
      selectedForCumeTotal,
      selected,
      showPercentage,
      percentageData,
      showThousands,
      thousandsData,
    } = this.props;
    return (
      <Cell selectedForCumeTotal={selectedForCumeTotal} style={selected ? { backgroundColor: '#E8F5F9' } : null}>
        <Container className="test row">
          <Data>
            <span>
              {showPercentage ? this.roundingPercentData(percentageData) : null}
            </span>
          </Data>
          <Data>
            <span>
              {showThousands ? this.roundingThousandsData(thousandsData) : null}
            </span>
          </Data>
          <Data
            style={{ paddingRight: '15px' }}
          >
            <span>
              {this.getIndexValue()}
            </span>
          </Data>
        </Container>
      </Cell>
    );
  }
}

const Container = styled.div`
  width: 300px;
  height: 32px;
  display: flex;
  jusify-content: flex-start;
  align-items: center;
`;

const Cell = styled.div`
    height: 32px;
    border-bottom: 2px solid #E2E7EA;    
    font-weight: 400;
    color: #37474F; 
    background-color:white;
    color: ${props => props.selectedForCumeTotal ? '#FF8000' : '#37474F'}; 
    width: 300px;
    display: inline-block;
    border-right: 2px solid #E2E7Ea;
`;

const Data = styled.div`
  height: 32px;
  max-height: 32px;
  width: 33%;
  text-align: right;
  span {
    font-size: 13px;
    line-height: 28px;
  }
`;


export default DataCell;

import React, { Component } from 'react';
import styled from 'styled-components';

import NavigationBar from '../GlobalComponents/NavigationBar';
import TitleBar from '../GlobalComponents/TitleBar';
import MemberForm from './MemberForm';

class NewUserForm extends Component {

  goBack = () => {
    this.props.history.push('/team/members');
  }

  componentDidMount() {
    document.title = 'Stowell Data | New User';
  }

  render() {
    return (
      <Container>
        <NavigationBar />
        <TitleBar>
          Manage Team
        </TitleBar>
        <MemberForm
          handleForm={this.goBack}
          edit={false}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  background: rgba(0,0,0,0.03);
  position: absolute;
  width: 100%;
  height: all !important;
  min-height: 100% !important;
`;

export default NewUserForm;

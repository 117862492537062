import React, { Component } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import _ from 'lodash';
import propTypes from 'prop-types';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';
import './Card.css';

const styles = {
  root: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '13px',
    color: '#37474F',
    marginRight: '1px',
  },
  icon: {
    fill: '#90A4AE',
  },
  selectMenu: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '13px',
    color: '#37474F',
  },
  disabled: {
    color: '#cccccc',
  },
};

class TargetMenu extends Component {
  render() {
    const {
      id,
      allTargetsLoaded,
      classes,
      currentTarget,
      currentType,
      currentUnit,
      changeTarget,
      targets,
    } = this.props;
    return (
      <Select
        value={currentTarget}
        disableUnderline
        classes={{
          root: classes.root,
          icon: classes.icon,
          selectMenu: classes.selectMenu,
          disabled: classes.disabled,
        }}
        style={{ flex: 2 }}
        IconComponent={KeyboardArrowDown}
        onChange={e => changeTarget(id, e.target.value)}
      >
        {
        _.map(targets, ({ id, singleTarget, hasIndex, name }) => (
          <MenuItem
            key={id}
            className="menu-item"
            value={id}
            disabled={
              (currentType === 'pie' && !singleTarget) || 
              (currentUnit === 'index' && !hasIndex)   || 
              (currentUnit === 'all' && singleTarget)  ||
              (id !== 0 && !allTargetsLoaded)
            }
          >{name}
          </MenuItem>
        ))
      }
      </Select>
    );
  }
}

TargetMenu.propTypes = {
  id: propTypes.string.isRequired,
  currentTarget: propTypes.number.isRequired,
  currentType: propTypes.string.isRequired,
  changeTarget: propTypes.func.isRequired,
};

export default withStyles(styles)(TargetMenu);

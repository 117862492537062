import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCheck } from '@fortawesome/free-solid-svg-icons';
import { loadQuestions } from '../../actions/categories';
import { saveProject } from '../../actions/projectActions';
import showNotification from '../../helpers/showNotification';
import { updateTitle, resetTargetAndBannerDispatch } from '../../actions/overviewActions';
import { downloadPresentation } from '../../actions/overviewExportActions';
import DownloadButton from './DownloadButton';

class Overview extends Component {
  state = {
    title: '',
    loading: false,
    saved: false,
    titleEdited: false,
  }

  componentDidMount() {
    const { loadQuestions } = this.props;
    this.preventRefresh();
    loadQuestions();
  }

  componentWillReceiveProps(nextProps) {
    this.setTitleFromProps(nextProps);
  }

  componentWillUnmount() {
    const { resetBannerAndTarget } = this.props;
    resetBannerAndTarget();
  }

  setTitleFromProps = (props) => {
    const { currentOverview } = props;
    this.setState({
      title: currentOverview.title,
    });
  };

  preventRefresh = () => {
    window.onbeforeunload = () => false;
  }

  saveProject = async () => {
    const { saveProject } = this.props;
    const { title } = this.state;
    this.setState({ loading: true, saved: false });

    try {
      await saveProject(title, 'OVERVIEW');
      this.setState({ loading: false, saved: true });
    } catch (error) {
      showNotification({ message: error.message, type: 'error' });
      this.setState({ loading: false, saved: false });
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    const { updateOverviewTitle } = this.props;
    this.setState({ titleEdited: true }, () => updateOverviewTitle(value));
  };

  getButtonText = () => {
    const { loading, saved } = this.state;
    if (loading) return 'Saving';
    if (!loading && saved) return 'Saved';
    if (!loading) return 'Save';
  }

  download = async (size) => {
    const { title } = this.state;
    const { downloadPresentation } = this.props;
    try {
      downloadPresentation(size, title);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const {
      title, loading, saved, titleEdited,
    } = this.state;
    return (
      <Menu>
        <Title value={title} onChange={this.handleChange} titleEdited={titleEdited} />
        <Buttons>
          <Button primary onClick={this.saveProject} disabled={loading} shadow>
            {
              (loading || saved)
              && <FontAwesomeIcon icon={saved ? faCheck : faSync} spin={loading} style={{ marginRight: '10px' }} />
            }
            {this.getButtonText()}
          </Button>
          <DownloadButton download={this.download} />
        </Buttons>
      </Menu>
    );
  }
}

const mapStateToProps = store => ({
  error: store.OverviewReducer.error,
  currentOverview: store.OverviewReducer.currentOverview,
  projects: _.filter(store.ProjectReducer.projects, pr => pr.type === 'PROJECT'),
});

const mapDispatchToProps = dispatch => ({
  loadQuestions: () => dispatch(loadQuestions()),
  saveProject: (title, type) => dispatch(saveProject(title, type)),
  downloadPresentation: (size, title) => dispatch(downloadPresentation(size, title)),
  updateOverviewTitle: title => dispatch(updateTitle(title)),
  resetBannerAndTarget: () => dispatch(resetTargetAndBannerDispatch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overview);

const Menu = styled.div`
  height: 79px;
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 0px 16px;
  border-bottom: 1px solid #DEE1E3;
`;

const Buttons = styled.div`
  margin: 30px 0px 18px 0px;
`;

const Title = styled.input`
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 400;
  width: 70%;
  height: 30px;
  text-overflow: ellipsis;
  color: ${({ titleEdited }) => (titleEdited ? '#37474F' : '#607D8B')};
  font-style: ${({ titleEdited }) => (titleEdited ? 'normal' : 'italic')};
  border: 1px solid white;
  padding: 0;
  margin: 30px 0px 18px 0px;
  &:hover {
    border: 1px solid #CFD8DC;
  }
`;

const Button = styled.button`
  height: 35px;
  font-weight: 500;
  margin-left: 15px;
  width: 100px;
  border: none;
  font-family: 'Roboto';
  font-size: 13px;
  border-radius: 3px;
  color: #FFFFFF;
  background: ${({ primary }) => (primary ? '#607D8B' : '#CFD8DC')};
  cursor: pointer;
  ${({ shadow }) => shadow && 'box-shadow: 0px 5px 10px #CCCCCC;'}
  ${({ disabled }) => disabled && 'opacity: 0.4;'}
  &:hover{
      opacity:0.8;
  }
`;

import {
  faTrash, faEdit, faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMembers, deleteMember } from '../../actions/teamActions';
import Datatable from '../GlobalComponents/DataTable/DataTable';
import MemberForm from './MemberForm';
import Header from './Header';
import PendingInvites from './PendingInvites';
import { DropDownMenu } from '../GlobalComponents/DropDown/DropDownMenu';
import { getUser } from '../../actions/profileActions';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      loading: true,
      teamId: '',
      formOpened: false,
      row: {},
    };
  }

  componentWillMount() {
    const { user, getMembers } = this.props;
    const { teamId } = user;
    getMembers(teamId._id)
      .then(() => {
        this.assignMembersToState(this.props);
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({message, type});
        this.setState({
          loading: false,
        });
      });
  }

  componentDidMount() {
    if (_.size(this.props.members) !== 0) {
      this.assignMembersToState(this.props);
    }
  }

  componentWillReceiveProps(props) {
    this.assignMembersToState(props);
  }

  assignMembersToState = async (props) => {
    const { user } = props;
    let newMembers = [];
    let members = [];
    const filteredMembers = _.values(props.members).filter(member => (member.status === 'ACTIVE' || member.status === 'INACTIVE'));
    newMembers = filteredMembers;
    if (user.role.name === 'admin' && user.role) {
      newMembers = filteredMembers.filter(member => member.role.name === 'admin');
    }
    members = _.sortBy(newMembers, dM => dM.first_name);
    this.setState({
      members,
      loading: false,
    });
  }

  deleteMember = (row) => {
    const { _id } = row;
    const { teamId } = this.state;
    const { deleteMember } = this.props;
    const data = {
      user: row,
      teamId,
      userId: _id,
    };
    deleteMember(data)
      .then((response) => {
        if (!response.data.error) {
          showNotification({message: `'${row.first_name}' was deactivated successfully!`, type:'success'});
        } else {
          const { message, type } = getErrorMessage(response.data.error);
          showNotification({message, type});
        }
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({message, type});
      });
  }

  dropdowMenu = (cell, row, index) => {
    const { user } = this.props;
    const menuOptions = [
      {
        text: 'Edit User',
        icon: faEdit,
        action: () => this.editMember(row),
      },
    ];
    if ((user.role.name === 'admin' || user.role.name === 'owner') && (row.status === 'ACTIVE')) {
      menuOptions.push({
        text: 'Remove User',
        icon: faTrash,
        action: () => this.deleteMember(row),
      });
    }
    return (
      <DropDownMenu
        menuOptions={menuOptions}
      />
    );
  }

  editMember = (row) => {
    const { formOpened } = this.state;
    this.setState({
      row,
      formOpened: !formOpened,
    });
  }

  handleForm = () => {
    this.props.history.push('/team/members/create');
  }

getRoleName = (cell, row) => {
  const { name } = row.role;
  let role;
  switch (name) {
    case 'admin': {
      role = 'Administrator';
      break;
    }
    case 'owner': {
      role = 'Business Owner';
      break;
    }
    case 'manager': {
      role = 'Manager';
      break;
    }
    case 'basic': {
      role = 'Basic';
      break;
    }
    default:
      break;
  }
  return (
    <p>{role}</p>
  );
}

render() {
  const columns = [
    {
      dataField: 'first_name',
      text: 'First Name',
      sort: true,
      style: {
        width: '20%',
      },
    },
    {
      dataField: 'last_name',
      text: 'Last Name',
      sort: true,
      style: {
        width: '20%',
      },
    },
    {
      dataField: 'email',
      text: 'Email',
      style: {
        width: '25%',
      },
    },
    {
      dataField: 'role.name',
      formatter: this.getRoleName,
      text: 'User Type',
      sort: true,
      style: {
        width: '15%',
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      style: {
        width: '10%',
      },
    },
    {
      formatter: this.dropdowMenu,
      style: {
        width: '10%',
      },
    },
  ];

  const {
    members, formOpened, row, loading,
  } = this.state;
  return (
    !formOpened
      ? (
        <ContentBody>
          <Header
            addUserFunction={this.handleForm}
          />
          {
              loading ? (
                <Loader>
                  <p>
                    <FontAwesomeIcon icon={faCircleNotch} size="3x" spin />

                  </p>
                </Loader>
              ) : (
                <div>
                  <Container>
                    <Datatable columns={columns} data={members} />
                  </Container>
                  <Container>
                    <PendingInvites />
                  </Container>
                </div>
              )
          }
        </ContentBody>
      ) : (
        <MemberForm
          row={row}
          edit
          handleForm={() => this.setState({ formOpened: false })}
        />
      ));
}
}

const ContentBody = styled.div`
  width: 84%;
  padding: 0px 8% 0 8%;
`;

const Container = styled.div`
  display: inline-block;
  width: 100%;
  padding: 20px 0 25px 0;
  ${''}
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
  color: #DEE1E3;
`;

const mapStateToProps = state => ({
  members: state.teamReducer.members,
  user: state.AppReducer.user,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getMembers: teamId => dispatch(getMembers(teamId)),
  deleteMember: data => dispatch(deleteMember(data)),
  getUser: () => dispatch(getUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);

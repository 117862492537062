import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import TargetCellContainer from './TargetCellContainer';
import AddTargetButton from './AddTargetButton';
import AddBannerButton from './AddBannerButton';
import BuildTargetModal from '../BuildTargetModal';
import BuildBannerModal from '../BuildBannerModal';
import EditTargetButton from './EditTargetButton';
import EditBannerButton from './EditBannerButton';

import { TableHeader } from './Table/TableHeader/TableHeader';
import { getContainerRect } from './Table/lib/getContainerRect';
import { Row } from './Table/TableBody/Row';
import { TableBody } from './Table/TableBody/Tbody';
import ColumnHeaderFixed from './Table/TableHeader/ColumnHeaderFixed';
import { HeaderColumnCell } from './Table/TableHeader/HeaderColumnCell';
import BannerColumnContainer from './BannerColumnContainer';
import DataCell from './DataCell';
import CumeTotalDataCell from './CumeTotalDataCell';
import AverageDataCell from './AverageDataCell';


class GridContainer extends Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.tableHeader = React.createRef();
    this.fixedColumn = React.createRef();
    this.tableWrapper = React.createRef();
    this.tableHandled = React.createRef();
    this.state = {
      tableWrapperHeight: 0,
      tableWidth: 0,
      canScrollTable: true,
      Data: this.props.Data,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  }

  componentWillUpdate() {
    this.fixedColumn.scrollTop = this.tableHandled.scrollTop;
    this.tableHeader.scrollLeft = this.tableHandled.scrollLeft;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDimensions);
  }

  setDimensions = () => {
    this.fixedColumn.scrollTop = this.tableHandled.scrollTop;
    this.tableHeader.scrollLeft = this.tableHandled.scrollLeft;
    this.setState({
      tableWidth: getContainerRect(this.table).width,
      tableWrapperHeight: getContainerRect(this.tableWrapper).height,
    });
  }

  handleTableScrollXY = (event) => {
    event.preventDefault();
    const { canScrollTable } = this.state;
    if (canScrollTable) {
      const { scrollLeft, scrollTop } = this.tableHandled;
      this.tableHeader.scrollLeft = scrollLeft;
      this.fixedColumn.scrollTop = scrollTop;
    }
  }

  preventScroll = (prevent) => {
    this.setState({
      canScrollTable: !prevent,
    });
  }

  getCumeTotalRow = BannerData => (
    <Row
      key={_.uniqueId('b')}
    >
      {
        BannerData.cumeTotalResults.map((e,i) => (
          <CumeTotalDataCell
            key={_.uniqueId('b')}
            value={e}
            position={i}
            showPercentage={this.props.showPercentage}
            showIndex={this.props.showIndex}
            showThousands={this.props.showThousands}
          />
        ))
      }
    </Row>
  )

  getTableBodyFooter = (BannerData) => {
    const cellFooter = [];
    let firstLap = -1;
    BannerData.BannerDataPoints.forEach((e, i) => {
      const isShown = e.isVisible && !e.combinedRow;
      if (isShown) {
        firstLap += 2;
        if (firstLap === 1) {
          cellFooter.push(
            <Row
              key={_.uniqueId('a')}
            >
              {
                e.value.map(() => (
                  <Cell
                    key={_.uniqueId('a')}
                  />
                ))
              }
            </Row>,
          );
        }
      }
    });
    return cellFooter;
  }


  getTableBody = (BannerData, tab) => {
    const { average } = BannerData;
    const body = [];
    let firstLap = -1;
    let firstLapWhenAny = -1;
    const firstLapWhenAnyBody = -1;
    let dataPointsShown = 0;
    const { showPercentage, showIndex, showThousands } = this.props;
    BannerData.BannerDataPoints.forEach((e, i) => {
      const isShown = e.isVisible && !e.combinedRow;
      if (isShown) {
        dataPointsShown += 1;
        firstLap += 2;
        if (firstLap === 1) {
          body.push(
            <HeaderSectionBodyRow
              key={_.uniqueId()}
            >
              {
                e.value.map((d, k) => (
                  <HeaderSectionBody
                    key={_.uniqueId()}
                  />
                ))
              }
              <RightBlankSpace
                key={_.uniqueId()}
              />
            </HeaderSectionBodyRow>,
          );
        }
        body.push(
          <Row
            key={_.uniqueId()}
          >
            {
              e.value.map((f, j) => (
                <DataCell
                  selectedForCumeTotal={e.selectedForCumeTotal}
                  selected={f.selected}
                  percentageData={f.percentage}
                  thousandsData={f.thousands}
                  indexData={f.index}
                  key={_.uniqueId()}
                  showPercentage={showPercentage}
                  showIndex={showIndex}
                  showThousands={showThousands}
                  position={j}
                />
              ))
            }
          </Row>,
        );
      }
    });
    if (dataPointsShown === 0) {
      firstLapWhenAny += 2;
      if (firstLapWhenAny === 1 && firstLap < 1) {
        body.push(
          <HeaderSectionBodyRow
            key={_.uniqueId()}
          >
            {
              tab.Targets.map(() => (
                <HeaderSectionBody
                  key={_.uniqueId()}
                />
              ))
            }
            <RightBlankSpace
              key={_.uniqueId()}
            />
          </HeaderSectionBodyRow>,
        );
      }
    }
    if (BannerData.hasCumeTotal) {
      body.push(
        this.getCumeTotalRow(BannerData),
      );
    }
    const newAverage = _.compact(average);
    if (newAverage.length > 0) {
      body.push(
        <Row>
          {
            average.map(d => (
              <AverageDataCell
                percentage={d}
              />
            ))
          }
        </Row>,
      );
    }
    body.push(
      this.getTableBodyFooter(BannerData),
    );
    return body;
  }

  updateRef = (el) => {
    this.fixedColumn = el;
  }

  render() {
    const { tableWrapperHeight } = this.state;
    const { Data, currentTab, chartWindowVisible } = this.props;
    const width = chartWindowVisible ? 'calc(100% - 750px)' : 'calc(100% - 300px)';
    const widthHeader = chartWindowVisible ? 'calc(100% - 750px - 18px)' : 'calc(100% - 300px - 18px)';
    return (
      <Wrapper
        className="wrappertest"
        innerRef={el => this.tableWrapper = el}
      >
        <TableInner
          width={width}
          chartWindowVisible={chartWindowVisible}
        >
          <TableWrapper
            innerRef={el => this.tableHandled = el}
            onScroll={this.handleTableScrollXY}
          >
            <Table
              newHeight={tableWrapperHeight}
              innerRef={el => this.table = el}
            >
              <TableHeader
                newWidth={widthHeader}
                innerRef={el => this.tableHeader = el}
              >
                {
                  Data.Tabs[currentTab].Targets.map((e, i) => (
                    <TargetCellContainer
                      isPlaceHolder={false}
                      targetQuantity={e.targetQuantity}
                      targetName={e.targetName}
                      targetThousands={e.targetThousands}
                      targetPercentage={e.targetPercentage}
                      showPercentage={this.props.showPercentage}
                      showIndex={this.props.showIndex}
                      showThousands={this.props.showThousands}
                      position={i}
                      functions={this.props.functions}
                      targetDataStructure={e.targetDataStructure}
                      key={i}
                    />
                  ))
                }
                <HeaderColumnCell>
                  <BuildTargetModal preventScroll={this.preventScroll} trigger={AddTargetButton} type="spreadsheet" />
                  <BuildTargetModal preventScroll={this.preventScroll} trigger={EditTargetButton} type="spreadsheetEditTarget" />
                </HeaderColumnCell>
              </TableHeader>
              <ColumnHeaderFixed
                newHeight={tableWrapperHeight}
                updateRef={this.updateRef}
              >
                {
                  Data.Tabs[currentTab].Banners.map((e, i) => (
                    <span key={i}>
                      {e.Visible && !e.combinedBanner ? (
                        <BannerColumnContainer
                          TargetData={this.props.Data.Tabs[this.props.currentTab].Targets}
                          BannerData={e}
                          functions={this.props.functions}
                          position={i}
                          showPercentage={this.props.showPercentage}
                          s
                          showIndex={this.props.showIndex}
                          showThousands={this.props.showThousands}
                          currentTab={this.props.currentTab}
                        />
                      ) : null
                      }
                    </span>
                  ))
                }
                <NewTableBody>
                  <Row>
                    <BuildBannerModal preventScroll={this.preventScroll} trigger={AddBannerButton} type="spreadsheet" />
                    <BuildBannerModal preventScroll={this.preventScroll} trigger={EditBannerButton} type="spreadsheetEditBanner" />
                  </Row>
                </NewTableBody>
              </ColumnHeaderFixed>
              {
                Data.Tabs[currentTab].Banners.map((e, i) => (
                  <span key={_.uniqueId('f')}>
                    {(e.Visible && !e.combinedBanner) && (
                    <TableBody
                      key={i}
                      style={{ paddingBottom: '15px' }}
                    >
                      {this.getTableBody(e, Data.Tabs[currentTab])}
                    </TableBody>
                    )
                      }
                  </span>
                ))
              }
              <TableBody style={{ height: '0' }}>
                {
                Data.Tabs[currentTab].Targets.map((e, i) => (
                  <BlankSpace
                    key={_.uniqueId()}
                  />
                ))
              }
                <RightBlankSpace
                  key={_.uniqueId()}
                />
              </TableBody>

              <NewTableBody />
            </Table>
          </TableWrapper>
        </TableInner>
      </Wrapper>
    );
  }
}


const Cell = styled.div`
  height: 32px;
  border-bottom: 2px solid #E2E7EA;    
  font-size: 13px;
  font-weight: 400;
  color: #37474F; 
  background-color:white;
  width: 300px;
  display: inline-block;
  border-right: 2px solid #E2E7Ea;
`;


const NewTableBody = styled(TableBody)`
  border: 1px solid transparent;
  height: 26px;
`;

const HeaderSectionBodyRow = styled(Row)`
  height: 38px;
`;

const HeaderSectionBody = styled.div`
  background-color: #E2E7EA;
  height: 38px;
  width: 300px;
  display: inline-block;
  box-sizing: border-box;
  border-top: 2px solid #607D8B;
`;

const RightBlankSpace = styled(HeaderSectionBody)`
  width: 200px;
  background: transparent;
  border-top: none;
`;

const BlankSpace = styled(HeaderSectionBody)`
  width: 300px;
  height: 0;
  background: transparent;
  border-top: none;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: -1;
  margin: 0 auto;
  width: 100%;
  height: calc(100% - 215px);
  background-color: rgb(246, 247, 248);
`;

const TableWrapper = styled.div`
  background-color: #F6F7F8;
  overflow: hidden;
  overflow-x: scroll;
  overflow-y: scroll;
`;

const Table = styled.div`
  height: ${({ newHeight }) => (newHeight ? `${newHeight - 10}px` : '100%')};;
  padding-top: 160px;
`;

const TableInner = styled.div`
  * {
    box-sizing: border-box;
  }
  padding-left: 300px;
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  width: ${({ width }) => width};
`;

export default GridContainer;

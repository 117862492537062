import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Breadcrumb from '../GlobalComponents/Builder/Breadcrumb';
import CloseIcon from '../GlobalComponents/Builder/CloseIcon';
import { openBrowserManager } from '../../actions/UIManagerActions';

class BannerSummary extends Component {
  questionClick = (blockId, question) => {
    const { edit } = this.props;
    let removeQuestion;
    if (edit) {
      ({ removeQuestion } = this.props.actions.templates.basic.question);
    } else {
      ({ removeQuestion } = this.props.actions.basic.question);
    }
    const payload = { blockId, question: { id: question, title: '' } };
    removeQuestion(payload);
  };

  answerClick = (block, answer) => {
    const { edit } = this.props;
    let removeDataPoint;
    if (edit) {
      ({ removeDataPoint } = this.props.actions.templates.basic.answer);
    } else {
      ({ removeDataPoint } = this.props.actions.basic.answer);
    }
    removeDataPoint(block, answer);
  };

  render() {
    const { basic } = this.props.data.builders;
    const { handleActiveTree } = this.props;
    const blockId = 0; // placeholder
    return (
      <Container>
        {_.map(basic, (question, index) => (
          <Question key={index}>
            <Title>
              <Breadcrumb
                path={question.path || { question: question.text }}
                toggleShowTree={handleActiveTree}
                handleActiveTree={handleActiveTree}
              />
              <CloseIcon onClick={() => this.questionClick(blockId, question.id)} />
            </Title>
            {_.map(question.answers, (answer, index) => (
              <DataPoint key={index}>
                <p>{answer.text}</p>
                <CloseIcon onClick={() => this.answerClick(blockId, answer.id)} />
              </DataPoint>
            ))}
          </Question>
        ))}
      </Container>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  handleActiveTree: () => dispatch(openBrowserManager()),
});

const Container = styled.div`
  width: 100%;
  max-height: 120px;
  overflow: auto;
  background: white;
  border: 1px solid #CFD8DC;
  border-bottom: 0px;
  box-sizing: border-box;
  &:empty { 
    display: none;
  }
`;

const Question = styled.div`
  padding-bottom: 7px;
`;

const Title = styled.div`
  color: #19A2C5;
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 400;
  margin: 0px;
  padding: 5px 2px;
  position: relative;
  &:hover {
    background: #E8F5F9;
    div {
      display: block;
    }
  }
`;

const DataPoint = styled.div`
  position: relative;
  &:hover {
    background: #E8F5F9;
    div {
      display: block;
    }
  }

  p {
    color: #37474F;
    font-size: 13px;
    margin: 0;
    padding: 3px 12px;
  }
`;

export default connect(null, mapDispatchToProps)(BannerSummary);

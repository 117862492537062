import React, { Component } from 'react';
import styled from 'styled-components';
import BodyContainerForm from './BodyContainerForm';

class LoginWindowBody extends Component {
  render() {
    return (
      <div>
        <BodyContainer>
          <BodyContainerForm />
          <b>Don't have a password?</b><br />
          Contact your manager or <HighLight>support@stowellco.com</HighLight> for assistance.
        </BodyContainer>
      </div>
    );
  }
}

const BodyContainer = styled.div`
    background-color:white;
    border-radius: 0px 0px 5px 5px;  
    padding:50px 30px 30px 30px;
    font-size:13px;    
    font-family: 'Roboto', sans-serif;
    font-weight:400;
    color: #37474F;   
`;

const HighLight = styled.span`
  color: #19A2C5;
`;


export default LoginWindowBody;

import React, { Component } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';

class Header extends Component {
  render() {
    const { addUserFunction } = this.props;
    return (
      <Wrapper>
        <Title>
          Account Users
        </Title>
        <AddUserButton
          onClick={addUserFunction}
        >
          <FontAwesomeIcon icon={faPlus} />
          {' '}
          Add a New User
        </AddUserButton>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0.5% 0px 0.5%;
    width: 99%;
`;

const Title = styled.div`
    font-family: 'Roboto';
    font-size: 16px;
    color: #37474F;
    font-weight: 500;
    color: rgb(45, 76, 103);
`;

const AddUserButton = styled.button`
  font-family: 'Roboto';
  width: 134px;
  height: 32px;
  background: #607D8B;
  border: 0;
  color: #FFFFFF;
  font-size: 13px;
  cursor: pointer;
  border-radius: 3%;
  font-weight: 500;
  &:hover{
    opacity:0.9;
  }

`;

Header.propTypes = {
  addUserFunction: propTypes.func,
};

export default Header;

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const EDIT_ACCOUNT_DETAILS = 'EDIT_ACCOUNT_DETAILS';
export const SET_USERS = 'SET_USERS';
export const CANCEL_INVITATION_FROM_ACCOUNTS = 'CANCEL_INVITATION_FROM_ACCOUNTS';
export const EDIT_ACCOUNT_DOCUMENTS = 'EDIT_ACCOUNT_DOCUMENTS';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const ADD_USER_TO_ACCOUNT_USERS = 'ADD_USER_TO_ACCOUNT_USERS';
export const EDIT_DATABASES = 'EDIT_DATABASES';
export const DELETE_DATABASE = 'DELETE_DATABASE';
export const API_CALL_FAILED = 'API_CALL_FAILED';


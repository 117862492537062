import { LOG_OUT } from '../actionTypes/appTypes';

const initialState = { categories: [], questions: [], selectData: [] };
const categoriesReducer = (state = { categories: [], questions: [], selectData: [] }, action) => {
  switch (action.type) {
    case 'LOAD_CATEGORIES':
      return { ...state, categories: action.payload };
    case 'LOAD_QUESTIONS':
      return { ...state, questions: action.payload };
    case 'LOAD_SELECT_DATA':
      return { ...state, selectData: action.payload };
    case LOG_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default categoriesReducer;

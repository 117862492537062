import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import propTypes from 'prop-types';
import TargetOperations from './TargetOperations';
import Builder from './Builder';

class Blocks extends Component {
  state = {
    activeTree: 0,
    showTree: false,
  }

  handleActiveTree = (blockId) => {
    const { activeTree } = this.state;
    if (activeTree === blockId) {
      this.setState(state => ({ activeTree: blockId, showTree: !state.showTree }));
    } else {
      this.setState({ activeTree: blockId, showTree: true });
    }
  }

  handleShowTree = (show) => {
    if (show) {
      this.setState({ showTree: false });
    } else {
      this.setState({ showTree: true });
    }
  }

  closeTree = () => {
    this.setState({ showTree: false });
  }

  toggleShowTree = blockId => this.setState({ activeTree: blockId, showTree: true });

  render() {
    const { actions, blocks } = this.props;
    const { activeTree, showTree } = this.state;
    let padding = 0;
    const isLastBlockEmpty = _.isEmpty(blocks[_.findLastKey(blocks)].questions);
    return (
      <Container>
        {_.map(blocks, (block, index) => {
          if (block.operator === 'AND') {
            if (padding < 100) {
              padding += 34;
            }
          }
          return (
            <Builder
              key={index}
              actions={actions}
              block={block}
              activeTree={activeTree}
              showTree={showTree}
              handleActiveTree={this.handleActiveTree}
              toggleShowTree={this.toggleShowTree}
              padding={padding}
            />
          );
        })}
        <TargetOperations padding={padding} showTree={showTree} handleShowTree={this.closeTree} visible={!isLastBlockEmpty} actions={actions.block} />
      </Container>
    );
  }
}

Blocks.propTypes = {
  actions: propTypes.shape({
    answer: propTypes.shape({
      addDataPoint: propTypes.func.isRequired,
      removeDataPoint: propTypes.func.isRequired,
    }).isRequired,
    block: propTypes.shape({
      addBlock: propTypes.func.isRequired,
      removeBlock: propTypes.func.isRequired,
    }).isRequired,
    builder: propTypes.shape({
      changeItemsOperator: propTypes.func.isRequired,
    }).isRequired,
    question: propTypes.shape({
      addQuestion: propTypes.func.isRequired,
      removeQuestion: propTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export default Blocks;

export default [
  '#F44336',
  '#26C6DA',
  '#9C27B0',
  '#FF9800',
  '#4CAF50',
  '#90A4AE',
  '#37474F',
  '#FFEB38',
  '#C62828',
  '#117189',
  '#4A14BC',
  '#E65100',
  '#1B5E20',
  '#607D8B',
  '#212121',
  '#FFD600',
  '#FFCDD2',
  '#C5E7F0',
  '#CE93DB',
  '#FFB74D',
  '#A5D6A7',
  '#CFD8DC',
  '#546E7A',
  '#FFF59D'
]
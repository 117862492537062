import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Breadcrumb from './Breadcrumb';
import CloseIcon from './CloseIcon';

class Target extends Component {
  questionClick = (blockId, question) => {
    const { actions: { question: { removeQuestion } } } = this.props;
    const payload = { blockId, question: { id: question, title: '' } };
    removeQuestion(payload);
    const checkbox = document.getElementsByName(`${blockId}-${question}`);
    checkbox.forEach((checkbox) => {
      checkbox.checked = false;
    });
  };

  answerClick = (block, answer) => {
    const { actions: { answer: { removeDataPoint } } } = this.props;
    removeDataPoint(block, answer);
    const checkbox = document.getElementById(`${block}-${answer}`);
    if (checkbox) checkbox.checked = false;
  };

  render() {
    const { block, handleActiveTree, toggleShowTree } = this.props;
    return (
      <Container>
        {_.map(block.questions, (question, index) => (
          <Question key={index}>
            <Title>
              <Breadcrumb
                blockId={block.id}
                handleActiveTree={handleActiveTree}
                path={question.path || { question: question.text }}
                toggleShowTree={toggleShowTree}
              />
              <CloseIcon onClick={() => this.questionClick(block.id, question.id)} />
            </Title>
            {_.map(question.answers, (answer, index) => (
              <DataPoint key={index}>
                <p>{answer.text}</p>
                <CloseIcon onClick={() => this.answerClick(block.id, answer.id)} />
              </DataPoint>
            ))}
          </Question>
        ))}
      </Container>
    );
  }
}

Target.propTypes = {
  actions: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  handleActiveTree: PropTypes.func.isRequired,
};

const Container = styled.div`
  width: 100%;
  max-height: 120px;
  overflow-y: auto;
  background: white;
  border: 1px solid #CFD8DC;
  border-bottom: none;
  box-sizing: border-box;
  &:empty { 
    border: none;
  }
`;

const Question = styled.div`
  margin: 0;
`;

const Title = styled.div`
  color: #19A2C5;
  font-size: 13px;
  font-family: 'Roboto';
  font-weight: 400;
  margin: 0px;
  padding: 5px 2px;
  position: relative;
  &:hover {
    background: #E8F5F9;
    div {
      display: block;
    }
  }
`;

const DataPoint = styled.div`
  position: relative;
  padding: 2px 0px;
  &:hover {
    background: #E8F5F9;
    div {
      display: block;
    }
  }

  p {
    margin: 0;
    font-size: 13px;
    color: #37474F;
    margin-left: 20px;
  }
`;

export default Target;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BlueConfirmDialog = ({
  show, title, message, onCancel, onConfirm, onConfirmButtonText,
}) => (
  <Container show={show}>
    <ConfirmBox>
      <Title>{title}</Title>
      <Body>{message}</Body>
      <Footer>
        <ButtonsContainer>
          <Button onClick={onCancel}>
            Cancel
          </Button>
          <Button primary onClick={onConfirm}>
            {!onConfirmButtonText ? 'Delete' : onConfirmButtonText}
          </Button>
        </ButtonsContainer>
      </Footer>
    </ConfirmBox>
  </Container>
);
BlueConfirmDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onConfirmButtonText: PropTypes.string.isRequired,
};
export default BlueConfirmDialog;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1s linear;
`;

const ConfirmBox = styled.div`
  position: absolute;
  background-color: #FFFFFF;
  top: 30%;
  width: 500px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: Roboto;
  background-color: #FFFFFF;
  border-radius: 4px 4px 0px 0px;
  padding: 18px 183px 0px 31px;
  color: #37474F;
  height: 61px;
  width: auto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.13px;
  line-height: 24px;
  border-bottom: 1px solid #CFD8DC; 
`;
const Body = styled.div`
  margin: 40px 30px 40px 30px;
  color: #37474F;
  font-size: 18px;
  height:62px;
`;

const Footer = styled.div`
  height: 61px;
  width: 100%;
  border-top: 1px solid #CFD8DC;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height:100%;
  &:last-child {
    margin-right: 20px;
  }
`;

const Button = styled.div`
  padding: 10px 20px;
  text-align: center;
  background-color: ${({ primary }) => (primary ? '#607D8B' : '#CFD8DC;')};
  color: ${({ primary }) => (primary ? '#FFFFFF' : '#607D8B')};
  margin-left: 10px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import BannerSummary from './BannerSummary';
import Builder from '../GlobalComponents/Builder';
import Browser from '../GlobalComponents/Browser';

class Templates extends Component {
  state = {
    activeTree: 0,
    showTree: false,
  }

  handleActiveTree = () => {
    this.setState(state => ({ showTree: !state.showTree }));
  }

  handleClick = (banner) => {
    const { restoreBasicBanner, restoreAdvancedBanner } = this.props;
    if (banner.bannerType === 'basic') {
      restoreBasicBanner(banner.data);
    } else if (banner.bannerType === 'advanced') {
      restoreAdvancedBanner(banner.data);
    }
  }

  getBuilder = () => {
    const { currentTemplate, actions } = this.props;
    const { activeTree, showTree } = this.state;
    const { basicBuilder, advancedBuilder } = currentTemplate;
    if (!_.isEmpty(advancedBuilder)) {
      return <Builder actions={actions.templates.advanced} blocks={advancedBuilder.blocks} />;
    }
    if (!_.isEmpty(basicBuilder)) {
      return (
        <Browser
          activeTree={activeTree}
          showTree={showTree}
          handleActiveTree={this.handleActiveTree}
          blockId={0}
          actions={actions.templates.basic}
        />
      );
    }
  }

  render() {
    const { currentTemplate, actions } = this.props;
    const { basicBuilder, advancedBuilder } = currentTemplate;
    return (
      <Container>
        <BannerSummary edit actions={actions} data={{ builders: { basic: basicBuilder, advanced: advancedBuilder } }} />
        {this.getBuilder()}
      </Container>
    );
  }
}

const mapStateToProps = store => ({
  currentTemplate: store.bannerReducer.currentTemplate,
});

const Container = styled.div`
  min-height: 450px;
  text-overflow: ellipsis;
  font-family: 'Roboto';
  font-size: 1em;
  text-align: left;
  }
`;
export default connect(mapStateToProps)(Templates);

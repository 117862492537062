import React, { Component } from 'react';
import styled from 'styled-components';
import NavigationBar from '../GlobalComponents/NavigationBar';
import TitleBar from '../GlobalComponents/TitleBar';
import Body from './Body';

class AccountManagement extends Component {
  componentDidMount() {
    document.title = 'Stowell Data | Account Managment';
  }

  render() {
    const { history } = this.props;
    return (
      <Container>
        <NavigationBar />
        <TitleBar>
          Accounts
        </TitleBar>
        <Body history={history} />
      </Container>
    );
  }
}

const Container = styled.div`
  background: rgba(0,0,0,0.03);
  position: absolute;
  width: 100%;
  height: all !important;
  min-height: 100% !important;
`;

export default AccountManagement;

import {
  LOAD_SPREADSHEET_CHARTS,
  SET_SPREADSHEET_CHART_LABELS,
  SET_SPREADSHEET_CHARTS_LOADED,
  RESET_SPREADSHEET_CHARTS,
} from '../actionTypes/spreadsheetChartTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const defaultState = {
  charts: [],
  editingChart: null,
  usedBanner: {},
  chartLabels: {},
  spreadSheetChartsLoaded: false,
};
const chartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_SPREADSHEET_CHARTS:
      return { ...state, charts: action.payload };
    case SET_SPREADSHEET_CHART_LABELS:
      return {
        ...state,
        chartLabels: action.payload,
      };
    case SET_SPREADSHEET_CHARTS_LOADED:
      return {
        ...state,
        spreadSheetChartsLoaded: action.payload,
      };
    case LOG_OUT:
      return defaultState;
    case RESET_SPREADSHEET_CHARTS:
      return defaultState;
    default:
      return state;
  }
};

export default chartReducer;

import React, { Component } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Subcategory from './Subcategory';

class Subcategories extends Component {
  render() {
    const {
      actions,
      activeCollapsibles,
      blockId,
      handlers,
      path,
      subcategories,
    } = this.props;
    return (
      <Container>
        {subcategories.map(sub => (
          <Subcategory
            key={sub.name}
            actions={actions}
            activeCollapsibles={activeCollapsibles}
            blockId={blockId}
            handlers={handlers}
            path={path}
            subcategory={sub}
          />
        ))}
      </Container>
    );
  }
}

Subcategories.propTypes = {
  actions: propTypes.object.isRequired,
  activeCollapsibles: propTypes.object.isRequired,
  blockId: propTypes.number.isRequired,
  handlers: propTypes.object.isRequired,
  path: propTypes.object.isRequired,
  subcategories: propTypes.array.isRequired,
};

const Container = styled.div`
  margin: 0;
`;

export default Subcategories;

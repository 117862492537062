import React, { Component } from 'react';
import styled from 'styled-components';


class CumeTotalDataCell extends Component {


  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  roundingPercentData = (percentData) => { 
    return percentData !== null ? parseFloat(Math.round(percentData.toFixed(2) * 100) / 100) : 0
  }

  roundingThousandsData = (thousandsData) => { 
    return thousandsData !== null ? parseFloat(Math.round(thousandsData.toFixed(0) * 100) / 100) : 0
  }

  roundingIndexData = (indexData, position) => {
    if (position > 0) {
      if (isNaN(indexData) || indexData === null) {
        return '-';
      }
      return indexData ? parseFloat(Math.round(indexData.toFixed(0) * 100) / 100) : 0;
    } 

    return null
  }


  render() {
    return (
      <Cell style={this.props.selected ? { backgroundColor: '#E8F5F9' } : null}>
        <Container>
          <Data>
            <span>
              {this.props.showPercentage ? this.props.value ? this.roundingPercentData(this.props.value.percentage) : null : null}
            </span>
          </Data>
          <Data>
            <span>
              {this.props.showThousands ? this.props.value ? this.roundingThousandsData(this.props.value.thousands) : null : null}
            </span>
          </Data>
          <Data
            style={{ paddingRight: '15px' }}
          >
            <span>
              {this.props.showIndex ? this.props.value ? this.roundingIndexData(this.props.value.index, this.props.position) : null : null}
            </span>
          </Data>
        </Container>
      </Cell>
    );
  }
}

const Container = styled.div`
  width: 300px;
  height: 100%;
  display: flex;
  jusify-content: flex-start;
  align-items: center;
`;

const Cell = styled.div`
    height: 32px;
    border-bottom: 2px solid #E2E7EA;    
    font-size: 13px;
    font-weight: 400;
    color: #FF8000; 
    margin-bottom: 0px;
    background-color:white;
    width: 300px;
    display: inline-block;
    border-right: 2px solid #E2E7Ea;
`;

const Data = styled.div`
  height: 32px;
  max-height: 32px;
  width: 33%;
  text-align: right;
  span {
    font-size: 13px;
    line-height: 28px;
  }
`;

export default CumeTotalDataCell;

import React, { Component, Fragment } from 'react';
import ButtonsBuilder from './ButtonsBuilder';
import ButtonsTemplate from './ButtonsTemplate';

class Buttons extends Component {
  render() {
    const { builder } = this.props.data;
    const { view } = builder;
    return (
      <Fragment>
        {view === 'builder' && <ButtonsBuilder {...this.props} />}
        {view === 'templates' && <ButtonsTemplate {...this.props} />}
      </Fragment>
    );
  }
}

export default Buttons;

import React, {Component} from 'react';
import styled from 'styled-components';

class UpdateTemplate extends Component {
  state = {
    title: '',
  };

  updateTemplate = () => {
    const {updateTitle} = this.props.actions;
    const {title} = this.state;
    updateTitle(title);
  };

  handleChange = (e) => {
    this.setState({title: e.target.value});
  };

  componentDidMount = () => {
    const {currentTemplate} = this.props.data;
    this.setState({title: currentTemplate.title});
  };

  render() {
    return (
      <Container>
        <Title>
          <label htmlFor="templateTitle">Template title</label>
          <input 
            type="text" 
            id="templateTitle" 
            name="templateTitle" 
            value={this.state.title} 
            onChange={this.handleChange}
            onBlur={this.updateTemplate}
          />
        </Title>
      </Container>
    );
  }
}

const Container = styled.div`
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 13px;
    font-weight: 400;
    font-family: 'Roboto';
    color: #607D8B;
    
  }
  input {
    border: 1px solid #5EBED6;
    width: 374px;
    height: 32px;
    border-radius: 2px;
    margin-top: 5px;
  }
`;

export default UpdateTemplate;

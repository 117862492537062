import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { connect } from 'react-redux';
import { restoreBuilder } from '../../../actions/bannerActions';
import { setEditingChart } from '../../../actions/overviewChartActions';

class EditQuestion extends Component {
  handleClick = () => {
    const {
      id,
      banner,
      handleClose,
      restoreBuilder,
      setEditingChart,
    } = this.props;
    const {builder,builderType} = banner.data;
    setEditingChart(id);
    restoreBuilder(builderType, builder);
    handleClose();
    const trigger = document.getElementById('edit-question-trigger');
    trigger.click();
  }

  render() {
    const iconStyle = {
      style: {
        width: '15px',
        height: '15px',
        margin: '5px 12px',
        color: '#9296a0',
      },
    };
    return (
      <MenuItem onClick={this.handleClick} style={{ paddingLeft: 5 }}>
        <FontAwesomeIcon icon={faEdit} {...iconStyle} />
        Edit Question
      </MenuItem>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  restoreBuilder: (type, data) => dispatch(restoreBuilder(type, data)),
  setEditingChart: id => dispatch(setEditingChart(id)),
});

export default connect(null, mapDispatchToProps)(EditQuestion);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { loadQuestions, loadCategories } from '../../actions/categories';
import { closeBreadcrumb } from '../../actions/breadcrumbActions';
import SearchBar from './SearchBar';
import CategoryTree from './CategoryTree';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

class Browser extends Component {

  componentDidMount() {
    const { loadQuestions, loadCategories, location } = this.props;
    Promise
    .all([
      loadQuestions(location.pathname),
      loadCategories(location.pathname),
    ])
    .catch((err) => {
      const { message, type } = getErrorMessage(err);
      showNotification({ message, type });
    });
  }

  browseClick = () => {
    const { blockId, closeBreadcrumb, handleActiveTree } = this.props;
    handleActiveTree(blockId);
    closeBreadcrumb();
  }

  render() {
    const {
      actions,
      activeTree,
      showTree,
      blockId,
    } = this.props;
    const open = activeTree === blockId && showTree;
    return (
      <div>
        <Container open={open}>
          <SearchContainer>
            <SearchBar blockId={blockId} actions={actions.answer} />
          </SearchContainer>
          <BrowseButton onClick={this.browseClick}>
            <FontAwesomeIcon style={{ color: '#90A4AE', fontSize: '13px' }} icon={faSearch} />
              Browse
            <FontAwesomeIcon style={{ color: '#90A4AE', fontSize: '13px' }} icon={open ? faAngleUp : faAngleDown} />
          </BrowseButton>
        </Container>
        {open && <CategoryTree blockId={blockId} actions={actions} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categoriesReducer.categories,
  questions: state.categoriesReducer.questions,
});

const mapDispatchToProps = dispatch => ({
  loadQuestions: path => dispatch(loadQuestions(path)),
  loadCategories: path => dispatch(loadCategories(path)),
  closeBreadcrumb: () => dispatch(closeBreadcrumb()),
});

Browser.propTypes = {
  actions: propTypes.object.isRequired,
  activeTree: propTypes.number.isRequired,
  blockId: propTypes.number.isRequired,
  handleActiveTree: propTypes.func.isRequired,
  showTree: propTypes.bool.isRequired,
  loadQuestions: propTypes.func.isRequired,
  loadCategories: propTypes.func.isRequired,
  location: propTypes.object.isRequired,
};

const Container = styled.div`
  width: 100%;
  border: 1px dashed #CFD8DC;
  ${({ open }) => (open && 'border-bottom: none;')}
  border-radius: 3px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  `;

const SearchContainer = styled.div`
  width: 80%;
`;

const BrowseButton = styled.button`
  width: 20%;
  display: flex;
  justify-content: space-around;
  background: white;
  font-size: 13px;
  font-family: 'Roboto';
  color: #37474F;
  border: none;
  &:focus {
    outline:0;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Browser));

import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import _ from 'lodash';
import styled from 'styled-components';
import Plotly from './Plotly';
import colors from './colors';
import './Chart.css';

const Plot = createPlotlyComponent(Plotly);

class Chart extends Component {
  parseData = () => {
    const {
      data, grouped, labels, target, targets, chartsData, type,
    } = this.props;
    const immutableData = _.cloneDeep(data);
    let charts = [];
    if (!chartsData.onlyBaseTarget && grouped) {
      const groupedChartLabels = immutableData.keys.map(key => key.label);
      charts = labels.labels.map((label, index) => {
        const chart = {
          name: label,
          x: groupedChartLabels,
          y: immutableData.keys.map(key => key.values[index]),
          marker: {
            color: colors[index],
          },
          hoverinfo: 'x+y',
        };
        return chart;
      });
    } else {
      const chartLabels = !_.isFunction(immutableData.keys) ? immutableData.keys.map(dt => dt.label) : immutableData.map(dt => dt.label);
      const values = !_.isFunction(immutableData.keys) ? immutableData.keys.map(dt => dt.values[0]) : immutableData.map(dt => dt.value);
      let colorIndex = target;
      _.forEach(targets, ({ singleTarget }) => {
        if (!singleTarget) {
          colorIndex -= 1;
        }
      });
      charts[0] = {
        x: chartLabels,
        y: values,
        marker: {
          color: colors[colorIndex],
        },
      };
    }
    const immutableCharts = _.cloneDeep(charts).map((chart) => {
      const immutableChart = _.cloneDeep(chart);
      const xValues = _.cloneDeep(immutableChart.x);
      const yValues = _.cloneDeep(immutableChart.y);
      if (type === 'bar') {
        const xReversedValues = _.reverse(_.cloneDeep(xValues));
        immutableChart.x = xReversedValues;
        const yReversedValues = _.reverse(_.cloneDeep(yValues));
        immutableChart.y = yReversedValues;
      }
      return immutableChart;
    });
    return immutableCharts;
  }

  getChartsArray = () => {
    const { type, data, chartsData } = this.props;
    let orientation;
    if (type === 'column') {
      orientation = 'v';
    } else if (type === 'bar') {
      orientation = 'h';
    }
    if (type === 'pie') {
      return [
        {
          values: !chartsData.onlyBaseTarget ? data.map(d => d.value) : data.keys.map(d => d.values[0]),
          labels: !chartsData.onlyBaseTarget ? data.map(d => d.label) : data.keys.map(d => d.label),
          hoverinfo: 'label+percent',
          textinfo: 'none',
          marker: {
            colors,
          },
          textposition: 'auto',
          type: 'pie',
          mode: 'none',
          sort: false,
          textfont: {
            size: 9,
          },

        },
      ];
    }
    const parsed = this.parseData();
    return parsed.map((bar) => {
      if (type === 'line') {
        return {
          ...bar,
          x: bar.x,
          y: bar.y,
          name: bar.name,
          mode: 'lines+markers',
          type: 'scatter',
          orientation: 'v',
          sort: false,
          labelFont: {
            size: 9,
          },
          hoverinfo: 'x+y',
        };
      } if (type === 'bar') {
        return {
          ...bar,
          hoverinfo: 'x+y',
          x: bar.y,
          y: bar.x,
          name: bar.name,
          labelFont: {
            size: 8,
          },
          textfont: {
            size: 8,
          },
          hoverlabel: {
            namelength: 5,
          },
          type: 'bar',
          orientation,
          sort: false,
        };
      } if (type === 'column') {
        return {
          ...bar,
          hoverinfo: 'x+y',
          x: bar.x,
          y: bar.y,
          name: bar.name,
          labelFont: {
            size: 8,
          },
          hoverlabel: {
            namelength: 5,
          },
          type: 'bar',
          orientation,
          sort: false,
        };
      }
      return undefined;
    });
  }

  getLayout = ({
    type, showLegend,
  }) => {
    const margin = {
      l: 30,
      t: 10,
      b: 90,
      r: 20,
    };
    const legendPosition = {
      x: 0,
      y: -0.44,
    };
    const [width, height] = [400, 287];
    switch (type) {
      case 'bar': {
        return {
          width,
          height,
          showlegend: showLegend,
          legend: legendPosition,
          margin,
          yaxis: {
            autorange: true,
            ticks: '',
            showticklabels: false,
          },
        };
      }
      case 'column': {
        return {
          width,
          height,
          showlegend: showLegend,
          legend: legendPosition,
          margin,
          xaxis: {
            autorange: true,
            ticks: '',
            showticklabels: false,
          },
        };
      }
      case 'line': {
        return {
          width,
          height,
          showlegend: showLegend,
          legend: legendPosition,
          margin,
          xaxis: {
            autorange: true,
            ticks: '',
            showticklabels: false,
          },
        };
      }
      case 'pie': {
        return {
          width,
          height,
          showlegend: false,
          legend: legendPosition,
          margin: {...margin, l: 0},
        };
      }
      default:
        return {
          width,
          height: 300,
          showlegend: showLegend,
          legend: legendPosition,
          margin: {
           ...margin,
            pad: 5,
          },
        };
    }
  }

  render() {
    const { legendX, showLegend, type } = this.props;
    const charts = this.getChartsArray();
    return (
      <StyledChart
        className="overview-chart"
        data={charts}
        layout={this.getLayout({
          type, legendX, showLegend,
        })}
      />
    );
  }
}

const StyledChart = styled(Plot)`
  background-color: transparent;
  .plot-container > .svg-container > svg {
    overflow: visible;
  }
`;
export default Chart;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { setTargetTitleError } from '../../../actions/UIManagerActions';
import { updateCharts } from '../../../actions/overviewChartActions';
import { resetModal } from '../../../actions/targetActions';
import { changeAllCardTarget2AndBase } from '../../../actions/overviewCardActions';

class ButtonsBuilder extends Component {

  handleApply = () => {
    const {
      close,
      actions,
      data,
      type,
      setTitleError,
      updateCharts,
      changeAllCardTarget2AndBase,
    } = this.props;
    const { builder, target, spreadsheet } = actions;
    const { builder: builderData, target: targetData } = data;
    const { currentTarget } = targetData;
    const { blocks } = builderData;
    const disabled = !currentTarget.title || _.isEmpty(blocks[0].questions);
    if (!disabled) {
      target.setQuery();
      if (type === 'spreadsheet') {
        spreadsheet.addTarget();
      }
      if (type === 'spreadsheetEditTarget') {
        spreadsheet.editTarget();
      }
      if (type === 'target2' || type === 'baseTarget') {
        target.copy(type);
      }
      builder.reset();
      target.reset('currentTarget');
      close();
      updateCharts();
      changeAllCardTarget2AndBase();
    } else if (!currentTarget.title) setTitleError(true);
  };

  resetModal = () => {
    const { close, actions, resetModal } = this.props;
    const { builder, target } = actions;
    close();
    resetModal();
    builder.reset();
    target.reset('currentTarget');
  }

  render() {
    const { data: { builder: { blocks } } } = this.props;
    const disabled = _.isEmpty(blocks[0].questions);
    return (
      <Container>
        <CancelButton onClick={this.resetModal}>Cancel</CancelButton>
        <ApplyButton onClick={this.handleApply} disabled={disabled}>Apply</ApplyButton>
      </Container>
    );
  }
}

const mapDispatchtoProps = dispatch => ({
  setTitleError: state => dispatch(setTargetTitleError(state)),
  updateCharts: () => dispatch(updateCharts()),
  changeAllCardTarget2AndBase: () => dispatch(changeAllCardTarget2AndBase()),
  resetModal: () => dispatch(resetModal()),
});

const Container = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
`;

const CancelButton = styled.button`
  flex: 1;
  height: 32px;
  margin-right: 24px;
  height: 32px;
  background: #CFD8DC;
  color: #607D8B;
  border: none;
  outline: none;
  border-radius: 2px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 20px;
  margin: 0 10px;
  cursor:pointer;
`;

const ApplyButton = styled.button`
  flex: 1;
  height: 32px;
  background: #607D8B;
  color: white;
  border: none;
  outline: none;
  border-radius: 2px; 
  font-family: 'Roboto';
  font-weight: 500;
  cursor: pointer;
  margin: 0 10px;
  &:disabled{
    color: #B0BEC5;
  }
`;

export default connect(null, mapDispatchtoProps)(ButtonsBuilder);

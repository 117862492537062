import _ from 'lodash';
import {
  GET_DOCUMENTS,
  ADD_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  SET_CURRENT_SURVEY,
  SET_OLDER_SURVEY,
  GET_TEAM_DOCUMENTS,
  CLEAN_DOCUMENTS,
} from '../actionTypes/documentTypes';
import { LOG_OUT } from '../actionTypes/appTypes';

const initialState = {
  documents: {},
  currentSurvey: {},
  olderSurvey: {},
};

const DocumentReducer = (state = initialState, action) => {
  let newDocument;
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };

    case GET_TEAM_DOCUMENTS:
      return { ...state, documents: action.payload };

    case ADD_DOCUMENT:
      newDocument = action.payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          [newDocument._id]: newDocument,
        },
      };

    case UPDATE_DOCUMENT:
      newDocument = action.payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          [newDocument._id]: newDocument,
        },
      };

    case DELETE_DOCUMENT:
      newDocument = _.filter(state.documents, o => o._id !== action.payload);
      return {
        ...state,
        documents: newDocument,
      };

    case SET_CURRENT_SURVEY:
      return {
        ...state,
        currentSurvey: action.payload,
      };

    case SET_OLDER_SURVEY:
      return {
        ...state,
        olderSurvey: action.payload,
      };

    case LOG_OUT:
      return initialState;

    case CLEAN_DOCUMENTS:
      return initialState;

    default:
      return state;
  }
};

export default DocumentReducer;

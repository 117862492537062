import React, { Component } from 'react';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu } from '@material-ui/core';
import { SubChildMenu } from './SubChildMenu';

export class ChildMenu extends Component {
    state = {
      anchorEl: null,
      subAnchorEl: null,
      open: false,
    }

    handleClick = (event, item) => {
      const { open } = this.state;
      this.setState({
        anchorEl: event.currentTarget,
      }, () => {
        setTimeout(() => {
          const { subAnchorEl } = this.state;
          this.setState({
            open: subAnchorEl ? true : !open,
          });
        }, 50);
      });
    };

    handleSubMenuState = (subAnchorEl) => {
      this.setState({
        subAnchorEl,
      });
    }

    handleClose = () => {
      const { handleParentClose } = this.props;
      this.setState({ open: false });
      if (handleParentClose) {
        handleParentClose();
      }
    }

    render() {
      const { anchorEl, open } = this.state;
      const { item } = this.props;
      const FontAwesomeIconStyle = {
        width: '15px',
        height: '15px',
        margin: '-1px 5px 0 5px',
        color: 'rgba(0,0,0,0.4)',
      };
      return (
        <MenuItem
          onClick={e => this.handleClick(e, item)}
          style={{
            fontSize: '0.85em',
          }}
        >
          {
          item.icon && (
            item.imageIcon
              ? (
                <ImageIcon src={item.icon} alt="" />
              )
              : (
                <FontAwesomeIcon
                  icon={item.icon}
                  style={FontAwesomeIconStyle}
                />
              )
          )
          }
          {item.text}
          {
            item.items
            && (
              <FontAwesomeIcon
                icon={faChevronRight}
                style={FontAwesomeIconStyle}
              />
            )
          }
          <Menu
            id="child-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
          >
            {
              item.items.map((option, i) => (
                item.items
                  ? (
                    <SubChildMenu
                      handleCloseHandler={() => {
                        const { handleParentClose } = this.props;
                        this.handleClose();
                        if (handleParentClose) {
                          handleParentClose();
                        }
                      }}
                      subMenuStateHandler={this.handleSubMenuState}
                      key={i}
                      item={option}
                    />
                  )
                  : (
                    <MenuItem
                      style={{
                        fontSize: '13px',
                      }}
                      key={i}
                      onClick={() => option.action()}
                    >
                      {
                        option.icon && (
                          option.imageIcon
                            ? (
                              <ImageIcon src={option.icon} alt="" />
                            )
                            : (
                              <FontAwesomeIcon
                                icon={option.icon}
                                style={FontAwesomeIconStyle}
                              />
                            )
                        )
                      }
                      {option.text}
                    </MenuItem>
                  )
              ))
            }
          </Menu>
        </MenuItem>
      );
    }
}

const ImageIcon = styled.img`
    padding: 0 8px 0 0;
    width: 18px !important;
    height: 18px !important;
  `;

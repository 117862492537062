import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

class TargetSize extends Component {
  render() {
    const { target2 } = this.props;
    return (
      <Size>
        <p style={{margin: 5}}>n = {target2.size || 0} </p>
      </Size>
    );
  }
}

TargetSize.propTypes = {
  target2: propTypes.shape({
    size: propTypes.number,
  }).isRequired,
};
const mapStateToProps = state => ({
  target2: state.targetReducer.target2,
});


const Size = styled.div`
  color: #607D8B;
  font-size: 12px;
`;

export default connect(mapStateToProps)(TargetSize);

const defaultState = {
  category: null,
  subcategory: null,
  question: null,
};

const breadcrumbsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'OPEN_CATEGORY':
      return { ...state, category: action.payload, subcategory: null, question: null };
    case 'CLOSE_CATEGORY':
      return defaultState;
    case 'OPEN_SUBCATEGORY':
      return { ...state, subcategory: action.payload, question: null };
    case 'CLOSE_SUBCATEGORY':
      return { ...state, subcategory: null, question: null };
    case 'OPEN_QUESTION':
      return { ...state, question: action.payload };
    case 'CLOSE_QUESTION':
      return { ...state, question: null };
    case 'OPEN_BREADCRUMB':
      return openBreadcrumb(state, action.payload);
    case 'CLOSE_BREADCRUMB':
      return defaultState;
    default:
      return state;
  }
};

function openBreadcrumb(state, payload) {
  const { category, subcategory, question } = payload;
  return { ...state, category, subcategory, question };
}

export default breadcrumbsReducer;

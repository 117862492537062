import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import styled from 'styled-components';
import _ from 'lodash';
import colors from './colors';
import Plotly from './Plotly';

const Plot = createPlotlyComponent(Plotly);

class TableChart extends Component {
  parseData = () => {
    const {
      data, labels, grouped, average, unit,
    } = this.props;

    const charts = [{
      cells: {
        values: [],
        align: ['left', 'right'],
        line: { color: 'black', width: 1 },
        font: { family: 'Arial', size: 11, color: ['black'] },
      },
      header: {
        align: 'right',
        line: { width: 1, color: 'black' },
        fill: { color: colors[0] },
        font: { family: 'Arial', size: 12, color: 'white' },
      },
    }];

    const targets = ['', ...labels.labels];

    const chartsQuantityByTarget = data.keys[0].values.length;
    const firstColumn = data.keys.map(key => key.label);
    charts[0].header.values = targets;
    charts[0].cells.values[0] = firstColumn;
    data.keys.forEach((key) => {

      if (unit === 'all') {
        if (chartsQuantityByTarget > 4) {

          for (let i = 0; i < 5; i += 1) {
            if (!charts[0].cells.values[i + 1]) {
              if (i === 0) {
                charts[0].cells.values[i + 1] = [key.values[0]];
              }
              if (i === 1) {
                charts[0].cells.values[i + 1] = [key.values[1]];
              }
              if (i === 2) {
                charts[0].cells.values[i + 1] = [key.values[((chartsQuantityByTarget - 1) / 2)]];
              }
              if (i === 3) {
                charts[0].cells.values[i + 1] = [key.values[((chartsQuantityByTarget - 1) / 2) + 1]];
              }
              if (i === 4) {
                charts[0].cells.values[i + 1] = [key.values[((chartsQuantityByTarget - 1) / 2) + 2]];
              }
            } else if (i === 0) {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[0]];
            } else if (i === 1) {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[1]];
            }
            else if (i === 2) {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[((chartsQuantityByTarget - 1) / 2)]];
            }
            else if (i === 3) {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[((chartsQuantityByTarget - 1) / 2) + 1]];
            }
            else if (i === 4) {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[((chartsQuantityByTarget - 1) / 2) + 2]];
            }
          }
        } else {
          for (let i = 0; i < 2; i += 1) {
            if (!charts[0].cells.values[i + 1]) {
              if (i === 0) {
                charts[0].cells.values[i + 1] = [key.values[0]];
              } else {
                charts[0].cells.values[i + 1] = [key.values[2]];
              }
            } else if (i === 0) {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[0]];
            } else {
              charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[2]];
            }
          }
        }
      } else {
        for (let i = 0; i < chartsQuantityByTarget; i += 1) {
          if (!charts[0].cells.values[i + 1]) {
            charts[0].cells.values[i + 1] = [key.values[i]];
          } else {
            charts[0].cells.values[i + 1] = [...charts[0].cells.values[i + 1], key.values[i]];
          }
        }
      }
    });

    if (average && typeof average === 'object' && average.length > 0) {
      const { values } = charts[0].cells;
      values[0] = [...values[0], 'Average'];
      values.forEach((value, index) => {
        if (index > 0) {
          values[index] = [...values[index], average[index - 1]];
        }
      });
    }
    return charts;
  }

  getChartsArray = () => {
    const data = this.parseData();
    const { orientation } = this.props;
    return data.map(bar => ({
      ...bar,
      x: orientation === 'h' ? bar.y : bar.x,
      y: orientation === 'h' ? bar.x : bar.y,
      name: bar.name,
      text: bar.y,
      textfont: {
        size: '8',
      },
      hoverinfo: 'none',
      type: 'table',
      orientation,
      sort: false,
      textposition: 'outside',

    }));
  }

  render() {
    const {
      marginLeft, legendX, showlegend,
    } = this.props;
    return (
      <AugmentedSize marginLeft={marginLeft}>
        <StyledChart
          divId="barChart"
          data={this.getChartsArray()}
          layout={{
            width: 450,
            height: 400,
            showlegend,
            legend: { x: legendX || 0.6, y: 1.2 },
          }}
        />
      </AugmentedSize>
    );
  }
}

const AugmentedSize = styled.div`
  position: absolute;
  top: -75px;
  left: -20px;
`;

const StyledChart = styled(Plot)`
  background-color: transparent
`;

export default TableChart;

import axios from 'axios';
import { GET_MARKET_INFO, GET_CURRENT_SURVEY } from '../actionTypes/marketTypes';

export const getMarketInfo = () => (dispatch, getState) => {
  const database = getState().AppReducer.currentDatabase;
  const account = getState().AppReducer.currentFolderName;
  return axios.post('/api/marketInfo', { account, database }).then((response) => {
    dispatch({ type: GET_MARKET_INFO, payload: response.data });
  }).catch(error => console.log(error));
};

export const getCurrentSurvey = () => (dispatch, getState) => {
  const database = getState().AppReducer.currentDatabase;
  const account = getState().AppReducer.currentFolderName;
  return axios.post('/api/marketInfo/currentSurvey', { account, database }).then((response) => {
    dispatch({ type: GET_CURRENT_SURVEY, payload: response.data.currentSurvey });
  }).catch(error => console.log(error));
};

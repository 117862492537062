// Target Selected
export const SET_TARGET_TITLE = 'SET_TARGET_TITLE';
export const SET_TARGET_QUERY = 'SET_TARGET_QUERY';
export const RESTORE_SAVED_TARGET = 'RESTORE_SAVED_TARGET';
export const COPY_TARGET = 'COPY_TARGET';
export const RESET_TARGET = 'RESET_CURRENT_TARGET';
// Target Builder
export const TOGGLE_TARGET_BUILDER_VIEW = 'TOGGLE_TARGET_BUILDER_VIEW';
export const ADD_TARGET_BLOCK = 'ADD_TARGET_BLOCK';
export const REMOVE_TARGET_BLOCK = 'REMOVE_TARGET_BLOCK';
export const ADD_TARGET_QUESTION = 'ADD_TARGET_QUESTION';
export const REMOVE_TARGET_QUESTION = 'REMOVE_TARGET_QUESTION';
export const ADD_TARGET_DATAPOINT = 'ADD_TARGET_DATAPOINT';
export const REMOVE_TARGET_DATAPOINT = 'REMOVE_TARGET_DATAPOINT';
export const CHANGE_TARGET_ITEMS_OPERATOR = 'CHANGE_TARGET_ITEMS_OPERATOR';
export const TARGET_BUILDER_RESET = 'TARGET_BUILDER_RESET';
export const GET_TARGET_SIZE = 'GET_TARGET_SIZE';
// Target Templates
export const LOAD_TARGET_TEMPLATES = 'LOAD_TARGET_TEMPLATES';
export const SAVE_TARGET_TEMPLATE = 'SAVE_TARGET_TEMPLATE';
export const UPDATE_TARGET_TEMPLATE = 'UPDATE_TARGET_TEMPLATE';
export const UPDATE_TARGET_TEMPLATE_TITLE = 'UPDATE_TARGET_TEMPLATE_TITLE';
export const RESTORE_TARGET_TEMPLATE = 'RESTORE_TARGET_TEMPLATE';
export const CHANGE_TARGET_TEMPLATES_MENU = 'CHANGE_TARGET_TEMPLATES_MENU';
export const SET_CURRENT_TARGET_TEMPLATE = 'SET_CURRENT_TARGET_TEMPLATE';
export const REMOVE_SELECTED_TARGET_TEMPLATE = 'REMOVE_SELECTED_TARGET_TEMPLATE';
export const SAVE_TARGET_TO_PROJECTS = 'SAVE_TARGET_TO_PROJECTS';
// Others
export const RESTORE_BASE_TARGET = 'RESTORE_BASE_TARGET';
export const SET_TARGET_ID = 'SET_TARGET_ID';
export const SET_BASE_TARGET = 'SET_BASE_TARGET';
export const CLEAR_TARGETS = 'CLEAR_TARGETS';
export const RESET_MODAL = 'RESET_MODAL';

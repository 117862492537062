import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Proptypes from 'prop-types';
import Chart from './Charts';
import { loadCharts } from '../../actions/spreadsheetActions';
import { setLabelsAction, setSpreadsheetChartsLoaded } from '../../actions/spreadsheetChartActions';

class ChartWindow extends Component {
  constructor(props) {
    super(props);
    this.chartChild = React.createRef();
    this.state = {
      targets: [],
      charts: [],
      labels: {},
    };
  }

  componentWillMount() {
    this.updateSpreadSheetData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateSpreadSheetData(nextProps);
  }

  updateSpreadSheetData = (props) => {
    const { spreadSheet, closeWindowCharts } = props;
    const spreadSheetData = spreadSheet.Data.Tabs;
    const { currentTab } = spreadSheet;
    const banners = this.getBanners(spreadSheetData[currentTab]);
    if (banners.length > 0) {
      this.parseDataToCharts(spreadSheetData[currentTab]);
    } else {
      closeWindowCharts();
    }
  }

  getCharts = () => {
    const { labels, charts, targets } = this.state;
    if (charts.length !== 0) {
      const chartsWithData = charts.filter(chart => chart.data.length !== 0);
      if (chartsWithData.length !== 0 && _.size(labels) !== 0) {
        return charts.map(chart => (
          <Chart
            type="spreadsheet"
            chartData={chart}
            labels={labels}
            key={_.uniqueId()}
            targets={targets.length}
          />
        ));
      }
    }
    return null;
  }

  parseDataToCharts = (spreadSheetData) => {
    const dataPoints = this.getDataPoints(spreadSheetData);
    const banners = this.getBanners(spreadSheetData);
    const bannersData = this.getBannersData(spreadSheetData);
    const targets = this.getTargets(spreadSheetData);
    this.setState({ targets });
    const chartData = this.getGroupedCharts(targets, dataPoints, banners, bannersData, targets.length);
    const groupedTargets = this.getGroupedTargets(targets);
    const labelsForGroupedTargetMenu = this.getLabelsForTargetMenu(targets);
    const labels = targets.length === 1 ? this.getSingleTargetMenu(targets) : this.getGroupedTargetMenu(groupedTargets, labelsForGroupedTargetMenu);
    this.addChartData(labels, chartData);
  }

  addChartData = (labels, chartData) => {
    const { loadCharts, setLabels, setSpreadsheetChartsLoaded } = this.props;
    loadCharts(chartData);
    setLabels(labels);
    setSpreadsheetChartsLoaded(true);
    this.setState({
      labels,
      charts: chartData,
    });
  }

  getGroupedTargetMenu = (targets) => {
    const payload = [];
    const { spreadSheet } = this.props;
    const { Targets } = spreadSheet.Data.Tabs[0];


    targets.forEach((label, index) => {
      payload[index] = {
        id: index,
        name: label,
        labels: Targets.map(target => target.targetName),
      };
    });
    return payload;
  }

  getSingleTargetMenu = (targets) => {
    const payload = [];
    targets.forEach((label, index) => {
      payload[index] = {
        id: index,
        name: label,
        labels: [label],
        singleTarget: true,
      };
    });
    return payload;
  };

  getLabelsForTargetMenu = (targets) => {
    let targetMenuLabels = {};
    targets.forEach((target, index) => {
      targetMenuLabels = {
        ...targetMenuLabels,
        [index]: targets.map(targetName => targetName),
      };
    });
    return targetMenuLabels;
  }

  getGroupedTargets = (targets) => {
    const groupedTargets = [];
    targets.forEach((target, index) => {
      const newIndex = index + 1;
      const makeLoop = newIndex < targets.length;
      if (makeLoop) {
        const newTargetChoice = `${targets[0]} & ${targets[newIndex]}`;
        groupedTargets.push(newTargetChoice);
      }
    });
    return groupedTargets;
  }

  getGroupedCharts = (targets, dataPoints, banners, bannersData, targetsCount) => {
    let data = [];
    let average;
    if (targets.length !== 0) {
      data = banners.map((banner, index) => {
        const labels = dataPoints[index].dataPoints.map(dp => dp.name);
        const newData = [];
        if (Array.isArray(bannersData[index].average)) {
          average = _.compact(bannersData[index].average);
        }
        const key = {
          type: 'grouped',
          average,
          percentages: {
            labels,
            keys: dataPoints[index].dataPoints.map((dp) => {
              const label = dp.name;
              const values = dp.value.map(value => value.percentage ? this.roundingPercentData(value.percentage) : 0);
              return {
                label,
                values,
              };
            }),
          },
          thousands: {
            labels,
            keys: dataPoints[index].dataPoints.map((dp) => {
              const label = dp.name;
              const values = dp.value.map(value => value.thousands ? this.roundingThousandsData(value.thousands) : 0);
              return {
                label,
                values,
              };
            }),
          },
          index: {
            keys: dataPoints[index].dataPoints.map((dp) => {
              const label = dp.name;
              const values = dp.value.map(value => value.index ? this.roundingIndexData(value.index) : 0);
              return {
                label,
                values,
              };
            }),
          },
        };

        if (targetsCount !== 0) {
          key.index = {
            keys: dataPoints[index].dataPoints.map((dp) => {
              const label = dp.name;
              const values = dp.value.map(value => value.index ? this.roundingIndexData(value.index) : 0);
              return {
                label,
                values,
              };
            }),
          };
        }

        newData.push(key);
        const chartData = {
          id: index,
          title: banner,
          data: newData,
        };
        return chartData;
      });
    }
    return data;
  }

  getSingleCharts = (targets, dataPoints, banners, bannersData) => {
    let data = [];
    let average = [];
    if (targets.length !== 0) {
      data = banners.map((banner, index) => {
        if (Array.isArray(bannersData[index].average)) {
          average = _.compact(bannersData[index].average);
        }
        const chartData = {
          id: index,
          title: banner,
          data: [{
            type: 'single',
            average,
            percentages: dataPoints[index].dataPoints.map(dp => ({
              label: dp.name,
              value: dp.value[0].percentage ? this.roundingPercentData(dp.value[0].percentage) : 0,
            })),
            thousands: dataPoints[index].dataPoints.map(dp => ({
              label: dp.name,
              value: dp.value[0].thousands ? this.roundingThousandsData(dp.value[0].thousands) : 0,
            })),
          }],
        };
        return chartData;
      });
    }
    return data;
  }

  roundingPercentData = percentData => percentData ? parseFloat(Math.round(percentData.toFixed(2) * 100) / 100) : 0

  roundingThousandsData = thousandsData => thousandsData ? parseFloat(Math.round(thousandsData.toFixed(0) * 100) / 100) : 0

  roundingIndexData = indexData => !isNaN(indexData) ? parseFloat(Math.round(indexData.toFixed(0) * 100) / 100) : 0

  getBanners = (spreadSheetData) => {
    const data = spreadSheetData;
    const banners = data.Banners;
    const visibleBanners = banners.filter(banner => banner.Visible && !banner.combinedBanner);
    const bannerNames = visibleBanners.map(bann => bann.BannerName);
    return bannerNames;
  }

  getBannersData = (spreadSheetData) => {
    const data = spreadSheetData;
    const banners = data.Banners;
    const visibleBanners = banners.filter(banner => banner.Visible && !banner.combinedBanner);
    return visibleBanners;
  }


  getDataPoints = (spreadSheetData) => {
    const data = spreadSheetData;
    const banners = data.Banners;
    const visibleBanners = banners.filter(banner => banner.Visible && !banner.combinedBanner);
    const completeDataPoints = visibleBanners.map(banner => banner.BannerDataPoints
      .filter(d => d.isVisible && !d.combinedRow));
    const dataPoints = completeDataPoints.map(dp => ({
      dataPoints: dp.map((dataP) => {
        const { name, value } = dataP;
        return {
          name,
          value,
        };
      }),
    }));
    return dataPoints;
  }

  getTargets = (spreadSheetData) => {
    const data = spreadSheetData;
    const targets = data.Targets.map((target, index) => {
      if (index === 0) {
        return 'Base Target';
      }
      return `Target ${index}`;
    });
    return targets;
  }

  handleMouseHover = () => {
    this.chartChild.style.paddingLeft = '500px';
    this.chartChild.style.marginLeft = '-500px';
  }

  handleMouseLeave = () => {
    this.chartChild.style.paddingLeft = '0';
    this.chartChild.style.marginLeft = '0';
  }

  updateRef = (e) => {
    this.chartChild = e;
  }

  render() {
    const { closeWindowCharts } = this.props;
    const { charts } = this.state;
    return (
      <Window>
        <TitleBar>
          <Title>
              Charts
          </Title>
          <DeleteButton onClick={closeWindowCharts}>
            <FontAwesomeIcon
              icon={faTimes}
            />
          </DeleteButton>
        </TitleBar>
        <ChartContainer
          innerRef={e => this.updateRef(e)}
        >
          <Panel
            onFocus={() => { }}
            onMouseOver={this.handleMouseHover}
            onMouseLeave={this.handleMouseLeave}
          >
            {
              charts
              && this.getCharts()
            }
          </Panel>
        </ChartContainer>
      </Window>
    );
  }
}

ChartWindow.propTypes = {
  setSpreadsheetChartsLoaded: Proptypes.func.isRequired,
  loadCharts: Proptypes.func.isRequired,
  setLabels: Proptypes.func.isRequired,
  closeWindowCharts: Proptypes.func.isRequired,
  spreadSheet: Proptypes.object.isRequired,
};

const Panel = styled.div`
  pointer-events: all;
  padding: 20px 0px;
  margin-top: -20px;
  background-color: #FFFFFF;
  box-shadow: 0px 10px 6px 0px rgba(0,0,0,0.35);
`;
const height = window.innerHeight;

const ChartContainer = styled.div`
  max-height: ${() => ((height > 1000) ? '75vh' : '65vh')};
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Window = styled.div`
  z-index: 1000;
  position: absolute;
  right: 0px;
  height: auto;
`;


const TitleBar = styled.div`
  height: 38px;
  width: 100%;
  background-color: #37474F;
  font-family: Roboto;
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 32px;
  box-shadow: 0px 10px 6px 0px rgba(0,0,0,0.35);
`;

const Title = styled.span`
  padding-top: 5px;
  margin: 15px 0px 0px 15px;
`;

const DeleteButton = styled.span`
  right:0;
  top: 3px;
  cursor:pointer;
  position:absolute;
  margin: 0px 15px 0px 0px;
  font-size: 20px;
`;

const mapStateToProps = state => ({
  spreadSheet: state.spreadsheet,
});

const mapDispatchToProps = dispatch => ({
  loadCharts: banners => dispatch(loadCharts(banners)),
  setLabels: labels => dispatch(setLabelsAction(labels)),
  setSpreadsheetChartsLoaded: status => dispatch(setSpreadsheetChartsLoaded(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartWindow);

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import propTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from '../FormComponents/CheckBox';

class Trigger extends Component {
  handleClick = (e, name) => {
    e.stopPropagation();
    this.handleChange(e, name);
  }

  handleChange = (e, name) => {
    const { toggle } = this.props;
    if (e.target.type === 'checkbox') return null;
    toggle();
    const {
      actions,
      blockId,
      question,
      checked,
    } = this.props;
    const { addQuestion, removeQuestion } = actions;
    const data = { blockId, question };
    if (!checked) {
      addQuestion(data);
    } else {
      removeQuestion(data);
    }
    const checkboxes = document.querySelectorAll(`input[name="${name}"]`);
    checkboxes.forEach((checkbox) => {
      checkbox.checked = !checked;
    });
    return 'done';
  };

  render() {
    const {
      blockId, question, checked, open,
    } = this.props;
    return (
      <Container>
        <Title open={open}>
          <IconWrapper>
            <FontAwesomeIcon icon={open ? faCaretDown : faCaretRight} />
          </IconWrapper>
          <Text>{question.text}</Text>
          <CheckboxContainer checked={checked}>
            <Checkbox
              onClick={this.handleClick}
              onChange={this.handleChange}
              name={`${blockId}-${question.id}`}
              value={question.id}
              checked={checked}
            />
          </CheckboxContainer>
        </Title>
      </Container>
    );
  }
}

Trigger.propTypes = {
  blockId: propTypes.number.isRequired,
  question: propTypes.shape({
    id: propTypes.string.isRequired,
    text: propTypes.string.isRequired,
  }).isRequired,
  checked: propTypes.bool.isRequired,
  actions: propTypes.shape({
    addQuestion: propTypes.func.isRequired,
    removeQuestion: propTypes.func.isRequired,
  }).isRequired,
  open: propTypes.bool,
  toggle: propTypes.func.isRequired,
};

Trigger.defaultProps = {
  open: false,
};

const CheckboxContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  transform: translateY(-50%);
  opacity: ${({ checked }) => (checked ? '100' : '0')};
`;

const Container = styled.div`
  &:hover {
    ${CheckboxContainer} {
      opacity: 100;
    }
  }
`;

const Title = styled.div`
  position: relative;
  min-height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  color: #2F3E48;
  background-color: ${({ open }) => (open ? '#E8F5F9' : 'white')};
  padding-left: 30px;
  &:hover {
    background: #E8F5F9;
  }
`;

const IconWrapper = styled.div`
  color: #98ABB4;
  min-width: 15px;
  font-size: 13px;
  font-weight: 400;
`;

const Text = styled.p`
  margin: 0px;
  margin-left: 5px;
  padding: 5px 0;
`;

export default Trigger;

import Axios from 'axios';
import _ from 'lodash';

import {
  GET_MEMBERS, DELETE_MEMBER, ADD_MEMBER, UPDATE_MEMBER, CANCEL_MEMBER,
} from '../actionTypes/teamTypes';
import { CANCEL_INVITATION_FROM_ACCOUNTS } from '../actionTypes/accountTypes';

const getMembersAction = payload => ({
  type: GET_MEMBERS,
  payload,
});

const deleteMemberAction = payload => ({
  type: DELETE_MEMBER,
  payload,
});

const addMemberAction = payload => ({
  type: ADD_MEMBER,
  payload,
});

const updateMemberAction = payload => ({
  type: UPDATE_MEMBER,
  payload,
});

const cancelMemberAction = (payload, fromAccount) => {
  if (fromAccount) {
    return {
      type: CANCEL_INVITATION_FROM_ACCOUNTS,
      payload,
    };
  }
  return {
    type: CANCEL_MEMBER,
    payload,
  };
};

const getMembers = teamId => (dispatch, getState) => new Promise((resolve, reject) => {
  const membersQuantity = _.size(getState().teamReducer.members);
  if (membersQuantity === 0) {
    Axios.get(`/api/team/${teamId}/members`)
      .then((response) => {
        const members = _.keyBy(response.data.members, '_id');
        dispatch(getMembersAction(members));
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  }
});

const deleteMember = data => dispatch => new Promise((resolve, reject) => {
  const {
    userId,
  } = data;
  Axios.put(`/api/users/${userId}/deactive`)
    .then((response) => {
      dispatch(deleteMemberAction(response.data.user));
      resolve(response);
    })
    .catch((err) => {
      // err.data
      reject(err);
    });
});

const cancelInvitation = (data, fromAccounts) => (dispatch, getState) => new Promise((resolve, reject) => {
  const {
    teamId, rows,
  } = data;
  const { user } = getState().AppReducer;
  const newData = {
    selectedMembers: _.values(rows),
  };
  Axios.put(`/api/team/${teamId}/members/cancel/invitation`, newData)
    .then((response) => {
      if (fromAccounts) {
        dispatch(cancelMemberAction(response.data.members.map(member => member._id), true));
        dispatch(cancelMemberAction(response.data.members.map(member => member._id)));
      } else {
        if (user.role.name === 'admin') {
          dispatch(cancelMemberAction(response.data.members.map(member => member._id), true));
        }
        dispatch(cancelMemberAction(response.data.members.map(member => member._id)));
      }
      resolve(response.data.members);
    })
    .catch((err) => {
      reject(err);
    });
});

const addMember = data => (dispatch, getState) => new Promise((resolve, reject) => {
  Axios.post(`/api/team/${data.teamId}/members/`, data)
    .then((response) => {
      if (response.data.error) {
        reject(response.data.error);
      } else {
        const member = {
          ...response.data.member,
          status: 'INVITED',
        };
        dispatch(addMemberAction(member));
        resolve(response.data);
      }
    })
    .catch((err) => {
      reject(err);
    });
});

const updateMember = data => dispatch => new Promise((resolve, reject) => {
  Axios.put(`/api/team/${data.teamId}/members/${data.userId}`, data)
    .then((response) => {
      if (response.data.error) {
        reject(response.data.error);
      } else {
        dispatch(updateMemberAction(response.data.member));
        resolve(response.data);
      }
    })
    .catch((err) => {
      reject(err);
    });
});

const sendInvitations = (data, sendToOwner) => new Promise((resolve, reject) => {
  const {
    rows,
    teamId,
  } = data;
  const information = {
    selectedMembers: _.values(rows),
  };
  Axios.post(`/api/team/${teamId}/members/send/invitation`, information)
    .then((result) => {
      if (result.status === 200) {
        resolve(result.data);
      } else {
        reject('Error sending invitations');
      }
    })
    .catch((err) => {
      console.log(err);
      reject(err);
    });
});

export {
  getMembers,
  deleteMember,
  addMember,
  updateMember,
  sendInvitations,
  cancelInvitation,
};

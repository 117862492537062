
import React, {useEffect, useState} from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import { ThemeProvider } from 'styled-components';
import ReactBreakpoints from 'react-breakpoints';
import { SnackbarProvider } from 'notistack'
import { createGenerateClassName } from '@material-ui/core/styles';
import ScreenValidation from './components/GlobalComponents/QueryScreenValidation';
import {theme} from './styles/theme/theme';
import {breakpoints} from './styles/theme/breakpoints';
import Main from './components/GlobalComponents/Main';
import { checkToken } from './helpers/checkToken';
import { getDbFolderFromUser } from './helpers/utils';
import {
  ALREADY_LOGGED, SET_CURRENT_USER, SET_DATABASE, SET_ACCOUNT_FOLDERNAME, SET_ACCOUNT_ID,
} from './actionTypes/appTypes';
import store from './store';
import { getAccountById, setAccountAction } from './actions/accountActions';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
});



function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    checkToken().then(({ user }) => {
      const isAdmin = user.role.name === 'admin';
      const currentDatabase = localStorage.getItem('currentDatabase');
      const currentAccountFolder = localStorage.getItem('currentAccountFolder');
      const currentAccountId = localStorage.getItem('currentAccountId');
      store.dispatch({ type: ALREADY_LOGGED });
      store.dispatch({ type: SET_ACCOUNT_ID, payload: currentAccountId || user.teamId._id });
      store.dispatch({ type: SET_CURRENT_USER, payload: user });

      if (isAdmin) {
        store.dispatch({ type: SET_ACCOUNT_FOLDERNAME, payload: currentAccountFolder });
        store.dispatch({ type: SET_DATABASE, payload: currentDatabase });
      if (currentAccountId){
         getAccountById(currentAccountId).then((response) => {
        store.dispatch(setAccountAction(response));
       });
      }
      } else {
        store.dispatch({ type: SET_ACCOUNT_FOLDERNAME, payload: user.teamId.folderName });
        store.dispatch({ type: SET_DATABASE, payload: currentDatabase || getDbFolderFromUser(user)});
      }
    }).catch((err) => {
      console.log('Error checking token', err);
    }).finally(() => {
      setLoading(false)  ;
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <JssProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <div>
            <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}/>
            <ReactBreakpoints breakpoints={breakpoints}>
              <ScreenValidation>
                <Main />
              </ScreenValidation>
            </ReactBreakpoints>
          </div>
        </ThemeProvider>
      </JssProvider>
    </>
  );
}

export default App

import React, { Component } from 'react';
import propTypes from 'prop-types';
import Question from './Question';

class Questions extends Component {
  render() {
    const {
      actions,
      activeCollapsibles,
      blockId,
      handlers,
      path,
      questions,
    } = this.props;
    return (
      <React.Fragment>
        {questions.map((question, index) => (
          <Question
            actions={actions}
            activeCollapsibles={activeCollapsibles}
            blockId={blockId}
            index={index}
            key={index}
            handlers={handlers}
            path={path}
            question={question}
          />
        ))}
      </React.Fragment>);
  }
}

Questions.propTypes = {
  actions: propTypes.object.isRequired,
  activeCollapsibles: propTypes.object.isRequired,
  blockId: propTypes.number.isRequired,
  handlers: propTypes.object.isRequired,
  path: propTypes.object.isRequired,
  questions: propTypes.array.isRequired,
};

export default Questions;

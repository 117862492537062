import React, { Component } from 'react';
import styled from 'styled-components';
import Tabs from '../GlobalComponents/Tabs/Tabs';
import Tab from '../GlobalComponents/Tabs/Tab';
import BrowserManager from './BrowserManager';
import BannerSummary from './BannerSummary';
import Builder from '../GlobalComponents/Builder';
import Templates from './Templates';
import TemplateDropdown from './TemplateDropdown';
import Scrollbar from '../GlobalComponents/Scrollbar';

class TabsSection extends Component {
  render() {
    const { actions, data } = this.props;
    return (
      <Container>
        <Tabs>
          <Tab tabTitle="Select Questions" linkClassName="custom-link">
            <ScrollContainer>
              <Scrollbar>
                <div style={{ padding: '10px' }}>
                  <BannerSummary actions={actions} data={data} />
                  <BrowserManager actions={actions.basic} />
                </div>
              </Scrollbar>
            </ScrollContainer>
          </Tab>
          <TemplateDropdown tabTitle="Templates" linkClassName="custom-link">
            <ScrollContainer>
              <Scrollbar>
                <div style={{ padding: '10px' }}>
                  <Templates actions={actions} />
                </div>
              </Scrollbar>
            </ScrollContainer>
          </TemplateDropdown>
          <Tab tabTitle="Build Advanced Question Blocks" linkClassName="custom-link">
            <ScrollContainer>
              <Scrollbar>
                <div style={{ padding: '10px' }}>
                  <Builder actions={actions.advanced} blocks={data.builders.advanced.blocks} />
                </div>
              </Scrollbar>
            </ScrollContainer>
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 566px;
  height: 100%;
  padding-left: 32px;
  margin-top: 18px;
  .tabs {
    margin-right: 3px;
  }

  ul.tabs-nav {
    margin: 0px;
    padding: 0px;
    list-style: none;
    border-bottom: 1px solid #CFD8DC;
  }

  .tabs-active-content {
    margin-top: 20px;
  }

  li.tab {
    display: inline-block;
    background: #F6F7F8;
    color: #37474F;
    border-radius: 2px;
    margin-right: 5px;
    font-size: 13px;
    margin-bottom: -1px;
  }

  li.tab svg {
    color: #90A4AE;
  }

  a.tab-link.custom-link {
    display: block;
    font-family: 'Roboto',sans-serif;
    text-align: center;
    cursor: pointer;
    padding: 5px;
    border: 1px solid #CFD8DC;
    margin-bottom: -1px;
    border-radius: 2px 2px 0 0;
  }

  a.tab-link.custom-link.active {
    font-weight: 500;
    border-bottom: 2px solid #F6F7F8;
  }
`;

const ScrollContainer = styled.div`
  width: 563px;
  height: 480px;
  padding: 5px;
`;

export default TabsSection;

import { configureStore } from '@reduxjs/toolkit'
import categoriesReducer from '../reducers/categoriesReducer';
import targetReducer from '../reducers/targetReducer';
import spreadsheetReducer from '../reducers/spreadsheetReducer';
import teamReducer from '../reducers/teamReducer';
import ProfileReducer from '../reducers/profileReducer';
import DocumentReducer from '../reducers/documentReducer';
import overviewChartReducer from '../reducers/overviewChartsReducer';
import spreadsheetChartReducer from '../reducers/spreadsheetChartsReducer';
import bannerReducer from '../reducers/bannerReducer';
import AppReducer from '../reducers/AppReducer';
import ProjectReducer from '../reducers/projectsReducer';
import AccountReducer from '../reducers/accountReducer';
import marketReducer from '../reducers/marketReducer';
import OverviewReducer from '../reducers/OverviewReducer';
import overviewCardReducer from '../reducers/overviewCardsReducer';
import spreadsheetCardReducer from '../reducers/spreadsheetCardsReducer';
import UIManagerReducer from '../reducers/UIManagerReducer';
import breadcrumbReducer from '../reducers/breadcrumbReducer';

const store = configureStore({
  reducer: {
    categoriesReducer,
    AppReducer,
    spreadsheet: spreadsheetReducer,
    targetReducer,
    bannerReducer,
    teamReducer,
    ProfileReducer,
    DocumentReducer,
    overviewChartReducer,
    spreadsheetChartReducer,
    ProjectReducer,
    AccountReducer,
    marketReducer,
    OverviewReducer,
    overviewCardReducer,
    spreadsheetCardReducer,
    UIManagerReducer,
    breadcrumbReducer,
  },
})

export default store;

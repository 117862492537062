import React,{Component} from 'react';
import styled from 'styled-components';

class AddTargetButton extends Component {
  

  constructor(props) {
    super(props)
    this.state = {
    };  

}


render() {
    return (
        <AddButtonContainer onClick={this.props.openModal} id='AddTargetButton'>
            <AddButtonElement>
                <AddButtonText>
                    + add Target
                </AddButtonText>
                <ButtonAdd>
                <AddButtonText>
                    Add
                </AddButtonText>
                </ButtonAdd>
            </AddButtonElement>
        </AddButtonContainer>)
    }
}

const AddButtonContainer = styled.div`
    width:175px; 
    float:left;
    height: 32px !important;
    display:inline-block;
    cursor:pointer;
    margin-bottom:20px;
`;

const AddButtonText = styled.div`
    margin-top:5px;
    float:left;
    font-size: 13px;
`;

const AddButtonElement = styled.div`   
    width:100%;
    border: 2px dashed #CFD8DC;
    background-color: white;
    height: 36px !important;
    border-radius: 2px;
    color: #CFD8DC;
    height:28px;
    padding:2px 2px 2px 10px;
    margin-left: 10px;
    &:hover {
        border-color:#19A2C5;
    };     
`;

const ButtonAdd = styled.div`
    height: 28px;
    border-radius: 2px;
    background-color: #C5E7F0;
    font-size:13px;  
    font-weight:500;
    color: #19A2C5;
    padding: 2px 10px 15px 10px !important;
    float:right;  
    border:none;
    display:none;    
    ${AddButtonElement}: hover &{
        display: block;
    }
    vertical-align:middle;
`;

export default AddTargetButton;
import React, { Component } from 'react';
import styled from 'styled-components';
import CheckBoxComponent from './CheckBoxModal'
import _ from 'lodash';



class AddTabModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCombined: false,
            selectedAll: false,
            Data: this.props.Data
        };
    }

    componentDidMount() {

        this.setState({
            tabsToAdd:{
                years: this.props.Data,
                isCombined: false
            }
        })
    }

    handleCheckbox = (pos) =>{
        let copy = _.cloneDeep(this.state.Data);   
        copy.Tabs[pos].isVisible = !copy.Tabs[pos].isVisible;
        this.setState({
            Data: copy
        })
    }

    handleSelectAllCheckBox = () =>{
        let copy = _.cloneDeep(this.state.Data); 
        copy.Tabs.forEach(year =>{
            year.isVisible = !this.state.selectedAll;
        })
        this.setState({
            Data: copy,
            selectedAll: !this.state.selectedAll
        })
    }

    handleIsCombined = () => {     
        let copy = _.cloneDeep(this.state.Data); 
        copy.CombinedTab.isVisible = !copy.CombinedTab.isVisible;
        this.setState({
            isCombined: !this.state.isCombined,
            Data: copy,
        })
    }

    componentDidUpdate() {
    }

    handleAddTabs = () => {
       const {currentTab} = this.props
        const {Tabs} = this.state.Data
        if (!Tabs[currentTab].isVisible){
            const newCurrentTabIndex = Tabs.findIndex((tab) => tab.isVisible)
            if (newCurrentTabIndex > -1){
                this.props.functions.setCurrentTab(newCurrentTabIndex)
            }
        }
        this.props.functions.addNewTabs(this.state.Data);
        this.props.closeTabModal();
    }

    render() {
        const tabLength = this.props.Data.Tabs.length;
        return (
            <ModalContainer>
                <ModalHeader>
                    {this.props.type === 1 ? 'Select Survey Years' : 'Trend'}
                </ModalHeader>   
                <ModalBody>   
                    {this.props.type === 1 ? <CheckBoxComponent handleCheckbox={this.handleSelectAllCheckBox} checkBoxText="Select All" isChecked={this.state.selectedAll}/> : <SubHeader>Select Survey Year</SubHeader>}
                    <br/>
                    <table style={{width:'100%'}}>
                        <tbody>
                        {this.state.Data.Tabs.map((year, i, array) => {  
                                return  <tr key={i}>
                                {year.Name !== 'Combined' ? <td><CheckBoxComponent handleCheckbox={this.handleCheckbox} pos={i} checkBoxText={year.isCurrentSurvey ? (year.Name + ' (Current Survey) ') : year.Name} isChecked={year.isVisible}/></td> : null}
                            </tr> 
                        })}   
                        </tbody>
                    </table>                                                                          
                </ModalBody>
                {this.props.type === 1 ? <ModalBody2><CheckBoxComponent isChecked={this.state.Data.Tabs[tabLength - 1].isVisible} handleCheckbox={this.handleCheckbox} pos={tabLength - 1} checkBoxText="Combine selected in single sheet" /></ModalBody2> : null}
                <ModalFooter>
                    <CancelButton onClick={this.props.closeTabModal}>
                        Cancel
                    </CancelButton>
                    <AddButton onClick={this.handleAddTabs}>
                        Add
                    </AddButton>
                </ModalFooter>         
            </ModalContainer>
        )
    }
}

const ModalContainer = styled.div`
   width:400px;
    font-size:20px;  
    font-family: Roboto;
    font-weight:500;
    color: #37474F;
    background-color:white;
    height: 600px;
    border-radius: 10px;
`;

const SubHeader = styled.div`
    font-size:13px;  
    font-family: Roboto;
    font-weight:500;
    color: #37474F;
`; 

const ModalHeader = styled.div`   
    padding: 10px;     
    border-bottom: 1pt solid #607D8B;
    height:30px;
`;

const ModalBody = styled.div`
    margin-bottom:10px;
    padding:10px;
    height:390px;
    overflow-y:auto;
`;

const ModalBody2 = styled.div`
    border-top: 1pt solid #607D8B;
    padding:10px;
`;

const ModalFooter = styled.div`
    border-top: 1pt solid #607D8B;
    background-color: white;
    min-height:50px;
    margin-top:10px;
    border-radius: 0px 0px 10px 10px;
`;

const CancelButton = styled.button`
    height:32px;
    color: #607D8B;   
    background-color:#CFD8DC;
    padding: 0px 15px 0px 15px;
    border-radius: 2px;
    border: 1pt solid #CFD8DC;
    cursor: pointer;
    margin: 10px 15px 0px 0px;
    float: right;
`;

const AddButton = styled.button`
    height:32px;
    color: #FFFFFF;   
    background-color:#607D8B;
    padding: 0px 15px 0px 15px;
    border-radius: 2px;
    border: 1pt solid #607D8B;
    cursor: pointer;
    margin: 10px 10px 0px 0px;
    float: right;
`;

export default AddTabModal;
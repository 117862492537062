import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck, faFile, faFilePdf, faFileImage,
} from '@fortawesome/free-solid-svg-icons';
import {
  Row,
  Col,
  ControlLabel,
  Title,
  Button,
} from '../../../GlobalComponents/BasicControls';
import showNotification from '../../../../helpers/showNotification';

export class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableOnSaving: false,
    };
  }

  componentWillMount() {
    const { type, content, updateMainTitle } = this.props;
    const { details } = content;
    if (type === 'edit') {
      if (details) {
        updateMainTitle(`${details.name}`);
      }
    }
  }

  changeTabSection = (state) => {
    const {
      type, content, updateMainTitle, setState,
    } = this.props;
    const { databases, support, details } = content;
    if (type === 'edit') {
      updateMainTitle(`Edit Account, ${details.name}`);
    }
    setState({
      reviewing: true,
      account: {
        ...content,
        databases: {
          ...databases,
          edit: state === 3 ? true : databases.edit,
        },
        support: {
          ...support,
          edit: state === 4 ? true : support.edit,
        },
      },
      selectedItemNav: state,
    });
  }

  createAccount = () => {
    this.setState({
      disableOnSaving: true,
    });
    showNotification({ message: 'Uploading files, please wait.', type: 'warning' })
    this.props.createAccount()
      .then((response) => {
        showNotification({ message: 'Account added successfully', type: 'success' });
        this.props.history.push('/accounts');
      })
      .catch((err) => {
        showNotification({ message: err.message, type: 'error' });
        this.setState({
          disableOnSaving: false,
        });
      });
  }

  renderDatabaseSections = () => {
    const { databases, databaseForms } = this.props.content;
    const { type } = this.props;
    return databaseForms.map((data, index) => (
      databases[index].name
      && (
      <Row key={index}>
        <Col size={12}>
          {this.setSectionHeader(`Database ${index + 1}: ${data.databaseName}`, 3)}
          <SmallSection>
            <ControlLabel>
              Database name
            </ControlLabel>
            <Paragraph>
              {databases[index].name}
            </Paragraph>
          </SmallSection>
          <SmallSection>
            <ControlLabel>
              Databases:
            </ControlLabel>
            {
              type === 'edit'
                ? databases[index].storagedDatabases
                && databases[index].storagedDatabases.length !== 0
                && databases[index].storagedDatabases.map((d, i) => (
                  <Paragraph key={i}>
                    <Icon icon={faFile} />
                    {' '}
                    {!d.new ? d.fileName : d.fileName.name}
                  </Paragraph>
                ))
                : _.map(databases[index].database, (d, i) => (
                  <Paragraph key={i}>
                    <Icon icon={faFile} />
                    {' '}
                    {d.name}
                  </Paragraph>
                ))
            }
          </SmallSection>
          <SmallSection>
            <ControlLabel>
                Current survey
            </ControlLabel>
            <Paragraph>
              {
                type !== 'edit'
                  ? databases[index].survey.current
                  && (
                  <div>
                    <Icon icon={faFilePdf} />
                    {databases[index].survey.current.name}
                  </div>
                  )
                  : databases[index].survey.currentStoraged
                    && (
                    <div>
                      <Icon icon={faFilePdf} />
                      {databases[index].survey.currentStoraged.fileName}
                    </div>
                    )
              }
            </Paragraph>
          </SmallSection>
          <SmallSection>
            <ControlLabel>
                Old survey
            </ControlLabel>
            <Paragraph>
              {
                type !== 'edit'
                  ? databases[index].survey.older
                  && (
                  <div>
                    <Icon icon={faFilePdf} />
                    {databases[index].survey.older.name}
                  </div>
                  )
                  : databases[index].survey.olderStoraged
                    && databases[index].survey.olderStoraged.length !== 0
                      && (
                      <div>
                        <Icon icon={faFilePdf} />
                        {databases[index].survey.olderStoraged[0].fileName}
                      </div>
                      )
              }
            </Paragraph>
          </SmallSection>
          <ControlLabel>
              Permissions
          </ControlLabel>
          <Items>
            <ul>
              {
              data.accesses.map((access, index) => (
                <li key={index}>
                  <Icon icon={faCheck} />
                  {' '}
                  {access}
                </li>
              ))
              }
            </ul>
          </Items>
        </Col>
      </Row>
      )));
  }

  setSectionHeader = (text, state) => {
    const { error_result } = this.props;
    return (
      <div>
        <Wrapper>
          <Topic>
            {text}
          </Topic>
          {
            !error_result
            && (
            <LinkButton
              onClick={() => this.changeTabSection(state)}
            >
            Edit
            </LinkButton>
            )}
        </Wrapper>
        <Line />
      </div>
    );
  }

  onCancelCreatingAndEditing = () => {
    const { type, onCancelCreating, history } = this.props;
    if (type === 'edit') {
      history.push('/accounts');
    } else {
      onCancelCreating();
    }
  }

  render() {
    const { type, content } = this.props;
    const { disableOnSaving } = this.state;
    const {
      details, contact, support, storagedLogo, resources,
    } = this.props.content;

    return (
      <Body>
        {
          type !== 'edit'
            && (
            <Title>
              Review
            </Title>
            )}
        <Row>
          <Col size={12}>
            {this.setSectionHeader('Account Details', 1)}
            <Topic>
              {details.name}
            </Topic>
            <Paragraph>
              {details.city}
              ,
              {' '}
              {details.state}
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            {this.setSectionHeader('Account Contacts', 2)}
            <Row>
              <Col size={6} style={{ paddingBottom: '0' }}>
                <Topic>
                  Primary Contact
                </Topic>
                {
                  contact[0]
                  && (
                    <div>
                      <Paragraph>
                        {contact[0].firstName}
                        {' '}
                        {contact[0].lastName}
                      </Paragraph>
                      <Paragraph>
                        {contact[0].email}
                      </Paragraph>
                      <Paragraph>
                        {contact[0].phone1}
                      </Paragraph>
                      {
                        contact[0].phone2
                        && (
                          <Paragraph>
                            {contact[0].phone2}
                          </Paragraph>
                        )}
                    </div>
                  )
              }
                <Wrapper style={{ justifyContent: 'initial' }}>
                  <ControlLabel style={{ width: 'auto' }}>
                    User Type:
                  </ControlLabel>
                  <Paragraph style={{ width: 'auto', paddingLeft: '10px' }}>
                    Business Owner
                  </Paragraph>
                </Wrapper>
              </Col>
              <Col size={6} style={{ paddingBottom: '0' }}>
                <Topic>
                  Secondary Contact
                </Topic>
                {
                  contact[1]
                    ? (
                      <div>
                        <Paragraph>
                          {contact[1].firstName}
                          {' '}
                          {contact[1].lastName}
                        </Paragraph>
                        <Paragraph>
                          {contact[1].email}
                        </Paragraph>
                        <Paragraph>
                          {contact[1].phone1}
                        </Paragraph>
                        {
                      contact[1].phone2
                      && (
                      <Paragraph>
                        {contact[1].phone2}
                      </Paragraph>
                      )}
                      </div>
                    ) : (
                      <Paragraph>
                    None
                      </Paragraph>
                    )}

              </Col>
            </Row>
          </Col>
        </Row>
        {this.renderDatabaseSections()}
        <Row>
          <Col size={12}>
            {this.setSectionHeader('Support Documents', 4)}
            <Items>
              <ul>
                {
                  (type === 'edit')
                    ? (
                      storagedLogo.name
                      && (
                        <li>
                          <Icon icon={faFileImage} />
                          {' '}
                          {storagedLogo.name}
                        </li>
                      )
                    )
                    : (
                      content.logoFile.name
                      && (
                        <li>
                          <Icon icon={faFileImage} />
                          {' '}
                          {content.logoFile.name}
                        </li>
                      )
                    )
                }
              </ul>
              <ul>
                {
                  _.map(support, (value, key) => (value && key !== 'edit') && (
                  <li key={key}>
                    <Icon icon={faFilePdf} />
                    {' '}
                      {(type === 'edit') ? `${value.name}.pdf` : value.name}
                  </li>
                  ))
                }
              </ul>
            </Items>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            {this.setSectionHeader('Resources', 5)}
            <Paragraph>
              {resources}
            </Paragraph>
          </Col>
        </Row>
        <Row>
          <Col size={3}>
            <Button
              onClick={this.onCancelCreatingAndEditing}
              type="button"
            >
              Cancel
            </Button>
          </Col>
          <Col size={6}>
            {type === 'edit'
              ? null
              : (
                <Button
                  onClick={this.createAccount}
                  disabled={disableOnSaving}
                  tColor="white"
                  bgColor="#455A64"
                >
              Activate Account
                </Button>
              )}
          </Col>
        </Row>
      </Body>
    );
  }
}

const Body = styled.div`
  ${''}
  width: 47%;
  padding: 0 3%;
`;

const Wrapper = styled.div`
  ${''}
  ${''}
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.5% 0px 0.5%;
  width: 100%;
`;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: rgb(33, 151, 250);
  cursor: pointer;
  outline: none;
`;

const Topic = styled(ControlLabel)`
  ${''}
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '13px')};
  color: rgba(65, 71, 76, 1);
`;

const Line = styled.hr`
  margin: 0 0 25px 0;
`;

const Paragraph = styled(ControlLabel)`
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding-bottom: 2px;
`;

const Items = styled.div`
  padding: 0;
  color: rgba(65, 71, 76, 1);
  ul {
    margin: 0;
    padding: 0;
    font-size: 13px;

    li {
      list-style-type: none;
      padding: 1px 0 5px 0;
    }
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: rgba(0,0,0,0.3);
  margin-right: 5px;
`;

const SmallSection = styled.div`
  padding-bottom: 15px;
`;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Browser from '../GlobalComponents/Browser';
import { toggleBrowserManager } from '../../actions/UIManagerActions';

class BrowserManager extends Component {
  render() {
    const { actions, browserManager, handleActiveTree } = this.props;
    const { activeTree, showTree } = browserManager;
    return (
      <Browser
        blockId={0}
        actions={actions}
        activeTree={activeTree}
        showTree={showTree}
        handleActiveTree={handleActiveTree}
      />
    );
  }
}

const mapStateToProps = state => ({
  browserManager: state.UIManagerReducer.browserManager,
});

const mapDispatchToProps = dispatch => ({
  handleActiveTree: () => dispatch(toggleBrowserManager()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowserManager);

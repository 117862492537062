import React, { Component } from "react";
import styled from "styled-components";
import Content from "./Content";

class Body extends Component {
  render() {
    return (
      <Wrapper>
        <Content history={this.props.history} />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: inline-block;
  ${'' /* border: 1px solid red; */}
  ${'' /* padding: 20px 0 0 0; */}
  width: 100%;
`;

export default Body;
import _ from 'lodash';
import Axios from 'axios';

import { GET_USER, UPDATE_USER } from '../actionTypes/profileTypes';
import { UPDATE_CURRENT_USER } from '../actionTypes/appTypes';

const getUserAction = payload => ({
  type: GET_USER,
  payload,
});

const updateUserAction = payload => ({
  type: UPDATE_USER,
  payload,
});

const updateCurrentUser = payload => ({
  type: UPDATE_CURRENT_USER,
  payload,
});

const getUser = () => (dispatch, getState) => new Promise((resolve, reject) => {
  if (_.size(getState().ProfileReducer.currentUser) === 0) {
    Axios.get('/api/users/current')
      .then((response) => {
        dispatch(getUserAction(response.data));
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    resolve();
  }
});

const verifyAndChangePassword = data => new Promise((resolve, reject) => {
  const {
    userId,
  } = data;

  Axios.post(`/api/users/${userId}/change-password`, data)
    .then((response) => {
      if (response.data.success) {
        resolve(response.data);
      } else {
        reject(response.data);
      }
    })
    .catch((err) => {
      reject(err);
    });
});

const updateUser = data => dispatch => new Promise((resolve, reject) => {
  const {
    userId,
  } = data;

  Axios.put(`/api/users/${userId}`, data)
    .then((response) => {
      dispatch(updateUserAction(response.data));
      dispatch(updateCurrentUser({ first_name: data.first_name, last_name: data.last_name }));
      resolve(response.data);
    })
    .catch((err) => {
      console.log(err);
      reject(err);
    });
});

export {
  getUser,
  updateUser,
  verifyAndChangePassword,
};

import React, { Component } from 'react';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import dots from '../../../assets/moreDots.png';
import { ChildMenu } from './ChildMenu';

export class DropDownMenu extends Component {

  state = {
    anchorEl: null,
  }

  handleClick = event => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null })

  render() {
    const FontAwesomeIconStyle = {
      width: '15px',
      height: '15px',
      margin: '-1px 5px 0 2px',
      color: 'rgba(0,0,0,0.4)',
    };

    const { anchorEl } = this.state;
    const { menuOptions, icon } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div>
        <Button
          aria-owns={open ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          {
          icon
            ? <img width="16" alt="" height="16" src={icon} />
            : <DotIcon src={dots} />
          }
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
        >
          {
            menuOptions.map((item, i) => (
              item.items
                ? (
                  <ChildMenu
                    key={i}
                    item={item}
                  />
                ) : (
                  <MenuItem
                    key={i}
                    style={{
                      fontSize: '0.85em',
                    }}
                    onClick={() => {
                      item.action();
                      this.handleClose();
                    }}
                  >
                    {
                      item.imageIcon
                        ?
                          <ImageIcon src={item.icon} alt="" />
                        : (
                          <FontAwesomeIcon
                            icon={item.icon}
                            style={FontAwesomeIconStyle}
                        />
                        )}
                    {item.text}
                    {
                      item.items
                      && (
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        style={FontAwesomeIconStyle}
                      />
                      )}
                  </MenuItem>
                )))
          }
        </Menu>
      </div>
    );
  }
}

const DotIcon = styled.img`
  padding: 0 8px 0 0;
`;

const ImageIcon = styled.img`
  padding: 0 8px 0 0;
  width: 18px !important;
  height: 18px !important;
`;

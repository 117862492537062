import React, { Component } from 'react';
import styled from 'styled-components';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NavigationBar from '../GlobalComponents/NavigationBar';
import Toolbar from './ToolBar';
import MainBar from './MainBar';
import TabsBar from './TabsBar';
import GridContainer from './GridContainer';
import ChartWindow from './ChartWindow';

import {
  SpreadSheetSelectAll,
  SpreadSheetSelectRow,
  SpreadSheetRemoveBlock,
  SpreadSheetUndoRemoveBlock,
  SpreadSheetRemoveRow,
  SpreadSheetUndoRemoveRow,
  SpreadSheetSortBlockAsc,
  SpreadSheetSortBlockDesc,
  SpreadSheetMergeBlock,
  SpreadSheetChangeBannerTitle,
  SpreadSheetMergeRow,
  SpreadSheetUnmergeRow,
  SpreadSheetUnmergeBlock,
  SpreadSheetUndoMergeBlock,
  SpreadSheetUndoMergeRow,
  SpreadSheetCopyBlock,
  SpreadSheetShowPercentage,
  SpreadSheetShowThousands,
  SpreadSheetShowIndex,
  SpreadSheetChangeRowName,
  SpreadSheetLoadYears,
  SpreadSheetSetCurrentTab,
  SpreadSheetAddNewTabs,
  SpreadSheetAddTarget,
  SpreadSheetAddBanner,
  SpreadSheetAddCumeTotal,
  SpreadSheetCalculateCumeTotal,
  SpreadSheetOpenWindowCharts,
  SpreadSheetCloseWindowCharts,
  SpreadSheetDeleteTab,
  SpreadSheetExportToExcel,
  SpreadSheetCopyTarget,
  SpreadSheetDeleteTarget,
  SpreadSheetLoadEditTarget,
  SpreadSheetSaveProject,
  SpreadSheetChangeProjectTitle,
  loadCharts,
  SpreadSheetLoadEditBanner,
  SpreadSheetSaveBannerTemplate,
} from '../../actions/spreadsheetActions';
import { getCurrentSurvey } from '../../actions/marketActions';
import { loadCategories } from '../../actions/categories';
import { saveTargetTemplate, restoreTargetTemplate, setDefaultBaseTarget } from '../../actions/targetActions';
import { saveBannerTemplate } from '../../actions/bannerActions';
import { getProjects, getSharedTemplates } from '../../actions/projectActions';
import { getSurveys } from '../../actions/documentActions';
import { downloadPresentation } from '../../actions/spreadsheetExportActions';
import { resetTargetAndBannerDispatch } from '../../actions/overviewActions';


class SpreadSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    };
    document.title = 'Stowell Data | Spreadsheet';
    this.selectAll = this.selectAll.bind(this);
    this.selectRow = this.selectRow.bind(this);
    this.deleteBlock = this.deleteBlock.bind(this);
    this.undoDeleteBlock = this.undoDeleteBlock.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.undoDeleteRow = this.undoDeleteRow.bind(this);
    this.sortBlockAsc = this.sortBlockAsc.bind(this);
    this.sortBlockDesc = this.sortBlockDesc.bind(this);
    this.mergeBlock = this.mergeBlock.bind(this);
    this.changeBannerTitle = this.changeBannerTitle.bind(this);
    this.mergeRows = this.mergeRows.bind(this);
    this.unmergeRows = this.unmergeRows.bind(this);
    this.unmergeBlock = this.unmergeBlock.bind(this);
    this.undoMergeBlock = this.undoMergeBlock.bind(this);
    this.undoMergeRow = this.undoMergeRow.bind(this);
    this.copyBlock = this.copyBlock.bind(this);
    this.showPercentage = this.showPercentage.bind(this);
    this.showThousands = this.showThousands.bind(this);
    this.showIndex = this.showIndex.bind(this);
    this.changeRowName = this.changeRowName.bind(this);
    this.loadDemo = this.loadDemo.bind(this);
    this.loadYears = this.loadYears.bind(this);
    this.setCurrentTab = this.setCurrentTab.bind(this);
    this.addNewTabs = this.addNewTabs.bind(this);
    this.addTarget = this.addTarget.bind(this);
    this.addBanner = this.addBanner.bind(this);
    this.addCumeTotal = this.addCumeTotal.bind(this);
    this.calculateCumeTotal = this.calculateCumeTotal.bind(this);
    this.openWindowCharts = this.openWindowCharts.bind(this);
    this.closeWindowCharts = this.closeWindowCharts.bind(this);
    this.deleteTab = this.deleteTab.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.copyTarget = this.copyTarget.bind(this);
    this.deleteTarget = this.deleteTarget.bind(this);
    this.getCurrentSurvey = this.getCurrentSurvey.bind(this);
    this.saveTargetTemplate = this.saveTargetTemplate.bind(this);
    this.saveBannerTemplate = this.saveBannerTemplate.bind(this);
    this.loadEditTarget = this.loadEditTarget.bind(this);
    this.restoreTargetTemplate = this.restoreTargetTemplate.bind(this);
    this.changeProjectTitle = this.changeProjectTitle.bind(this);
    this.saveProject = this.saveProject.bind(this);
    this.getCurrentSurveyFile = this.getCurrentSurveyFile.bind(this);
    this.loadEditBanner = this.loadEditBanner.bind(this);
    this.saveBannerTemplateFromSpreadsheet = this.saveBannerTemplateFromSpreadsheet.bind(this);


    this.functionCollection = {
      selectAll: this.selectAll,
      selectRow: this.selectRow,
      deleteBlock: this.deleteBlock,
      sortBlockAsc: this.sortBlockAsc,
      sortBlockDesc: this.sortBlockDesc,
      changeBannerTitle: this.changeBannerTitle,
      copyBlock: this.copyBlock,
      changeRowName: this.changeRowName,
      addTarget: this.addTarget,
      addBanner: this.addBanner,
      addCumeTotal: this.addCumeTotal,
      calculateCumeTotal: this.calculateCumeTotal,
      copyTarget: this.copyTarget,
      deleteTarget: this.deleteTarget,
      saveTargetTemplate: this.saveTargetTemplate,
      saveBannerTemplate: this.saveBannerTemplate,
      saveBannerTemplateFromSpreadsheet: this.saveBannerTemplateFromSpreadsheet,
      loadEditTarget: this.loadEditTarget,
      loadEditBanner: this.loadEditBanner,
    };
    this.toolbarFunctionCollection = {
      deleteRow: this.deleteRow,
      mergeBlock: this.mergeBlock,
      mergeRows: this.mergeRows,
      unmergeRows: this.unmergeRows,
      unmergeBlock: this.unmergeBlock,
      showIndex: this.showIndex,
      showPercentage: this.showPercentage,
      showThousands: this.showThousands,
      openWindowCharts: this.openWindowCharts,
    };
    this.tabsFunctionCollection = {
      setCurrentTab: this.setCurrentTab,
      addNewTabs: this.addNewTabs,
      deleteTab: this.deleteTab,
    };

    this.mainBarFunctionCollection = {
      changeProjectTitle: this.changeProjectTitle,
      saveProject: this.saveProject,
      exportToExcel: this.exportToExcel,
      downloadPresentation: this.props.downloadPresentation,
    };
  }

  componentWillMount() {
    loadCharts([]);
  }

  componentWillUnmount() {
    const { resetTargetsAndBanners } = this.props;
    resetTargetsAndBanners();
  }

  // REDUX FUNCTIONS

  selectAll(BannerPos) {
    this.props.dispatchSelectAll(BannerPos);
    this.setState({
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    });
  }

  selectRow(BannerPos, RowNum) {
    this.props.dispatchSelectRow(BannerPos, RowNum);
    this.setState({
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    });
  }

  deleteBlock(BannerPos) {
    this.props.dispatchRemoveBlock(BannerPos);
    this.setState({
      SnackBar: {
        open: true,
        function: this.undoDeleteBlock,
        message: 'Question removed from spreadsheet',
      },
    });
  }

  undoDeleteBlock() {
    this.props.dispatchUndoRemoveBlock();
    this.setState({
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    });
  }

  deleteRow() {
    this.props.dispatchRemoveRow();
    this.setState({
      SnackBar: {
        open: true,
        function: this.undoDeleteRow,
        message: 'Data-Point removed from spreadsheet',
      },
    });
  }

  undoDeleteRow() {
    this.props.dispatchUndoRemoveRow();
    this.setState({
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    });
  }

  sortBlockAsc(BannerPos, TargetPos, type) {
    this.props.dispatchSortBlockAsc(BannerPos, TargetPos, type);
  }

  sortBlockDesc(BannerPos, TargetPos, type) {
    this.props.dispatchSortBlockDesc(BannerPos, TargetPos, type);
  }

  mergeBlock() {
    this.props.dispatchMergeBlock();
    this.setState({
      SnackBar: {
        open: true,
        function: this.undoMergeBlock,
        message: 'Selected questions have been merged.',
      },
    });
  }

  changeBannerTitle(BannerPos, BannerName) {
    this.props.dispatchChangeBannerTitle(BannerPos, BannerName);
  }

  mergeRows() {
    this.props.dispatchMergeRows();
    setTimeout(() => {
      this.setState({
        SnackBar: {
          open: true,
          function: this.undoMergeRow,
          message: 'Selected rows have been merged.',
        },
      });
    }, 2000);

  }

  unmergeRows() {
    this.props.dispatchUnmergeRows();
  }

  unmergeBlock() {
    this.props.dispatchUnmergeBlock();
  }

  undoMergeBlock() {
    this.props.dispatchUndoMergeBlock();
    this.setState({
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    });
  }

  undoMergeRow() {
    this.props.dispatchUndoMergeRow();
    this.setState({
      SnackBar: {
        open: false,
        function: null,
        message: null,
      },
    });
  }

  copyBlock(BannerPos) {
    this.props.dispatchCopyBlock(BannerPos);
  }

  showPercentage() {
    this.props.dispatchShowPercentage();
  }

  showThousands() {
    this.props.dispatchShowThousands();
  }

  showIndex() {
    this.props.dispatchShowIndex();
  }

  changeRowName(BannerPos, RowNum, RowName) {
    this.props.dispatchChangeRowName(BannerPos, RowNum, RowName);
  }

  loadDemo() {
    this.props.dispatchLoadDemo();
  }

  loadYears() {
    this.props.dispatchLoadYears();
  }

  setCurrentTab(currentTab) {
    this.props.dispatchSetCurrentTab(currentTab);
  }

  addNewTabs(tabsToAdd) {
    this.props.dispatchAddNewTabs(tabsToAdd);
  }

  addTarget() {
    this.props.dispatchDemoAddTarget();
  }


  addBanner(question) {
    this.props.dispatchDemoAddBanner(question);
  }

  addCumeTotal(BannerPos, BannerData) {
    this.props.dispatchAddCumeTotal(BannerPos, BannerData);
  }

  calculateCumeTotal(BannerPos, BannerData) {
    this.props.dispatchCalculateCumeTotal(BannerPos, BannerData);
  }

  openWindowCharts() {
    this.props.dispatchOpenWindowCharts();
  }

  closeWindowCharts() {
    this.props.dispatchCloseWindowCharts();
  }

  deleteTab(TabPos) {
    this.props.dispatchDeleteTab(TabPos);
  }

  exportToExcel() {
    SpreadSheetExportToExcel({...this.props.Data, DisplayedUnits: this.props.DisplayedUnits}, this.props.Title);
  }

  copyTarget(TargetPos) {
    this.props.dispatchCopyTarget(TargetPos);
  }

  deleteTarget(TargetPos) {
    this.props.dispatchDeleteTarget(TargetPos);
  }

  getCurrentSurvey() {
    return this.props.dispatchGetCurrentSurvey();
  }

  saveTargetTemplate(title, data) {
    this.props.dispatchSaveTargetTemplate(title, data);
  }

  getProjectsAndTemplates() {
    this.props.dispatchGetProjects();
    this.props.dispatchGetSharedTemplates();
  }

  saveBannerTemplate(data) {
    this.props.dispatchSaveBannerTemplate(data);
  }

  loadEditTarget(data, position, title) {
    this.props.dispatchLoadEditTarget(position, title);
    this.props.dispatchRestoreTargetTemplate(data);
  }

  restoreTargetTemplate() {
    this.props.restoreTargetTemplate();
  }

  changeProjectTitle(title) {
    this.props.dispatchChangeProjectTitle(title);
  }

  saveProject() {
    return this.props.dispatchSaveProject();
  }

  getCurrentSurveyFile() {
    this.props.dispatchGetCurrentSurveyFile();
  }

  loadEditBanner(data, position, title) {
    this.props.dispatchLoadEditBanner(position, title);
  }

  saveBannerTemplateFromSpreadsheet(data) {
    this.props.dispatchSpreadsheetSaveBannerTemplate(data);
  }

  // SNACKBAR FUNCTIONS

    handleRequestClose = (event, reason) => {
      const { SnackBar } = this.state;
      this.setState({
        SnackBar: {
          ...SnackBar,
          open: false,
        },
      });
    };

    preventRefresh = () => {
      window.onbeforeunload = () => false;
    }

    componentDidMount() {
      const { loadCategories, setDefaultBaseTarget } = this.props;
      this.preventRefresh();
      this.getCurrentSurvey().then(() => {
        loadCategories('/spreadsheet').then(() => {
          setDefaultBaseTarget();
          this.loadYears();
        });
      });
      this.getProjectsAndTemplates();
      this.getCurrentSurveyFile();
    }

    render() {
      const { charts, chartWindowVisible, spreadSheetChartsLoaded } = this.props;
      return (
        <div>
          <NavigationBar />
          <MainContainer>
            <MainBar chartsLoaded={spreadSheetChartsLoaded} Title={this.props.Title} functions={this.mainBarFunctionCollection} charts={charts} chartWindowVisible={chartWindowVisible} />
            <Toolbar
              Data={this.props.Data}
              functions={this.toolbarFunctionCollection}
              DataBlocksSelected={this.props.DataBlocksSelected}
              canMergeRows={this.props.canMergeRows}
              canUnmergeBlock={this.props.canUnmergeBlock}
              canUnmergeRow={this.props.canUnmergeRow}
              canMergeBlocks={this.props.canMergeBlocks}
              canRemoveRows={this.props.canRemoveRows}
              showPercentage={this.props.showPercentage}
              showIndex={this.props.showIndex}
              showThousands={this.props.showThousands}
              currentTab={this.props.currentTab}
            />
            {chartWindowVisible ? <ChartWindow closeWindowCharts={this.closeWindowCharts} /> : null }
            <TabsBar
              yearList={this.props.yearList}
              Data={this.props.Data}
              functions={this.tabsFunctionCollection}
              currentSurvey={this.props.currentSurvey}
              currentTab={this.props.currentTab}
            />
            <GridContainer
              Data={this.props.Data}
              functions={this.functionCollection}
              showPercentage={this.props.showPercentage}
              showIndex={this.props.showIndex}
              showThousands={this.props.showThousands}
              currentTab={this.props.currentTab}
              targetList={this.props.targetList}
              bannerList={this.props.bannerList}
              targetState={this.props.targetState}
              bannerState={this.props.bannerState}
              chartWindowVisible={chartWindowVisible}
            />
          </MainContainer>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.SnackBar.open}
            message={this.state.SnackBar.message}
            autoHideDuration={3000}
            onClose={this.handleRequestClose}
            action={(
              <Button key="undo" color="primary" size="small" onClick={this.state.SnackBar.function} currentTab={this.props.currentTab}>
                UNDO
              </Button>
)}
          />
        </div>
      );
    }
}


const MainContainer = styled.div`
    ${''}
    margin:0;
`;

const mapStateToProps = state => ({
  Data: state.spreadsheet.Data,
  DisplayedUnits: {showPercentage: state.spreadsheet.showPercentage, showIndex: state.spreadsheet.showIndex, showThousands: state.spreadsheet.showThousands},
  DataBlocksSelected: state.spreadsheet.DataBlocksSelected,
  canMergeRows: state.spreadsheet.canMergeRows,
  canUnmergeBlock: state.spreadsheet.canUnmergeBlock,
  canUnmergeRow: state.spreadsheet.canUnmergeRow,
  canMergeBlocks: state.spreadsheet.canMergeBlocks,
  canRemoveRows: state.spreadsheet.canRemoveRows,
  showPercentage: state.spreadsheet.showPercentage,
  showThousands: state.spreadsheet.showThousands,
  showIndex: state.spreadsheet.showIndex,
  yearList: state.spreadsheet.yearList,
  currentTab: state.spreadsheet.currentTab,
  targetList: state.spreadsheet.targetList,
  bannerList: state.spreadsheet.bannerList,
  targetState: state.spreadsheet.targetState,
  bannerState: state.spreadsheet.bannerState,
  chartWindowVisible: state.spreadsheet.chartWindowVisible,
  currentSurvey: state.marketReducer.currentSurvey,
  Title: state.spreadsheet.Title,
  spreadSheetChartsLoaded: state.spreadsheetChartReducer.spreadSheetChartsLoaded,
  charts: state.spreadsheetChartReducer.charts,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectAll: bindActionCreators(Object.assign(SpreadSheetSelectAll), dispatch),
  dispatchSelectRow: bindActionCreators(Object.assign(SpreadSheetSelectRow), dispatch),
  dispatchRemoveBlock: bindActionCreators(Object.assign(SpreadSheetRemoveBlock), dispatch),
  dispatchUndoRemoveBlock: bindActionCreators(Object.assign(SpreadSheetUndoRemoveBlock), dispatch),
  dispatchRemoveRow: bindActionCreators(Object.assign(SpreadSheetRemoveRow), dispatch),
  dispatchUndoRemoveRow: bindActionCreators(Object.assign(SpreadSheetUndoRemoveRow), dispatch),
  dispatchSortBlockAsc: bindActionCreators(Object.assign(SpreadSheetSortBlockAsc), dispatch),
  dispatchSortBlockDesc: bindActionCreators(Object.assign(SpreadSheetSortBlockDesc), dispatch),
  dispatchMergeBlock: bindActionCreators(Object.assign(SpreadSheetMergeBlock), dispatch),
  dispatchChangeBannerTitle: bindActionCreators(Object.assign(SpreadSheetChangeBannerTitle), dispatch),
  dispatchMergeRows: bindActionCreators(Object.assign(SpreadSheetMergeRow), dispatch),
  dispatchUnmergeRows: bindActionCreators(Object.assign(SpreadSheetUnmergeRow), dispatch),
  dispatchUnmergeBlock: bindActionCreators(Object.assign(SpreadSheetUnmergeBlock), dispatch),
  dispatchUndoMergeBlock: bindActionCreators(Object.assign(SpreadSheetUndoMergeBlock), dispatch),
  dispatchUndoMergeRow: bindActionCreators(Object.assign(SpreadSheetUndoMergeRow), dispatch),
  dispatchCopyBlock: bindActionCreators(Object.assign(SpreadSheetCopyBlock), dispatch),
  dispatchShowPercentage: bindActionCreators(Object.assign(SpreadSheetShowPercentage), dispatch),
  dispatchShowThousands: bindActionCreators(Object.assign(SpreadSheetShowThousands), dispatch),
  dispatchShowIndex: bindActionCreators(Object.assign(SpreadSheetShowIndex), dispatch),
  dispatchChangeRowName: bindActionCreators(Object.assign(SpreadSheetChangeRowName), dispatch),
  dispatchLoadYears: bindActionCreators(Object.assign(SpreadSheetLoadYears), dispatch),
  dispatchSetCurrentTab: bindActionCreators(Object.assign(SpreadSheetSetCurrentTab), dispatch),
  dispatchAddNewTabs: bindActionCreators(Object.assign(SpreadSheetAddNewTabs), dispatch),
  dispatchDemoAddTarget: bindActionCreators(Object.assign(SpreadSheetAddTarget), dispatch),
  dispatchDemoAddBanner: bindActionCreators(Object.assign(SpreadSheetAddBanner), dispatch),
  dispatchAddCumeTotal: bindActionCreators(Object.assign(SpreadSheetAddCumeTotal), dispatch),
  dispatchCalculateCumeTotal: bindActionCreators(Object.assign(SpreadSheetCalculateCumeTotal), dispatch),
  dispatchOpenWindowCharts: bindActionCreators(Object.assign(SpreadSheetOpenWindowCharts), dispatch),
  dispatchCloseWindowCharts: bindActionCreators(Object.assign(SpreadSheetCloseWindowCharts), dispatch),
  dispatchDeleteTab: bindActionCreators(Object.assign(SpreadSheetDeleteTab), dispatch),
  dispatchCopyTarget: bindActionCreators(Object.assign(SpreadSheetCopyTarget), dispatch),
  dispatchDeleteTarget: bindActionCreators(Object.assign(SpreadSheetDeleteTarget), dispatch),
  dispatchGetCurrentSurvey: bindActionCreators(Object.assign(getCurrentSurvey), dispatch),
  dispatchSaveTargetTemplate: bindActionCreators(Object.assign(saveTargetTemplate), dispatch),
  dispatchGetProjects: bindActionCreators(Object.assign(getProjects), dispatch),
  dispatchGetSharedTemplates: bindActionCreators(Object.assign(getSharedTemplates), dispatch),
  dispatchSaveBannerTemplate: bindActionCreators(Object.assign(saveBannerTemplate), dispatch),
  dispatchLoadEditTarget: bindActionCreators(Object.assign(SpreadSheetLoadEditTarget), dispatch),
  dispatchRestoreTargetTemplate: bindActionCreators(Object.assign(restoreTargetTemplate), dispatch),
  dispatchSaveProject: bindActionCreators(Object.assign(SpreadSheetSaveProject), dispatch),
  dispatchChangeProjectTitle: bindActionCreators(Object.assign(SpreadSheetChangeProjectTitle), dispatch),
  dispatchGetCurrentSurveyFile: bindActionCreators(Object.assign(getSurveys), dispatch),
  dispatchLoadEditBanner: bindActionCreators(Object.assign(SpreadSheetLoadEditBanner), dispatch),
  dispatchSpreadsheetSaveBannerTemplate: bindActionCreators(Object.assign(SpreadSheetSaveBannerTemplate), dispatch),
  loadCharts: charts => dispatch(loadCharts(charts)),
  downloadPresentation: (title, size) => dispatch(downloadPresentation('spreadsheet', size, title)),
  resetTargetsAndBanners: () => dispatch(resetTargetAndBannerDispatch()),
  loadCategories: () => dispatch(loadCategories()),
  setDefaultBaseTarget: () => dispatch(setDefaultBaseTarget()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpreadSheet);

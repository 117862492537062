import React from 'react';
import { connect } from 'react-redux';
import {
  addBlock,
  removeBlock,
  addQuestion,
  removeQuestion,
  addDataPoint,
  removeDataPoint,
  changeItemsOperator,
  targetBuilderReset,
  restoreTargetTemplate,
  setTargetTitle,
  setTargetQuery,
  loadTargetTemplates,
  saveTargetTemplate,
  updateTargetTemplate,
  updateTargetTemplateTitle,
  toggleTargetBuilderView,
  changeTargetTemplatesMenu,
  setCurrentTargetTemplate,
  copyTarget,
  resetTarget,
  setTargetId,
} from '../../../actions/targetActions';

import {
  SpreadSheetAddTarget,
  SpreadSheetEditTarget,
} from '../../../actions/spreadsheetActions';

const withActions = (WrappedComponent) => {
  class ModalActions extends React.Component {
    render() {
      const {
        addBlock,
        removeBlock,
        addQuestion,
        removeQuestion,
        addDataPoint,
        removeDataPoint,
        reset,
        toggleBuilderView,
        loadTemplates,
        updateTemplate,
        setTitle,
        setQuery,
        changeTemplatesMenu,
        saveTemplate,
        restoreTemplate,
        setCurrentTemplate,
        updateTemplateTitle,
        changeItemsOperator,
        addTarget,
        editTarget,
        copyTarget,
        resetTarget,
        setTargetId,
      } = this.props;
      const actions = {
        target: {
          setTitle, setQuery, copy: copyTarget, reset: resetTarget, setTargetId,
        },
        builder: { reset, toggleView: toggleBuilderView, changeItemsOperator },
        block: { addBlock, removeBlock },
        question: { addQuestion, removeQuestion },
        answer: { addDataPoint, removeDataPoint },
        templates: {
          load: loadTemplates,
          save: saveTemplate,
          update: updateTemplate,
          updateTitle: updateTemplateTitle,
          changeMenu: changeTemplatesMenu,
          restore: restoreTemplate,
          setCurrent: setCurrentTemplate,
        },
        spreadsheet: { addTarget, editTarget },
      };
      const {
        view,
        templatesMenu,
        templatesList,
        currentTarget,
        currentTemplate,
        builderBlocks,
      } = this.props;
      const data = {
        builder: {
          view,
          blocks: builderBlocks,
        },
        target: {
          currentTarget,
        },
        templates: {
          currentTemplate,
          list: templatesList,
          menu: templatesMenu,
        },
      };
      return (
        <WrappedComponent
          {...this.props}
          actions={actions}
          data={data}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    view: state.targetReducer.view,
    templatesMenu: state.targetReducer.templates.menu,
    currentTarget: state.targetReducer.currentTarget,
    currentTemplate: state.targetReducer.templates.currentTemplate,
    builderBlocks: state.targetReducer.builder.blocks,
    templatesList: state.targetReducer.templates.list,
  });

  const mapDispatchToProps = dispatch => ({
    addBlock: operator => dispatch(addBlock(operator)),
    removeBlock: data => dispatch(removeBlock(data)),
    addQuestion: data => dispatch(addQuestion(data)),
    removeQuestion: data => dispatch(removeQuestion(data)),
    addDataPoint: (blockId, dataPoint, path) => dispatch(addDataPoint(blockId, dataPoint, path)),
    removeDataPoint: (blockId, dataPoint) => dispatch(removeDataPoint(blockId, dataPoint)),
    reset: () => dispatch(targetBuilderReset()),
    toggleBuilderView: data => dispatch(toggleTargetBuilderView(data)),
    updateTemplate: data => dispatch(updateTargetTemplate(data)),
    updateTemplateTitle: data => dispatch(updateTargetTemplateTitle(data)),
    setTitle: data => dispatch(setTargetTitle(data)),
    setQuery: () => dispatch(setTargetQuery()),
    changeTemplatesMenu: data => dispatch(changeTargetTemplatesMenu(data)),
    saveTemplate: (title, data) => dispatch(saveTargetTemplate(title, data)),
    restoreTemplate: data => dispatch(restoreTargetTemplate(data)),
    setCurrentTemplate: data => dispatch(setCurrentTargetTemplate(data)),
    loadTemplates: () => dispatch(loadTargetTemplates()),
    changeItemsOperator: data => dispatch(changeItemsOperator(data)),
    addTarget: () => dispatch(SpreadSheetAddTarget()),
    editTarget: () => dispatch(SpreadSheetEditTarget()),
    copyTarget: type => dispatch(copyTarget(type)),
    resetTarget: type => dispatch(resetTarget(type)),
    setTargetId: id => dispatch(setTargetId(id)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ModalActions);
};

export default withActions;

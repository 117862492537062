import React, { Component } from "react";
import _ from "lodash/fp";
import styled from "styled-components";
import {
  Row,
  Col,
  Title,
  ControlLabel,
} from "../../../GlobalComponents/BasicControls";
import ContactData from "./data";

export class UserTypes extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col size={12}>
            <Title style={{ paddingBottom: 0 }}>
              Stowell Data User Types
                  </Title>
            <Paragraph>
              {ContactData.userTypes.information}
            </Paragraph>
          </Col>
        </Row>
        {
          _.map((item) =>
            <Row key={item.id}>
              <Col size={12} style={{ paddingBottom: '5px' }}>
                <Topic
                  fontSize='0.9em'>
                  {item.title}
                </Topic>
                <Paragraph style={{ padding: 0 }}>
                  {item.text}
                </Paragraph>
                <Items>
                  <ul>
                    {
                      item.choices.map((choice, index) => <li key={index}>{choice}</li>)
                    }
                  </ul>
                </Items>
              </Col>
            </Row>
          )(ContactData.userTypes.types)
        }
      </div>
    )
  }
}
const Items = styled.div`
  padding: 0;
  color: rgba(65, 71, 76, 1);
  ul {
    margin: 0;
    padding: 0 0 0 20px;
    font-size: 0.85em;

    li {
      padding: 1px 0 5px 0;
    }
  }
`

const Topic = styled(ControlLabel)`
  ${'' /* border: 1px solid red; */}
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4em')};
  color: rgba(65, 71, 76, 1);
`;

const Paragraph = styled(ControlLabel)`
  ${'' /* border: 1px solid red; */}
  width: 90%;
  padding-top: 10px;
  text-align: justify;
  ${'' /* letter-spacing: 0.1px; */}
  line-height: 150%;
  font-size: 0.85em;
  color: rgba(65, 71, 76, 1);
`;
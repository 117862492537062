import React, { Component } from 'react';
import styled from 'styled-components';
import TitleBar from './TitleBar';
import TargetSummary from './TargetSummary';
import TargetBuilder from './TargetBuilder';
import Templates from '../Templates';
import withActions from './withActions';

class ModalContent extends Component {
  render() {
    const { actions, data, close, type } = this.props;
    const { view } = data.builder;
    return (
      <Wrapper>
        <LeftSection>
          <TitleBar actions={actions} view={view} />
          {view === 'builder' && <TargetBuilder actions={actions} data={data} />}
          {view === 'templates' && <Templates actions={actions} data={data} />}
        </LeftSection>

        <RightSection>
          <TargetSummary
            actions={actions}
            data={data}
            close={close}
            type={type}
            blocks={data.builder.blocks}
            view={view}
          />
        </RightSection>
      </Wrapper>
    );
  }

}

const ContentWithActions = withActions(ModalContent);

const Wrapper = styled.div`
  display: flex;
  width: 945px;
  height: 624px;
  position: relative;
`;

const LeftSection = styled.div`
  width: 632px;
  height: 624px;
  background: #f6f7f8;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-right: 2px solid #dee1e3;
  overflow: hidden;
`;

const RightSection = styled.div`
  background-color: #FFFFFF;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  width: 313px;
  height: 100%;
`;

export default ContentWithActions;

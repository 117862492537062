import React, { Component } from 'react';
import styled from 'styled-components';

export class HiddenContent extends Component {
  render() {
    return (
      <Background>
        <Box>
          <div className="content image hidden" />
          <div className="content">
            <p>
              <span className="content__stowell">STOWELL </span>
              <span className="content__data">DATA</span>
            </p>
            <p>
              We’re working on optimizing Stowell Data for tablet viewing.
            </p>
            <p>
              For now, please sign in on your laptop or desktop. 
            </p>
            <p>
              If you are on a laptop or desktop, please maximize your browser window.
            </p>
          </div>
          <div className="content image first-shown" />
        </Box>
      </Background>
    );
  }
}
const Background = styled.div`
    z-index: -99999;
    background-color: #19A2C5;
    position: absolute;
    width: 100%;
    height: all;
    min-height: 100%;
    display: flex;
    justify-content: center;
`;

const Box = styled.div`
  z-index: 999999;
  background-color: white;
  width: 520px;
  height: 269px;
  margin-top: 15%;
  margin-bottom: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: flex-end;

  .hidden {
    display: none;
  }

  .content {
    padding: 25px 15px 0 25px;
    color: #37474F;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.06px;
    line-height: 21px;
    width: 259px;
    &__stowell {
      color: #263238;
      font-size: 20px;
      font-weight: 800;
    }
    &__data {
      color: #263238;
      font-size: 20px;
    }
  }

  .image {
    height: 214px;
    width: 259px;
    margin: 15px;
    background-image: url('/images/on-working.gif');
    background-size: contain;
    background-repeat: no-repeat;
  }

  @media (max-width: 641px) {
    width: 310px;
    height: 450px;
    flex-flow: column;
    justify-content: flex-start;
    .content {
      padding: 5px 15px 0 20px;
    }
    .first-shown {
      display: none;
    }
    .hidden {
      display: block;
    }
  }
  @media (min-width: 1141px) {
  }
`;

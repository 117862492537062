import React, { Component } from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Answer from './Answer';

class Answers extends Component {
  render() {
    const {
      actions,
      answers,
      blockId,
      bannerAdvanced,
      bannerBasic,
      browserType,
      currentTab,
      path,
      question,
      targetBlocks,
      currentTemplate,
    } = this.props;
    let questionsList;
    if (browserType === 'target') {
      questionsList = targetBlocks[blockId].questions;
    } else if (browserType === 'banner') {
      if (currentTab === 0) {
        questionsList = bannerBasic;
      } else if (currentTab === 1) {
        if (currentTemplate.banner.bannerType === 'basic') {
          questionsList = currentTemplate.basicBuilder;
        } else {
          questionsList = currentTemplate.advancedBuilder;
        }
      } else if (currentTab === 2) {
        questionsList = bannerAdvanced[blockId].questions;
      }
    }
    const questionInBuilder = questionsList[question.id];
    return (
      <AnswersContainer>
        {_.map(answers, (answer, index) => (
          <Answer
            answer={answer}
            actions={actions}
            blockId={blockId}
            key={index}
            question={question}
            questionInBuilder={questionInBuilder}
            path={path}
          />
        ))}
      </AnswersContainer>
    );
  }
}


Answers.propTypes = {
  actions: Proptypes.object.isRequired,
  answers: Proptypes.object.isRequired,
  blockId: Proptypes.number.isRequired,
  bannerAdvanced: Proptypes.object.isRequired,
  bannerBasic: Proptypes.object.isRequired,
  browserType: Proptypes.string.isRequired,
  currentTab: Proptypes.number.isRequired,
  path: Proptypes.object.isRequired,
  question: Proptypes.object.isRequired,
  targetBlocks: Proptypes.object.isRequired,
  currentTemplate: Proptypes.object.isRequired,
};

const mapStateToProps = state => ({
  targetBlocks: state.targetReducer.builder.blocks,
  bannerAdvanced: state.bannerReducer.advancedBuilder.blocks,
  bannerBasic: state.bannerReducer.basicBuilder,
  browserType: state.UIManagerReducer.browserType,
  currentTab: state.bannerReducer.currentTab,
  currentTemplate: state.bannerReducer.currentTemplate,
});

const AnswersContainer = styled.div`
  background: white;
  min-height: 36px;
  cursor: pointer;
`;

export default connect(mapStateToProps)(Answers);

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  Row, Col, Form, FormControl, FormGroup, Button, Title, ControlLabel,
} from '../GlobalComponents/BasicControls';
import { getUser, verifyAndChangePassword, updateUser } from '../../actions/profileActions';
import { getErrorMessage } from '../../actions/appActions';
import showNotification from '../../helpers/showNotification';

// bHOrggdl

class Body extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
      userId: '',
      disableCredentialsButton: false,
      disableBasicButton: false,
    };
  }

  componentDidMount() {
    const { first_name, last_name, email } = this.props.user;
    this.setState({
      firstName: first_name,
      lastName: last_name,
      email,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (_.isEmpty(this.props.user) && !_.isEmpty(nextProps.user)) {
      const { first_name, last_name, email } = nextProps.user;
      this.setState({
        firstName: first_name,
        lastName: last_name,
        email,
      });
    }
  }

  setFocus = (name) => {
    const element = document.getElementsByName(name);
    element[0].focus();
  }

  handleInputText = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value,
    });
  }

  changeCredentials = () => {
    const {
      currentPassword, newPassword, newPasswordConfirmation,
    } = this.state;
    const data = {
      currentPassword,
      newPassword,
      userId: this.props.user._id,
    };

    if (newPassword.trim() === '' && newPasswordConfirmation.trim() === '') {
      showNotification({ message: 'Please, type a valid new password', type: 'error' });
      this.setState({
        newPassword: newPassword.trim(),
        newPasswordConfirmation: newPasswordConfirmation.trim(),
      });
    } else if (newPassword === newPasswordConfirmation) {
      this.setState({ disableCredentialsButton: true });
      this.props.verifyAndChangePassword(data)
        .then((response) => {
          showNotification({ message: response.message, type: 'success' });
          this.setState({
            currentPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            disableCredentialsButton: false,
          });
          if (!response.message && !response.matched) {

          } else {

            this.setFocus('currentPassword');
          }
        })
        .catch((err) => {
          showNotification({ message: err.message, type: 'error' });
          this.setState({
            disableCredentialsButton: false,
          });
          this.setFocus('currentPassword');
        });
    } else {
      showNotification({ message: 'Password confirmation must match Password', type: 'error' });
      this.setState({
        disableCredentialsButton: false,
      });
      this.setFocus('newPassword');
    }

  }

  changeBasicInformation = () => {
    this.setState({ disableBasicButton: true });
    const {
      firstName, lastName, email,
    } = this.state;
    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
      userId: this.props.user._id,
    };
    this.props.updateUser(data)
      .then(() => {
        showNotification({ message: 'Basic Information updated successfully', type: 'success' });
        this.setState({
          disableBasicButton: false,
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
        this.setState({
          disableBasicButton: false,
        });
      });
  }

  handleSubmit = (e, changingPassword) => {
    e.preventDefault();
    if (changingPassword) {
      this.changeCredentials();
    } else {
      this.changeBasicInformation();
    }
  }

  render() {
    return (
      <Wrapper>
        <ContentBody>
          <Row>
            <Col size={6}>
              <Form onSubmit={e => this.handleSubmit(e, false)}>
                <Row>
                  <Col size={12}>
                    <Title>
                      Basic Information
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col size={11}>
                    <FormGroup>
                      <ControlLabel>
                        First Name
                      </ControlLabel>
                      <FormControl
                        autoFocus
                        required
                        autoComplete="off"
                        type="text"
                        value={this.state.firstName}
                        name="firstName"
                        onChange={e => this.handleInputText(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size={11}>
                    <FormGroup>
                      <ControlLabel>
                        Last Name
                      </ControlLabel>
                      <FormControl
                        required
                        type="text"
                        autoComplete="off"
                        value={this.state.lastName}
                        name="lastName"
                        onChange={e => this.handleInputText(e)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size={11}>
                    <FormGroup>
                      <ControlLabel>
                        Email address
                      </ControlLabel>
                      {this.state.email}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size={3}>
                    <Button
                      disabled={this.state.disableBasicButton}
                      tColor="white"
                      bgColor="#455A64"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col size={6}>
              <Form onSubmit={e => this.handleSubmit(e, true)}>
                <Row>
                  <Col size={12}>
                    <Title>
                      Change Password
                    </Title>
                  </Col>
                </Row>
                <Row>
                  <Col size={11}>
                    <FormGroup>
                      <ControlLabel>
                        Verify current password
                      </ControlLabel>
                      <FormControl
                        type="password"
                        value={this.state.currentPassword}
                        name="currentPassword"
                        onChange={e => this.handleInputText(e)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size={11}>
                    <FormGroup>
                      <ControlLabel>
                        New password
                      </ControlLabel>
                      <FormControl
                        type="password"
                        value={this.state.newPassword}
                        name="newPassword"
                        onChange={e => this.handleInputText(e)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size={11}>
                    <FormGroup>
                      <ControlLabel>
                        Confirm new password
                      </ControlLabel>
                      <FormControl
                        type="password"
                        value={this.state.newPasswordConfirmation}
                        name="newPasswordConfirmation"
                        onChange={e => this.handleInputText(e)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col size={3}>
                    <Button
                      disabled={this.state.disableCredentialsButton}
                      tColor="white"
                      bgColor="#455A64"
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ContentBody>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: inline-block;
  ${''}
  width: 100%;
`;

const ContentBody = styled.div`
  ${''}
  width: 70%;
  padding: 30px 15% 0 15%;
`;

const mapStateToProps = state => ({
  user: state.AppReducer.user,
});

const mapDispatchToProps = dispatch => ({
  getUser: data => dispatch(getUser(data)),
  verifyAndChangePassword: data => verifyAndChangePassword(data),
  updateUser: data => dispatch(updateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Body);

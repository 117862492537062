import React, { Component } from 'react';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import _ from 'lodash';
import angleDown from '../../../../assets/down-arrow-angle.svg';
import ConfirmDialog from '../../../GlobalComponents/BlueConfirmDialog';
import { CustomConfirmDialog } from '../../../GlobalComponents/CustomConfirmDialog';
import {
  Form,
  Row,
  Col,
  FormGroup,
  FormControl,
  ControlLabel,
  Title,
  Button,
  NiceRadioButton,
} from '../../../GlobalComponents/BasicControls';
import { UserTypes } from './UserTypeList';
import ContactData from './data';
import { getErrorMessage } from '../../../../actions/appActions';
import showNotification from '../../../../helpers/showNotification';

export class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OpenModal: false,
      OpenReplacePrimaryModal: false,
      OpenReplaceSecondaryModal: false,
      AlteredContacts: false,
      disableOnSaving: false,
      contactSelected: null,
      indexKeeped: '',
      newRoleSelected: '',
      selectedContactId: '',
      addingContactSelected: {
        0: false,
        1: false,
      },
      replacingExistingSecondContact: false,
    };
  }

  handleInputText = (e) => {
    const index = e.currentTarget.getAttribute('id');
    const { name } = e.target;
    let { value } = e.target;
    const contact = this.props.state;
    if (name === 'email') {
      value = value.toLowerCase();
    }
    const { setState, content } = this.props;
    setState({
      account: {
        ...content.account,
        contact: {
          ...contact,
          [index]: {
            ...contact[index],
            [name]: value,
          },
        },
      },
    });
  }

  handleInputRadio = (e) => {
    const index = 1;
    const { value, name } = e.target;
    const contact = this.props.state;
    this.props.setState({
      account: {
        ...this.props.content.account,
        contact: {
          ...contact,
          [index]: {
            ...contact[index],
            [name]: value,
          },
        },
      },
    });
  }

  setNewRole = (e) => {
    const { value } = e.target;
    this.setState({
      newRoleSelected: value,
    });
  }

  addNewContactForm = (index) => {
    const contact = this.props.state;
    this.setState({
      AlteredContacts: true,
    });
    this.props.setState({
      showNewFormAdded: true,
      account: {
        ...this.props.content.account,
        contact: {
          ...contact,
          [index]: {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            role: 'manager',
            creating: true,
            joining: false,
          },
        },
      },
    });
  }

  deleteContactFromState = (indexKeeped) => {
    const { state, setState, content } = this.props;
    const contact = state[indexKeeped];
    const contactSelected = _.values(state).filter((d, i) => (i !== indexKeeped && !d.creating));
    if (contactSelected.length !== 0) {
      this.setState({
        contactSelected: contactSelected[0],
        indexKeeped,
      }, () => this.toggleConfirmModal());
    } else {
      this.setState({
        AlteredContacts: false,
      });
      setState({
        showNewFormAdded: false,
        account: {
          ...content.account,
          contact: {
            [indexKeeped]: {
              ...contact,
            },
          },
        },
      });
    }
  }

  deleteContact = () => {
    const {
      deleteUserByEmailHandler, setState, state, content,
    } = this.props;
    const { contactSelected, indexKeeped } = this.state;
    deleteUserByEmailHandler(contactSelected)
      .then(() => {
        showNotification({message: 'Contact deleted sucessfully', type: 'success'})
        this.setState({
          contactSelected: null,
          disableOnSaving: false,
          OpenModal: false,
        }, () => {
          if (indexKeeped === 0) {
            const contact = state[indexKeeped];
            setState({
              showNewFormAdded: false,
              account: {
                ...content.account,
                contact: {
                  [indexKeeped]: {
                    ...contact,
                  },
                },
              },
            });
          }
        });
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({message, type})
        this.setState({
          OpenModal: false,
        });
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      type, setState, createUsersInEditing, state,
    } = this.props;
    const { contactSelected } = this.state;
    if (type === 'edit') {
      this.setState({
        disableOnSaving: true,
      });
      const replacingExistingSecondContact = (contactSelected !== null && state[1].email);
      createUsersInEditing(replacingExistingSecondContact, contactSelected)
        .then(() => {
          showNotification({message: 'Contact information updated', type: 'success'})
          this.setState({
            contactSelected: null,
            disableOnSaving: false,
          });
          setState({
            selectedItemNav: 6,
          });
        })
        .catch(() => {
          showNotification({message: 'Error updating contact information', type: 'error'})
          this.setState({
            disableOnSaving: false,
          });
        });
    } else {
      const { content } = this.props;
      if (content.account.contact[0].email) {
        this.findIfEmailsExist(content.account.contact);
      } else {
        setState({
          selectedItemNav: 3,
        });
      }
    }
  }

  findIfEmailsExist = data => this.props.findIfEmailsExist(data)

  toggleConfirmModal = () => {
    const { OpenModal } = this.state;
    this.setState({ OpenModal: !OpenModal });
  }

  toggleReplacePrimaryConfirmModal = () => {
    const { OpenReplacePrimaryModal, contactSelected } = this.state;
    this.setState({
      OpenReplacePrimaryModal: !OpenReplacePrimaryModal,
      contactSelected: OpenReplacePrimaryModal ? null : contactSelected,
    });
  }

  openReplaceSecondaryConfirmModal = () => {
    this.setState({
      OpenReplaceSecondaryModal: true,
    });
  }

  closeReplaceSecondaryConfirmModal = () => {
    this.setState({
      OpenReplaceSecondaryModal: false,
      contactSelected: null,
    });
  }

  deletePrimaryContact = () => {
    this.setState({
      AlteredContacts: true,
      OpenModal: false,
    });
    this.props.setState({
      account: {
        ...this.props.content.account,
        contact: {
          0: {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            type: 'manager',
          },
        },
      },
    });
  }


  replaceContact = (index) => {
    const { state } = this.props;
    const contactSelected = state[index];
    this.setState({
      contactSelected,
    }, () => {
      if (index === 0) {
        this.toggleReplacePrimaryConfirmModal();
      } else {
        this.openReplaceSecondaryConfirmModal();
      }
    });
  }

  onCancelCreatingAndEditing = () => {
    const { type, onCancelCreating, onCancelEditing } = this.props;
    if (type === 'edit') {
      onCancelEditing();
    } else {
      onCancelCreating();
    }
  }

  setBackContactToReplace = () => {
    const { contactSelected } = this.state;
    const { setState, content } = this.props;
    const index = 1;
    setState({
      account: {
        ...content.account,
        contact: {
          ...content.account.contact,
          [index]: contactSelected,
        },
      },
    }, () => this.setState({
      contactSelected: null,
    }));
  }

  getContactHeader = (showDeleteButton, index, id, joining) => {
    const { addingContactSelected } = this.state;
    if (showDeleteButton) {
      if (!id) {
        return (
          <LinkButton
            style={{ color: '#19A2C5', marginLeft: '18%', fontSize: '15px' }}
            type="button"
            onClick={() => {
              const { replacingExistingSecondContact } = this.state;
              if (replacingExistingSecondContact) {
                this.setBackContactToReplace();
              } else {
                this.deleteContactFromState(index === 0 ? 1 : 0);
              }
            }}
          >
            Cancel
          </LinkButton>
        );
      }
      if (!joining) {
        return (
          <DeleteButtonContainer>
            <LinkButton
              style={{ color: '#19A2C5', marginLeft: '18%', fontSize: '15px' }}
              type="button"
              onClick={() => this.replaceContact(index)}
            >
              Replace
            </LinkButton>
            |
            <LinkButton
              style={{ color: '#19A2C5', marginLeft: '18%', fontSize: '15px' }}
              type="button"
              onClick={() => this.deleteContactFromState(index === 0 ? 1 : 0)}
            >
              Delete
            </LinkButton>
          </DeleteButtonContainer>
        );
      }
    }
    if (addingContactSelected[index]) {
      return (
        <LinkButton
          style={{ color: '#19A2C5', marginLeft: '18%', fontSize: '15px' }}
          type="button"
          onClick={() => this.deleteContactAddedFromSelect(index)}
        >
          Undo
        </LinkButton>
      );
    }
    return null;
  }

  deleteContactAddedFromSelect = (index) => {
    const { setState, content } = this.props;
    setState({
      account: {
        ...content.account,
        contact: {
          ...content.account.contact,
          [index]: {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            role: index === 0 ? 'owner' : 'manager',
            creating: true,
            joining: false,
          },
        },
      },
    }, () => {
      const { addingContactSelected } = this.state;
      this.setState({
        addingContactSelected: {
          ...addingContactSelected,
          [index]: false,
        },
      });
    });
  }

  getMembers = () => {
    let newMembers = [];
    const { team } = this.props;
    const { members, contact } = team;
    const acceptedMembers = members.filter(member => member.status === 'INVITED' || member.status === 'ACTIVE');
    if (contact.length !== 0) {
      const filteredMembers = contact.map(c => acceptedMembers.filter(member => member._id !== c._id));
      newMembers = filteredMembers[0];
    } else {
      newMembers = acceptedMembers;
    }
    return newMembers;
  }

  handleContactSelection = (e) => {
    const { value, name } = e.target;
    const { selectedContactId } = this.state;
    this.setState({
      selectedContactId: {
        ...selectedContactId,
        [name]: value,
      },
    });
  }

  setContactSelectedToForm = (e) => {
    const index = e.target.value;
    const { selectedContactId, addingContactSelected } = this.state;
    const { setState, content } = this.props;
    const members = this.getMembers();
    const membersById = _.keyBy(members, '_id');
    const memberToSet = membersById[selectedContactId[index]];
    if (memberToSet) {
      const {
        _id, first_name, last_name, phone1, phone2, role, email,
      } = memberToSet;
      setState({
        account: {
          ...content.account,
          contact: {
            ...content.account.contact,
            [index]: {
              id: _id,
              firstName: first_name,
              lastName: last_name,
              email,
              phone1,
              phone2,
              role: parseInt(index, 10) === 0 ? 'owner' : role.name,
              creating: false,
              joining: true,
            },
          },
        },
      }, () => {
        this.setState({
          addingContactSelected: {
            ...addingContactSelected,
            [index]: true,
          },
        });
      });
    }
  }

  cleanSecondContactForm = () => {
    const { setState, content } = this.props;
    const index = 1;
    setState({
      account: {
        ...content.account,
        contact: {
          ...content.account.contact,
          [index]: {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            role: 'manager',
            creating: true,
            joining: false,
          },
        },
      },
    }, () => {
      this.setState({
        replacingExistingSecondContact: true,
      }, () => this.setState({
        OpenReplaceSecondaryModal: false,
      }));
    });
  }

  setContactForm = (position, index) => {
    const { type, state } = this.props;
    const {
      firstName, lastName, email, phone1, phone2, creating, id, joining,
    } = state[index];
    const { selectedContactId } = this.state;
    const showDeleteButton = index === 0 ? !!id : (id ? true : (!!creating));
    return (
      <div>
        <Row>
          <Col size={12}>
            <ContactHeader>
              <CustomTitle>
                {position}
                {' '}
                Account Contact
                {'  '}
              </CustomTitle>
              {
                this.getContactHeader(showDeleteButton, index, id, joining)
              }
            </ContactHeader>
          </Col>
        </Row>
        {
          (type === 'edit' && (!id || (id && joining)))
          && (
            <Row>
              <Col size={12}>
                <ContactHeader>
                  <Select
                    value={selectedContactId[index]}
                    name={index}
                    placeholder="Select from team"
                    onChange={e => this.handleContactSelection(e)}
                  >
                    <option
                      value="null"
                    >
                      Select from team
                    </option>
                    {
                      this.getMembers().map(contact => (
                        <option
                          value={contact._id}
                        >
                          {contact.email}
                        </option>
                      ))
                    }
                  </Select>
                  <Button
                    key={index}
                    value={index}
                    disabled={selectedContactId === 'null' || !selectedContactId}
                    type="button"
                    onClick={e => this.setContactSelectedToForm(e)}
                  >
                    Add
                  </Button>
                </ContactHeader>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col size={12}>
            <FormGroup>
              <ControlLabel>
                First Name
              </ControlLabel>
              <FormControl
                key="123"
                autoComplete="off"
                autoFocus
                type="text"
                name="firstName"
                value={firstName}
                id={index}
                onChange={e => this.handleInputText(e)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col size={12}>
            <FormGroup>
              <ControlLabel>
                Last Name
              </ControlLabel>
              <FormControl
                autoComplete="off"
                type="text"
                name="lastName"
                value={lastName}
                id={index}
                onChange={e => this.handleInputText(e)}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col size={12}>
            <FormGroup
              style={{
                fontSize: '13px',
              }}
            >
              <ControlLabel>
                Email
              </ControlLabel>
              {type === 'edit' && !creating
                ? <span>{email}</span>
                : (
                  <FormControl
                    autoComplete="off"
                    type="email"
                    name="email"
                    value={email}
                    id={index}
                    onChange={e => this.handleInputText(e)}
                  />
                )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <FormGroup>
              <ControlLabel>
                Phone 1
              </ControlLabel>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                className="form-control"
                autoComplete="off"
                type="text"
                name="phone1"
                value={phone1}
                guide={false}
                id={index}
                render={(ref, props) => (
                  <FormControl innerRef={ref} {...props} />
                )}
                onBlur={() => {}}
                onChange={e => this.handleInputText(e)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col size={12}>
            <FormGroup>
              <ControlLabel>
                Phone 2 (optional)
              </ControlLabel>
              <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                className="form-control"
                autoComplete="off"
                type="text"
                name="phone2"
                value={phone2}
                guide={false}
                id={index}
                render={(ref, props) => (
                  <FormControl innerRef={ref} {...props} />
                )}
                onBlur={() => {}}
                onChange={e => this.handleInputText(e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }

  getContactModalBody = () => {
    let message = '';
    let modalTitle = '';
    const { contactSelected } = this.state;
    if (contactSelected) {
      const isPrimary = contactSelected.role === 'owner';
      const contactType = isPrimary ? 'primary' : 'secondary';
      message = `Are you sure you want to delete your ${contactType} contact and replace them with a new ${contactType} contact?`;
      modalTitle = `Delete ${contactType} contact?`;
    }
    return {
      message,
      modalTitle,
    };
  }

  saveContactWithNewRole = () => {
    const { contactSelected, newRoleSelected } = this.state;
    const { Id, updateContactRole } = this.props;
    if (newRoleSelected) {
      this.setState({
        disableOnSaving: true,
      });
      const data = {
        userId: contactSelected.id,
        role: newRoleSelected,
      };
      const teamId = Id;
      updateContactRole(data, teamId)
        .then(() => {
          showNotification({message: 'User type changed successfully', type: 'success'})
          this.setState({
            contactSelected: null,
            disableOnSaving: false,
            OpenReplacePrimaryModal: false,
          });
        })
        .catch((err) => {
          showNotification({message: 'Error changing user type', type: 'error'})
          this.setState({
            disableOnSaving: false,
          });
        });
    } else {
      showNotification({message: 'Please, select a user type', type: 'warning'})
    }
  }

  render() {
    const {
      type, state, content,
    } = this.props;
    const {
      disableOnSaving, OpenModal, OpenReplacePrimaryModal, OpenReplaceSecondaryModal,
    } = this.state;
    const { message, modalTitle } = this.getContactModalBody();
    const { showNewFormAdded } = content;
    return (
      <Body>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col size={6}>
              {this.setContactForm('Primary', 0)}
              <Row>
                <Col size={12}>
                  <ControlLabel>
                    User Type (not editable)
                  </ControlLabel>
                  <Topic
                    fontSize="13px"
                  >
                    {ContactData.defaultType.title}
                  </Topic>
                  <Paragraph>
                    {ContactData.defaultType.text}
                  </Paragraph>
                </Col>
              </Row>
              <Line />
              {
                (showNewFormAdded || state[1] !== undefined)
                && (
                <div>
                  {this.setContactForm('Secondary', 1)}
                  <Row>
                    <Col size={12}>
                      <ControlLabel>
                    User Type
                      </ControlLabel>
                      <div>
                        <NiceRadioButton
                          checked={state[1].role === 'manager'}
                          id="option1"
                          name="role"
                          value="manager"
                          onClick={e => this.handleInputRadio(e)}
                        >
                          <ControlLabel
                            style={{ fontWeight: 800 }}
                          >
                          Manager
                          </ControlLabel>
                        </NiceRadioButton>
                        <NiceRadioButton
                          checked={state[1].role === 'basic'}
                          id="option2"
                          name="role"
                          value="basic"
                          onClick={e => this.handleInputRadio(e)}
                        >
                          <ControlLabel
                            style={{ fontWeight: 800 }}
                          >
                          Basic
                          </ControlLabel>
                        </NiceRadioButton>
                      </div>
                    </Col>
                  </Row>
                </div>
                )}
              {
                !showNewFormAdded
                && (
                <Row>
                  <Col size={6}>
                    <LinkButton
                      type="button"
                      onClick={() => this.addNewContactForm(1)}
                    >
                      Add secondary contact
                    </LinkButton>
                  </Col>
                </Row>
                )}
              <Row>
                <Col size={2}>
                  <Button
                    onClick={this.onCancelCreatingAndEditing}
                    type="button"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col size={2}>
                  <Button
                    disabled={disableOnSaving}
                    tColor="white"
                    bgColor="#455A64"
                  >
                    {type === 'edit' ? 'Save' : 'Continue'}
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col size={5} xOffset={1}>
              <UserTypes />
            </Col>
          </Row>
        </Form>
        <ConfirmDialog
          show={OpenModal}
          title={modalTitle}
          message={message}
          onConfirm={this.deleteContact}
          onCancel={this.toggleConfirmModal}
        />
        <ConfirmDialog
          show={OpenReplaceSecondaryModal}
          onConfirmButtonText="Continue"
          title="Replace secondary contact?"
          message="You can choose from the existing users or invite a new one"
          onConfirm={this.cleanSecondContactForm}
          onCancel={this.closeReplaceSecondaryConfirmModal}
        />
        <CustomConfirmDialog
          show={OpenReplacePrimaryModal}
          onConfirmButtonText="Save"
          title="Replace primary contact?"
          message="To replace your Primary Contact you must first change their User Type:"
          onConfirm={this.saveContactWithNewRole}
          onCancel={this.toggleReplacePrimaryConfirmModal}
        >
          <NiceRadioButton
            id="option3"
            name="role"
            value="manager"
            onClick={e => this.setNewRole(e)}
          >
            <ControlLabel
              style={{ fontWeight: 800 }}
            >
              Manager
            </ControlLabel>
          </NiceRadioButton>
          <NiceRadioButton
            id="option4"
            name="role"
            value="basic"
            onClick={e => this.setNewRole(e)}
          >
            <ControlLabel
              style={{ fontWeight: 800 }}
            >
              Basic
            </ControlLabel>
          </NiceRadioButton>
        </CustomConfirmDialog>
      </Body>
    );
  }
}

const Body = styled.div`
  ${''}
  width: 98%;
  padding: 0 2%;
`;

const CustomTitle = styled(Title)`
  padding: 2px 0 0px 0;
`;
const ContactHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0.5% 0px 0.5%;
  width: 99%;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(33, 151, 250);
`;

const Paragraph = styled(ControlLabel)`
  ${''}
  width: 90%;
  padding-top: 10px;
  text-align: justify;
  ${''}
  line-height: 150%;
  font-size: 0.85em;
  color: rgba(65, 71, 76, 1);
`;

const Topic = styled(ControlLabel)`
  ${''}
  font-weight: 500;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  color: rgba(65, 71, 76, 1);
`;

const Line = styled.hr``;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: rgb(33, 151, 250);
  cursor: pointer;
  outline: none;
  padding: 0;
  padding: 0 25px 0 0;
`;

const Select = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 1px;
text-overflow: '';
border: 1px solid rgba(0,0,0,0.2);
outline: none;
padding: 8px 48px 8px 6px;
background-color: white;
background-image: url(${angleDown});
background-repeat: no-repeat;
background-size: 14px 14px;
background-position: 96%;
font-size: 13px;
width: 85%;
`;

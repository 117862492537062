import React, { Component } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import barChart from '../../../assets/ico-barChart.svg';
import colChart from '../../../assets/ico-colChart.svg';
import pieChart from '../../../assets/ico-pieChart.svg';
import lineChart from '../../../assets/ico-lineChart.svg';
import tableChart from '../../../assets/ico-tableChart.svg';
import './Card.css';

const styles = {
  root: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '13px',
    color: '#37474F',
    marginRight: '1px',
  },
  icon: {
    fill: '#90A4AE',
  },
  selectMenu: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '13px',
    color: '#37474F',
  },
  dropdownStyle: {
    paddingTop: 0,
  },
};

class ChartTypeMenu extends Component {
  render() {
    const {
      id, 
      currentType,
      changeType,
      grouped,
      classes,
      currentUnit,
    } = this.props;
    return (
      <Select
        value={currentType}
        disableUnderline
        classes={{
          root: classes.root,
          icon: classes.icon,
          selectMenu: classes.selectMenu,
        }}
        MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        style={{ flex: 2 }}
        IconComponent={KeyboardArrowDown}
        onChange={e => changeType(id, e.target.value)}
      >
        <MenuItem
          disabled={currentUnit === 'all'}
          value="bar"
          className="menu-item"
        >
          <Icon src={barChart} alt="bar-icon" />Bar Chart
        </MenuItem>
        <MenuItem
          disabled={currentUnit === 'all'}
          value="column"
          className="menu-item"
        >
          <Icon src={colChart} alt="col-icon" /> Column Chart
        </MenuItem>
        <MenuItem
          disabled={currentUnit === 'all'}
          value="line"
          className="menu-item"
        >
          <Icon src={lineChart} alt="line-icon" /> Line Chart
        </MenuItem>
        <MenuItem
          disabled={currentUnit !== 'percentages' || grouped}
          value="pie"
          className="menu-item"
        >
          <Icon src={pieChart} alt="pie-icon" /> Pie Chart
        </MenuItem>
        <MenuItem
          value="table"
          className="menu-item"
        ><Icon src={tableChart} alt="table-icon" /> Table
        </MenuItem>
      </Select>
    );
  }
}

const Icon = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 5px;
  color: #90A4AE;
`;
ChartTypeMenu.propTypes = {
  id: propTypes.string.isRequired,
  currentType: propTypes.string.isRequired,
  changeType: propTypes.func.isRequired,
  grouped: propTypes.bool.isRequired,
};

export default withStyles(styles)(ChartTypeMenu);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import ModalContent from './ModalContent';
import { resetModal } from '../../../actions/targetActions';
import { setBrowserType, closeBrowserManager } from '../../../actions/UIManagerActions';
import './Modal.css';

const withOpenModal = (WrappedComponent, callback) => props => (
  <WrappedComponent {...props} openModal={callback} />
);

class BuildTargetModal extends Component {
  state = { open: false };

  open = () => {
    const { preventScroll, setBrowserType, closeBrowserManager } = this.props;
    if (preventScroll) {
      preventScroll(true);
    }
    this.setState({ open: true });
    setBrowserType('target');
    closeBrowserManager();
  };

  close = () => {
    const { resetModal } = this.props;
    this.setState({ open: false }, () => {
      const { preventScroll } = this.props;
      if (preventScroll) {
        preventScroll(false);
      }
    });

    resetModal();
  };

  render() {
    const { open } = this.state;
    const { type, trigger } = this.props;
    const TriggerWithOpenModal = withOpenModal(trigger, this.open);
    return (
      <div>
        <TriggerWithOpenModal />
        <Modal
          open={open}
          onClose={this.close}
          classNames={{
            overlay: 'overlay',
            modal: 'modal',
            closeButton: 'closeButton',
          }}
          center
        >
          <ModalContent close={this.close} type={type} />
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setBrowserType: type => dispatch(setBrowserType(type)),
  closeBrowserManager: () => dispatch(closeBrowserManager()),
  resetModal: () => dispatch(resetModal()),
});

export default connect(null, mapDispatchToProps)(BuildTargetModal);

import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import Template from './Template';

class List extends Component {
  state = {
    showList: true,
  }

  componentDidMount() {
    this.props.actions.load();
  }

   handleClick = () => {
     this.setState({ showList: false });
   };

   render() {
     const { currentTemplate } = this.props.data;
     const { actions, targets, user } = this.props;
     const sortedTargets = _.orderBy(targets, 'createdAt', 'asc');;
     return (
       <Container>
         {
           _.map(sortedTargets, (template, index) => (
             <Template
               key={index}
               user={user}
               template={template}
               toggle={this.handleClick}
               actions={actions}
               currentTemplate={currentTemplate}
             />
           ))
         }
       </Container>
     );
   }
}

const Container = styled.div`
  display: block;
  height: 562px;
  margin-left: 32px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Roboto';
  text-align: left;
  }
`;
const mapStateToProps = (store) => {
  const { sharedTemplates, projects } = store.ProjectReducer;
  const filteredProjects = _.filter(projects, p => p.subType === 'TARGET');
  const filteredSharedTemplates = _.filter(sharedTemplates, p => p.subType === 'TARGET');
  return {
    targets: { ..._.keyBy(filteredSharedTemplates, '_id'), ..._.keyBy(filteredProjects, '_id') },
    user: store.AppReducer.user,
  };
};
export default connect(mapStateToProps)(List);

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import _ from 'lodash';
import Chart from './Chart';
import TableChart from './TableChart';

class ChartContainer extends Component {
  symbols = {
    percentages: '%',
    thousands: '000s',
  }

  renderAverage = (average) => {
    if (!_.isEmpty(average)) {
      if (typeof average === 'object') {
        return (
          <AverageContainer>
            <p className="averageTitle">Averages:</p>
            <Average>
              <Text>{average.toString()}</Text>
            </Average>
          </AverageContainer>
        );
      } else {
        return (
          <AverageContainer>
            <p className="averageTitle">Average:</p>
            <Average>
              <Text>{average}</Text>
            </Average>
          </AverageContainer>
        );
      }
    } 
    return null;
  }

  labelsForUnitAll = (labels, target, newLabels, targets) => {
    const aux = labels[target];
    const auxLabels = aux.shortLabels ? aux.shortLabels : aux.labels;
    const units = ['percentages', 'index', 'thousands'];
    const refactoringAuxLabels = _.slice(auxLabels, 0, 2);
    let indexCounter = 0;
    _.forEach(units, (u) => {
      refactoringAuxLabels.forEach((lbl) => {
        if (u === 'index') {
          if (indexCounter === 0) {
            if (targets === 1) {
              newLabels.labels = [...newLabels.labels];
            } else {
              newLabels.labels = [...newLabels.labels, 'Index'];
            }
          }
          indexCounter += 1;
        } else {
          newLabels.labels = [...newLabels.labels, `${lbl} ${this.symbols[u]}`];
        }
      });
    });
    return newLabels;
  }

  render() {
    const {
      data, type, unit, target, labels, chartsData, targets,
    } = this.props;
    
    const { index } = data[0];
    let newLabels = { labels: [] };
    let currentData;
    if (unit === 'all') {
      const { percentages, thousands } = _.cloneDeep(data[target]);
      currentData = {
        keys: percentages.keys ? percentages.keys : percentages,
        labels: percentages.labels ? percentages.labels : percentages,
      };
      _.forEach(currentData.keys, (keys, i) => {
        const percentagesValues = percentages.keys[i].values;
        const thousandsValues = thousands.keys[i].values;
        currentData.keys[i].values = [...percentagesValues, ...thousandsValues];
      });
      const auxCurrentDataKeys = currentData.keys;
      _.forEach(auxCurrentDataKeys, (k, i) => {
        if (Array.isArray(auxCurrentDataKeys[i].values)) {
          let firstPart;
          let secondPart;
          if (targets === 1) {
            firstPart = auxCurrentDataKeys[i].values.slice(0, 1);
            secondPart = auxCurrentDataKeys[i].values.slice(1, 2);
          } else {
            firstPart = auxCurrentDataKeys[i].values.slice(0, 2);
            secondPart = auxCurrentDataKeys[i].values.slice(targets, targets + 2);
          }
          currentData.keys[i].values = [...firstPart, index.keys[i].values[1] ? index.keys[i].values[1] : '', ...secondPart];
        }
      });
      newLabels = this.labelsForUnitAll(labels, target, newLabels, targets);
    } else {
      newLabels = labels[target];
      currentData = data[target][unit];
    }
    const grouped = data[target].type === 'grouped';
    const { average } = data[target];
    _.forEach(newLabels.labels, (label, i) => {
      if (label.length > 50) {
        newLabels.labels[i] =  _.truncate(label, { length: 50})
      }
    })
    
    return (
      <Fragment>
        {type === 'table'
          ? <TableChart unit={unit} data={currentData} labels={newLabels} average={average} chartsData={chartsData} />
          : <Chart type={type} targets={labels} labels={newLabels} target={target} data={currentData} chartsData={chartsData} average={average} grouped={grouped} />}
        {(type !== 'table') && this.renderAverage(average)}
      </Fragment>
    );
  }
}

const AverageContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: -10px;
  left: 32px;

  .averageTitle {
    margin-left: 12px;
    font-family: Roboto;
    font-weight: 400;
    color: #37474F;
    font-size: 13px;
  }
`;

const Average = styled.div`
  padding: 10px;
  color: #37474F;
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  margin: 0;
  padding: 3px;
  font-size: 13px;
  color: #37474F;
`;

ChartContainer.propTypes = {
  type: propTypes.string.isRequired,
  unit: propTypes.string.isRequired,
  target: propTypes.number.isRequired,
  data: propTypes.array.isRequired,
};

export default ChartContainer;

export const DataStructure = {
  Tabs: [{
    Name: '',
    Targets: [],
    Banners: [],
    isVisible: false,
    isCurrentSurvey: false,
  },
  ],
};

const defaultState = {
  Title: 'Untitled project',
  Data: DataStructure,
  lastBannerDeleted: null,
  lastDataPointsDeleted: [],
  DataBlocksSelected: 0,
  lastMergedBlock: null,
  canMergeBlocks: false,
  canMergeRows: false,
  canUnmergeBlock: false,
  canUnmergeRow: false,
  canRemoveRows: false,
  lastMergedRowsBlock: null,
  lastMergedRowPosition: null,
  showPercentage: true,
  showThousands: true,
  showIndex: false,
  yearList: [],
  currentTab: 0,
  lastTabWhereThereWasAMerge: 0,
  targetState: 0,
  bannerState: 0,
  chartWindowVisible: false,
  currentSurveyYear: 0,
  targetCount: 0,
  bannerCount: 0,
  yearsAreLoaded: false,
  editTargetPosition: 0,
  isLoadedFromMyProjects: false,
  currentLoadedProject: null,
  editBannerPosition: 0,
  targetTemplateLoaded: false,
  bannerTemplateLoaded: false,
};

const spreadsheetReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SPREADSHEET_SELECT_ALL':
      return {
        ...state,
        Data: action.Data,
        canUnmergeBlock: action.canUnmergeBlock,
        canMergeRows: false,
        canMergeBlocks: action.canMergeBlocks,
        canUnmergeRow: false,
      };
    case 'SPREADSHEET_SELECT_ROW':
      return {
        ...state,
        Data: action.Data,
        canMergeRows: action.canMergeRows,
        canUnmergeRow: action.canUnmergeRow,
        canMergeBlocks: false,
        canUnmergeBlock: false,
        canRemoveRows: action.canRemoveRows,
      };
    case 'SPREADSHEET_REMOVE_BLOCK':
      return {
        ...state,
        Data: action.Data,
        lastBannerDeleted: action.lastBannerDeleted,
        canMergeBlocks: false,
        canUnmergeBlock: false,
        bannerCount: state.bannerCount - 1,
      };
    case 'SPREADSHEET_UNDO_REMOVE_BLOCK':
      return {
        ...state,
        Data: action.Data,
        lastBannerDeleted: null,
      };
    case 'SPREADSHEET_REMOVE_ROW':
      return {
        ...state,
        Data: action.Data,
        lastDataPointsDeleted: action.lastDataPointsDeleted,
        canRemoveRows: false,
      };
    case 'SPREADSHEET_UNDO_REMOVE_ROW':
      return {
        ...state,
        Data: action.Data,
        lastDataPointsDeleted: [],
      };
    case 'SPREADSHEET_SORT_BLOCK_ASC':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_SORT_BLOCK_DESC':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_MERGE_BLOCK':
      return {
        ...state,
        Data: action.Data,
        lastMergedBlock: action.lastMergedBlock,
        canMergeBlocks: false,
        lastTabWhereThereWasAMerge: action.lastTabWhereThereWasAMerge,
      };
    case 'SPREADSHEET_CHANGE_BANNER_TITLE':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_MERGE_ROWS':
      return {
        ...state,
        Data: action.Data,
        lastMergedRowsBlock: action.lastMergedRowsBlock,
        lastMergedRowPosition: action.lastMergedRowPosition,
        canMergeRows: false,
        canMergeBlocks: false,
        lastTabWhereThereWasAMerge: action.lastTabWhereThereWasAMerge,
      };
    case 'SPREADSHEET_UNMERGE_ROWS':
      return {
        ...state,
        Data: action.Data,
        canUnmergeRow: action.canUnmergeRow,
      };
    case 'SPREADSHEET_UNMERGE_BLOCK':
      return {
        ...state,
        Data: action.Data,
        canUnmergeBlock: false,
      };
    case 'SPREADSHEET_UNDO_MERGE_BLOCK':
      return {
        ...state,
        Data: action.Data,
        canUnmergeBlock: false,
        canMergeBlocks: false,
      };
    case 'SPREADSHEET_UNDO_MERGE_ROW':
      return {
        ...state,
        Data: action.Data,
        lastMergedRowPosition: null,
        lastMergedRowsBlock: null,
        canUnmergeRow: false,
        canMergeBlocks: false,
      };
    case 'SPREADSHEET_COPY_BLOCK':
      return {
        ...state,
        Data: action.Data,
      };

    case 'SPREADSHEET_SHOW_PERCENTAGE':
      return {
        ...state,
        showPercentage: action.showPercentage,
      };
    case 'SPREADSHEET_SHOW_THOUSANDS':
      return {
        ...state,
        showThousands: action.showThousands,
      };
    case 'SPREADSHEET_SHOW_INDEX':
      return {
        ...state,
        showIndex: action.showIndex,
      };
    case 'SPREADSHEET_CHANGE_ROW_NAME':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_LOAD_DEMO':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_LOAD_YEARS':
      return {
        ...state,
        yearList: action.yearList,
        currentSurveyYear: action.currentSurveyYear,
        Data: action.Data,
        currentTab: action.currentTab,
        yearsAreLoaded: true,
      };
    case 'SPREADSHEET_SET_CURRENT_TAB':
      return {
        ...state,
        currentTab: action.currentTab,
      };
    case 'SPREADSHEET_ADD_NEW_TABS':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_ADD_TARGET':
      return {
        ...state,
        Data: action.payload.Data,
        targetCount: state.targetCount + 1,
        targetTemplateLoaded: false,
      };
    case 'SPREADSHEET_ADD_BANNER':
      return {
        ...state,
        Data: action.payload.Data,
        bannerCount: state.bannerCount + 1,
        bannerTemplateLoaded: false,
      };
    case 'SPREADSHEET_ADD_CUME_TOTAL':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_CALCULATE_CUME_TOTAL':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_OPEN_WINDOW_CHARTS':
      return {
        ...state,
        chartWindowVisible: action.chartWindowVisible,
      };
    case 'SPREADSHEET_CLOSE_WINDOW_CHARTS':
      return {
        ...state,
        chartWindowVisible: action.chartWindowVisible,
      };
    case 'SPREADSHEET_DELETE_TAB':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_COPY_TARGET':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_DELETE_TARGET':
      return {
        ...state,
        Data: action.Data,
        targetCount: state.targetCount - 1,
      };
    case 'SPREADSHEET_CALCULATE_COMBINED':
      return {
        ...state,
        Data: action.Data,
      };
    case 'SPREADSHEET_SET_EDIT_TARGET_POSITION':
      return {
        ...state,
        editTargetPosition: action.editTargetPosition,
      };
    case 'SPREADSHEET_CHANGE_PROJECT_TITLE':
      return {
        ...state,
        Title: action.Title,
      };
    case 'SPREADSHEET_LOAD_FROM_MYPROJECTS':
      return {
        ...state,
        ...action.Data,
        isLoadedFromMyProjects: true,
        currentLoadedProject: action.template,
      };
    case 'SPREADSHEET_LOAD_EDIT_TARGET':
      return {
        ...state,
        editTargetPosition: action.editTargetPosition,
      };
    case 'SPREADSHEET_LOAD_EDIT_BANNER':
      return {
        ...state,
        editBannerPosition: action.editBannerPosition,
      };
    case 'SPREADSHEET_RESET':
      return defaultState;
    case 'SPREADSHEET_LOAD_TARGET_TEMPLATE':
      return {
        ...state,
        targetTemplateLoaded: true,
      };
    case 'SPREADSHEET_LOAD_BANNER_TEMPLATE': {
      if(action.payload.dataStructureBanner){
        return {
          ...state,
          Data: { Tabs : action.payload.dataStructureBanner },
          bannerTemplateLoaded: true,
          haveStructureBanner: true,
        };
      } 
        return {
          ...state,
          bannerTemplateLoaded: true,
          haveStructureBanner: false,
        };
      
    }
    case 'SPREADSHEET_ADD_INITIAL_TARGET':
      return {
        ...state,
        targetTemplateLoaded: false,
        bannerTemplateLoaded: false,
        Data: action.payload.Data,
        targetCount: state.targetCount + 1,
        bannerCount: state.bannerCount + 1,
      };
    case 'SPREADSHEET_CHANGE_SAVED_NEW_PROJECT':
      return {
        ...state,
        isLoadedFromMyProjects: true,
        currentLoadedProject: action.currentLoadedProject,
      };
      case 'SPREADSHEET_LOAD_YEARS_FROM_TEMPLATE':
      return {
        ...state,
        yearList: action.yearList,
        currentSurveyYear: action.currentSurveyYear,
        currentTab: action.currentTab,
        yearsAreLoaded: true,
      };
      
    default:
      return state;
  }
};

export default spreadsheetReducer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import BasicPreview from './BasicPreview';
import AdvancedPreview from './AdvancedPreview';

class Previews extends Component {
  previewSelector = (currentTemplate) => {
    const { currentTab } = this.props;

    if (!_.isEmpty(currentTemplate.basicBuilder)) {
      return <BasicPreview currentTab={currentTab} basicBuilder={currentTemplate.basicBuilder} />;
    }

    if (!_.isEmpty(currentTemplate.advancedBuilder)) {
      return (
        <AdvancedPreview
          currentTab={currentTab}
          advancedBuilder={currentTemplate.advancedBuilder}
        />
      );
    }
    return null;
  };

  render() {
    const {
      currentTab, basicBuilder, advancedBuilder, currentTemplate,
    } = this.props;
    return (
      <PreviewContainer>
        {currentTab === 0 && <BasicPreview basicBuilder={basicBuilder} />}
        {currentTab === 1 && this.previewSelector(currentTemplate)}
        {currentTab === 2 && <AdvancedPreview advancedBuilder={advancedBuilder} />}
      </PreviewContainer>
    );
  }
}

const mapStateToProps = state => ({
  advancedBuilder: state.bannerReducer.advancedBuilder,
  basicBuilder: state.bannerReducer.basicBuilder,
  currentTab: state.bannerReducer.currentTab,
  currentTemplate: state.bannerReducer.currentTemplate,
});

const PreviewContainer = styled.div`
  background-color: white;
  margin-top: 10px;
  width: 100%;
  height: 550px;
  position: relative;
`;

export default connect(mapStateToProps)(Previews);

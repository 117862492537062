import React, { Component } from 'react';
import styled from 'styled-components';
import Content from './Content';

class Body extends Component {
  render() {
    const { history } = this.props;
    return (
      <Wrapper>
        <Content history={history} />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

export default Body;

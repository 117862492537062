import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import BuildTargetModal from '../BuildTargetModal';
import BuildBannerModal from '../BuildBannerModal';
import BaseTarget from './BaseTarget';
import Target2 from './Target2';
import CustomBanner from './CustomBanner';
import TargetDropdown from '../GlobalComponents/TargetDropdown';
import MarketSize from './MarketSize';
import BannerDropdown from './BannerDropdown';
import TargetSize from './TargetSize';
import './SelectionArea.css';

const iconStyle = {
  color: '#90A4AE',
};

class SelectionArea extends Component {
  render() {
    const baseTargetInfo = `<b>Base Target</b><br/>A broad group of respondents <br/>used as a comparison to Target 2.<br/>
      Most common Base Target is Adults 18+.`;
    const target2Info = `<b>Target 2</b><br/>The group you want to learn more about.<br/>
      Examples include Ford Drivers, Upscale Furniture Buyers,<br/> Adults 18-34 That Use Snapchat.`;
    const bannerInfo = `<b>Templates</b><br/> The type of information you want<br/> to learn about your Target.<br/>
    Select a predefined template or<br/> customize your own.`;

    return (
      <Container>
        <Block>
          <Title>
            <Icon>1</Icon>
            <LargeText>WHO</LargeText>
            {' '}
            do you want to target?
          </Title>
          <Base>
            <Info>
              Base Target &nbsp;
              <FontAwesomeIcon
                icon={faQuestionCircle}
                data-tip={baseTargetInfo}
                style={iconStyle}
              />
            </Info>
            <MarketSize />
          </Base>
          <BuildTargetModal trigger={BaseTarget} type="baseTarget" />
        </Block>

        <Block>
          <Title>
            <WhiteIcon>&nbsp;</WhiteIcon>
            <LargeText>&nbsp;</LargeText>
          </Title>
          <Base>
            <Info>
              Target 2 &nbsp;
              <FontAwesomeIcon
                icon={faQuestionCircle}
                data-tip={target2Info}
                style={iconStyle}
              />
            </Info>
            <TargetSize />
          </Base>
          <BuildTargetModal trigger={Target2} type="target2" />
          <TargetDropdown />
        </Block>

        <Block>
          <Title>
            <Icon>2</Icon>
            <LargeText>WHAT</LargeText>
            {' '}
            do you want to know about your target?
          </Title>
          <Base>
            <Info>
              Template &nbsp;
              <FontAwesomeIcon icon={faQuestionCircle} data-tip={bannerInfo} style={iconStyle} />
            </Info>
            <div><p style={{ margin: 5, fontSize: '12px' }}>&nbsp;</p></div>
          </Base>
          <BannerDropdown />
          <BuildBannerModal trigger={CustomBanner} />
        </Block>

        <ReactTooltip
          place="right"
          effect="solid"
          type="light"
          border
          html
          className="tooltip"
        />
      </Container>
    );
  }
}

const Container = styled.div`
  width: 97%;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin: 20px;
`;

const Block = styled.div`
  position: relative;
  width: 32%;
`;

const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #ff8000;
  font-weight: 500;
  font-size: 16px;
`;

const LargeText = styled.span`
  font-family: "Roboto";
  font-size: 1.1em;
  margin-right: 5px;
`;

const Icon = styled.div`
  margin: 5px;
  width: 25px;
  height: 25px;
  background: #ff8000;
  color: #ffffff;
  border-radius: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WhiteIcon = styled(Icon)`background: #ffffff;`;

const Info = styled.div`
  color: #607D8B;
  font-size: 13px;
  font-family: "Roboto";
  font-weight: 400;
`;

const Base = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
`;

export default SelectionArea;

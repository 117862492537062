import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Trigger from './CategoryTrigger';
import Subcategories from './Subcategories';
import Questions from './Questions';

const capitalize = text => text.toLowerCase()
  .split(/([\s|/])/)
  .map(s => s.charAt(0).toUpperCase() + s.substring(1))
  .join(' ');

class Category extends Component {
  render() {
    const {
      activeCollapsibles,
      category,
      blockId,
      actions,
      handlers,
    } = this.props;
    const { category: activeCategory } = activeCollapsibles;
    const { questions, subcategories } = category;
    const title = capitalize(category.name);
    const open = title === activeCategory;
    return (
      <Wrapper>
        <Collapsible
          accordionPosition={title}
          open={open}
          handleTriggerClick={open ? handlers.category.close : handlers.category.open}
          trigger={<Trigger title={title} />}
          triggerWhenOpen={<Trigger title={title} open />}
        >
          {category.questions.length > 0
            && (
              <Questions
                actions={actions}
                activeCollapsibles={activeCollapsibles}
                blockId={blockId}
                handlers={handlers}
                path={{ category: title }}
                questions={questions}
              />
            )
          }
          {category.hasSubcategories
            && (
            <Subcategories
              actions={actions}
              activeCollapsibles={activeCollapsibles}
              blockId={blockId}
              handlers={handlers}
              path={{ category: title }}
              subcategories={subcategories}
            />
            )
          }
        </Collapsible>
      </Wrapper>
    );
  }
}

Category.propTypes = {
  category: propTypes.object.isRequired,
  blockId: propTypes.number.isRequired,
  actions: propTypes.object.isRequired,
  activeCollapsibles: propTypes.object.isRequired,
  handlers: propTypes.object.isRequired,
};

const Wrapper = styled.div`
  border: 1px #DEE1E3 solid;
  font-size: 13px;
  font-family: Roboto;
  border-bottom: none;
  &:last-child {
    border-bottom: 1px #DEE1E3 solid;
  }
`;

export default Category;

import React from 'react';
import PropTypes from 'prop-types';

const Tab = props => (
  <li className="tab">
    <a
      style={{ textDecoration: 'none', color: '#37474F' }}
      href="/"
      className={`tab-link ${props.linkClassName} ${props.isActive ? 'active' : ''}`}
      onClick={(event) => {
        event.preventDefault();
        props.onClick(props.tabIndex);
      }}
    >
      {props.tabTitle}
    </a>
  </li>
);

Tab.propTypes = {
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  linkClassName: PropTypes.string.isRequired,
};

export default Tab;

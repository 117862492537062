import React, { Component } from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import _ from 'lodash';
import styled from 'styled-components';
import Plotly from './Plotly';
import colors from './colors';
import './Chart.css';

const Plot = createPlotlyComponent(Plotly);

const plotlyData = {
  name: 'SF Zoo',
  type: 'pie',
  labels: [],
  values: [],
  hoverinfo: 'label+percent',
  labelFont: {size: 8},
  marker: { colors },
  mode: 'none',
  textfont: {size: 9},
  textinfo: 'none',
  textposition: 'auto',
  hoverlabel: {namelength: 5},
  sort: false,
};

const layout = {
  width: 400,
  height: 287,
  showlegend: false,
  legend: { x: 0.75, y: -0.42 },
  margin: { l: 0,t: 10, b: 90, r: 20 },
  xaxis: {
    autorange: true,
    ticks: '',
    showticklabels: false,
  },
};

class PieChart extends Component {
  render() {
    const { data, labels: targetLabels, target } = this.props;
    const grouped = data.length > 1;
    const dataSets = data.map(({labels,values}, index) => {
      const dataSet = _.cloneDeep(plotlyData);
      dataSet.labels = labels;
      dataSet.values = values;
      dataSet.name = grouped ? targetLabels[index].name : targetLabels[target].name;
      return dataSet;
    });
    return (
      <StyledChart
        className="overview-chart"
        data={dataSets}
        layout={{...layout, showlegend: grouped}}
      />
    );
  }
}

const StyledChart = styled(Plot)`
  background-color: transparent;
  .plot-container > .svg-container > svg {
    overflow: visible;
  }
`;
export default PieChart;

import React, { Component, Fragment } from 'react';
import { isEmpty } from 'lodash';
import Preloader from './Preloader';
import Card from './Card';
import './Chart.css';


class Chart extends Component {
  render() {
    const {
      chartData, labels, hideQuestionMenu, type, targets,
    } = this.props;
    const {
      id, title, data, builder, builderType,
    } = chartData;
    const loading = isEmpty(chartData);
    return (
      <Fragment>
        {loading
          ? <Preloader />
          : (
            <Card
              type={type}
              labels={labels}
              id={id}
              title={title}
              data={data}
              builder={builder}
              builderType={builderType}
              hideQuestionMenu={hideQuestionMenu}
              targets={targets}
            />
          )}
      </Fragment>
    );
  }
}

export default Chart;

import React,{Component} from 'react';
import styled from 'styled-components';

class TabsButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iconName:{}
    };  
}

handleTab = (position) => {
  this.props.functions.setCurrentTab(position)
}

handleDeleteTab = (position) => {
  this.props.functions.deleteTab(position);
}

  render() {
    return (
      <span style={{marginLeft: '2px'}}>
      <TabBtn currentTab={this.props.currentTab} position={this.props.position} onClick={() => this.handleTab(this.props.position)}>      
        {this.props.tabYear}   
      </TabBtn>
    </span>)
  }
}

const TabBtn = styled.button`
    height: 30px;
    font-size:13px;  
    font-family: Roboto;
    font-weight:400;
    color: ${({ currentTab, position }) => (currentTab === position ? '#FFFFFF' : '#37474F')}; 
    background-color: ${({ currentTab, position }) => (currentTab === position ? '#607D8B' : '#E2E7EA')};
    border:none;
    border-radius: 4px 4px 0 0;
    margin: 6px 0px 0px 0px !important;
    cursor: pointer;
    &:hover {
      background-color: #CFD8DC;
    };    
    width: auto;
    padding: 0px 15px 0px 15px;
`;

export default TabsButton;
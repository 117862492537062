import React from 'react';
import PropTypes from 'prop-types';

import { ToolbarButton } from './ToolbarButton';


const ToolbarButtonThousands = (props) => {
  const { functions, showThousands } = props;
  return (
    <ToolbarButton
      onClick={functions.showThousands}
      active={showThousands}
      text="000s"
    />
  );
};

ToolbarButtonThousands.propTypes = {
  functions: PropTypes.instanceOf(Object).isRequired,
  showThousands: PropTypes.bool,
};

ToolbarButtonThousands.defaultProps = {
  showThousands: false,
};

export default ToolbarButtonThousands;

import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Media } from 'react-breakpoints';
import UserDropdown from './UserDropdown';
import { SpreadSheetReset } from '../../actions/spreadsheetActions';
import { resetSpreadSheetCharts } from '../../actions/spreadsheetChartActions'; 
import { resetSpreadsheetCards } from '../../actions/spreadsheetCardActions';
import { MobileNavigationBar } from './ResponsiveViews/MobileNavigationBar';

const AdminBar = ({
   user, handleDropdownClick, showingUserDropDown,
}) => (
  <React.Fragment>
    <Header>
      <p className="stowell">STOWELL</p>
      {' '}
      <p className="data">DATA</p>
    </Header>
    <AdminRightContainer>
      <Menu right>
        <Item><Link activeClassName="navitem-active" to="/accounts">Accounts</Link></Item>
      </Menu>
      <RightMenu admin onClick={handleDropdownClick}>

        <ProfileIcon>{user.first_name[0]}</ProfileIcon>
        <Profile>
          <b>
            {`${user.first_name} ${user.last_name}`}
            <br />
            {user.teamId.name}
          </b>
        </Profile>
        <Arrow>
          <FontAwesomeIcon icon={faAngleDown} />
        </Arrow>
      </RightMenu>
    </AdminRightContainer>
    <UserDropdown user={user} showingUserDropDown={showingUserDropDown} />
  </React.Fragment>

);

const BasicBar = ({
  user,account, handleDropdownClick, showingUserDropDown, dropDown, resetSpreadSheet,
}) => {
  let resources = '';
  if (user.role.name === 'admin') {
    resources = account?.resources;
  } else {
    resources = user?.teamId?.resources;
  }

  let accountName = '';
  if (user.role.name === 'admin') {
    accountName = account?.name;
  } else {
    accountName = user?.teamId?.name;
  }

  return (
    <React.Fragment>
      <Header>
        <p className="stowell">STOWELL</p>
        {' '}
        <p className="data">DATA</p>
        {' '}
        {accountName ? ` / ${accountName}` : ''}
      </Header>
  
      <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div>
            <Menu>
            {resources && (<Item><ALink href={resources} target='_blank'>RESOURCES</ALink></Item>)}
              <Item><Link activeClassName="navitem-active" to="/overview">OVERVIEW</Link></Item>
              <Item noWrap><Link activeClassName="navitem-active" to="/projects">MY PROJECTS</Link></Item>
              <Item><Link smalltext="true" activeClassName="navitem-active" to="/support">SUPPORT</Link></Item>
              <Item><Link smalltext="true" activeClassName="navitem-active" to="/documents">DOCUMENTS</Link></Item>
            </Menu>
          </div>
  
          <div>
            <Link to="/spreadsheet">
              <RouterButton onClick={resetSpreadSheet}>
                <p>
              New Project
                </p>
              </RouterButton>
            </Link>
          </div>
        </div>
  
        <RightMenu onClick={handleDropdownClick}>
          <ProfileIcon>{user.first_name[0]}</ProfileIcon>
          <Profile>
            <b>
              {`${user.first_name} ${user.last_name}`}
            </b>
            <br />
            {user.teamId.name}
          </Profile>
          <Arrow>
            <FontAwesomeIcon icon={faAngleDown} />
          </Arrow>
        </RightMenu>
      </div>
      <UserDropdown user={user} innerRef={dropDown} showingUserDropDown={showingUserDropDown} />
    </React.Fragment>
  );
}


class NavigationBar extends Component {
    state = {
      showingUserDropDown: false,
    }

    dropDown = React.createRef();

    toggleDropDown = () => {
      this.setState({ showingUserDropDown: !this.state.showingUserDropDown });
    }

    handleDropdownClick = () => {
      if (!this.state.showingUserDropDown) {
        document.addEventListener('click', this.handleOutsideClick, false);
      } else {
        document.removeEventListener('click', this.handleOutsideClick, false);
      }
      this.toggleDropDown();
    }

    handleOutsideClick = (e) => {
      if (this.dropDown.current) {
        if (this.dropDown.current.contains(e.target)) {
          return;
        }
        this.handleDropdownClick();
      }
    }

    isOnAccountsOrManageMeTeamPage = () => {
      const { match } = this.props;
      return match.path.includes('account') || match.path.includes('team/members');
    }

    resetSpreadSheet = () => {
      this.props.dispatchSpreadSheetReset();
      this.props.dispatchChartsReset();
      this.props.dispatchCardsReset();
    }

  getDesktopMenu = ({
    showingUserDropDown,
    user,
    databases,
    account,
    showAdminBar,
  }) => (showAdminBar
    ? (
      <AdminBar
        user={user}
        databases={databases}
        showingUserDropDown={showingUserDropDown}
        handleDropdownClick={this.handleDropdownClick}
        dropDown={this.dropDown}
      />
    )
    : (
      <BasicBar
        user={user}
        databases={databases}
        account={account}
        showingUserDropDown={showingUserDropDown}
        handleDropdownClick={this.handleDropdownClick}
        dropDown={this.dropDown}
        resetSpreadSheet={this.resetSpreadSheet}
      />
    ))

  render() {
    const { showingUserDropDown } = this.state;
    const { user, databases,account } = this.props; 
    const showAdminBar = (user.role.name === 'admin') && this.isOnAccountsOrManageMeTeamPage();
    const data = {
      showingUserDropDown,
      user,
      databases,
      account,
      showAdminBar,
    };
    return (
      <Media>
        {({ breakpoints, currentBreakpoint }) => {
          const wSize = breakpoints[currentBreakpoint];
          if (wSize < breakpoints.navigationBar) {
            return (
              <MobileNavigationBar
                user={user}
              />
            );
          }
          return (
            <Nav>
              {this.getDesktopMenu(data)}
            </Nav>
          );
        }}
      </Media>
    );
  }
}

const mapStateToProps = (store) => {
  const databases = _.map(store.AppReducer.user.markets, market => ({
    label: market.name,
    value: market.folderName,
  }));
  return {
    user: store.AppReducer.user,
    accountId: store.AppReducer.currentAccountId,
    account: store.AccountReducer.account.team,
    databases,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatchSpreadSheetReset: () => dispatch(SpreadSheetReset()),
  dispatchChartsReset: () => dispatch(resetSpreadSheetCharts()),
  dispatchCardsReset: () => dispatch(resetSpreadsheetCards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationBar));

const Nav = styled.nav`
  background: #263238;
  height: 60px;
  font-family: 'Roboto', sans-serif;
  color: #F6F7F8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-sizing: border-box;
`;


const Header = styled.div`
  font-size: 20px;
  min-width: 151px;
  .stowell {
    margin: 0;
    display: inline-block;
    font-family: 'OpenSansExtrabold';
  }
  .data {
    margin: 0;
    display: inline-block;
    font-family: 'OpenSansRegular';
  }
`;

const Menu = styled.ul`
  padding: 0px;
  padding-right: 30px;
  list-style: none;
  display: flex;
  align-items: center;
`;

const Item = styled.li`
  display: inline;
  margin-left: 34px;
  font-weight: 500;
  ${({ noWrap }) => (noWrap ? 'min-width: 110px;' : '')}
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: #B0BEC5;
  font-size: 16px;
  ${({ smalltext }) => (smalltext ? 'font-size: 13px;' : '')}
`;

const ALink = styled.a`
  text-decoration: none;
  color: #B0BEC5;
  font-size: 16px;
  ${({ smalltext }) => (smalltext ? 'font-size: 13px;' : '')}
`;

const RouterButton = styled.div`
  background-color: #19A2C5;
  width: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: none;
  border-radius: 2px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  p {
    margin: 0px;
  }
  &:hover{
      color: #F6F7F8;
  }
`;

const RightMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover{
    color: #B0BEC5;
  }
  div:first-child {
    margin-left: 30px;
    margin-right: 0px;
  }
  div {
    margin: 0px 10px;
  }
  div:last-child {
    margin: 0px;
  }
`;

const ProfileIcon = styled.div`
  width: 32px;
  height: 32px;
  background: #FF8000;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  font-size: 24px;
  text-align: center;
  border-radius: 2px;
`;

const Profile = styled.div`
  margin: 0;
  font-size: 13px;
  line-height: 20px;
`;

const Arrow = styled.div`
  color: #FFFFFF;
  &:hover {
    color: #FFFFFF;   
  }
`;

const AdminRightContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

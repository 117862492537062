import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Documents from '../DocumentManagement';
import Login from '../Login/Login';
import Overview from '../Overview';
import Profile from '../Profile/Profile';
import SpreadSheet from '../SpreadSheet/SpreadSheet';
import Support from '../Support/Support';
import NewUserForm from '../TeamManagement/NewUserForm';
import TeamManagement from '../TeamManagement/TeamManagemet';
import Authenticate from '../../helpers/Authenticate';
import DatabaseSelector from '../DatabaseSelector';
import PasswordRecovery from '../Login/PasswordRecovery/SendRecoveryEmailForm';
import ProjectManagement from '../ProjectManagement/ProjectManagement';
import RecoveryPasswordForm from '../Login/PasswordRecovery/RecoveryPasswordForm';
import AccountManagement from '../AccountManagement/AccountManagement';
import AddUser from '../AccountManagement/Account/AddAccount';
import ConfirmInvitation from './ConfirmInvitation';
import EditAccount from '../AccountManagement/Account/EditAccount';
import ErrorPage from './ErrorPage';


const privateRoutes = [
  {
    component: Overview,
    path: '/overview',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: NewUserForm,
    path: '/team/members/create',
    canAccess: {
      admin: true, owner: true, manager: true,
    },
  },
  {
    component: TeamManagement,
    path: '/team/members',
    isExact: true,
    canAccess: {
      admin: true, owner: true, manager: true,
    },
  },
  {
    component: Support,
    path: '/support',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: Profile,
    path: '/profile',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: Documents,
    path: '/documents',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: ProjectManagement,
    path: '/projects',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: ProjectManagement,
    path: '/document/new',
    canAccess: {
      admin: true, owner: true, manager: true,
    },
  },
  {
    component: SpreadSheet,
    path: '/spreadsheet',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: DatabaseSelector,
    path: '/database-selector',
    canAccess: {
      admin: true, owner: true, manager: true, basic: true,
    },
  },
  {
    component: AccountManagement,
    path: '/accounts',
    canAccess: {
      admin: true,
    },
  },
  {
    component: AddUser,
    path: '/account/new',
    canAccess: {
      admin: true,
    },
  },
  {
    component: EditAccount,
    path: '/account/edit/:Id',
    canAccess: {
      admin: true,
    },
  },
];

const RenderNoPermissionPage = () => <ErrorPage text="You do not have acces to this page" type='forbidden'/>;
const RenderNotFoundPage = () => <ErrorPage text="This page does not exist." />;

const PrivateRoutes = ({ user }) => (
  <React.Fragment>
    <Switch>
      {
        privateRoutes.map(({
          component, path, isExact, canAccess,
        }) => {
          if (user.role) {
            if (canAccess.hasOwnProperty(user.role.name)) {
              return <Route key={path} path={path} component={component} exact={isExact} />;
            }
          }
          return <Route key={path} exact={isExact} path={path} component={RenderNoPermissionPage} />;
        })
      }
      <Route exact path="/" component={ProjectManagement} />
      <Route component={RenderNotFoundPage} />
    </Switch>
  </React.Fragment>
);

const Main = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route exact path="/password-recovery" component={PasswordRecovery} />
    <Route path="/reset_password/:token" component={RecoveryPasswordForm} />
    <Route path="/confirm_invitation/:teamId/:userId" component={ConfirmInvitation} />
    <Route path="/" component={Authenticate(PrivateRoutes)} />
  </Switch>
);

export default Main;

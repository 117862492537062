import React from 'react';
import { connect } from 'react-redux';
import {
  addBlock,
  removeBlock,
  addQuestion,
  removeQuestion,
  addDataPoint,
  removeDataPoint,
  changeItemsOperator,
  addBasicQuestion,
  removeBasicQuestion,
  addBasicDataPoint,
  removeBasicDataPoint,
  setBanner,
  editAddBasicQuestion,
  editRemoveBasicQuestion,
  editRemoveBasicDataPoint,
  editAddBlock,
  editRemoveBlock,
  editAddQuestion,
  editRemoveQuestion,
  editAddDataPoint,
  editRemoveDataPoint,
  editChangeItemsOperator,
  setEditingTo,
  editAddBasicDataPoint,
} from '../../actions/bannerActions';
import {
  SpreadSheetAddBanner,
  SpreadSheetEditBanner,
} from '../../actions/spreadsheetActions';
import {
  loadCharts, addCharts, updateChart, setUsedBanner,
} from '../../actions/overviewChartActions';

const withActions = (WrappedComponent) => {
  class ModalActions extends React.Component {
    render() {
      const {
        addBasicQuestion,
        removeBasicQuestion,
        addBasicDataPoint,
        removeBasicDataPoint,
        addBlock,
        removeBlock,
        addQuestion,
        removeQuestion,
        addDataPoint,
        removeDataPoint,
        changeItemsOperator,
        setBanner,
        basicBuilder,
        advancedBuilder,
        addBanner,
        editBanner,
        currentTab,
        editAddBasicQuestion,
        editRemoveBasicQuestion,
        editAddBasicDataPoint,
        editRemoveBasicDataPoint,
        editAddBlock,
        editRemoveBlock,
        editAddQuestion,
        editRemoveQuestion,
        editAddDataPoint,
        editRemoveDataPoint,
        editChangeItemsOperator,
        chartsData,
        addCharts,
        updateChart,
        setUsedBanner,
      } = this.props;
      const actions = {
        basic: {
          question: { addQuestion: addBasicQuestion, removeQuestion: removeBasicQuestion },
          answer: { addDataPoint: addBasicDataPoint, removeDataPoint: removeBasicDataPoint },
        },
        advanced: {
          builder: { changeItemsOperator },
          block: { addBlock, removeBlock },
          question: { addQuestion, removeQuestion },
          answer: { addDataPoint, removeDataPoint },
        },
        banner: { setBanner },
        charts: {
          load: chartsData, add: addCharts, update: updateChart, setUsedBanner,
        },
        spreadsheet: { addBanner, editBanner },
        templates: {
          basic: {
            question: { addQuestion: editAddBasicQuestion, removeQuestion: editRemoveBasicQuestion },
            answer: { addDataPoint: editAddBasicDataPoint, removeDataPoint: editRemoveBasicDataPoint },
          },
          advanced: {
            builder: { changeItemsOperator: editChangeItemsOperator },
            block: { addBlock: editAddBlock, removeBlock: editRemoveBlock },
            question: { addQuestion: editAddQuestion, removeQuestion: editRemoveQuestion },
            answer: { addDataPoint: editAddDataPoint, removeDataPoint: editRemoveDataPoint },
          },
        },
      };
      const data = {
        builders: { basic: basicBuilder, advanced: advancedBuilder },
        currentTab,
      };
      return (
        <WrappedComponent
          {...this.props}
          actions={actions}
          data={data}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    basicBuilder: state.bannerReducer.basicBuilder,
    advancedBuilder: state.bannerReducer.advancedBuilder,
    currentTab: state.bannerReducer.currentTab,
  });

  const mapDispatchToProps = dispatch => ({
    addBasicQuestion: data => dispatch(addBasicQuestion(data)),
    removeBasicQuestion: data => dispatch(removeBasicQuestion(data)),
    addBasicDataPoint: (blockId, dataPoint) => dispatch(addBasicDataPoint(blockId, dataPoint)),
    removeBasicDataPoint: (blockId, dataPoint) => dispatch(removeBasicDataPoint(blockId, dataPoint)),
    editAddBasicQuestion: (data) => {
      dispatch(editAddBasicQuestion(data));
      dispatch(setEditingTo(true));
    },
    editRemoveBasicQuestion: (data) => {
      dispatch(editRemoveBasicQuestion(data));
      dispatch(setEditingTo(true));
    },
    editAddBasicDataPoint: (blockId, dataPoint) => {
      dispatch(editAddBasicDataPoint(blockId, dataPoint));
      dispatch(setEditingTo(true));
    },
    editRemoveBasicDataPoint: (blockId, dataPoint) => {
      dispatch(editRemoveBasicDataPoint(blockId, dataPoint));
      dispatch(setEditingTo(true));
    },
    addBlock: operator => dispatch(addBlock(operator)),
    removeBlock: data => dispatch(removeBlock(data)),
    addQuestion: data => dispatch(addQuestion(data)),
    removeQuestion: data => dispatch(removeQuestion(data)),
    addDataPoint: (blockId, dataPoint, path) => dispatch(addDataPoint(blockId, dataPoint, path)),
    removeDataPoint: (blockId, dataPoint) => dispatch(removeDataPoint(blockId, dataPoint)),
    changeItemsOperator: data => dispatch(changeItemsOperator(data)),
    editAddBlock: (operator) => {
      dispatch(editAddBlock(operator));
      dispatch(setEditingTo(true));
    },
    editRemoveBlock: (data) => {
      dispatch(editRemoveBlock(data));
      dispatch(setEditingTo(true));
    },
    editAddQuestion: (data) => {
      dispatch(editAddQuestion(data));
      dispatch(setEditingTo(true));
    },
    editRemoveQuestion: (data) => {
      dispatch(editRemoveQuestion(data));
      dispatch(setEditingTo(true));
    },
    editAddDataPoint: (blockId, dataPoint) => {
      dispatch(editAddDataPoint(blockId, dataPoint));
      dispatch(setEditingTo(true));
    },
    editRemoveDataPoint: (blockId, dataPoint) => {
      dispatch(editRemoveDataPoint(blockId, dataPoint));
      dispatch(setEditingTo(true));
    },
    editChangeItemsOperator: (data) => {
      dispatch(editChangeItemsOperator(data));
      dispatch(setEditingTo(true));
    },
    setBanner: (type, data) => dispatch(setBanner(type, data)),
    addBanner: () => dispatch(SpreadSheetAddBanner()),
    editBanner: () => dispatch(SpreadSheetEditBanner()),
    chartsData: () => dispatch(loadCharts()),
    addCharts: () => dispatch(addCharts()),
    updateChart: () => dispatch(updateChart()),
    setUsedBanner: banner => dispatch(setUsedBanner(banner)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(ModalActions);
};

export default withActions;

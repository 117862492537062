import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash/fp';
import __ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faFile } from '@fortawesome/free-solid-svg-icons';
import { ControlLabel } from '../../GlobalComponents/BasicControls';
import {
  AccountDetails, Contacts, DatabasesAndSurveys, Resources, Review,
} from './ContentParts';
import {
  createAccount,
  getAccountById,
  setAccountAction,
  EditAccountDetails,
  EditAccountContacts,
  uploadAndEditDatabases,
  deleteDatabaseFromLocalFiles,
  createUsersInEditing,
  deleteUserbyEmail,
  updateContactRole,
  joinContactToTeam,
  removeContact,
  editLogo,
} from '../../../actions/accountActions';
import SupportDocuments from './ContentParts/SupportDocuments';
import { Icon } from '../../GlobalComponents/DataTable/DropdownMenu';
import { CustomConfirmDialog } from '../../GlobalComponents/CustomConfirmDialog';
import { filesAccepted } from './filesAccepted';
import { getErrorMessage } from '../../../actions/appActions';
import showNotification from '../../../helpers/showNotification';

class EditAccountContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalDB: false,
      reviewing: false,
      showNewFormAdded: false,
      selectedItemNav: 6,
      account: {
        logoFile: {},
        storagedLogo: {
          name: '',
          url: '',
          deleted: false,
        },
        details: {
          name: '',
          city: '',
          state: '',
        },
        contact: {
          0: {
            firstName: '',
            lastName: '',
            email: '',
            phone1: '',
            phone2: '',
            role: 'owner',
          },
        },
        databaseForms: [
          {
            databaseName: 'Manager',
            accesses: ['Manager', 'Business Owner'],
          },
          {
            databaseName: 'Sales',
            accesses: ['User', 'Manager', 'Business Owner'],
          },
        ],
        databases: {
          edit: true,
          0: {
            name: '',
            database: '',
            folderName: '',
            storagedDatabases: [],
            _type: 1,
            survey: {
              currentStoraged: {},
              olderStoraged: [],
              current: '',
              older: '',
            },
          },
          1: {
            name: '',
            database: '',
            folderName: '',
            storagedDatabases: [],
            _type: 2,
            survey: {
              currentStoraged: {},
              olderStoraged: [],
              current: '',
              older: '',
            },
          },
        },
        support: {
          edit: false,
          coding: '',
          listing: '',
          distribution: '',
          methodology: '',
          descriptions: '',
        },
        resources: '',
      },
      error_result: true,
    };
    this.setState = this.setState.bind(this);
  }

  componentWillMount() {
    const { match, setAccount } = this.props;
    const { Id } = match.params;
    getAccountById(Id)
      .then((account) => {
        setAccount(account);
        this.setDBInfoFromProps(this.props);
      })
      .catch((err) => {
        const { message, type } = getErrorMessage(err);
        showNotification({ message, type });
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setDBInfoFromProps(nextProps);
  }

  updateParentDatabaseState = (dbInformation) => {
    const { account } = this.state;
    this.setState({
      account: {
        ...account,
        databases: {
          ...account.databases,
          ...dbInformation,
        },
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedItemNav, reviewing } = this.state;
    this.setState({
      selectedItemNav: !reviewing ? (selectedItemNav + 1) : 6,
    });
  }

  handleEditAccountDetails = (Id, data, e) => {
    e.preventDefault();
    const { EditAccountDetails } = this.props;
    return EditAccountDetails(Id, data);
  }

  handleEditAccountContacts = (Id, data, e, replaced) => {
    e.preventDefault();
    data.first_name = data.firstName;
    data.last_name = data.lastName;
    return this.props.EditAccountContacts(Id, data, replaced);
  }

  handleEditAccountContactsAltered = (Id, data, e, replaced) => {
    e.preventDefault();
    const accountData = this.props.account.team;
    accountData.contact = data;
    this.props.EditAccountContacts(Id, accountData, replaced);
  }

 handleNavItem = (e) => {
   const { value } = e.currentTarget;
   this.setState({
     selectedItemNav: parseInt(value, 10),
   });
 }

 getDocumentDescription = (key) => {
   let description = '';
   switch (key) {
     case 'descriptions':
       description = 'Descriptions of psychographic groups and guide to implementing them.';
       break;
     case 'methodology':
       description = 'Detailed information on how and when your studies were conducted.';
       break;
     case 'distribution':
       description = 'Population data used to determine county, gender, and race (if applicable) quotas.';
       break;
     case 'listing':
       description = 'Verbatim answers classified as “Other.”  Potentially a source for new leads.';
       break;
     case 'coding':
       description = 'Information on how Domestic/European/Asian Vehicle Make and White Collar/Blue Collar are defined.  Examples of the types of answers coded in Leisure Activities and Occupations questions.';
       break;
     default:
       break;
   }
   return description;
 }

 createAccount = () => {
   const {
     details, contact, databases, support,
   } = this.state.account;
   const { createAccount } = this.props;
   const newDatabases = _.values(databases);
   const data = {
     userParams: _.values(contact).map(user => ({
       first_name: user.firstName,
       last_name: user.lastName,
       email: user.email,
       phone1: user.phone1,
       phone2: user.phone2,
       role: user.role,
     })),
     teamParams: {
       name: details.name,
       city: details.city,
       state: details.state,
     },
     databases: _.compact(newDatabases.filter(value => value !== true)).map((databaseInformation) => {
       const { name, survey, database } = databaseInformation;
       return {
         name,
         database,
         _type: databaseInformation._type,
         current_survey: survey.current ? survey.current : '',
         older_survey: survey.older ? survey.older : '',

       };
     }),
     documents: _.compact(__.map(support, (value, key) => (key !== 'edit' && value) && {
       file: value,
       name: key,
       description: this.getDocumentDescription(key),
     })),
   };
   return createAccount(data);
 }

  getUserParams = users => users.map(user => ({
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    phone1: user.phone1,
    phone2: user.phone2,
    role: user.role,
  }))

  createUsersInEditing = (replaceExistingSecondContact, contactSelected) => {
    const {
      createUsersInEditing, match, EditAccountContacts, joinContactToTeam, removeContact, setAccount,
    } = this.props;
    const { account } = this.state;
    const {
      contact,
    } = account;
    const newContacts = _.values(contact).filter(d => d.creating && d.email && !d.joining);
    const contactsToUpdate = _.values(contact).filter(d => !d.creating && !d.joining);
    const contactsToJoin = _.values(contact).filter(d => !d.creating && d.joining);
    const dataToInsert = {
      userParams: this.getUserParams(newContacts),
    };
    const dataToUpdate = {
      userParams: this.getUserParams(contactsToUpdate),
    };
    const dataToJoin = {
      userParams: this.getUserParams(contactsToJoin),
    };

    const promises = [];
    const teamId = match.params.Id;
    if (newContacts.length !== 0) {
      promises.push(createUsersInEditing(dataToInsert, teamId));
    }
    if (contactsToUpdate.length !== 0) {
      promises.push(EditAccountContacts(dataToUpdate, teamId));
    }
    if (contactsToJoin.length !== 0) {
      promises.push(joinContactToTeam(dataToJoin, teamId));
    }
    if (replaceExistingSecondContact) {
      const data = {
        userId: contactSelected.id,
      };
      promises.push(removeContact(data, teamId));
    }
    return Promise.all(promises).then(() => getAccountById(teamId).then((account) => {
      setAccount(account);
    }));
  }

  deleteUserByEmailHandler = (email) => {
    const { match, deleteUserbyEmail } = this.props;
    const teamId = match.params.Id;
    return deleteUserbyEmail(email, teamId);
  }

 getFormForTab = () => {
   const { selectedItemNav, account, error_result } = this.state;
   const { updateContactRole, editLogo } = this.props;
   switch (selectedItemNav) {
     case 1:
       return (
         <AccountDetails
           history={this.props.history}
           setState={this.setState}
           state={account.details}
           content={this.state}
           handleSubmit={this.handleSubmit}
           handleEditAccountDetails={this.handleEditAccountDetails}
           type="edit"
           onCancelEditing={this.onCancelEditing}
           Id={this.props.match.params.Id}
         />
       );
     case 2:
       return (
         <Contacts
           setState={this.setState}
           state={account.contact}
           content={this.state}
           handleSubmit={this.handleSubmit}
           type="edit"
           handleEditAccountContacts={this.handleEditAccountContacts}
           handleEditAccountContactsAltered={this.handleEditAccountContactsAltered}
           onCancelEditing={this.onCancelEditing}
           createUsersInEditing={this.createUsersInEditing}
           deleteUserByEmailHandler={this.deleteUserByEmailHandler}
           updateContactRole={updateContactRole}
           team={this.props.account.team}
           Id={this.props.match.params.Id}
         />
       );
     case 3:
       return (
         <DatabasesAndSurveys
           updateParentDatabaseState={this.updateParentDatabaseState}
           setState={this.setState}
           uploadAndEditDatabases={this.props.uploadAndEditDatabases}
           state={account.databases}
           content={this.state}
           handleSubmit={this.handleSubmit}
           type="edit"
           Id={this.props.match.params.Id}
           deleteDatabaseFromLocalFiles={this.props.deleteDatabaseFromLocalFiles}
           onCancelEditing={this.onCancelEditing}
           validateDatabasesFilesRequired={this.validateDatabasesFilesRequired}
           toogleCustomConfirmDialog={this.toogleCustomConfirmDialog}
         />
       );
     case 4:
       return (
         <SupportDocuments
           setState={this.setState}
           state={account.support}
           content={this.state}
           handleSubmit={this.handleSubmit}
           teamId={this.props.match.params.Id}
           onCancelEditing={this.onCancelEditing}
           editLogoFile={editLogo}
           type="edit"
         />
       );
       case 5:
       return (
         <Resources
           history={this.props.history}
           setState={this.setState}
           state={account.resources}
           content={this.state}
           handleSubmit={this.handleSubmit}
           handleEditAccountDetails={this.handleEditAccountDetails}
           type="edit"
           onCancelEditing={this.onCancelEditing}
           Id={this.props.match.params.Id}
         />
       );
     case 6:
       return (
         <Review
           history={this.props.history}
           createAccount={this.createAccount}
           setState={this.setState}
           content={this.state.account}
           type="edit"
           error_result={error_result}
           updateMainTitle={this.props.updateMainTitle}
         />
       );
     default:
       break;
   }
 }

 updateMainTitle = (name) => {
   const { updateMainTitle } = this.props;
   updateMainTitle(name);
 }

 getLogoFromProps = (props) => {
   const { team } = props.account;
   const storagedLogo = {
     name: team.logo ? team.logo.name : '',
     url: team.logo ? team.logo.url : '',
     deleted: false,
   };
   return storagedLogo;
 }

 getContactInfoFromProps = (props) => {
   const { team } = props.account;
   const { account } = this.state;
   const contacts = [];
   const filteredContact = team.contact.filter(d => d._id);
   const ownerContact = filteredContact.filter(contact => contact.role.name === 'owner');
   const notOwnerContact = filteredContact.filter(contact => contact.role.name !== 'owner');
   if (ownerContact.length !== 0) {
     const {
       _id, first_name, last_name, email, phone1, phone2, role,
     } = ownerContact[0];
     contacts.push({
       id: _id,
       joining: false,
       creating: false,
       firstName: first_name,
       lastName: last_name,
       email,
       phone1,
       phone2,
       role: role.name,
       index: 0,
     });
   } else {
     contacts.push({
       joining: false,
       creating: true,
       firstName: '',
       lastName: '',
       email: '',
       phone1: '',
       phone2: '',
       role: 'owner',
       index: 0,
     });
   }

   if (notOwnerContact.length !== 0) {
     const {
       _id, first_name, last_name, email, phone1, phone2, role,
     } = notOwnerContact[0];
     contacts.push({
       id: _id,
       joining: false,
       creating: false,
       firstName: first_name,
       lastName: last_name,
       email,
       phone1,
       phone2,
       role: role.name,
       index: 1,
     });
   }

   const contactToAssingInState = __.keyBy(contacts, 'index');
   const contact = {
     ...account.contact,
     ...contactToAssingInState,
   };
   return contact;
 }

  getDatabaseInfoFromProps = (props) => {
    const dbInformation = props.account.databases;
    const { account } = this.state;
    let dbSrtucture = {
      edit: account.databases.edit,
    };
    for (let index = 0; index < dbInformation.length; index += 1) {
      const element = dbInformation[index];
      const {
        name, database, survey, folderName,
      } = element;
      let currentStoraged = null;
      let olderStoraged = [];
      if (survey) {
        if (_.size(survey) !== 0) {
          if (survey.current) {
            currentStoraged = {
              fileName: survey.current,
              deleted: false,
              new: false,
            };
          }
          olderStoraged = survey.older.map(d => ({
            fileName: d,
            deleted: false,
            new: false,
          }));
        }
      }

      dbSrtucture = {
        ...dbSrtucture,
        [index]: {
          name: !name ? '' : name,
          database: '',
          folderName: !folderName ? 'none' : folderName,
          storagedDatabases: !database ? [] : database.map(d => ({
            fileName: d,
            deleted: false,
            new: false,
          })),
          _type: element._type,
          survey: {
            currentStoraged,
            olderStoraged,
            current: '',
            older: '',
          },
        },
      };
    }

    const databaseForms = dbInformation.map((d, i) => (i === 0
      ? {
        databaseName: 'Manager',
        accesses: ['Manager', 'Business Owner'],
      }
      : {
        databaseName: 'Sales',
        accesses: ['User', 'Manager', 'Business Owner'],
      }));

    return {
      databaseForms,
      databases: dbSrtucture,
    };
  }

 getDocumentsInfoFromProps = (props) => {
   const { team } = props.account;
   const support = {
     edit: false,
     coding: team.documents.find(x => x.name === 'coding'),
     listing: team.documents.find(x => x.name === 'listing'),
     distribution: team.documents.find(x => x.name === 'distribution'),
     methodology: team.documents.find(x => x.name === 'methodology'),
     descriptions: team.documents.find(x => x.name === 'descriptions'),
   };

   return support;
 }

 getAccountDetailsFromProps = (props) => {
   const { team } = props.account;
   const details = {
     name: team.name,
     city: team.city,
     state: team.state,
   };
   return details;
 }

 setDBInfoFromProps = (props) => {
   const { team } = props.account;
   this.updateMainTitle(team.name);
   const { databaseForms, databases } = this.getDatabaseInfoFromProps(props);
   const contact = this.getContactInfoFromProps(props);
   const support = this.getDocumentsInfoFromProps(props);
   const details = this.getAccountDetailsFromProps(props);
   const storagedLogo = this.getLogoFromProps(props);
   const { account } = this.state;
   this.setState({
     account: {
       ...account,
       storagedLogo,
       details,
       contact,
       databaseForms,
       databases,
       support,
       resources: team.resources,
     },
     error_result: false,
   });
 }

 onCancelEditing = () => {
   this.setDBInfoFromProps(this.props);
   this.setState({
     selectedItemNav: 6,
   });
 }

  goBack = () => {
    const { history } = this.props;
    history.push('/accounts');
  }

  validateDatabasesFilesRequired = () => {
    const allDatabases = [];
    const { account } = this.state;
    const databases = __.values(account.databases);
    const selectedDatabases = _.compact(databases).filter(d => d.name && d.storagedDatabases.length !== 0);
    if (selectedDatabases.length !== 0) {
      const filteredDB = selectedDatabases.map(d => d.storagedDatabases.filter(data => !data.deleted));
      const AllDBSelected = filteredDB.map(d => d.map((data) => {
        if (data.fileName.name) {
          return data.fileName.name;
        }
        return data.fileName;
      }));
      AllDBSelected.forEach((value) => {
        allDatabases.push({
          databases: value,
        });
      });
    }
    let canContinue = true;
    if (allDatabases.length !== 0) {
      allDatabases.forEach((data) => {
        if (canContinue) {
          let equalDB = 0;
          data.databases.forEach((fileAdded) => {
            if (filesAccepted.includes(fileAdded)) {
              equalDB += 1;
            }
          });
          if (equalDB !== filesAccepted.length) {
            canContinue = false;
          }
        }
      });
    }
    return canContinue;
  }

  toogleCustomConfirmDialog = () => {
    const { openModalDB } = this.state;
    this.setState({
      openModalDB: !openModalDB,
    });
  }

  render() {
    const { openModalDB } = this.state;
    return (
      <ContentBody>
        <Header>
          <LinkLabel
            onClick={this.goBack}
          >
            <Icon
              icon={faAngleLeft}
              right={8}
            />
            Back to Accounts
          </LinkLabel>
        </Header>
        <Container>
          {this.getFormForTab()}
        </Container>
        <CustomConfirmDialog
          onConfirmButtonText="Accept"
          show={openModalDB}
          title="Warning!"
          message="Please, make sure to include the following in databases files:"
          onConfirm={this.toogleCustomConfirmDialog}
          onCancel={this.toogleCustomConfirmDialog}
        >
          {
            filesAccepted.map((value, index) => (
              <Paragraph key={index}>
                <IconFile icon={faFile} />
                {' '}
                {value}
              </Paragraph>
            ))
          }
        </CustomConfirmDialog>
      </ContentBody>
    );
  }
}

const IconFile = styled(FontAwesomeIcon)`
  color: rgba(0,0,0,0.3);
  margin-right: 5px;
`;

const Paragraph = styled(ControlLabel)`
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  padding-bottom: 2px;
`;

const Header = styled.div`
  width: 98%;
  height: 25px;
  padding: 10px 0 0 2%;
`;

const LinkLabel = styled.button`
  font-family: 'Roboto';
  color: rgba(45, 76, 186, 1);
  font-size: 13px;
  font-weight: 400;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;

const ContentBody = styled.div`
  width: 100%;
`;

const Container = styled.div`
  display: inline-block;
  width: 84%;
  padding: 20px 0 25px 8%;
  ${''}

  .header {
    &__nav-list {
      ${''}
      width: 100%;
      ${''}
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      padding: 0px;

      li {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const mapStateToProps = state => ({
  account: state.AccountReducer.account,
});

const mapDispatchToProps = dispatch => ({
  createAccount: data => dispatch(createAccount(data)),
  setAccount: data => dispatch(setAccountAction(data)),
  uploadAndEditDatabases: (data, teamId, teamName) => dispatch(uploadAndEditDatabases(data, teamId, teamName)),
  EditAccountDetails: (Id, data, replaced) => dispatch(EditAccountDetails(Id, data, replaced)),
  EditAccountContacts: (data, teamId) => dispatch(EditAccountContacts(data, teamId)),
  deleteDatabaseFromLocalFiles: (teamId, data) => dispatch(deleteDatabaseFromLocalFiles(teamId, data)),
  createUsersInEditing: (data, teamId) => dispatch(createUsersInEditing(data, teamId)),
  joinContactToTeam: (data, teamId) => dispatch(joinContactToTeam(data, teamId)),
  deleteUserbyEmail: (email, teamId) => dispatch(deleteUserbyEmail(email, teamId)),
  updateContactRole: (data, teamId) => dispatch(updateContactRole(data, teamId)),
  removeContact: (data, teamId) => removeContact(data, teamId),
  editLogo: (file, teamId) => editLogo(file, teamId),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditAccountContent));

import React, { Component } from 'react';
import styled from 'styled-components';
import SelectOperations from './SelectOperations';
import Target from './Target';
import Browser from '../Browser';
import CloseIcon from './CloseIcon';

class Builder extends Component {
  removeBlock = (blockId) => {
    const { removeBlock } = this.props.actions.block;
    const payload = { blockId };
    removeBlock(payload);
  }

  render() {
    const {
      actions,
      activeTree,
      handleActiveTree,
      toggleShowTree,
      showTree,
      block,
      padding,
    } = this.props;
    return (
      <Container padding={padding}>
        <Operations>
          <Text>{block.operator && <Operator>{`${block.operator}, `}</Operator>}</Text>
          <SelectOperations itemsOperator={block.itemsOperator} blockId={block.id} actions={actions.builder} />
          <Text> &nbsp;of the following criteria:</Text>
          {block.id !== 0 && <CloseIcon onClick={() => this.removeBlock(block.id)} />}
        </Operations>
        <Wrapper>
          <Target
            block={block}
            actions={actions}
            handleActiveTree={handleActiveTree}
            toggleShowTree={toggleShowTree}
          />
          <Browser
            blockId={block.id}
            actions={actions}
            activeTree={activeTree}
            showTree={showTree}
            handleActiveTree={handleActiveTree}
          />
        </Wrapper>
      </Container>
    );
  }
}

const Container = styled.div`
  ${({ padding }) => `padding-left: ${padding}px`}
`;

const Operations = styled.div`
  position: relative;
  &:hover {
    div {
      display: block;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  background: white;
`;

const Text = styled.p`
  display: inline-block;
  font-family: 'Roboto';
  font-weight: bold;
  color: #37474F;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
`;

const Operator = styled.span`
display: inline-block;
font-family: 'Roboto';
font-weight: bold;
font-size: 1em;
margin-right: 5px;
`;

export default Builder;

import React, { Component } from 'react';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu } from '@material-ui/core';

export class SubChildMenu extends Component {
  state = {
    anchorEl: null,
  }

  handleClick = (event) => {
    const { anchorEl } = this.state;
    const newAnchorEl = anchorEl ? null : event.currentTarget;
    this.setState({
      anchorEl: newAnchorEl,
    }, () => {
      const { handleCloseHandler, subMenuStateHandler } = this.props;
      subMenuStateHandler(newAnchorEl);
      if (anchorEl) {
        handleCloseHandler();
      }
    });
  };

  handleClose = () => this.setState({ anchorEl: null }, () => {
    const { subMenuStateHandler } = this.props;
    subMenuStateHandler(null);
  })

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const { item } = this.props;
    const FontAwesomeIconStyle = {
      width: '15px',
      height: '15px',
      margin: '-1px 5px 0 5px',
      color: 'rgba(0,0,0,0.4)',
    };
    return (
      <MenuItem
        onClick={this.handleClick}
        style={{
          fontSize: '0.85em',
        }}
      >
        {
          item.icon && (
            item.imageIcon
              ? (
                <ImageIcon src={item.icon} alt="" />
              )
              : (
                <FontAwesomeIcon
                  icon={item.icon}
                  style={FontAwesomeIconStyle}
                />
              )
          )
        }
        {item.text}
        {
          item.items
          && (
            <FontAwesomeIcon
              icon={faChevronRight}
              style={FontAwesomeIconStyle}
            />
          )
        }
        <Menu
          id="child-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          style={{
            // backgroundColor: 'red',
          }}
        >
          {
            item.items.map((option, i) => (
              <MenuItem
                style={{
                  fontSize: '13px',
                }}
                key={i}
                onClick={() => option.action()}
              >
                {
                 option.icon && (
                   option.imageIcon
                     ? (
                       <ImageIcon src={option.icon} alt="" />
                     )
                     : (
                       <FontAwesomeIcon
                         icon={option.icon}
                         style={FontAwesomeIconStyle}
                       />
                     )
                 )
                }
                {option.text}
              </MenuItem>
            ))
          }
        </Menu>
      </MenuItem>
    );
  }
}

const ImageIcon = styled.img`
  padding: 0 8px 0 0;
  width: 18px !important;
  height: 18px !important;
`;

import { cloneDeep } from 'lodash';
import {
  ADD_OVERVIEW_CARDS,
  ADD_OVERVIEW_CARD,
  DELETE_OVERVIEW_CARD,
  CHANGE_OVERVIEW_CARD_TYPE,
  CHANGE_OVERVIEW_CARD_UNIT,
  CHANGE_OVERVIEW_CARD_TARGET,
  CHANGE_OVERVIEW_ALL_CARD_TARGET,
} from '../actionTypes/overviewCardTypes';

const defaultState = {
  cards: {},
};

const initialCardState = {
  chartType: 'column',
  unit: 'percentages',
  target: 0,
};

const cardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_OVERVIEW_CARD:
      return { ...state, cards: { ...state.cards, [action.payload]: initialCardState } };
    case DELETE_OVERVIEW_CARD:
      return deleteCard(state, action.payload);
    case CHANGE_OVERVIEW_CARD_TYPE:
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload.id]: {
            ...state.cards[action.payload.id],
            chartType: action.payload.type,
          },
        },
      };
    case CHANGE_OVERVIEW_CARD_UNIT:
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload.id]: {
            ...state.cards[action.payload.id],
            unit: action.payload.unit,
          },
        },
      };
    case CHANGE_OVERVIEW_CARD_TARGET:
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload.id]: {
            ...state.cards[action.payload.id],
            target: action.payload.target,
          },
        },
      };
    case ADD_OVERVIEW_CARDS:
      return {
        ...state,
        cards: action.payload,
      };
    case CHANGE_OVERVIEW_ALL_CARD_TARGET:
      return {
        ...state,
        cards: updateAllCardTarget(state, action),
      };
    default:
      return state;
  }
};

function updateAllCardTarget(state, action) {
  const  cards  = cloneDeep(state.cards);
  Object.keys(cards).forEach((i) => {
    cards[i] = {
      ...cards[i],
      target: cards[i].chartType === 'pie' ? 1 : action.payload.target,
    };
  },
  );
  return cards;
}
function deleteCard(state, id) {
  const newState = cloneDeep(state);
  delete newState.cards[id];
  return newState;
}

export default cardReducer;
